
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-legal-notice-dialog mail-dialog">
    <div class="mail__dialog-header" layout="row center-center">
        <div>
            <button id="mobile_sidebar_about_legal_backbtn" (click)="close()" class="brand-color mat-button show-in-mobile">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div id="mobile_sidebar_about_legal_header" class="header-title ">
            <span class="disable-select">{{ 'LEGAL_NOTICE' | translate }}</span>
        </div>
    </div>
  <div class="mail__dialog-body">
      <mat-list>
        <mat-list-item id="mobile_sidebar_about_legal_terms">
            <mat-icon class="disable-select">assignment</mat-icon>
            <a target="_blank" class="open-new-window disable-select non-decoration" href="{{termsOfUse_en}}">{{ 'TERMS_OF_USE' | translate }}</a>
        </mat-list-item>
        <mat-list-item id="mobile_sidebar_about_legal_data_privacy">
          <mat-icon class="disable-select">lock</mat-icon>
          <a target="_blank" class="open-new-window disable-select non-decoration" href="{{dataPrivacy_en}}">
            {{ 'DATA_PRIVACY' | translate }}
          </a>
        </mat-list-item>
      </mat-list>
    </div>
</div>
