
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { Router } from "@angular/router";
import { PreferenceService } from "../../../preference/shared/services/preference.service";
import { ConfigService } from "src/app/config.service";
import { MobileAboutDialogComponent } from "../mobile-about-dialog/mobile-about-dialog.component";
import { GeneralSettingsDialogComponent } from "../general-settings-dialog/general-settings-dialog.component";
import { AvatarDialogComponent } from "../avatar-dialog/avatar-dialog.component";
import { AppearanceDialogComponent } from "../appearance-dialog/appearance-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-preferences-dialog-component",
  templateUrl: "./preferences.component.html"
})
export class PreferencesDialogComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  preferenceTitle = "PREFERENCES.SETTINGS";
  constructor(
    private router: Router,
    private preferenceService: PreferenceService,
    private config: ConfigService,
    private matDialog: MatDialog
  ) {
    this.preferenceService.setPreferenceTitle(this.preferenceTitle);
  }

  navigate(url) {
    this.router.navigate(["/preferences", url]);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }
  aboutDialog(): void {
    this.matDialog.open(MobileAboutDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  openGeneralSettingsDialog(): void {
    this.matDialog.open(GeneralSettingsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar-general-settings"
    });
  }

  openProfileDialog() {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      panelClass: "mail__dialog",
    };
    this.matDialog.open(AvatarDialogComponent, dialogArgs);
  }

  openAppearanceDialog(): void {
    this.matDialog.open(AppearanceDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar-appearance-dialog"
    });
  }

}
