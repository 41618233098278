
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PreferenceService } from "../shared/services/preference.service";
import { ActivatedRoute } from "@angular/router";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Preference } from "../shared/models";
import { takeUntil, filter, take } from "rxjs/operators";
import { Subject } from "rxjs";
import * as _ from "lodash";
import { PreferenceRepository } from "../repositories/preference.repository";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";

const DEFAULT_PREFS = [
    "zimbraPrefCalendarReminderEmail"
];

@Component({
    selector: "vp-preference-notification",
    templateUrl: "./notifications.component.html"
})
export class NotificationsComponent implements OnInit, OnDestroy {

    preferenceTitle = "NOTIFICATIONS_PREFERENCES";
    notificationForm: FormGroup;
    preference: any = {};
    private isAlive$ = new Subject<boolean>();
    valueChanges$: any;
    originalValue: string = "";
    constructor(
        private preferenceService: PreferenceService,
        private activatedRoute: ActivatedRoute,
        private broadCaster: Broadcaster,
        private ngxHotKeyService: NgxHotkeysService,
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private preferenceRepo: PreferenceRepository,
    ) {
        this.activatedRoute.paramMap.subscribe(res => {
            setTimeout(() => {
                this.broadCaster.broadcast(BroadcastKeys.OPEN_PREFERENCE_TAB, "notifications");
            }, 50);
        });
        this.preferenceService.setPreferenceTitle(this.preferenceTitle);
        const form = {};
        for (const key of DEFAULT_PREFS) {
            if (key === "zimbraPrefCalendarReminderEmail") {
                form[key] = ["", Validators.email];
            } else {
                form[key] = [""];
            }
        }
        this.notificationForm = this.fb.group(form);
        this.preferenceService.onSaveChanges().pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.savePreferences();
        });
    }

    ngOnInit() {
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
        this.preferenceService.getPreferences().pipe(take(1)).subscribe(res => {
            DEFAULT_PREFS.forEach(key => {
                const preference: Preference = _.find(res, { key: key });
                const value = preference ? preference.value : "";
                this.preference[key] = value;
                this.originalValue = value;
                this.notificationForm.patchValue(this.preference);
            });
            if (!this.valueChanges$) {
                this.valueChanges$ = this.notificationForm.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(changes => {
                    this.preferenceService.setPreferenceChanges(true);
                });
            }
        });
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    cancelPreferences(): void {
        this.preferenceService.navigateTo("PREFERENCES_LBL");
    }

    savePreferences(): void {
        const changes = this.getChangesValue().filter(f => f.key.startsWith("zimbraPref"));
        if (changes.length > 0) {
            this.preferenceService.modifyPrefs(changes).subscribe(res => {
                this.preferenceRepo.updatePreferences(changes);
                this.preferenceService.showMessage("PREFERENCES_SAVED");
                this.preferenceService.navigateTo();
            });
        }
    }

    private getChangesValue(): Preference[] {
        let preferences: Preference[] = [];
        DEFAULT_PREFS.forEach(key => {
            if (!_.isEqual(this.preference[key], this.notificationForm.value[key])) {
                let value = this.notificationForm.value[key];
                if (typeof value === "boolean") {
                    value = value ? "TRUE" : "FALSE";
                }
                preferences = [...preferences, ...[{ key, value }]];
            }
        });
        return preferences;
    }

    undoChange(): void {
        this.notificationForm.controls["zimbraPrefCalendarReminderEmail"].setValue(this.originalValue);
        this.preferenceService.showMessage("PREFERENCES.PAGE_REVERTED");
        this.changeDetectorRef.markForCheck();
    }
}
