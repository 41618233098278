
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy, NgZone } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { TranslateService } from "@ngx-translate/core";
import { MailOperations } from "../../common/enums/mail-enum";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-calendar-color-control-dialog",
  templateUrl: "./color-control-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalendarColorControlDialogComponent implements OnDestroy {

  private isAlive$ = new Subject<boolean>();
  mailOperations = MailOperations;
  existingColor: any = "black";

  constructor(
    private dialogRef: MatDialogRef<CalendarColorControlDialogComponent>,
    private changeDetection: ChangeDetectorRef,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.folderColor) {
      this.existingColor = data.folderColor;
    }
    this.changeDetection.markForCheck();
  }

  handleColorChange(event) {
    this.existingColor = event.color.hex;
  }

  @HostListener("keydown.esc")
  public hideDialog(): void {
    this.dialogRef.close({ selectedColor: null });
  }

  changeColor() {
    this.dialogRef.close({ selectedColor: this.existingColor });
  }

  close(): void {
    this.dialogRef.close({ selectedColor: null });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }
}
