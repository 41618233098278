
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { DirectoryTag } from "../common/models/directory-tag.model";
import { DirectoryTagActionTypes } from "../actions/directory-tag.action";


export interface DirectoryTagState extends EntityState<DirectoryTag> {
  selectedDirectoryTag: DirectoryTag;
  isLoading: boolean;
  isLoaded: boolean;
}

export const directoryTagAdapter: EntityAdapter<DirectoryTag> = createEntityAdapter<DirectoryTag>({
  selectId: (directoryTag: DirectoryTag) => directoryTag.id
});

export const initialState: DirectoryTagState = directoryTagAdapter.getInitialState({
  selectedDirectoryTag: null,
  isLoading: false,
  isLoaded: false
});

export function DirectoryTagReducer(state = initialState, action: Action): DirectoryTagState {
  switch (action.type) {
    case DirectoryTagActionTypes.SELECT_DIRECTORY_TAG: {
      return {
        ...state,
       selectedDirectoryTag: action.payload
      };
    }

    case DirectoryTagActionTypes.LOAD_DIRECTORY_TAGS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case DirectoryTagActionTypes.LOAD_DIRECTORY_TAGS_SUCCESS: {
      return directoryTagAdapter.addAll(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case DirectoryTagActionTypes.LOAD_DIRECTORY_TAGS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case DirectoryTagActionTypes.CREATE_DIRECTORY_TAG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case DirectoryTagActionTypes.LOAD_MORE_DIRECTORY_TAGS_SUCCESS: {
      return directoryTagAdapter.addMany(action.payload, {
        ...state,
        isloading: false,
        isLoaded: true
      });
    }

    case DirectoryTagActionTypes.CREATE_DIRECTORY_TAG_SUCCESS: {
      return directoryTagAdapter.addOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case DirectoryTagActionTypes.CREATE_DIRECTORY_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case DirectoryTagActionTypes.RESET_DIRECTORY_TAG_STATE: {
      return directoryTagAdapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedDirectoryTag = (state: DirectoryTagState) => state.selectedDirectoryTag;
export const _getDirectoryIsLoading = (state: DirectoryTagState) => state.isLoading;
export const _getDirectoryIsLoaded = (state: DirectoryTagState) => state.isLoaded;
