
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PreferenceRepository } from "../repositories/preference.repository";
import { PreferenceService } from "../shared/services/preference.service";
import { Subject } from "rxjs";
import { filter, takeUntil, take } from "rxjs/operators";
import * as _ from "lodash";
import { Preference } from "../shared/models";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { CalendarFolder } from "src/app/common/models/calendar.model";
import { CalendarState } from "src/app/calendar/store/reducers";
import { Store } from "@ngrx/store";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { getCalendarFolders } from "src/app/calendar/store/selectors";
import { CalendarConstants } from "src/app/common/utils/calendar-constants";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common.service";

const AVAILABLE_KEYS = [
    "zimbraPrefCalendarUseQuickAdd",
    "zimbraPrefUseTimeZoneListInCalendar",
    "zimbraPrefDefaultCalendarId"
];

@Component({
    selector: "vp-create-appointment-preference",
    templateUrl: "./create-appointment-setting.component.html"
})
export class CreateAppointmentSettingComponent implements OnInit, OnDestroy {

    preferenceTitle = "PREFERENCES.CREATING_APPOINTMENTS";
    preferenceSettingsForm: FormGroup;
    private isAlive$ = new Subject<boolean>();
    private preference = {};
    private valueChanges$;
    calendarFolders: CalendarFolder[] = [];
    allFolders: CalendarFolder[] = [];
    calendarFolder: CalendarFolder;
    mailServerVersion: string = "8.6";
    isShowDefaultCalendar: boolean = false;
    allPreferences: Preference[] = [];

    constructor(
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private preferenceRepo: PreferenceRepository,
        private preferenceService: PreferenceService,
        private activatedRoute: ActivatedRoute,
        private broadCaster: Broadcaster,
        private ngxHotKeyService: NgxHotkeysService,
        private store: Store<CalendarState>,
        private translateService: TranslateService,
        private commonService: CommonService
    ) {
        this.getMailServerVersion();
        this.activatedRoute.paramMap.subscribe(res => {
            setTimeout(() => {
                this.broadCaster.broadcast(BroadcastKeys.OPEN_PREFERENCE_TAB, "create-appointment-setting");
            }, 50);
        });
        const generalForm = {};
        for (const key of AVAILABLE_KEYS) {
            generalForm[key] = [""];
        }
        this.preferenceSettingsForm = this.fb.group(generalForm);
        this.preferenceService.setPreferenceTitle(this.preferenceTitle);
        this.preferenceService.onSaveChanges().pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.savePreferences();
        });
        this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res !== null) {
                if (!!res && res !== null) {
                    this.calendarFolders = res;
                    this.allFolders = this.getCalendarFolders(this.calendarFolders);
                    this.calendarFolders = this.calendarFolders.filter(f => f.id !== "3");
                    this.calendarFolders.map(f => {
                        if (f.perm) {
                            this.calendarFolders.splice(this.calendarFolders.indexOf(f), 1);
                        }
                        const childFolders = CalenderUtils.getChildFolders([f]);
                        if (childFolders.length > 0) {
                            childFolders.map(fp => {
                                this.calendarFolders.push(fp);
                                if (fp.perm) {
                                    this.calendarFolders.splice(this.calendarFolders.indexOf(fp), 1);
                                }
                            });
                        }
                    });
                    this.changeDetectorRef.markForCheck();
                }
            }
        });
    }

    ngOnInit() {
        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), takeUntil(this.isAlive$))
            .subscribe(preferences => {
                this.allPreferences = preferences;
                this.bindFormData(preferences);
                console.log("[getPreferences]", this.preferenceSettingsForm.value, this.preference);
                this.changeDetectorRef.markForCheck();
                if (!this.valueChanges$) {
                    this.valueChanges$ = this.preferenceSettingsForm.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(changes => {
                        this.preferenceService.setPreferenceChanges(true);
                    });
                }
            });
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
    }

    cancelPreferences(): void {
        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), take(1)).subscribe(preferences => {
                this.bindFormData(preferences);
                this.preferenceService.navigateTo("PREFERENCES_LBL");
                this.changeDetectorRef.markForCheck();
            });
    }

    private getChangesValue(): Preference[] {
        let preferences: Preference[] = [];
        AVAILABLE_KEYS.forEach(key => {
            if (!_.isEqual(this.preference[key], this.preferenceSettingsForm.value[key])) {
                let value = this.preferenceSettingsForm.value[key];
                if (typeof value === "boolean") {
                    value = value ? "TRUE" : "FALSE";
                }
                preferences = [...preferences, ...[{ key, value }]];
            }
        });
        return preferences;
    }

    savePreferences(): void {
        const changes = this.getChangesValue();
        if (!this.isShowDefaultCalendar) {
            for (let i = 0; i < changes.length ; i++) {
                if (changes[i].key === "zimbraPrefDefaultCalendarId") {
                  changes.splice(i, 1);
                }
              }
        }
        if (changes.length > 0) {
            this.preferenceService.modifyPrefs(changes).subscribe(res => {
                this.preferenceRepo.updatePreferences(changes);
                this.preferenceService.showMessage("PREFERENCES_SAVED");
                this.broadCaster.broadcast(BroadcastKeys.HIDE_MOBILE_PREFERENCE_SAVE_FOOTER);
                this.preferenceService.navigateTo();
            });
        }
    }

    private bindFormData(preferences: Preference[]): void {
        AVAILABLE_KEYS.forEach(key => {
            const preference: Preference = _.find(preferences, { key: key });
            if (preference) {
                let value: any = preference.value;
                if (value === "TRUE") {
                    value = true;
                } else if (value === "FALSE") {
                    value = false;
                }
                if (preference.key === "zimbraPrefDefaultCalendarId") {
                    const folder = this.allFolders.filter(f => f.id === value);
                    this.calendarFolder = folder[0];
                }
                this.preference[key] = value;
                this.preferenceSettingsForm.get(key).setValue(value);
            }
        });
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    undoChange(): void {
        this.bindFormData(this.allPreferences);
        this.preferenceService.showMessage("PREFERENCES.PAGE_REVERTED");
    }

    getCalendarFolders(folders: CalendarFolder[]): CalendarFolder[] {
        const foldersWithoutTrash: CalendarFolder[] = [];
        folders.forEach((folder: CalendarFolder) => {
            if (folder.id !== "3" && folder.name !== "Trash" && !folder.url) {
                foldersWithoutTrash.push(folder);
                if (folder.perm) {
                    foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
                }
                const childFolders = CalenderUtils.getChildFolders([folder]);
                if (childFolders.length > 0) {
                    childFolders.map(f => {
                        foldersWithoutTrash.push(f);
                        if (folder.perm) {
                            foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
                        }
                    });
                }
            }
        });
        return foldersWithoutTrash;
    }

    getFolderNameById(folderId: string): string {
        const fld = [...this.calendarFolders, ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let folderName: string = "";
        if (!!folderId) {
            fld.map(f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            folderName = f.name;
                        }
                    } else {
                        if (f.id === folderId) {
                            folderName = f.name;
                        }
                    }
                } else {
                    if (f.id === folderId) {
                        folderName = f.name;
                    }
                }
            });
        }
        return folderName;
    }

    changeFolder(folder: CalendarFolder): void {
        this.calendarFolder = folder;
        this.preferenceSettingsForm.get("zimbraPrefDefaultCalendarId").setValue(folder.id);
    }

    getFolderNameKey(name): string {
        if (!!name) {
            const key = name.toUpperCase() + "_FOLDER";
            let folderName = name;
            if (CalendarConstants.SYSTEM_FOLDERS.indexOf(key) !== -1) {
                this.translateService.get("CALENDARS." + key).pipe(take(1)).subscribe(text => {
                    folderName = text;
                });
            }
            return folderName;
        }
        return "";
    }

    private getMailServerVersion(): void {
        this.commonService.getZimbraVersion().pipe(take(1)).subscribe(res => {
          this.mailServerVersion = res.GetVersionInfoResponse ? res.GetVersionInfoResponse[0].info[0].version : this.mailServerVersion;
          const version = this.mailServerVersion.substr(0, 3);
          if (+version >= 8.8) {
            this.isShowDefaultCalendar = true;
          } else {
            this.isShowDefaultCalendar = false;
          }
          this.changeDetectorRef.markForCheck();
        });
    }

}
