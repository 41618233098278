
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-avatar-cropper-dialog mail-dialog">
    <div class="mail__dialog-header" layout="row center-center ">
        <div>
            <button (click)="close()" class="brand-color mat-button show-in-mobile">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
            <button class="brand-color mat-button hide-in-mobile">
                <mat-icon class="disable-select">photo_camera</mat-icon>
            </button>
        </div>
        <div class="header-title ">
            <span class="disable-select">{{ 'PROFILE_PHOTO' | translate }}</span>
        </div>
        <div class= "desktop-close hide-in-mobile">
            <button class="brand-color mat-button" (click) = "close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="row">
            <div class="user-profile__upload-avatar">
                <div id="cropper-image" class="cropper-image" [ngStyle]="{ display: avatarURL === null ? 'block': 'none' }">
                    <img-cropper #cropper [image]="imageData" [settings]="cropperSettings"></img-cropper>
                </div>
                <img draggable="false" class="preview-image-item" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (error)="imgLoadOnError()">
                <div class="file-upload disable-select">
                    <input id="custom-file-input" style="display:none" #cropFileChooser type="file" class="hide" (click)="cropFileChooser.value = null;"
                        (change)="fileChangeListener($event)" accept="image/*">
                    <button class="upload-text-button disable-select"
                        id="cropperButton" (click)="cropFileChooser.click()">{{'UPLOAD'|translate}}</button>
                    <button class="upload-text-button disable-select" *ngIf="avatarURL !== null" style="margin-left: 5px;"
                        id="cropperButton" (click)="removeUserAvatar()">{{'REMOVE'| translate}}</button>
                    <!-- <button class="upload-text-button disable-select" *ngIf="(userProfile && userProfile.imageData)"
                        id="cropperButton" (click)="cropFileChooser.click()">{{'CHANGE'|translate}}</button> -->
                </div>
                <!-- <div class="file-upload disable-select" *ngIf="(userProfile && userProfile.imageData)">
                    <button class="remove-text-button disable-select" id="cropperButton" (click)="removeUserAvtar()">{{'REMOVE'|translate}}</button>
                </div> -->
            </div>
        </div>
        <div class="save-section disable-select">
            <button id="save_crop_image_btn" class="save_crop_image_btn item-gt-visibility disable-select" (click)="saveCropperImge()"
                [disabled]="!isFileUploded" [class.disabled] = "!isFileUploded">{{'SAVE' | translate}}</button>
        </div>
    </div>
  </div>
  <div class="action-notifier disable-select" *ngIf="isFileUploded">
    <div class="notifiler-title disable-select">
        {{ 'SAVE_PROFILE_PHOTO' | translate }}
    </div>
    <vp-mail-operation-button (click)="saveCropperImge()" [iconType]="globalOperationIcon" *ngIf="(isMobileView || isHandsetLandscape) && isFileUploded"></vp-mail-operation-button>
</div>
