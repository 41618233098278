
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="mobile-header">
        <div>
            <button mat-icon-button aria-label="icon-button" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="header-lbl">
            {{ 'CALENDARS.APPOINTMENT_PREVIEW_LBL' | translate }}
        </div>
        <div>
        </div>
    </div>
    <!-- Desktop View -->
    <div class="desktop">
        <div class="header">
            <div class="header-text">
                {{ 'CALENDARS.APPOINTMENT_PREVIEW_LBL' | translate }}
            </div>
            <div class="header-operation">
                <button mat-icon-button aria-label="icon-button" (click)="close()">
                    <mat-icon class="close">close</mat-icon>
                </button>
            </div>
        </div>
        <mat-divider class="divider-top"></mat-divider>
        <div class="content">
            <div class="item">
                <div class="icon">
                    <mat-icon>title</mat-icon>
                </div>
                <div class="item-content font-size-16">
                    {{appointment.name}}
                </div>
            </div>
            <div class="item attendee-preview-item" *ngIf="appointment.at && appointment.at.length > 0">
                <div class="icon">
                    <mat-icon>group</mat-icon>
                </div>
                <div class="item-content attendee-content font-size-14">
                    <div class="user-avatar-attendee">
                        <div class="avatar-attendee">
                            <vp-avatar [type]="'mail-chip-avatar'"
                                [user]="{firstLastCharacters: appointment?.or.a.charAt(0), color: '#317bbc', imageData: getAvatar(appointment?.or.a)}">
                            </vp-avatar>
                        </div>
                        <div class="avatar-email">
                            {{appointment?.or.a}} <span
                                class="organizer-lbl">({{ 'CALENDARS.ORGANIZER_LBL' | translate }})</span>
                        </div>
                    </div>
                    <span *ngFor="let attendee of appointment.at">
                        <div class="user-avatar-attendee">
                            <div class="avatar-attendee">
                                <vp-avatar [type]="'mail-chip-avatar'"
                                    [user]="{firstLastCharacters: attendee.a.charAt(0), color: '#317bbc', imageData: getAvatar(attendee.a)}">
                                </vp-avatar>
                            </div>
                            <div class="avatar-email">
                                {{attendee.d || attendee.a}}
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" *ngIf="appointment.loc !== ''">
                <div class="icon">
                    <mat-icon>room</mat-icon>
                </div>
                <div class="item-content font-size-16">
                    {{ appointment.loc}}
                </div>
            </div>

            <div class="item">
                <div class="icon">
                    <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </div>
                <div class="item-content font-size-14">
                    <span *ngIf="locale === 'en'">
                        {{ startDate | date: 'MMMM d, y h:mm a':'': locale }}
                    </span>
                    <span *ngIf="locale === 'de'">
                        {{ startDate | date: 'd. MMMM y, HH:mm':'': locale }}
                    </span>
                </div>
            </div>

            <div class="item">
                <div class="icon">
                    <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar"></mat-icon>
                </div>
                <div class="item-content font-size-14">
                    <span *ngIf="locale === 'en'">
                        {{ endDate | date: 'MMMM d, y h:mm a':'': locale }}
                    </span>
                    <span *ngIf="locale === 'de'">
                        {{ endDate | date: 'd. MMMM y, HH:mm':'': locale }}
                    </span>
                </div>
            </div>

            <div class="item">
                <div class="icon">
                    <mat-icon>work</mat-icon>
                </div>
                <div class="item-content font-size-14">
                    <span [class]="'cal-display-bg cal-color-' + appointment?.fb"></span>
                    <span *ngIf="appointment?.fb === 'O'">{{'CALENDARS.OUT_OF_OFFICE' | translate }}</span>
                    <span *ngIf="appointment?.fb === 'T'">{{'CALENDARS.TENTATIVE' | translate }}</span>
                    <span *ngIf="appointment?.fb === 'B'">{{'CALENDARS.BUSY' | translate }}</span>
                    <span *ngIf="appointment?.fb === 'F'">{{'CALENDARS.FREE' | translate }}</span>
                </div>
            </div>

            <div class="item">
                <div class="icon">
                    <mat-icon *ngIf="replyType===''" class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-alert">
                    </mat-icon>
                    <mat-icon *ngIf="replyType==='AC'" class="mdi-16px accept" fontSet="mdi" fontIcon="mdi-check">
                    </mat-icon>
                    <mat-icon *ngIf="replyType==='DE'" class="mdi-16px declined" fontSet="mdi" fontIcon="mdi-close-box">
                    </mat-icon>
                    <mat-icon *ngIf="replyType==='TE'" class="mdi-16px tentetive" fontSet="mdi" fontIcon="mdi-help">
                    </mat-icon>
                </div>
                <div class="item-content font-size-14">
                    <span *ngIf="replyType===''">{{ 'CALENDARS.YOU_HAVE_NOT_ACCEPT_CALENDAR_LBL' | translate }}</span>
                    <span *ngIf="replyType==='AC'">{{ 'CALENDARS.YOU_ACCEPT_INVITATION_LBL' | translate }}</span>
                    <span *ngIf="replyType==='DE'">{{ 'CALENDARS.YOU_DECLINED_INVITATION_LBL' | translate }}</span>
                    <span *ngIf="replyType==='TE'">{{ 'CALENDARS.YOU_TENTETIVE_INVITATION_LBL' | translate }}</span>
                </div>
            </div>
            <div class="item attendee-preview-item">
                <div class="icon">
                    <mat-icon>description</mat-icon>
                </div>
                <div class="item-content font-size-14 description-content">
                    <div [innerHTML]="description | vpSafeHtml"></div>
                </div>
            </div>
        </div>
        <div class="not-organize-me">
            <div class="content-msg">
                {{ 'CALENDARS.GOING_LBL' | translate }}
            </div>
            <div class="options">
                <button mat-button aria-label="icon-button" [disabled]="replyType==='AC'"
                    (click)="sendReplyRequest('ACCEPT')">
                    {{ 'COMMON.YES' | translate }}
                </button>
                <button mat-button aria-label="icon-button" [disabled]="replyType==='DE'"
                    (click)="sendReplyRequest('DECLINE')">
                    {{ 'COMMON.NO' | translate }}
                </button>
                <button mat-button aria-label="icon-button" [disabled]="replyType==='TE'"
                    (click)="sendReplyRequest('TENTATIVE')">
                    {{ 'CALENDARS.MAY_BE_LBL' | translate }}
                </button>
            </div>
        </div>

    </div>
</div>