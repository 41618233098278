
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnChanges } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Store, select } from "@ngrx/store";
import { distinctUntilChanged, take } from "rxjs/operators";
import { getOnlineStatus, getLastPhotoUpdateByEmail } from "src/app/reducers";
import { SetLastPhotoUpdate } from "src/app/actions/app";
import { AppState } from "src/app/reducers/app";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";

@Component({
  selector: "vp-avatar",
  templateUrl: "./user-avatar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges, OnDestroy {
  @Input() type;
  @Input() user;
  avatarURL: string;
  photoLastUpdate: any;
  photoLastUpdateSubscription$: any;
  email: string;
  constructor(private config: ConfigService, private changeDetectionRef: ChangeDetectorRef, private store: Store<AppState>,
    private electronService: ElectronService) {

  }
  getColor() {
    let color = "#888888";
    if (this.user) {
      if (!this.avatarURL && this.user.color) {
        color = this.user.color;
      }
    }
    return color;
  }

  ngOnChanges(data) {
    if (this.type === "desktop-profile" || this.type === "profile-dialog") {
      if (!!this.user) {
        if (!!this.user.email) {
          this.user.firstLastCharacters = this.user.email.charAt(0);
          this.changeDetectionRef.markForCheck();
        }
      }
    }
    // if (this.user && this.user.imageData) {
    //   this.avatarURL = this.user.imageData;
    //   this.changeDetectionRef.markForCheck();
    // } else
    if (this.user && this.user.email) {
      this.email = this.user.email;
      this.ngOnDestroy();
      this.photoLastUpdateSubscription$ = this.store.select(state => getLastPhotoUpdateByEmail(state, this.user.email))
      .subscribe(photoLastUpdate => {
        if (!!photoLastUpdate) {
          this.photoLastUpdate = photoLastUpdate;
        } else {
          this.photoLastUpdate = new Date().getTime();
          this.store.dispatch(new SetLastPhotoUpdate({email: this.user.email, timestamp: this.photoLastUpdate}));
        }
        this.buildAvatarURL();
      });
    }
  }

  ngOnDestroy() {
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
  }

  imgLoadOnError(event) {
    this.avatarURL = null;
    this.changeDetectionRef.markForCheck();
  }

  private avatarVersion() {
    let version;
    const currentTimestamp = new Date().getTime();
    // // load new ver of avatar
    if ((!this.photoLastUpdate
        || (this.photoLastUpdate > 0 && currentTimestamp - this.photoLastUpdate >= this.config.AVATAR_SYNC_INTERVAL)
        || (this.photoLastUpdate < 0 && currentTimestamp - Math.abs(this.photoLastUpdate) >= this.config.AVATAR_SYNC_INTERVAL)
      )
    ) {
      // set new version
      version = "?ver=" + currentTimestamp;
    // use old ver
    } else {
      if (this.photoLastUpdate && this.photoLastUpdate > 0) {
        version = "?ver=" + this.photoLastUpdate;
      } else {
        version = null; // no version available yet, so show a default image
      }
      // console.log("[AvatarComponent][avatarVersion] old", this.bare, version);
    }
    return version;
  }

  private buildAvatarURL() {
    let isOnline = false;
    this.store.pipe(select(getOnlineStatus), distinctUntilChanged(), take(1)).subscribe(v => {
      isOnline = v;
    });
    if (isOnline) {
      const avatarVersion = this.avatarVersion();
      let avatarId = "";
      if (environment.isElectron) {
        avatarId = this.electronService.md5(this.user.email);
      } else {
        avatarId = md5(this.user.email);
      }
      if (this.config.get("avatarURL")) {
        this.avatarURL = `${this.config.get("avatarURL")}/${avatarId}.jpg${avatarVersion}`;
        this.changeDetectionRef.markForCheck();
      }
    }
  }
}
