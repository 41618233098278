
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { take, takeUntil } from "rxjs/operators";
import { CommonService } from "src/app/services/common.service";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { CalendarRootState } from "src/app/calendar/store/selectors";
import { Store } from "@ngrx/store";
import { UpdateCalendarEventSuccessAction } from "src/app/calendar/store/actions";
import * as _ from "lodash";
import { CalendarEvent } from "src/app/common/models/calendar.model";
import { ToastService } from "src/app/common/providers/toast.service";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import * as moment from "moment-timezone";
import { timer } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "src/app/config.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { VNCNotificationsService } from "../notifications";

@Component({
    selector: "vp-calender-appointment-reminder-dialog",
    templateUrl: "./appointment-reminder.component.html"
})

export class AppointmentReminderDialogComponent implements OnInit {
    listoptions: any[] = [];
    snoozeAllItem: any = "1-MINUTE";
    currentLocale: string = "en";

    constructor(
        public commonService: CommonService,
        private calendarRepository: CalendarRepository,
        private store: Store<CalendarRootState>,
        private changeDetectionRef: ChangeDetectorRef,
        private toastService: ToastService,
        private translateService: TranslateService,
        private configService: ConfigService,
        private broadcaster: Broadcaster,
        private notificationsService: VNCNotificationsService
    ) {
        this.overDueCountCall();
        timer(0, 60000).subscribe(val => {
            this.overDueCountCall();
        });
    }

    ngOnInit() {
        this.listoptions = this.calendarRepository.reminderOptionsWithoutBefore();
    }

    open(): void {

    }

    dismissAppointmentReminder(app: any): void {
        const dismissOn = new Date().getTime();
        this.commonService.dismissApptRequest(app.id, dismissOn).pipe(take(1)).subscribe(res => {
            this.updateEventAlarmListAfterAction(res, "dismiss");
            this.updateEventAlarmActiveList(app.id);
            app.alarmData = undefined;
            this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: app.eventId, changes: app }));
        });
    }

    updateEventAlarmListAfterAction(res: any, type: string, appt?: any) {
        if (type === "snooz") {
            this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: appt.eventId, changes: appt }));
        }
    }

    updateEventAlarmActiveList(apptId) {
        let apptIndex = -1;
        this.commonService.alarmEventsActive.forEach((appt, indx) => {
            if (appt.id === apptId) {
                apptIndex = indx;
            }
        });
        if (apptIndex !== -1) {
            this.commonService.alarmEventsActive.splice(apptIndex, 1);
        }
        if (this.commonService.alarmEventsActive.length === 0) {
            this.commonService.isEventReminderActivated = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    snoozAppointment(app: any): void {
        const newApp = _.clone(app, true);
        const unit = app.alarmItem.split("-")[1];
        const value = app.alarmItem.split("-")[0];
        let newAlarmTimes: number;
        if (unit === "HOURS_BEFORE" || unit === "HOURS" || unit === "HOUR") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 60 * 1000);
        } else if (unit === "MINUTE_BEFORE" || unit === "MINUTE" || unit === "MINUTES") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 1000);
        } else if (unit === "DAY_BEFORE" || unit === "DAY" || unit === "DAYS") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 24 * 60 * 1000);
        } else if (unit === "WEEK_BEFORE" || unit === "WEEK" || unit === "WEEKS") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 24 * 7 * 60 * 1000);
        } else {
            newAlarmTimes = new Date().getTime();
        }
        app.alarmData[0].nextAlarm = newAlarmTimes;
        this.commonService.snozeApptRequest(app.id, newAlarmTimes).pipe(take(1)).subscribe(res => {
            this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: app.eventId, changes: app }));
            this.updateEventAlarmActiveList(app.id);
        });

    }

    snoozAllAppointment(): void {
        const app = this.snoozeAllItem;
        const allAppointment = this.commonService.alarmEventsActive;
        const snoozItems: any[] = [];
        const unit = app.split("-")[1];
        const value = app.split("-")[0];
        let newAlarmTimes: number;
        if (unit === "HOURS_BEFORE" || unit === "HOURS" || unit === "HOUR") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 60 * 1000);
        } else if (unit === "MINUTE_BEFORE" || unit === "MINUTE" || unit === "MINUTES") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 1000);
        } else if (unit === "DAY_BEFORE" || unit === "DAY" || unit === "DAYS") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 24 * 60 * 1000);
        } else if (unit === "WEEK_BEFORE" || unit === "WEEK" || unit === "WEEKS") {
            newAlarmTimes = new Date().getTime() + (value * 60 * 24 * 7 * 60 * 1000);
        } else {
            newAlarmTimes = new Date().getTime();
        }
        allAppointment.map(aptmt => {
            aptmt.alarmData[0].nextAlarm = newAlarmTimes;
            snoozItems.push({
                id: aptmt.id,
                until: newAlarmTimes
            });
        });
        this.commonService.snoozeAllApptRequest(snoozItems).pipe(take(1)).subscribe(res => {
            allAppointment.map( item => {
                this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: item.eventId, changes: item }));
                this.updateEventAlarmActiveList(item.id);
            });
            this.commonService.isEventReminderActivated = false;
            this.commonService.alarmEventsActive = [];
            this.changeDetectionRef.markForCheck();
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    dismissAllAppointmentReminder(): void {
        const dismissOn = new Date().getTime();
        const allAppointment = this.commonService.alarmEventsActive;
        const dismissItems: any[] = [];
        allAppointment.map(aptmt => {
            dismissItems.push({
                id: aptmt.id,
                dismissedAt: dismissOn
            });
        });
        this.commonService.dismissAllApptRequest(dismissItems).pipe(take(1)).subscribe(res => {
            allAppointment.map( item => {
                item.alarmData = undefined;
                this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: item.eventId, changes: item }));
            });
            this.commonService.isEventReminderActivated = false;
            this.commonService.alarmEventsActive = [];
            this.changeDetectionRef.markForCheck();
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    overDueCountCall(): void {
        this.configService.currentLanguage.subscribe(res => {
            if (!!res) {
              if (res === "en") {
                this.currentLocale = "en";
              } else {
                this.currentLocale = "de";
              }
            } else {
              this.currentLocale = "en";
            }
            if (this.currentLocale !== "en") {
                registerLocaleData(localeDE);
            } else {
                registerLocaleData(localEN);
            }
            this.changeDetectionRef.markForCheck();
        });
        if (!!this.commonService.alarmEventsActive && this.commonService.alarmEventsActive.length > 0 ) {
            this.commonService.alarmEventsActive.map( appt => {
                let deltaTime = null;
                const startTime = moment(appt.start).toDate().getTime();
                const endTime = moment(appt.end).toDate().getTime();
                const now = (new Date()).getTime();
                if (!appt.alarmData || appt.alarmData.length === 0) {
                    deltaTime = now - startTime;
                } else {
                    const alarmInstStart = appt.alarmData[0].nextAlarm;
                    if (alarmInstStart) {
                        deltaTime = now - startTime;
                    } else if (appt.getEndTime()) {
                        deltaTime = now - endTime;
                    }
                }
                const formatDalta = CalenderUtils.formatDeltaString(deltaTime, appt.allDay);
                appt.reminderTimeCount = "";
                if (formatDalta.key) {
                    if (formatDalta.key === "reminderOverdueByHoursMinutes") {
                        const args = formatDalta.args;
                        const hours = args[4];
                        const minutes = args[5];
                        if (hours === 0) {
                            const mint: number = minutes;
                            if (mint > 1) {
                                this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_MINUTES", { minutes: minutes })
                                .pipe(take(1)).subscribe((text: string) => {
                                    appt.reminderTimeCount = text;
                                });
                            } else {
                                this.translateService.get("CALENDARS.OVER_DUE_BY_MINUTES", { minutes: minutes })
                                .pipe(take(1)).subscribe((text: string) => {
                                    appt.reminderTimeCount = text;
                                });
                            }
                        } else {
                            const hr: number = hours;
                            if (hr > 1) {
                                this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_HOURS_MINUTES", { hours: hours, minutes: minutes })
                                .pipe(take(1)).subscribe((text: string) => {
                                    appt.reminderTimeCount = text;
                                });
                            } else {
                                if (minutes > 1) {
                                    this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_HOUR_MINUTES", { hours: hours, minutes: minutes })
                                    .pipe(take(1)).subscribe((text: string) => {
                                        appt.reminderTimeCount = text;
                                    });
                                } else {
                                    this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_HOUR_MINUTE", { hours: hours, minutes: minutes })
                                    .pipe(take(1)).subscribe((text: string) => {
                                        appt.reminderTimeCount = text;
                                    });
                                }
                            }
                        }
                    } else if (formatDalta.key === "reminderOverdueByYears") {
                        const args = formatDalta.args;
                        const years = args[1];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_YEARS", { years: years })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByYearsMonths") {
                        const args = formatDalta.args;
                        const years = args[1];
                        const month = args[2];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_YEARS_MONTHS", { years: years, month: month })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByMonths") {
                        const args = formatDalta.args;
                        const month = args[2];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_MONTH", { month: month })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByMonthsDays") {
                        const args = formatDalta.args;
                        const month = args[2];
                        const day = args[3];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_MONTH_DAY", { month: month, day: day })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByDays") {
                        const args = formatDalta.args;
                        const day = args[3];
                        if (day > 1) {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_DAYS", { day: day }).pipe(take(1))
                            .subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        } else {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_DAYS", { day: day }).pipe(take(1))
                            .subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        }
                } else if (formatDalta.key === "reminderOverdueByDaysHours") {
                        const args = formatDalta.args;
                        const day = args[3];
                        const hours = args[4];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_DAY_HOURS", { day: day, hours: hours })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderOverdueByToday") {
                        const args = formatDalta.args;
                        const day = args[3];
                        this.translateService.get("CALENDARS.DUE_TODAY").pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderOverdueByMinutes") {
                        const args = formatDalta.args;
                        const minutes = args[5];
                        if (minutes > 1) {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_MINUTES", { minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        } else {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_MINUTES", { minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        }
                    } else if (formatDalta.key === "reminderInHoursMinutes") {
                        const args = formatDalta.args;
                        const hours = args[4];
                        const minutes = args[5];
                        if (hours === 0) {
                            this.translateService.get("CALENDARS.IN_MINUTES", { minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        } else {
                            this.translateService.get("CALENDARS.IN_HOURS_MINUTES", { hours: hours, minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        }
                    } else if (formatDalta.key === "reminderInYears") {
                        const args = formatDalta.args;
                        const years = args[1];
                        this.translateService.get("CALENDARS.IN_YEARS", { years: years }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInYearsMonths") {
                        const args = formatDalta.args;
                        const years = args[1];
                        const month = args[2];
                        this.translateService.get("CALENDARS.IN_YEARS_MONTH", { years: years, month: month })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInMonths") {
                        const args = formatDalta.args;
                        const month = args[2];
                        this.translateService.get("CALENDARS.IN_MONTHS", { month: month }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInMonthsDays") {
                        const args = formatDalta.args;
                        const month = args[2];
                        const day = args[3];
                        this.translateService.get("CALENDARS.IN_MONTHS_DAYS", { month: month, day: day })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInDays") {
                        const args = formatDalta.args;
                        const day = args[3];
                        this.translateService.get("CALENDARS.IN_DAY", { day: day }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInDaysHours") {
                        const args = formatDalta.args;
                        const day = args[3];
                        const hours = args[4];
                        this.translateService.get("CALENDARS.IN_DAY_HOURS", { day: day, hours: hours })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderInToday") {
                        const args = formatDalta.args;
                        const day = args[3];
                        this.translateService.get("CALENDARS.IN_TODAY").pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderInMinutes") {
                        const args = formatDalta.args;
                        const minutes = args[5];
                        this.translateService.get("CALENDARS.IN_MINUTES", { minutes: minutes }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }
                } else {
                    if (formatDalta === "Now") {
                        this.translateService.get("CALENDARS.IN_NOW").pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                        this.translateService.get("CALENDARS.APPOINTMENT_REMINDER_LBL").pipe(take(1)).subscribe((title: string) => {
                            this.openNotification(title, appt.title);
                        });
                    }
                }
            });
            this.changeDetectionRef.markForCheck();
        }
    }

    openEditEvent(app: any): void {
        const allAppointment = this.commonService.alarmEventsActive;
        const snoozItems: any[] = [];
        let newAlarmTimes: number;
        newAlarmTimes = new Date().getTime() + (5 * 60 * 1000);
        allAppointment.map(aptmt => {
            aptmt.alarmData[0].nextAlarm = newAlarmTimes;
            snoozItems.push({
                id: aptmt.id,
                until: newAlarmTimes
            });
        });
        this.commonService.snoozeAllApptRequest(snoozItems).pipe(take(1)).subscribe(res => {
            allAppointment.map( item => {
                this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: item.eventId, changes: item }));
                this.updateEventAlarmActiveList(item.id);
            });
            this.broadcaster.broadcast("OPEN_CALENDAR_EVENT_FROM_REMINDER", {
                event: app
            });
            this.commonService.isEventReminderActivated = false;
            this.commonService.alarmEventsActive = [];
            this.changeDetectionRef.markForCheck();
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    openNotification(title: string, content: string): void {
        this.notificationsService.newConversation(title, content);
    }
}
