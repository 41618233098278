<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="mail__dialog-header mobile_header">
        <div>
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div id="mobile_sidebar_about_faq_header" class="header_lbl disable-select">
            {{ 'SUGGEST_PREFERENCES' | translate }}
        </div>
        <div class="submit"></div>
    </div>
    <div class="mail__dialog-header desktop_view">
        <div class="header_lbl disable-select">
            {{ 'SUGGEST_PREFERENCES' | translate }}
        </div>
        <div class="submit">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body mail__dialog-body--pad">
        <div class="content">
            <div class="time-prefrences">
                <span class="lbl-heading">{{ 'TIME_PEREFERENCES' | translate }}</span>
                <div class="time">
                    <div class="left">
                        {{ 'SUGGESTION_TIMES' | translate }}:
                    </div>
                    <div class="right">
                        <mat-checkbox [(ngModel)]="includeMyWorkingHours">{{ 'ONLY_INCLUDE_MY_WORKING_HOURS' | translate }}</mat-checkbox>
                        <mat-checkbox [(ngModel)]="includeOtherWorkingHours">{{ 'ONLY_INCLUDE_OTHER_ATTENDEES_WORKING_HOURS' | translate }}</mat-checkbox>
                    </div>
                </div>
                <div class="recurrence">
                    <div class="left">
                        Recurrence:
                    </div>
                    <div class="right">
                        <mat-form-field>
                            <mat-label>{{ 'CALCULATE_AVAILABILITY_NUMBER_INSTANCE' | translate }}</mat-label>
                            <mat-select [(ngModel)]="availabilityOfNumbers">
                              <mat-option *ngFor="let availability of availabilityNumbers" [value]="availability">{{availability}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="location-prefrences">
                <span class="lbl-heading">{{ 'LOCATION_PREFERENCES' | translate }}</span>
                <div class="name-site">
                    <div class="left">
                        <mat-form-field>
                            <input matInput [(ngModel)]="location_name" placeholder="{{ 'CALENDARS.NAME_LBL' | translate }}" />
                        </mat-form-field>
                    </div>
                    <div class="right">
                        <mat-form-field>
                            <input matInput [(ngModel)]="location_site" placeholder="{{ 'CALENDARS.SITE_LBL' | translate }}" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="capacity-building">
                    <div class="left">
                        <mat-form-field>
                            <input matInput [(ngModel)]="location_capacity" placeholder="{{ 'CALENDARS.MINIMUM_CAPACITY_LBL' | translate }}" />
                        </mat-form-field>
                    </div>
                    <div class="right">
                        <mat-form-field>
                            <input matInput [(ngModel)]="location_building" placeholder="{{ 'CALENDARS.BUILDING_LBL' | translate }}" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="description-floor">
                    <div class="left">
                        <mat-form-field>
                            <input matInput [(ngModel)]="location_description" placeholder="{{ 'CALENDARS.DESCRIPTION_EQUPMENT_LBL' | translate }}" />
                        </mat-form-field>
                    </div>
                    <div class="right">
                        <mat-form-field>
                            <input matInput [(ngModel)]="location_floor" placeholder="{{ 'CALENDARS.FLOOR_LBL' | translate }}" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <button mat-button (click)="close()">
                {{ 'COMMON.CANCEL' | translate }}
            </button>
            <button mat-button (click)="save()">
                {{ 'COMMON.SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer">
            <button mat-button (click)="close()">
                {{ 'COMMON.CANCEL' | translate }}
            </button>
            <button mat-button (click)="save()">
                {{ 'COMMON.SAVE' | translate }}
            </button>
        </div>
    </div>
</div>