
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { Subject } from "rxjs";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "vp-save-change-appointment-dialog",
    templateUrl: "./save-change-appointment.component.html"
})
export class SaveChangeAppointmentDialogComponent implements OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    constructor(
        public dialogRef: MatDialogRef<SaveChangeAppointmentDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_APPOINTMENT_SAVE_CHANGE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.ngZone.run(() => {
                this.close("no");
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.complete();
        this.isAlive$.next(false);
    }

    close(value: string): void {
        this.dialogRef.close({ value: value });
    }
}
