
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="preference-container">
  <div class="preference-sidebar">
      <vp-preference-sidebar></vp-preference-sidebar>
  </div>
  <div class="preference-component" [class.show-footer]="showFooter">
        <div [fxHide.gt-sm]="true" class="preference-header-mobile">
            <button mat-icon-button (click)="goBack()" #backButton><mat-icon>keyboard_arrow_left</mat-icon></button>
            <div class="preference-header-title">{{ preferenceTitle | translate }}</div>
        </div>
        <router-outlet></router-outlet>
        <div class="preference-footer-mobile" fxLayout="row" [fxHide.gt-sm]="true" *ngIf="showFooter">
            <div class="preference-header-title">{{ 'SAVE_CHANGES' | translate }}</div>
            <button mat-fab color="primary" (click)="saveChanges()"><mat-icon>check</mat-icon></button>
        </div>
  </div>
</div>
