/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { DirectoryTag } from "../common/models/directory-tag.model";
import { AppActionTypes } from "./app";


export class DirectoryTagActionTypes extends AppActionTypes {
  static SELECT_DIRECTORY_TAG = "[DIRECTORY TAGS] Select Directory Tag";
  static LOAD_DIRECTORY_TAGS = "[DIRECTORY TAGS] Load Directory Tags";
  static LOAD_DIRECTORY_TAGS_SUCCESS = "[DIRECTORY TAGS] Load Directory Tags Success";
  static LOAD_DIRECTORY_TAGS_FAIL = "[DIRECTORY TAGS] Load Directory Tags Fail";

  static CREATE_DIRECTORY_TAG = "[DIRECTORY TAGS] Create Directory Tag";
  static CREATE_DIRECTORY_TAG_FAIL = "[DiRECTORY TAGS] Create Directory Tag Fail";
  static CREATE_DIRECTORY_TAG_SUCCESS = "[DIRECTORY TAGS] Create Directory Tag Success";

  static RESET_DIRECTORY_TAG_STATE = "[DIRECTORY TAGS] Reset Directory tag State";

  static LOAD_MORE_DIRECTORY_TAGS_SUCCESS = "[DIRECTORY TAGS] Load More Directory tags Success";

}

export class SetSelectedDirectoryTag implements Action {
  readonly type = DirectoryTagActionTypes.SELECT_DIRECTORY_TAG;
  constructor(public payload: DirectoryTag) {
  }
}

export class LoadDirectoryTagsSuccess implements Action {
  readonly type = DirectoryTagActionTypes.LOAD_DIRECTORY_TAGS_SUCCESS;
  constructor(public payload: DirectoryTag[] ) { }
}

export class LoadDirectoryTags implements Action {
  readonly type = DirectoryTagActionTypes.LOAD_DIRECTORY_TAGS;
}

export class LoadDirectoryTagsFail implements Action {
  readonly type = DirectoryTagActionTypes.LOAD_DIRECTORY_TAGS_FAIL;
}

export class CreateDirectoryTag implements Action {
  readonly type = DirectoryTagActionTypes.CREATE_DIRECTORY_TAG;
}

export class CreateDirectoryTagFail implements Action {
  readonly type = DirectoryTagActionTypes.CREATE_DIRECTORY_TAG_FAIL;
}

export class CreateDirectoryTagSuccess implements Action {
  readonly type = DirectoryTagActionTypes.CREATE_DIRECTORY_TAG_SUCCESS;
  constructor(public payload: DirectoryTag) { }
}

export class ResetDirectoryTagState implements Action {
  readonly type = DirectoryTagActionTypes.RESET_DIRECTORY_TAG_STATE;
  constructor() { }
}

export class LoadMoreDirectoryTagsSuccessAction implements Action {
  readonly type = DirectoryTagActionTypes.LOAD_MORE_DIRECTORY_TAGS_SUCCESS;
  constructor(public payload:  DirectoryTag[]) { }
}
