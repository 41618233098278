
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="header">
    <div class="mobile-back">
        <mat-icon (click)="close()">chevron_left</mat-icon>
    </div>
    <div class="text">{{ 'APPEARANCE' | translate }}</div>
    <div class="icon">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
</div>
<div class="theme-lbl">{{ 'THEME_LBL' | translate }}</div>
<div class="content">
    <div class="themes">
        <div *ngFor="let theme of themes" class="theme-item" (click)="themeChange(theme)">
            <div class="border {{theme.title}}" [class.selected-theme]="theme.selected">
                <div class="item">
                    <div class="operation">
                        <div class="add-event">
                            <div class="bg-color-item">
                                <mat-icon class="mdi-10px" fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
                            </div>
                        </div>
                        <div class="day-item">
                            <div class="date-item">{{todayDate | date: 'd': '': language}}</div>
                            <div class="day-name">{{todayDate | date: 'EEE': '': language}}</div>
                        </div>
                        <div class="today">
                            <div class="today-division">{{'CALENDARS.TODAY' | translate }}</div>
                        </div>
                    </div>
                    <div class="left-right">
                        <div class="left">
                            <div class="time-line">All Day</div>
                            <div class="time-line">1 AM</div>
                            <div class="time-line">2 AM</div>
                            <div class="time-line">3 AM</div>
                        </div>
                        <div class="right">
                            <div class="event-1">Lene Vestgaard Hau - Vacation</div>
                            <div class="event-2">Planning the project<br />10 AM - 1 PM</div>
                        </div>
                    </div>
                    <div class="border-item"></div>
                </div>
            </div>
            <div class="theme-name {{theme.title}}" [class.selected-theme]="theme.selected"> {{ theme.name}}</div>
        </div>
    </div>
</div>
<div class="footer" *ngIf="isThemeChange">
    <span>{{ 'UPDATE_APPEARANCE' | translate }}</span>
    <button mat-button (click)="changeThemeUpdate()">
        <mat-icon>check</mat-icon>
    </button>
</div>