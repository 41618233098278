
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Subject } from "rxjs";
import { Preference } from "src/app/preference/shared/models";
import { PreferenceService } from "src/app/preference/shared/services/preference.service";
import * as _ from "lodash";
import { take, takeUntil } from "rxjs/operators";
import { PreferenceRepository } from "src/app/preference/repositories/preference.repository";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";

const DEFAULT_PREFS = [
  "zimbraPrefCalendarReminderEmail"
];

@Component({
  selector: "vp-email-notification-configure",
  templateUrl: "./email-notification-configure.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EmailNotificationConfigureComponent implements OnInit, OnDestroy {
  notificationForm: FormGroup;
  preference: any = {};
  private isAlive$ = new Subject<boolean>();
  valueChanges$: any;
  originalValue: string = "";
  constructor(
    public dialogRef: MatDialogRef<EmailNotificationConfigureComponent>,
    private ngxHotKeyService: NgxHotkeysService,
    private preferenceService: PreferenceService,
    private fb: FormBuilder,
    private preferenceRepo: PreferenceRepository,
    private broadcaster: Broadcaster,
    private ngZone: NgZone
  ) {
    const form = {};
    for (const key of DEFAULT_PREFS) {
      if (key === "zimbraPrefCalendarReminderEmail") {
        form[key] = ["", Validators.email];
      } else {
        form[key] = [""];
      }
    }
    this.notificationForm = this.fb.group(form);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
    this.preferenceService.getPreferences().pipe(take(1)).subscribe(res => {
      DEFAULT_PREFS.forEach(key => {
        const preference: Preference = _.find(res, { key: key });
        const value = preference ? preference.value : "";
        this.preference[key] = value;
        this.originalValue = value;
        this.notificationForm.patchValue(this.preference);
      });
      if (!this.valueChanges$) {
        this.valueChanges$ = this.notificationForm.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(changes => {
          this.preferenceService.setPreferenceChanges(true);
        });
      }
    });
    this.broadcaster.on<any>(BroadcastKeys.HIDE_EMAIL_NOTIFICATION_CONFIGURE).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.ngZone.run(() => {
          this.close();
        });
      });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
  }

  savePreferences(): void {
    const changes = this.getChangesValue().filter(f => f.key.startsWith("zimbraPref"));
    if (changes.length > 0) {
      this.preferenceService.modifyPrefs(changes).subscribe(res => {
        this.preferenceRepo.updatePreferences(changes);
        this.preferenceService.showMessage("PREFERENCES_SAVED");
        this.close();
      });
    }
  }

  private getChangesValue(): Preference[] {
    let preferences: Preference[] = [];
    DEFAULT_PREFS.forEach(key => {
      if (!_.isEqual(this.preference[key], this.notificationForm.value[key])) {
        let value = this.notificationForm.value[key];
        if (typeof value === "boolean") {
          value = value ? "TRUE" : "FALSE";
        }
        preferences = [...preferences, ...[{ key, value }]];
      }
    });
    return preferences;
  }

}
