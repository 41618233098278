
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-confirmation-wrapper">
  <h3 class="dialog__title"></h3>
  <div class="dialog__content">
    <p [innerHTML]="'INSTALL_APP_DESC'|translate" *ngIf="!appName"></p>
    <p [innerHTML]="'PLEASE_INSTALL_APP'|translate: {'appName': appName}" *ngIf="appName"></p>
  </div>
  <div class="dialog__actions">
    <button mat-button *ngIf="!appName" (click)="installApp()">{{ 'INSTALL'|translate }}</button>
    <button mat-button (click)="cancel()">{{ 'CANCEL'|translate }}</button>
  </div>
</div>
