
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ConfigService } from "src/app/config.service";

@Component({
  selector: "vp-preference-sidebar",
  templateUrl: "./preference-sidebar.component.html"
})
export class PreferenceSidebarComponent implements OnInit, OnDestroy {
  navRoutes = [
    {
      url: "/preferences/general",
      label: "MAIN_PREFERENCES",
      icon: "mdi-settings-box"
    }, {
      url: "/preferences/work-week-hours",
      label: "PREFERENCES.WORK_WEEK_AND_HOURS",
      icon: "mdi-calendar-clock"
    }, {
      url: "/preferences/create-appointment-setting",
      label: "PREFERENCES.CREATING_APPOINTMENTS",
      icon: "mdi-calendar-check"
    }, {
      url: "/preferences/permissions-setting",
      label: "PREFERENCES.PERMISSIONS",
      icon: "mdi-lock"
    }, {
      url: "/preferences/apple-ical",
      label: "PREFERENCES.APPLE_ICAL",
      icon: "mdi-apple"
    }, {
      url: "/preferences/notifications",
      label: "NOTIFICATIONS_PREFERENCES",
      icon: "mdi-bell"
    },
    {
      url: "/preferences/security",
      label: "SECURITY_PREF_LBL",
      icon: "mdi-lock"
    }
  ];
  currentUrl = "";
  private isAlive$ = new Subject<boolean>();
  isSecurityFeatureEnabled: boolean = false;
  constructor(private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private config: ConfigService,
    private ngxHotKeyService: NgxHotkeysService) { }
  ngOnInit() {
    this.currentUrl = this.router.routerState.snapshot.url;
    this.router.events.pipe(filter(e => e instanceof NavigationEnd), map((data: NavigationEnd) => data.url)).subscribe(url => {
      this.currentUrl = url;
      this.changeDetectorRef.markForCheck();
    });
    this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
    this.isSecurityFeatureEnabled = this.config.two_factor_authentication;
    if (!this.isSecurityFeatureEnabled) {
      this.navRoutes = this.navRoutes.filter( route => route.url !== "/preferences/security");
    }
  }

  ngOnDestroy(): void {
    this.changeDetectorRef.detach();
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
  }

}
