
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

setupTheme();

if (environment.production || environment.isCordova || environment.isElectron) {
  enableProdMode();
  // window.console.log = function () {
  // };
  // window.console.debug = function () {
  // };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  function loadExternalStyle(styleUrl: string) {
    return new Promise((resolve, reject) => {
      let styleElement = document.createElement("link");
      styleElement.rel = "stylesheet";
      styleElement.type = "text/css";
      styleElement.href = styleUrl;
      styleElement.onload = function() {
        showApp();
        resolve(true);
      };
      document.head.appendChild(styleElement);
      styleElement = null;
    });
  }

function loadExternalScript(scriptUrl: string) {
  return new Promise(resolve => {
    let scriptElement = document.createElement("script");
    scriptElement.src = scriptUrl;
    scriptElement.onload = resolve;
    document.body.appendChild(scriptElement);
    scriptElement = null;
  });
}

function showApp() {
  document.querySelector("vp-app").removeAttribute("style");
}

function setupTheme() {
  const HASH_VALUE = "2ea02a22277d14926e9419d7ab0d7aa77a058503";
  console.log("[setupTheme]", new Date(), environment.theme);
  if (environment.isCordova) {
    loadExternalScript("cordova.js");
    loadExternalStyle("assets/css/smooth-scroll.css");
  }
  const currentName = localStorage.getItem("theme") || environment.theme;
  const themePath = `${HASH_VALUE}.`;
  document.querySelector("vp-app").setAttribute("style", "display: none");
  if (currentName === "green") {
    loadExternalStyle(themePath + "green.css").then(() => { showApp(); }).catch(() => { showApp(); });
  } else {
    loadExternalStyle(themePath + "vnccalendar.css").then(() => { showApp(); }).catch(() => { showApp(); });
  }
  document.title = environment.title;
}
