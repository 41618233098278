
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone } from "@angular/core";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take } from "rxjs/operators";
import { ConfigService } from "src/app/config.service";
import { ElectronService } from "src/app/services/electron.service";
import { TranslateService } from "@ngx-translate/core";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-servicedesk-dialog",
  templateUrl: "./servicedesk-dialog.component.html"
})
export class ServicedeskDialogComponent implements OnInit {

  serviceDeskURL: string;
  headerText: string = "VNC service desk ";
  buttonText: string = "Visit VNC service desk ";
  constructor(
    private mailBroadcaster: Broadcaster,
    public dialogRef: MatDialogRef<ServicedeskDialogComponent>,
    private ngZone: NgZone,
    private configService: ConfigService,
    private electronService: ElectronService,
    private translateService: TranslateService
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_SERVICEDESK).pipe(take(1)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    let browserLang = this.translateService.getBrowserLang();
    const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
        : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      browserLang = localLang;
    }
    browserLang = browserLang.match(/en|de/) ? browserLang : "en";
    this.serviceDeskURL = "https://vncproject.vnc.biz/helpdesk/incidents/new";
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
