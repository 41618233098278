
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { CommonService } from "src/app/services/common.service";
import { ToastService } from "src/app/common/providers/toast.service";

@Component({
    selector: "vp-suggest-preferences-dialog",
    templateUrl: "./suggest-preference-dialog.component.html"
})
export class SuggestPreferencesDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    availabilityNumbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    includeMyWorkingHours: boolean = false;
    includeOtherWorkingHours: boolean = false;
    availabilityOfNumbers: number = 2;
    location_name: string = "";
    location_site: string = "";
    location_capacity: string = "";
    location_building: string = "";
    location_description: string = "";
    location_floor: string = "";
    constructor(
        private dialogRef: MatDialogRef<SuggestPreferencesDialogComponent>,
        private changeDetection: ChangeDetectorRef,
        private dialog: MatDialog,
        private mailBroadcaster: Broadcaster,
        private ngZone: NgZone,
        private commonService: CommonService,
        private toastService: ToastService
    ) {
        this.commonService.getMailBoxLocationMetaData().pipe(take(1)).subscribe(res => {
            if (!!res && res.GetMailboxMetadataResponse && res.GetMailboxMetadataResponse[0].meta) {
                const response = res.GetMailboxMetadataResponse[0].meta[0]._attrs;
                if (!!response) {
                    if (response.building) {
                        this.location_building = response.building;
                    }
                    if (response.capacity) {
                        this.location_capacity = response.capacity;
                    }
                    if (response.desc) {
                        this.location_description = response.desc;
                    }
                    if (response.floor) {
                        this.location_floor = response.floor;
                    }
                    if (response.my_working_hrs_pref && response.my_working_hrs_pref === "true") {
                        this.includeMyWorkingHours = true;
                    }
                    if (response.others_working_hrs_pref && response.others_working_hrs_pref === "true") {
                        this.includeOtherWorkingHours = true;
                    }
                    if (response.site) {
                        this.location_site = response.site;
                    }
                    if (response.name) {
                        this.location_name = response.name;
                    }
                    if (response.recurrenceSelect) {
                        this.availabilityOfNumbers = parseInt(response.recurrenceSelect, 10);
                    }
                    this.changeDetection.markForCheck();
                }
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    ngOnInit() {
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_SUGGEST_PREFERENCES_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    close() {
        this.dialogRef.close();
    }

    save(): void {
        const sendObject = {};
        sendObject["my_working_hrs_pref"] = this.includeMyWorkingHours;
        sendObject["others_working_hrs_pref"] = this.includeOtherWorkingHours;
        sendObject["recurrenceSelect"] = this.availabilityOfNumbers.toString();
        if (this.location_name !== "") {
            sendObject["name"] = this.location_name;
        }
        if (this.location_site !== "") {
            sendObject["site"] = this.location_site;
        }
        if (this.location_capacity !== "") {
            sendObject["capacity"] = this.location_capacity;
        }
        if (this.location_building !== "") {
            sendObject["building"] = this.location_building;
        }
        if (this.location_description !== "") {
            sendObject["desc"] = this.location_description;
        }
        if (this.location_floor !== "") {
            sendObject["floor"] = this.location_floor;
        }
        this.commonService.setMailBoxLocationMetaData(sendObject).pipe(take(1)).subscribe(res => {
            if (!!res && res.SetMailboxMetadataResponse) {
                this.toastService.show("SUGGESTION_PREFRENCES_SAVED");
                this.close();
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }
}
