
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PreferenceRepository } from "../repositories/preference.repository";
import { PreferenceService } from "../shared/services/preference.service";
import { Subject } from "rxjs";
import { filter, takeUntil, take } from "rxjs/operators";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import { Preference } from "../shared/models";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { WorkHoursDialogComponent } from "./work-hours-dialog/work-hours-dialog.component";
import { ElectronService } from "src/app/services/electron.service";
import { registerLocaleData } from "@angular/common";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { TranslateService } from "@ngx-translate/core";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ConfigService } from "src/app/config.service";
import { MatDialog } from "@angular/material/dialog";

const AVAILABLE_KEYS = [
    "zimbraPrefTimeZoneId",
    "zimbraPrefCalendarWorkingHours",
    "zimbraPrefUseTimeZoneListInCalendar"
];

@Component({
    selector: "vp-work-week-hour-setting",
    templateUrl: "./work-week-hour.component.html"
})
export class WorkWeekHourComponent implements OnInit, OnDestroy {

    preferenceTitle = "PREFERENCES.WORK_WEEK_AND_HOURS";
    preferenceForm: FormGroup;
    private isAlive$ = new Subject<boolean>();
    private preference = {};
    private valueChanges$;
    allPreferences: Preference[] = [];
    zimbraWorkingHours: string;
    timezone: string = "";
    startTime: Date;
    endTime: Date;
    timeOption: Date[];
    disabledCustomButton: boolean = true;
    customWorkingHours: string = "";
    browserLang: string = "en";

    constructor(
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private preferenceRepo: PreferenceRepository,
        private preferenceService: PreferenceService,
        private activatedRoute: ActivatedRoute,
        private broadCaster: Broadcaster,
        private ngxHotKeyService: NgxHotkeysService,
        private dialog: MatDialog,
        private electronService: ElectronService,
        private translateService: TranslateService,
        private configService: ConfigService
    ) {
        this.setLocale();
        this.startTime = new Date();
        this.endTime = new Date();
        this.timeOption = this.generateTimePointOptions();
        this.activatedRoute.paramMap.subscribe(res => {
            setTimeout(() => {
                this.broadCaster.broadcast(BroadcastKeys.OPEN_PREFERENCE_TAB, "work-week-hours");
            }, 50);
        });
        const generalForm = {};
        for (const key of AVAILABLE_KEYS) {
            generalForm[key] = [""];
        }
        generalForm["sunday"] = [false];
        generalForm["monday"] = [false];
        generalForm["tuesday"] = [false];
        generalForm["wedensDay"] = [false];
        generalForm["thursday"] = [false];
        generalForm["friday"] = [false];
        generalForm["saturday"] = [false];
        generalForm["normalCustomeHours"] = ["normal"];
        generalForm["startHour"] = ["08:00 AM"];
        generalForm["endHour"] = ["05:00 PM"];

        this.preferenceForm = this.fb.group(generalForm);
        this.preferenceService.setPreferenceTitle(this.preferenceTitle);
        this.preferenceService.onSaveChanges().pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.savePreferences();
        });
    }

    ngOnInit() {
        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), takeUntil(this.isAlive$))
            .subscribe(preferences => {
                this.allPreferences = preferences;
                this.bindFormData(preferences);
                console.log("[getPreferences]", this.preferenceForm.value, this.preference);
                this.changeDetectorRef.markForCheck();
                if (!this.valueChanges$) {
                    this.valueChanges$ = this.preferenceForm.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(changes => {
                        this.preferenceService.setPreferenceChanges(true);
                    });
                }
            });
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
        this.configService.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe(res => {
            setTimeout(() => {
                this.setLocale();
            }, 1000);
        });
    }

    cancelPreferences(): void {
        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), take(1)).subscribe(preferences => {
                this.bindFormData(preferences);
                this.preferenceService.navigateTo("PREFERENCES_LBL");
                this.changeDetectorRef.markForCheck();
            });
    }

    private getChangesValue(): Preference[] {
        let preferences: Preference[] = [];
        AVAILABLE_KEYS.forEach(key => {
            if (!_.isEqual(this.preference[key], this.preferenceForm.value[key])) {
                let value = this.preferenceForm.value[key];
                if (typeof value === "boolean") {
                    value = value ? "TRUE" : "FALSE";
                }
                preferences = [...preferences, ...[{ key, value }]];
            }
        });
        return preferences;
    }

    savePreferences(): void {
        const changes = this.getChangesValue();
        let workingHours: string = "";
        if (this.preferenceForm.get("normalCustomeHours").value === "normal") {
            workingHours = this.getWorkingHours();
        } else {
            if (!!this.customWorkingHours && this.customWorkingHours !== "") {
                workingHours = this.customWorkingHours;
            } else {
                workingHours = this.getWorkingHours();
            }
        }
        changes.push({
            key: "zimbraPrefCalendarWorkingHours",
            value: workingHours
        });
        if (changes.length > 0) {
            this.preferenceService.modifyPrefs(changes).subscribe(res => {
                this.preferenceRepo.updatePreferences(changes);
                this.preferenceService.showMessage("PREFERENCES_SAVED");
                this.broadCaster.broadcast(BroadcastKeys.HIDE_MOBILE_PREFERENCE_SAVE_FOOTER);
                this.preferenceService.navigateTo();
            });
        }
    }

    private bindFormData(preferences: Preference[]): void {
        AVAILABLE_KEYS.forEach(key => {
            const preference: Preference = _.find(preferences, { key: key });
            if (preference) {
                let value: any = preference.value;
                if (value === "TRUE") {
                    value = true;
                } else if (value === "FALSE") {
                    value = false;
                }
                if (preference.key === "zimbraPrefCalendarWorkingHours") {
                    this.zimbraWorkingHours = value;
                    this.setWorkingHours();
                }
                if (preference.key === "zimbraPrefTimeZoneId") {
                    this.timezone = value;
                }
                this.preference[key] = value;
                this.preferenceForm.get(key).setValue(value);
            }
        });
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    private generateTimePointOptions(date?: Date): Date[] {
        date = date ? new Date(date) : new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        const timeList: Date[] = [];

        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                const newDate = new Date(date);
                newDate.setHours(i);
                newDate.setMinutes(j * 15);
                timeList.push(newDate);
            }
        }

        return timeList;
    }

    private setWorkingHours(): void {
        const days = this.zimbraWorkingHours.split(",");
        if (days.length > 0) {
            days.map(item => {
                const data = item.split(":");
                const d = data[0];
                const enable = data[1];
                if (d === "1" && enable === "Y") {
                    this.preferenceForm.get("sunday").setValue(true);
                } else if (d === "2" && enable === "Y") {
                    this.preferenceForm.get("monday").setValue(true);
                } else if (d === "3" && enable === "Y") {
                    this.preferenceForm.get("tuesday").setValue(true);
                } else if (d === "4" && enable === "Y") {
                    this.preferenceForm.get("wedensDay").setValue(true);
                } else if (d === "5" && enable === "Y") {
                    this.preferenceForm.get("thursday").setValue(true);
                } else if (d === "6" && enable === "Y") {
                    this.preferenceForm.get("friday").setValue(true);
                } else if (d === "7" && enable === "Y") {
                    this.preferenceForm.get("saturday").setValue(true);
                }
            });
            const isCustomHourEnable = this.getCustomHourEnable(days);
            const startTime = days[0].split(":")[2];
            const endTime = days[0].split(":")[3];
            this.setStartEndTimeForHours(startTime, endTime);
            if (isCustomHourEnable) {
                this.preferenceForm.get("normalCustomeHours").setValue("custom");
                this.enableDisableWorkHours(false);
            }
        }
    }

    getCustomHourEnable(days: any[]): boolean {
        const startEndTime: any[] = [];
        days.map(d => {
            const start = d.split(":")[2];
            const end = d.split(":")[3];
            startEndTime.push({
                startTime: start,
                endTime: end
            });
        });
        for (let i = 1; i < startEndTime.length; i++) {
            if (startEndTime[i].startTime !== startEndTime[i - 1].startTime || startEndTime[i].endTime !== startEndTime[i - 1].endTime) {
                this.setStartEndTimeForHours(startEndTime[i].startTime, startEndTime[i].endTime);
                return true;
            }
        }
        return false;
    }

    enableDisableWorkHours(value: boolean): void {
        if (value) {
            this.preferenceForm.get("sunday").enable();
            this.preferenceForm.get("monday").enable();
            this.preferenceForm.get("tuesday").enable();
            this.preferenceForm.get("wedensDay").enable();
            this.preferenceForm.get("thursday").enable();
            this.preferenceForm.get("friday").enable();
            this.preferenceForm.get("saturday").enable();
            this.preferenceForm.get("startHour").enable();
            this.preferenceForm.get("endHour").enable();
            this.disabledCustomButton = true;
        } else {
            this.preferenceForm.get("sunday").disable();
            this.preferenceForm.get("monday").disable();
            this.preferenceForm.get("tuesday").disable();
            this.preferenceForm.get("wedensDay").disable();
            this.preferenceForm.get("thursday").disable();
            this.preferenceForm.get("friday").disable();
            this.preferenceForm.get("saturday").disable();
            this.preferenceForm.get("startHour").disable();
            this.preferenceForm.get("endHour").disable();
            this.disabledCustomButton = false;
        }
    }

    normalCustomHourChange(ev: any): void {
        if (ev.value === "custom") {
            this.enableDisableWorkHours(false);
        } else {
            this.enableDisableWorkHours(true);
        }
    }

    setStartEndTimeForHours(start: string, end: string): void {
        if (!!start && !!end) {
            const m = moment();
            const startHours = start.substr(0, 2);
            const startMinutes = start.substr(2, 3);
            const endHours = end.substr(0, 2);
            const endMinutes = end.substr(2, 3);

            m.set({ hour: startHours, minute: startMinutes });
            const startHourMinutes = m.format("hh:mm A");
            this.preferenceForm.get("startHour").setValue(startHourMinutes);

            m.set({ hour: endHours, minute: endMinutes });
            const endHourMinutes = m.format("hh:mm A");
            this.preferenceForm.get("endHour").setValue(endHourMinutes);
        }
    }

    getWorkingHours(): string {
        const startTime = this.preferenceForm.get("startHour").value;
        const endTime = this.preferenceForm.get("endHour").value;
        const startHoursTime = moment(startTime, ["h:mm A"]).format("HHmm");
        const endHoursTime = moment(endTime, ["h:mm A"]).format("HHmm");
        const workingHours = "6:" + (this.preferenceForm.get("friday").value ? "Y" : "N") + ":" + startHoursTime + ":" +
            endHoursTime + "," +
            "7:" + (this.preferenceForm.get("saturday").value ? "Y" : "N") + ":" + startHoursTime + ":" + endHoursTime + "," +
            "1:" + (this.preferenceForm.get("sunday").value ? "Y" : "N") + ":" + startHoursTime + ":" + endHoursTime + "," +
            "2:" + (this.preferenceForm.get("monday").value ? "Y" : "N") + ":" + startHoursTime + ":" + endHoursTime + "," +
            "3:" + (this.preferenceForm.get("tuesday").value ? "Y" : "N") + ":" + startHoursTime + ":" + endHoursTime + "," +
            "4:" + (this.preferenceForm.get("wedensDay").value ? "Y" : "N") + ":" + startHoursTime + ":" + endHoursTime + "," +
            "5:" + (this.preferenceForm.get("thursday").value ? "Y" : "N") + ":" + startHoursTime + ":" + endHoursTime;
        return workingHours;
    }

    openCustomHourDialog(): void {
        const dialog = this.dialog.open(
            WorkHoursDialogComponent, {
                maxWidth: "100vw",
                data: { workingHours: this.zimbraWorkingHours, timeZone: this.timezone },
                panelClass: "calendar-custom-work-hour"
            });
        dialog.afterClosed().pipe(take(1)).subscribe(result => {
            if (!!result && result.workHours) {
                this.customWorkingHours = result.workHours;
            }
        });
    }

    undoChange(): void {
        this.preferenceForm.get("sunday").setValue(false);
        this.preferenceForm.get("monday").setValue(false);
        this.preferenceForm.get("tuesday").setValue(false);
        this.preferenceForm.get("wedensDay").setValue(false);
        this.preferenceForm.get("thursday").setValue(false);
        this.preferenceForm.get("friday").setValue(false);
        this.preferenceForm.get("saturday").setValue(false);
        this.bindFormData(this.allPreferences);
        this.preferenceService.showMessage("PREFERENCES.PAGE_REVERTED");
    }

    setLocale() {
        this.browserLang = this.translateService.getBrowserLang();
        const localLang = this.electronService.isElectron
            ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
            : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
          this.browserLang = localLang;
        }
        this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
        if (this.browserLang === "de") {
          registerLocaleData(localeDE, this.browserLang);
        } else {
          registerLocaleData(localEN, this.browserLang);
        }
    }

}
