
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="notify-settings">
    <form [formGroup]="notificationForm">
        <div class="header">
            {{ 'NOTIFICATION_BY_EMAIL' | translate }}
        </div>
        <mat-divider class="mobile-hide"></mat-divider>
        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.NOTIFY' | translate }}:
            </div>
            <div class="item">
                <mat-form-field class="md-block mb0 email-address-input email_field">
                    <input type="email" matInput placeholder="{{ 'ENTER_EMAIL_ADDRESS' | translate}}"
                        formControlName="zimbraPrefCalendarReminderEmail">
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" class="footer-bottom-desktop">
            <div class="button-actions footer-actions">
                <button class="save" (click)="savePreferences()">{{'COMMON.SAVE'| translate }}</button>
                <button class="cancel" (click)="cancelPreferences()">{{'CANCEL'| translate }}</button>
                <button class="save" (click)="undoChange()"> {{ 'UNDO_CHANGES' | translate }}</button>
            </div>
        </div>
    </form>
</div>