
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="calendar-main-settings">
    <form [formGroup]="mainSettingsForm">
        <div class="header">
            {{ 'MAIN_PREFERENCES' | translate }}
        </div>
        <div class="description">
        </div>

        <!-- Timezone values -->
        <div class="element">
            <div class="lbl">
                {{ 'CALENDARS.TIME_ZONE_LBL' | translate }}:
            </div>
            <div class="item">
                <mat-form-field>
                    <mat-select formControlName="zimbraPrefTimeZoneId">
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.key">{{
                            timezone.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.DEFAULT_VIEW' | translate }}:
            </div>
            <div class="item">
                <mat-form-field>
                    <mat-select formControlName="zimbraPrefCalendarInitialView">
                        <mat-option [value]="'day'">{{ 'PREFERENCES.DAY_VIEW_LBL' | translate }}
                        </mat-option>
                        <mat-option [value]="'workWeek'">{{ 'PREFERENCES.WORK_WEEK_VIEW' | translate }}
                        </mat-option>
                        <mat-option [value]="'week'">{{ 'PREFERENCES.7_DAY_WEEK_VIEW' | translate }}
                        </mat-option>
                        <mat-option [value]="'month'">{{ 'PREFERENCES.MONTH_VIEW' | translate }}
                        </mat-option>
                        <mat-option [value]="'list'">{{ 'PREFERENCES.LIST_VIEW' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.STARTS_WEEK_ON_LBL' | translate }}:
            </div>
            <div class="item">
                <mat-form-field>
                    <mat-select formControlName="zimbraPrefCalendarFirstDayOfWeek">
                        <mat-option [value]="'0'"> {{ 'SUNDAY' | translate }} </mat-option>
                        <mat-option [value]="'1'"> {{ 'MONDAY' | translate }} </mat-option>
                        <mat-option [value]="'2'"> {{ 'TUESDAY' | translate }} </mat-option>
                        <mat-option [value]="'3'"> {{ 'WEDNESDAY' | translate }} </mat-option>
                        <mat-option [value]="'4'"> {{ 'THURSDAY' | translate }} </mat-option>
                        <mat-option [value]="'5'"> {{ 'FRIDAY' | translate }} </mat-option>
                        <mat-option [value]="'6'"> {{ 'SATURDAY' | translate }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.DEFAULT_APPOINTMENT_VISIBILITY' | translate }}:
            </div>
            <div class="item">
                <mat-form-field>
                    <mat-select formControlName="zimbraPrefCalendarApptVisibility">
                        <mat-option [value]="'public'">{{ 'PREFERENCES.PUBLIC_LBL' | translate }}
                        </mat-option>
                        <mat-option [value]="'private'">{{ 'PREFERENCES.PRIVATE_LBL' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="element">
            <div class="lbl">
            </div>
            <div class="item">
                <div>
                    <mat-checkbox formControlName="zimbraPrefCalendarAlwaysShowMiniCal">
                        {{ 'PREFERENCES.ALWAYS_SHOW_MINI_CALENDAR' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="zimbraPrefShowCalendarWeek">
                        {{ 'PREFERENCES.SHOW_CALENDAR_WEEK_NUMBER' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="zimbraPrefCalendarAutoAddInvites">
                        {{ 'PREFERENCES.AUTOMATIC_ADD_RECEIVE_CALENDAR' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="zimbraPrefCalendarShowDeclinedMeetings">
                        {{ 'PREFERENCES.SHOW_DECLINED_MEETINGS' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.INVITATION_REPONSE' | translate }}:
            </div>
            <div class="item">
                <mat-checkbox formControlName="zimbraPrefDeleteInviteOnReply">
                    {{ 'PREFERENCES.DELETE_INVITE_ON_REPLY' | translate }}
                </mat-checkbox>

            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="element">
            <div class="lbl align-self-item">
                {{ 'PREFERENCES.FORWARD_INVITE' | translate }}:
            </div>
            <div class="item">
                <div class="section-description">
                    {{ 'PREFERENCES.FOR_MY_SHARED_DEFAULT_CAL_INVITE' | translate }}
                </div>
                <div>
                    <mat-form-field class="md-block mb0 email-address-input" style="width:60%;">
                        <input matInput type="email" matInput placeholder="{{ 'PREFERENCES.ENTER_EMAIL' | translate}}"
                            formControlName="zimbraPrefCalendarForwardInvitesTo">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
        <div class="element">
            <div class="lbl align-self-item">
                {{ 'PREFERENCES.SHOW_REMINDERS_LBL' | translate }}:
            </div>
            <div class="item">
                <div>
                    <mat-form-field>
                        <mat-select formControlName="zimbraPrefCalendarApptReminderWarningTime">
                            <mat-option [value]="'0'">{{ 'PREFERENCES.NEEVER_LBL' | translate }}</mat-option>
                            <mat-option [value]="'-1'">{{ 'PREFERENCES.AT_TIME_OF_EVENT' | translate }}
                            </mat-option>
                            <mat-option [value]="'1'">{{ 'PREFERENCES.1_MINUTE_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'5'">{{ 'PREFERENCES.5_MINUTE_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'10'">{{ 'PREFERENCES.10_MINUTE_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'15'">{{ 'PREFERENCES.15_MINUTE_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'30'">{{ 'PREFERENCES.30_MINUTE_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'45'">{{ 'PREFERENCES.45_MINUTE_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'60'">{{ 'PREFERENCES.60_MINUTE_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'120'">{{ 'PREFERENCES.2_HOUR_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'180'">{{ 'PREFERENCES.3_HOUR_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'240'">{{ 'PREFERENCES.4_HOUR_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'300'">{{ 'PREFERENCES.5_HOUR_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'1080'">{{ 'PREFERENCES.18_HOUR_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'1440'">{{ 'PREFERENCES.1_DAY_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'2880'">{{ 'PREFERENCES.2_DAY_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'4320'">{{ 'PREFERENCES.3_DAY_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'5760'">{{ 'PREFERENCES.4_DAY_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'10080'">{{ 'PREFERENCES.1_WEEK_BEFORE' | translate }}
                            </mat-option>
                            <mat-option [value]="'20160'">{{ 'PREFERENCES.2_WEEK_BEFORE' | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-checkbox formControlName="zimbraPrefCalendarShowPastDueReminders">
                        {{ 'PREFERENCES.SHOW_REMINDER_PAST_MEETINGS' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="zimbraPrefCalendarReminderSoundsEnabled">
                        {{ 'PREFERENCES.PLAY_A_SOUND' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="zimbraPrefCalendarReminderFlashTitle">
                        {{ 'PREFERENCES.FLASH_BROWSER_TITLE' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="zimbraPrefCalendarToasterEnabled">
                        {{ 'PREFERENCES.SHOW_POPUP_NOTIFICATION' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.DEFAULT_APPOINTMENT_DURATION' | translate }}:
            </div>
            <div class="item">
                <mat-form-field>
                    <mat-select formControlName="zimbraPrefCalendarDefaultApptDuration">
                        <mat-option [value]="'30m'">{{ 'PREFERENCES.30_MINUTES_LBL' | translate }}
                        </mat-option>
                        <mat-option [value]="'60m'">{{ 'PREFERENCES.60_MINUTES_LBL' | translate }}
                        </mat-option>
                        <mat-option [value]="'90m'">{{ 'PREFERENCES.90_MINUTES_LBL' | translate }}
                        </mat-option>
                        <mat-option [value]="'120m'">{{ 'PREFERENCES.120_MINUTES_LBL' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row">
            <div class="button-actions footer-actions">
                <button class="save" (click)="savePreferences()">{{'COMMON.SAVE'| translate }}</button>
                <button class="cancel" (click)="cancelPreferences()">{{'CANCEL'| translate }}</button>
                <button class="save" (click)="undoChange()"> {{ 'UNDO_CHANGES' | translate }}</button>
            </div>
        </div>
    </form>
</div>