
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ConfigService } from "../config.service";
import { Observable, BehaviorSubject } from "rxjs";
import { SearchItem } from "../shared/models/search-item";

@Injectable()
export class HeaderService {

  openSubFolder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  openRefineSearchDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private httpService: HttpService,
              private configService: ConfigService) {}

  searchRequest(query: string, types: string): Observable<any> {
    const body = {
      limit: "10",
      query: query,
      types: types
    };
    return this.httpService.post<any>(this.configService.API_URL + "api/searchRequest", body);
  }

  parseSearchItem(res: any): SearchItem[] {
    const searchItem: SearchItem[] = [];
    if (res.m) {
      if (res.m.su) {
        searchItem.push({type: "message", item: res.m.su});
      } else {
        res.m.map(message => {
          if (message.su) {
            searchItem.push({type: "message", item: message.su});
          }
        });
      }
    }
    return searchItem;
  }
}
