
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, Inject, ChangeDetectorRef, OnInit, AfterViewInit, NgZone } from "@angular/core";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Subject } from "rxjs";
import { takeUntil, filter, take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Grant, CalendarFolder } from "src/app/common/models/calendar.model";
import { CommonService } from "src/app/services/common.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { CalendarState } from "src/app/calendar/store/reducers";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { getUserProfile } from "src/app/reducers";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-folder-revoke-share",
    templateUrl: "./share-folder-revoke-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareFolderRevokeComponent implements OnDestroy, OnInit {

    grant: Grant;
    mailFolder: CalendarFolder;
    messageType: string = "1";
    note: string = "";
    private isAlive$ = new Subject<boolean>();
    fromAddress: string;
    grantorId: string;
    constructor(
        private dialogRef: MatDialogRef<ShareFolderRevokeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectionRef: ChangeDetectorRef,
        private hotKeyService: NgxHotkeysService,
        private commonSerice: CommonService,
        private toastService: ToastService,
        private store: Store<CalendarState>,
        private translate: TranslateService,
        private mailBroadcaster: Broadcaster,
        private ngZone: NgZone
    ) {
        this.hotKeyService.pause(this.hotKeyService.hotkeys);
        this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
            if (res.email) {
                this.checkEmailArray(res.email);
            } else {
                this.checkEmailArray(CalenderUtils.getEmailFromStorage());
            }
        });
        this.grant = this.data.grant;
        this.mailFolder = this.data.targetFolder;
        this.grantorId = this.data.grantorId;
        this.changeDetectionRef.markForCheck();
    }

    ngOnDestroy() {
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    ngOnInit() {
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_REVOKE_SHARE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    close(value?: boolean): void {
        this.dialogRef.close({revoke: value});
    }

    checkEmailArray(emails): void {
        if (Array.isArray(emails)) {
            this.fromAddress = emails[0];
        } else if (emails) {
            this.fromAddress = emails;
        }
    }

    sendRevoke(): void {
        if (this.grant.gt === "pub") {
            this.messageType = "2";
            this.folderAction();
            return;
        }
        if (this.messageType === "1") {
            this.folderAction();
            const createEmail = this.createEmail(this.grant);
            this.sendEmail(
                createEmail.subject,
                createEmail.htmlContent,
                createEmail.plainContent,
                createEmail.xmlShare,
                this.grant
            );
        } else if (this.messageType === "2") {
            this.folderAction();
        } else if (this.messageType === "3") {
            this.folderAction();
            const createEmail = this.createEmail(this.grant);
            this.sendEmail(
                createEmail.subject,
                createEmail.htmlContent,
                createEmail.plainContent,
                createEmail.xmlShare,
                this.grant
            );
        }
    }

    folderAction(): void {
        const zid = this.grant.gt === "pub" ? "99999999-9999-9999-9999-999999999999" : this.grant.zid;
        const body = {
            zid: zid,
            id: this.mailFolder.id,
            op: "!grant"
        };
        this.commonSerice.folderAction(body).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.toastService.show("REVOKE_SHARE_MSG");
            if (this.messageType === "2") {
                this.close(true);
            }
        });
    }

    createEmail(grant: Grant): any {
        const folderName = this.mailFolder.name;
        const grantZid = grant.gt === "guest" ? grant.zid : grant.d;
        let plainText = "";
        this.translate.get("REVOKE_PLAIN_TEXT", {
            fromAddress: this.fromAddress,
            folderName: folderName,
            grantee: grantZid
        }).pipe(take(1)).subscribe((text: string) => {
            plainText = text;
        });

        let htmlContent = "";
        this.translate.get("REVOKE_HTML_TEXT", {
            fromAddress: this.fromAddress,
            folderName: folderName,
            grantee: grantZid
        }).pipe(take(1)).subscribe((text: string) => {
            htmlContent = text;
        });

        if (this.note !== "" && this.note !== undefined) {
            plainText = plainText + "\n*~*~*~*~*~*~*~*~*~*\n" + this.note;

            htmlContent = htmlContent +
                "<table border='0' cellpadding='3' cellspacing='0' style='color:#333;font:1em sans-serif;'>" +
                "<tr valign='top'><th align='right'>Notes:</th><td>" + this.note + "</td></tr>" +
                "</table>";
        }

        const xmlShare = "<share xmlns='urn:zimbraShare' version='0.2' action='delete' >" +
            "<grantee id=\"" + grant.zid + "\" email=\"" + grantZid + "\" name=\"" + grantZid + "\" />" +
            "<grantor id=\"" + this.grantorId + "\" email=\"" + this.fromAddress + "\" name=\"" + this.fromAddress + "\" />" +
            "<link id=\"" + this.mailFolder.id + "\" name=\"" + folderName + "\" view=\"message\" perm=\"r\" />" +
            "<notes>" + this.note + "</notes>" +
            "</share>";

        let subject = "";
        this.translate.get("SHARE_REVOKE_MAIL_SUBJECT", {
            fromAddress: this.fromAddress,
            folderName: folderName
        }).pipe(take(1)).subscribe((text: string) => {
            subject = text;
        });

        return { plainText: plainText, htmlContent: htmlContent, xmlShare: xmlShare, subject: subject };
    }

    sendEmail(subject: string, htmlContent: string, plainContent: string, shareContent: string, grant: Grant) {
        const grantee = grant.gt === "guest" ? grant.zid : grant.d;
        const request = {
            su: subject,
            content: htmlContent,
            e: [
                {
                    a: grantee,
                    t: "t"
                },
                {
                    a: this.fromAddress,
                    t: "f"
                }
            ],
            mp: [
                {
                    "@": {
                        ct: "multipart/alternative"
                    },
                    mp: [
                        {
                            "@": {
                                ct: "text/plain"
                            },
                            content: plainContent
                        },
                        {
                            "@": {
                                ct: "text/html"
                            },
                            content: htmlContent
                        },
                        {
                            "@": {
                                ct: "xml/x-zimbra-share"
                            },
                            content: shareContent
                        }
                    ]
                }
            ]
        };

        const sendRequest = {
            SendMsgRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                m: request
            }
        };

        this.commonSerice.createBatchRequest(sendRequest).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.toastService.show("REVOKE_SHARE_MSG");
            this.close(true);
        });
    }

}
