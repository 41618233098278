
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-mobile-tags-dialog mail-dialog">
    <div class="mail__dialog-header" layout="row center-center">
        <div>
            <button id="mobile_Sidebar_tag_backbtn" (click)="close()" class="brand-color mat-button">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div id="mobile_Sidebar_tag_header" class="header-title ">
            <span class="disable-select">{{ 'TAGS' | translate}}</span>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="sidebar-list">
            <div fxLayout="row" fxLayoutAlign="start center" class="tag-search-bar">
                <input id="mobile_Sidebar_tag_searchbar" matInput [(ngModel)]="searchText" type="text" placeholder="{{'FIND_TAG' | translate}}" />
                <button mat-icon-button>
                    <mat-icon id="mobile_Sidebar_tag_searchicon" class="disable-select">search</mat-icon>
                </button>
            </div>
            <mat-list id="mobile_Sidebar_searchlist">
                <mat-list-item *ngFor="let tag of tags | vpTagNameSearch : searchText; let i = index;" (click)="openTagEvent(tag)" vpLongPress [timeout]="500" (onLongPress)="$event.stopPropagation();openTagListOperation(tag)">
                    <div class="folder-icon disable-select">
                        <mat-icon [style.color]="tag.rgb || tag.color || defaultColor" class="disable-select">local_offer</mat-icon>
                    </div>
                    <div class="folder-title disable-select">
                        <span class="disable-select">{{ tag.name }}</span>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</div>
<vp-mail-operation-button (click)="createNewTag()" [iconType]="globalOperationIcon"></vp-mail-operation-button>