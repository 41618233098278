
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, NgZone } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { take, takeUntil } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { ResizeEvent } from "angular-resizable-element";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";

@Component({
    selector: "vp-calendar-find-location-dialog",
    templateUrl: "./calendar-find-location-dialog.component.html"
})
export class CalendarFindLocationDialogComponent implements OnInit, OnDestroy {
    nameField: string = "";
    descriptionField: string = "";
    contactField: string = "";
    siteField: string = "";
    buildingField: string = "";
    floorField: string = "";
    calResource: any[] = [];
    selectedCalResouce: any[] = [];
    selectedItems: any[] = [];
    selectedLocationItem: any[] = [];
    usersData: any[] = [];
    isMobileScreen: boolean = false;
    allowMultipleLocation: boolean = false;
    columns: any[] = [];
    selectedColumns: any[] = [];
    private isAlive$ = new Subject<boolean>();
    dateInfo: any;
    itemsById: any = {};
    constructor(
        public dialogRef: MatDialogRef<CalendarFindLocationDialogComponent>,
        private commonService: CommonService,
        private toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private breakpointObserver: BreakpointObserver,
        private translateService: TranslateService,
        private broadcaster: Broadcaster,
        private ngZone: NgZone
    ) {
        this.translateService.get([
            "CALENDARS.NAME_LBL",
            "CALENDARS.LOCATION_EQUIPMENT_LBL",
            "CALENDARS.CONTACT_EQUIPMENT_LBL",
            "CALENDARS.CAPACITY_LBL",
            "CALENDARS.STATUS_EQUIPMENT_LBL"
        ]).pipe(take(1)).subscribe(res => {
            this.columns = [{
                width: "100px",
                title: res["CALENDARS.NAME_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.LOCATION_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CONTACT_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CAPACITY_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.STATUS_EQUIPMENT_LBL"]
            }];
            this.selectedColumns = [{
                width: "100px",
                title: res["CALENDARS.NAME_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.LOCATION_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CONTACT_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CAPACITY_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.STATUS_EQUIPMENT_LBL"]
            }];
        });
        if (this.data.usersData) {
            const uData = this.data.usersData;
            uData.map(item => {
                this.selectedCalResouce.push({
                    id: item.email,
                    name: item.name,
                    email: item.email,
                    resourceType: "location",
                    fullName: item.email,
                    capacity: "",
                    contactEmail: ""
                });
            });
        }
        if (this.data.dateInfo) {
            this.dateInfo = this.data.dateInfo;
        }
    }

    ngOnInit() {
        this.breakpointObserver
    .observe(["(max-width: 599px)"])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobileScreen = true;
      } else {
        this.isMobileScreen = false;
      }
    });

    this.broadcaster.on<any>(BroadcastKeys.HIDE_FIND_LOCATION_DIALOG).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.ngZone.run(() => {
            this.close();
        });
      });

    }

    ngOnDestroy() {
        this.isAlive$.complete();
        this.isAlive$.next(false);
    }

    close(): void {
        this.dialogRef.close();
    }

    searchRequest(): void {
        const request = {
            "SearchCalendarResourcesRequest": {
                "@": {
                    xmlns: "urn:zimbraAccount"
                },
                attrs: "fullName,email,zimbraCalResLocationDisplayName," +
                "zimbraCalResCapacity,zimbraCalResContactEmail,description,zimbraCalResType",
                limit: 50,
                needExp: 1,
                offset: 0,
                searchFilter: {
                    conds: {
                        cond: [{
                            attr: "zimbraCalResType",
                            op: "eq",
                            value: "Location"
                        }]
                    }
                }
            }
        };
        if (this.nameField !== "") {
            request.SearchCalendarResourcesRequest["name"] = this.nameField;
        }
        if (this.descriptionField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "description",
                "op": "has",
                "value": this.descriptionField
            });
        }
        if (this.contactField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResCapacity",
                "op": "has",
                "value": this.descriptionField
            });
        }
        if (this.siteField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResSite",
                "op": "has",
                "value": this.siteField
            });
        }
        if (this.buildingField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResBuilding",
                "op": "has",
                "value": this.buildingField
            });
        }
        if (this.floorField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResFloor",
                "op": "has",
                "value": this.floorField
            });
        }
        this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
            if (!!res && res.SearchCalendarResourcesResponse && res.SearchCalendarResourcesResponse[0].calresource) {
                const calendarResouce = res.SearchCalendarResourcesResponse[0].calresource;
                this.calResource = [];
                const emails = [];
                calendarResouce.map(cal => {
                    if (cal._attrs.zimbraCalResType === "Location") {
                        emails.push(cal._attrs.email);
                        this.calResource.push({
                            id: cal.id,
                            name: cal.name,
                            email: cal._attrs.email,
                            resourceType: cal._attrs.zimbraCalResType,
                            fullName: cal._attrs.fullName,
                            capacity: cal._attrs.zimbraCalResCapacity ? cal._attrs.zimbraCalResCapacity : "",
                            contactEmail: cal._attrs.zimbraCalResContactEmail ? cal._attrs.zimbraCalResContactEmail : ""
                        });
                    }
                });
                this.commonService.getFreeBusy({ startTime: this.dateInfo.startTime.getTime(), endTime: this.dateInfo.endTime.getTime(), uid: emails.join(",") }).subscribe(v => {
                    console.log("[getFreeBusy]", this.dateInfo, v);
                    this._handleResponseFreeBusy(emails, v);
                });
            } else {
                this.calResource = [];
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    private _handleResponseFreeBusy(emails, result) {
        let itemsById = {};
        for (let email of emails) {
            let item: any = {};
            item = { txt: "STATUS_UNKNOWN", className: "unknown-status" };
            itemsById[email] = item;
        }

        let args = result.usr;
        for (let i = args.length; --i >= 0;) {
            let el = args[i];
            let id = el.id;
            if (!id) {
                continue;
            }
            let item = itemsById[id];
            if (!item) {
                continue;
            }
            let status = "STATUS_WORKING";
            item.status = 0;
            item.className = "free-status";
            if (el.b) {
                status = "STATUS_BUSY";
                item.status = 1;
                item.className = "busy-status";
            } else if (el.u) {
                status = "STATUS_OUT";
                item.status = 2;
                item.className = "outofoffice-status";
            } else if (el.t) {
                status = "STATUS_TENTATIVE";
                item.className = "tentative-status";
                item.status = 3;
            }
            item.txt = status;
            itemsById[id] = item;
        }
        this.itemsById = itemsById;
        console.log("[_handleResponseFreeBusy]", itemsById);
    }

    selectEvent(item: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedItems.indexOf(item) === -1) {
            if ($event.ctrlKey) {
                if (this.allowMultipleLocation) {
                    this.selectedItems.push(item);
                } else {
                    this.selectedItems = [];
                    this.selectedItems.push(item);
                }
            } else {
                this.selectedItems = [];
                this.selectedItems.push(item);
            }
        } else {
            if (this.selectedItems.length > 1) {
                this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
            }
        }
    }

    isSelectedItem(item: any): boolean {
        return this.selectedItems.indexOf(item) !== -1;
    }

    selectAll(): void {
        this.calResource.map(item => {
            this.selectedCalResouce.push(item);
        });
        this.selectedItems = [];
        this.calResource = [];
    }

    addItem(): void {
        this.selectedItems.map(item => {
            this.selectedCalResouce.push(item);
            this.calResource.splice(this.calResource.indexOf(item), 1);
        });
        this.selectedItems = [];
    }

    selectResouceEvent(item: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedLocationItem.indexOf(item) === -1) {
            if ($event.ctrlKey) {
                if (this.allowMultipleLocation) {
                    this.selectedLocationItem.push(item);
                } else {
                    this.selectedLocationItem = [];
                    this.selectedLocationItem.push(item);
                }
            } else {
                this.selectedLocationItem = [];
                this.selectedLocationItem.push(item);
            }
        } else {
            if (this.selectedLocationItem.length > 1) {
                this.selectedLocationItem.splice(this.selectedLocationItem.indexOf(item), 1);
            }
        }
    }

    isSelectedLocationItem(item: any): boolean {
        return this.selectedLocationItem.indexOf(item) !== -1;
    }

    removeLocation(): void {
        this.selectedLocationItem.map(item => {
            this.calResource.push(item);
            this.selectedCalResouce.splice(this.selectedCalResouce.indexOf(item), 1);
        });
        this.selectedLocationItem = [];
    }

    removeAll(): void {
        this.selectedCalResouce.map(item => {
            this.calResource.push(item);
        });
        this.selectedLocationItem = [];
        this.selectedCalResouce = [];
    }

    submit(): void {
        console.log("[Selected Location]", this.selectedCalResouce);
        this.dialogRef.close({ location: this.selectedCalResouce });
    }

    removeFromClose(item: any): void {
        this.calResource.push(item);
        this.selectedCalResouce.splice(this.selectedCalResouce.indexOf(item), 1);
        this.selectedLocationItem = [];
    }

    onResizeEnd(event: ResizeEvent, columnName: string): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
        }
    }
}
