
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  GeneralComponent
} from "./general/general.component";
import { PreferenceComponent } from "./preference.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { WorkWeekHourComponent } from "./work-week-hours/work-week-hour.component";
import { PermissionPreferenceComponent } from "./permissions/permissions.component";
import { AppleIcalComponent } from "./apple-ical/apple-ical.component";
import { CreateAppointmentSettingComponent } from "./create-appointment/create-appointment-setting.component";
import { PreferencesDialogComponent } from "../shared/components/preferences-dialog/preferences.component";
import { SecurityComponent } from "./security/security.component";

const routes: Routes = [
  {
    path: "",
    component: PreferenceComponent,
    children: [
      {
        path: "",
        redirectTo: "general",
        pathMatch: "full",
      },
      {
        path: "prefOptions",
        component: PreferencesDialogComponent
      },
      {
        path: "general",
        component: GeneralComponent,
      },
      {
        path: "work-week-hours",
        component: WorkWeekHourComponent
      },
      {
        path: "permissions-setting",
        component: PermissionPreferenceComponent
      },
      {
        path: "apple-ical",
        component: AppleIcalComponent
      },
      {
        path: "create-appointment-setting",
        component: CreateAppointmentSettingComponent
      },
      {
        path: "notifications",
        component: NotificationsComponent
      },
      {
        path: "security",
        component: SecurityComponent
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule
  ]
})
export class PreferenceRoutingModule { }
