
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { CalendarFolder } from "src/app/common/models/calendar.model";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import { Preference } from "src/app/preference/shared/models";

export class CalenderUtils {

    /* tslint:disable */
    static addrPat = /(((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*"(([^\\"])|(\\([^\x0A\x0D])))+"\s*))\@((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*\[(\s*(([^\[\]\\])|(\\([^\x0A\x0D])))+)*\s*\]\s*)))/;

    static addrAngleQuotePat = /(\s*<'(((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*"(([^\\"])|(\\([^\x0A\x0D])))+"\s*))\@((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*\[(\s*(([^\[\]\\])|(\\([^\x0A\x0D])))+)*\s*\]\s*)))'>\s*)/;

    static addrAnglePat = /(\s*<(((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*"(([^\\"])|(\\([^\x0A\x0D])))+"\s*))\@((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*\[(\s*(([^\[\]\\])|(\\([^\x0A\x0D])))+)*\s*\]\s*)))>\s*)$/;

    static addrPat1 = /(^|"|\s)(((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*"(([^\\"])|(\\([^\x0A\x0D])))+"\s*))\@((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*\[(\s*(([^\[\]\\])|(\\([^\x0A\x0D])))+)*\s*\]\s*)))/;

    static addrOnlyPat = /^((((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*"(([^\\"])|(\\([^\x0A\x0D])))+"\s*))\@((\s*([^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+(\.[^\x00-\x1F\x7F\u0080-\uFFFF()<>\[\]:;@\,."\s]+)*)\s*)|(\s*\[(\s*(([^\[\]\\])|(\\([^\x0A\x0D])))+)*\s*\]\s*))))$/;

    static COLOR__RE = /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})/i;
    static lightOrDark(color) {
        /* tslint:disable */
        if (color === "cyan" || color === "yellow" || color === "pink" || color === "orange") {
            return "light";
        }
        // Variables for red, green, blue values
        let r, g, b, hsp;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            r = color[1];
            g = color[2];
            b = color[3];
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace(
                color.length < 5 && /./g, "$&$&"));
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {
            return "light";
        } else {
            return "dark";
        }
    }

    static getChildFolders(folders: CalendarFolder[]): CalendarFolder[] {
        let allFolders: CalendarFolder[] = [];
        let childFolders: CalendarFolder[] = [];
        folders.filter(f => f.folder && f.folder.length > 0).forEach(f => {
            childFolders = [...childFolders, ...f.folder];
            allFolders = CalenderUtils.getChildFolders(childFolders);
        });
        return [...allFolders, ...childFolders];
    }

    static getProfileFromStorage(): any {
        if (!!localStorage.profileUser) {
            return JSON.parse(localStorage.profileUser);
        } else {
            return {};
        }
    }

    static copyToClipboard(stringArray: String[]): void {
        let str = stringArray.join("\n");
        window.Clipboard = (function (window, document, navigator) {
            let textArea,
                copy;
            function isOS() {
                return navigator.userAgent.match(/ipad|iphone/i);
            }
            function createTextArea(text) {
                textArea = document.createElement("textArea");
                textArea.value = text;
                document.body.appendChild(textArea);
            }
            function selectText() {
                let range,
                    selection;
                if (isOS()) {
                    range = document.createRange();
                    range.selectNodeContents(textArea);
                    selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    textArea.setSelectionRange(0, 999999);
                } else {
                    textArea.select();
                }
            }
            function copyToClipboard() {
                document.execCommand("copy");
                document.body.removeChild(textArea);
            }
            copy = function (text) {
                createTextArea(text);
                selectText();
                copyToClipboard();
            };
            return {
                copy: copy
            };
        })(window, document, navigator);
        window.Clipboard.copy(str);
    }

    static getParentById(flatFolders: any, parentId: string): CalendarFolder {
        let parent: CalendarFolder;
        flatFolders.map(f => {
            if (f.l && f.id === parentId) {
                parent = f;
            } else {
                const childFolders = CalenderUtils.getChildFolders([f]);
                if (!!childFolders && childFolders.length > 0) {
                    childFolders.map(child => {
                        if (child.id === parentId) {
                            parent = f;
                        }
                    })
                }
            }
        });
        return parent;
    }

    static addChildFolder(parent: CalendarFolder, newFolder: any): void {
        if (parent.id === newFolder.l) {
            if (!parent.folder) {
                parent.folder = [newFolder as CalendarFolder];
            } else if (!parent.folder.find(fd => fd.id === newFolder.id)) {
                parent.folder.push(newFolder as CalendarFolder);
                parent.folder = _.sortBy(parent.folder, f => f.name.toLowerCase());
            }
        } else if (parent.folder && parent.folder.length > 0) {
            parent.folder = parent.folder.map(folder => {
                CalenderUtils.addChildFolder(folder, newFolder);
                return folder;
            });
        }
    }

    static removeChildFolder(parent: CalendarFolder, deletedFolder: any): void {
        if (parent.id === deletedFolder.l) {
            _.remove(parent.folder, { id: deletedFolder.id });
        } else if (parent.folder && parent.folder.length > 0) {
            parent.folder = parent.folder.map(folder => {
                CalenderUtils.removeChildFolder(folder, deletedFolder);
                return folder;
            });
        }
    }

    static updateChildFolder(parent: CalendarFolder, updatedFolder: any): CalendarFolder {
        parent.folder = parent.folder.map(folder => {
            if (folder.id === updatedFolder.id) {
                folder = { ...folder, ...updatedFolder };
                console.log("[updateChildFolder]", folder);
                return folder;
            } else if (folder.folder && folder.folder.length > 0) {
                folder = CalenderUtils.updateChildFolder(folder, updatedFolder);
            }
            return folder;
        });
        return parent;
    }

    static getCalendarFolderById(flatFolders: any, id: string): CalendarFolder {
        let folder: CalendarFolder;
        flatFolders.map(f => {
            if (f.l && f.id === id) {
                folder = f;
            } else {
                const childFolders = CalenderUtils.getChildFolders([f]);
                if (!!childFolders && childFolders.length > 0) {
                    childFolders.map(child => {
                        if (child.id === id) {
                            folder = child;
                        }
                    })
                }
            }
        });
        return folder;
    }

    static getEmailFromStorage(): string {
        const profile = CalenderUtils.getProfileFromStorage();
        if (!!profile) {
            return this.checkEmailArray(profile.email);
        }
        return "";
    }

    static checkEmailArray(emails): string {
        if (Array.isArray(emails)) {
            return emails[0];
        } else if (emails) {
            return emails;
        }
    }

    static isZimbraFeatureEnabled(pref: Preference[], featureName: string): boolean {
        let isEnabled = true;
        if (pref.length > 0) {
            pref.filter( p => {
                 if (p.key === featureName && p.value === "FALSE") {
                     isEnabled = false;
                 }
            });
        }
        return isEnabled;
    }

    static calendarTimeLineScroll(): void {
        setTimeout(() => {
            const timeLine = document.querySelector(".timeline-bar");
            if (timeLine !== null && !!timeLine ) {
                console.log("[Calendar] Calendar Time Line Scroll to current time");
                timeLine.scrollIntoView();
            }
        }, 500);
    }

    static _getColors (color): any {
        color = color;
        var hs = { bgcolor: this.darken(color, 0) };
        var hd = { bgcolor: this.deepen(hs.bgcolor, 0.9) };
        var bs = { bgcolor: this.lighten(color, 0.5)  };
        var bd = { bgcolor: this.deepen(bs.bgcolor, 0.9) };
        var cs = this.components(hs.bgcolor);
        var cd = this.components(hd.bgcolor);
        var ss = cs[0]+cs[1]+cs[2];
        var sd = cd[0]+cd[1]+cd[2];
        if (ss/sd > 1 - 0.3) {
            hs.bgcolor = this.lighten(hd.bgcolor, 0.3);
            bs.bgcolor = this.lighten(bd.bgcolor, 0.3);
        }
        return { standard: { header: hs, body: bs }, deeper: { header: hd, body: bd } };
    };

    static components(color): any {
        var m = this.COLOR__RE.exec(color);
        return m ? [parseInt(m[1],16),parseInt(m[2],16),parseInt(m[3],16)] : null;
    }

    static __lighten(value, delta): any {
        return Math.max(0, Math.min(255, value + (255-value)*delta));
    }

    static __darken (value, delta): any {
        return Math.max(0, Math.min(255, value + (1-value)*delta));
    };

    static deepen(color, adjustment): any {
        var comps = this.components(color);
        var index = 0;
        for (var i = 1; i < comps.length; i++) {
            if (comps[i] > comps[index]) {
                index = i;
            }
        }
        for (var i = 0; i < comps.length; i++) {
            var multiplier = comps[index] ? (comps[i] / comps[index]) : 1;
            comps[i] = Math.floor(comps[i] * multiplier * (adjustment || 1));
        }
        return this.color(comps[0],comps[1],comps[2]);
    };

    static __pad (value, width, prefix?): any {
        if (!prefix) prefix = "0";
        var s = String(value);
        for (var i = s.length; i < width; i++) {
            s = prefix + s;
        }
        return s;
    };

    static color(r, g, b): any {
        return [
            "#",
            this.__pad(Number(Math.round(r)).toString(16), 2),
            this.__pad(Number(Math.round(g)).toString(16), 2),
            this.__pad(Number(Math.round(b)).toString(16), 2)
        ].join("");
    };

    static _isDark(color): any {
        var c = this.components(color);
        return c[0]+c[1]+c[2] < 384;
    };

    static darken (color, delta): any {
        var comps = this.components(color);
        return comps ? this.color(
            this.__darken(comps[0],delta),
            this.__darken(comps[1],delta),
            this.__darken(comps[2],delta)
        ) : "";
    };

    static lighten(color, delta): any {
        var comps = this.components(color);
        return comps ? this.color(
            this.__lighten(comps[0],delta),
            this.__lighten(comps[1],delta),
            this.__lighten(comps[2],delta)
        ) : "";
    };

    static standardize_color(str): any {
        var ctx = document.createElement("canvas").getContext("2d");
        ctx.fillStyle = str;
        return ctx.fillStyle;
    }

    static getBackgroundGrandientColor(color: string): string {
        let colour = color;
        if (color.startsWith("#")) {
          const schemaColor = CalenderUtils._getColors(color)
          colour = schemaColor.standard.body.bgcolor;
        } else {
          if (color ===  "blue") {
            colour = "#98b6e9";
          } else if (color === "cyan") {
            colour = "#8ee7f9"
          } else if (color === "green") {
            colour = "#a6ddcf";
          } else if (color === "purple") {
            colour = "#cbafe4";
          } else if (color === "red") {
            colour = "#eaa6a6";
          } else if (color === "yellow") {
            colour = "#eaeb8d";
          } else if (color === "pink") {
            colour = "#edbfe2";
          } else if (color === "gray") {
            colour = "#d9e9e9";
          } else if (color === "orange") {
            colour = "#ecd49c";
          }
        }
        const grandient = "-webkit-linear-gradient(top, rgb(255, 255, 255), " + colour + ")";
        return grandient;
    }

    static _prelimCheck(str): any {
        const atIndex = str.indexOf('@');
        const dotIndex = str.lastIndexOf('.');
        return ((atIndex != -1) && (dotIndex != -1) && (dotIndex > atIndex) && (dotIndex != str.length - 1));
    }
    
    static validateAddress(str): any {
        str = str.trim();
        return this._prelimCheck(str) && this.addrOnlyPat.test(str);
    }
    
    static parseEmailAddress(str): any {
        let addr, name;
        str = str.trim();
        const prelimOkay = this._prelimCheck(str);
        const customInvalidEmailPats = [];
        if (!(prelimOkay && str.match(this.addrPat))) {
            return null;
        }
        let parts = str.match(this.addrAngleQuotePat) || str.match(this.addrAnglePat);
        if (parts && parts.length) {
            addr = parts[2];
            str = str.replace(this.addrAnglePat, '');
        }
        else {
            parts = str.match(this.addrPat1);
            if (parts && parts.length) {
                if (parts[1] === '"') {
                    return null;    // unmatched quote
                }
                
                var parts1 = str.match(this.addrPat);
                addr = parts1 && parts1.length && parts1[0] ? parts1[0].trim() : parts[0];
                str = str.replace(this.addrPat, '');
            }
        }
        if (!addr || !this.validateAddress(addr)) {
            return null;
        }
        for (let i = 0; i < customInvalidEmailPats.length; i++) {
            if (customInvalidEmailPats[i].test(addr)) {
                return null;
            }
        }
        if (str) {
            name = str.trim();
            name = name.replace(/\\"/g,"&quot;");
            name = name.trim()
            name = name.replace(/&quot;/g, '"');
        }
        const data = {
            addr: addr,
            name: name
        };
        return data;
    }

    static isPastDate(startDate: Date, isAllDay: boolean): boolean {
        const todaysDateTime = new Date();
        const todaysDate = new Date(new Date().setHours(0, 0, 0));
        const apptStartDate = new Date(new Date(startDate).setHours(0, 0, 0));
        const apptStartDateTime = new Date(startDate);
        let isApptDateInPast: boolean = false;
        let isApptTimeInPast: boolean = false;
        let pastDateTime: boolean = false;
        if (Date.parse(apptStartDate.toString()) - Date.parse(todaysDate.toString()) < 0) {
            isApptDateInPast = true;
        }
        if (!isAllDay) {
            var secondsElapsed = (apptStartDateTime.getTime() - todaysDateTime.getTime()) / 1000;
            if (secondsElapsed <  0) {
                isApptTimeInPast = true;
            }
        }
        if (isApptDateInPast && isApptTimeInPast) {
            pastDateTime = true;
        } else if (isApptDateInPast) {
            pastDateTime = true;
        } else if (isApptTimeInPast) {
            pastDateTime = true;
        }
        return pastDateTime;
    }

    static formatDeltaString(deltaMSec, isAllDay): any {
        if (deltaMSec > 0 && deltaMSec < 60000) {
            return "Now";
        }
        const prefix = deltaMSec < 0 ? "In" : "OverdueBy";
        deltaMSec = Math.abs(deltaMSec);
        let years  = 0;
        let months = 0;
        let days   = 0;
        let hours  = 0;
        let mins   = 0;
        let secs   = 0;
        years =  Math.floor(deltaMSec / (86400000 * 365));
        if (years !== 0) {
            deltaMSec -= years * 86400000 * 365;
        }
        months = Math.floor(deltaMSec / (86400000 * 30.42));
        if (months > 0) {
            deltaMSec -= Math.floor(months * 86400000 * 30.42);
        }
        days = Math.floor(deltaMSec / 86400000);
        hours = Math.floor(deltaMSec / 3600000);
        if (hours > 0) {
            deltaMSec -= hours * 3600000;
        }
        mins = Math.floor(deltaMSec / 60000);
        if (mins > 0) {
            deltaMSec -= mins * 60000;
        }
        secs = Math.floor(deltaMSec / 1000);
        if (secs > 30 && mins < 59) {
            mins++;
        }
        secs = 0;
        let amount;
        if (years > 0) {
            amount = "Years";
            if (years <= 3 && months > 0) {
                amount = "YearsMonths";
            }
        } else if (months > 0) {
            amount = "Months";
            if (months <= 3 && days > 0) {
                amount = "MonthsDays";
            }
        } else if (days > 0) {
            amount = "Days";
            if (!isAllDay && (days <= 2 && hours > 0)) {
                amount = "DaysHours";
            }
        } else {
            if (isAllDay) {
                amount ="Today";
            } else {
                if (hours > 0) {
                    amount = "Hours";
                    if (hours < 5 && mins > 0) {
                        amount = "HoursMinutes";
                    }
                } else {
                    amount = "Minutes";
                }
            }
        }
        const key = ["reminder", prefix, amount].join("");
        const args = [deltaMSec, years, months, days, hours, mins, secs];
        if (amount == "Minutes" && mins == 0) {
             return "Now";
        }
        return {key: key, args: args};
    }

    static indexOf(array, object, strict?): any {
        if (array) {
            for (var i = 0; i < array.length; i++) {
                var item = array[i];
                if ((strict && item === object) || (!strict && item == object)) {
                    return i;
                }
            }
        }
        return -1;
    }

    static plainTextToHTML(str: string): string {
        if (str !== undefined && str !== null) {
            return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
        } else {
            return "";
        }
    }

    static convertDateToTimezone(date: Date, inviteTZ: string, clientTZ: string): Date {
        const clientOffset =  moment().tz(clientTZ).utcOffset();
        const inviteOffset =  moment().tz(inviteTZ).utcOffset();
        return new Date(date.getTime() + (clientOffset - inviteOffset) * 60 * 1000);
    }

}
