
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<ul class="sidebar-nav">
  <li *ngFor="let r of navRoutes; index as i;" [ngClass]="{'current': r.url === currentUrl}">
    <div>
      <a routerLink="{{ r.url }}" class="layout-row">
        <mat-icon class="mdi-20px" fontSet="mdi" [fontIcon]="r.icon"></mat-icon>
        <span>{{ r.label | translate }}</span>
      </a>
    </div>
  </li>
</ul>
