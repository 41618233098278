
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="calendar-work-week-hour-settings">
    <form [formGroup]="preferenceForm">
        <div class="header">
            {{ 'PREFERENCES.WORK_WEEK_AND_HOURS' | translate }}
        </div>
        <div class="description">
            {{ 'PREFERENCES.TIME_ZONE_PREFERENCES_LBL' | translate }} : {{timezone}}
        </div>
        <mat-divider></mat-divider>
        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.WORK_WEEK' | translate }}:
            </div>
            <div class="item grid-column-days">
                <mat-checkbox formControlName="sunday">{{ 'PREFERENCES.SUN' | translate }}</mat-checkbox>
                &nbsp;
                <mat-checkbox formControlName="monday">{{ 'PREFERENCES.MON' | translate }}</mat-checkbox>
                &nbsp;
                <mat-checkbox formControlName="tuesday">{{ 'PREFERENCES.TUE' | translate }}</mat-checkbox>
                &nbsp;
                <mat-checkbox formControlName="wedensDay">{{ 'PREFERENCES.WED' | translate }}</mat-checkbox>
                &nbsp;
                <mat-checkbox formControlName="thursday">{{ 'PREFERENCES.THU' | translate }}</mat-checkbox>
                &nbsp;
                <mat-checkbox formControlName="friday">{{ 'PREFERENCES.FRI' | translate }}</mat-checkbox>
                &nbsp;
                <mat-checkbox formControlName="saturday">{{ 'PREFERENCES.SAT' | translate }}</mat-checkbox>
            </div>
        </div>

        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.WORK_HOURS' | translate }}:
            </div>
            <div class="item">
                <mat-form-field class="hunderd-size">
                    <mat-select formControlName="startHour">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="to-field">{{ 'CALENDARS.TO_WORD_LBL' | translate }}</span>
                <mat-form-field class="hunderd-size">
                    <mat-select formControlName="endHour">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="element">
            <div class="lbl">

            </div>
            <div class="item">
                <mat-radio-group formControlName="normalCustomeHours" (change)="normalCustomHourChange($event)">
                    <mat-radio-button value="normal">{{ 'PREFERENCES.NORMAL_LBL' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="custom">{{ 'PREFERENCES.CUSTOM_LBL' | translate }}
                        <button mat-button id="customize-button" class="customize-button" type="button"
                            (click)="openCustomHourDialog()"
                            [disabled]="disabledCustomButton">{{'PREFERENCES.CUSTOMIZE_LBL' | translate }}</button>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div fxLayout="row">
            <div class="button-actions footer-actions">
                <button class="save" (click)="savePreferences()">{{'COMMON.SAVE'| translate }}</button>
                <button class="cancel" (click)="cancelPreferences()">{{'CANCEL'| translate }}</button>
                <button class="save" (click)="undoChange()"> {{ 'UNDO_CHANGES' | translate }}</button>
            </div>
        </div>
    </form>
</div>