
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subject, Observable } from "rxjs";
import { takeUntil, take, debounceTime, filter } from "rxjs/operators";
import { ConfirmationData, ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { Router } from "@angular/router";
import { ContextMenuComponent } from "ngx-contextmenu";
import { CreateTagComponent } from "../create-tag/create-tag.component";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { MailTag } from "src/app/common/models/mail-tag.model";
import { RootState, getOnlineStatus } from "src/app/reducers";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { ContextMenuFixService } from "../../context-menu.service";

const DEFAULT_COLOR = "#a2aab3";
const COLOR_LIST = [DEFAULT_COLOR, "blue", "cyan", "green", "purple", "red", "yellow", "pink", "gray", "orange"];
@Component({
    selector: "vp-tags-list",
    templateUrl: "./tags-list.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsListComponent implements OnInit, OnDestroy {
    tags: MailTag[] = [];
    defaultColor = DEFAULT_COLOR;
    expanded: boolean = true;
    isDirectoryTag: boolean = false;
    @ViewChild("tagMenu", {static: false}) contextMenu: ContextMenuComponent;
    @Input() moduleType = "mail";
    @Output() selectTagEvent = new EventEmitter<MailTag>();
    @Output() deleteTagEvent = new EventEmitter<MailTag>();

    contextMenuActions = {
        "isEnableNewTag": true,
        "isEnableMarkRead": false,
        "isEnableRename": true,
        "isEnableDelete": true,
        "isEnableColorControl": true
    };
    tagColor: string = DEFAULT_COLOR;
    selectedTag: MailTag;
    private isAlive$ = new Subject<boolean>();
    private changeColor = new Subject();
    constructor(
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<RootState>,
        private router: Router,
        private dialog: MatDialog,
        private contextMenuService: ContextMenuFixService,
        private mailBroadcaster: Broadcaster,
        private calendarRepository: CalendarRepository,
        private configService: ConfigService
    ) {
        this.store.select(getOnlineStatus).pipe(filter(v => !!v), take(1)).subscribe(v => {
            if (this.configService.useVNCdirectoryAuth) {
                this.loadTags();
                this.isDirectoryTag = true;
                this.enalbledDisabledContextMenu();
            } else {
                setTimeout(() => {
                    this.loadTags();
                    this.isDirectoryTag = this.configService.useVNCdirectoryAuth;
                    this.enalbledDisabledContextMenu();
                }, environment.isCordova || environment.isElectron ? 3000 : 1000);
            }
        });
    }

    ngOnInit() {
        this.changeColor.pipe(
            debounceTime(1000)
        ).subscribe(e => {
            this.closeContextMenuOnScroll();
            this.calendarRepository.updateTagColor(this.selectedTag.id, this.tagColor);
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    openCalendarList(tag: MailTag): void {
        this.selectTagEvent.emit(tag);
    }

    deleteTag(tag: MailTag): void {
        const data: ConfirmationData = {
            action: "delete",
            titleKey: "WARNING",
            contentKey: "DELETE_TAG_CONFIRMATION",
            contentParam: { tagName: tag.name },
            actionKey: "YES"
        };
        this.openConfirmationDialog(data, { width: "300px", panelClass: "delete-tag-dialog-confirm" }).pipe(take(1)).subscribe(result => {
            if (result && result.confirmed) {
                this.calendarRepository.deleteTag(tag.id).subscribe(tagId => {
                    console.log("[deleteTag]", tagId);
                    if (tagId !== null) {
                        this.deleteTagEvent.emit(tag);
                    }
                });
            }
        });
    }

    deleteSelectedTag(): void {
        this.deleteTag(this.selectedTag);
    }

    private openConfirmationDialog(data: ConfirmationData, style: any): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: data,
            ...style
        });
        return dialogRef.afterClosed();
    }

    onContextMenu($event: MouseEvent, tag: MailTag) {
        $event.stopPropagation();
        $event.preventDefault();
        console.log("[onContextMenu]", tag);
        this.selectedTag = tag;
        this.tagColor = tag.rgb || tag.color || DEFAULT_COLOR;
        this.contextMenuService.show.next({
            contextMenu: this.contextMenu,
            event: $event,
            item: tag,
        });
    }

    updateDefaultColor(color: string) {
        this.tagColor = color;
        this.changeColor.next(true);
    }

    handleColorChange(event: any) {
        this.tagColor = event.color.hex;
        this.changeColor.next(event);
    }

    createNewTag() {
        this.dialog.open(CreateTagComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "tag_create_dialog",
            data: {isRename: false }
        });
    }

    renameTag() {
        this.dialog.open(CreateTagComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "tag_create_dialog",
            data: { tag: this.selectedTag, isRename: true }
        });
    }

    closeContextMenuOnScroll() {
    }

    getTagColor(color) {
        const isDark = CalenderUtils.lightOrDark(color) === "dark";
        if (isDark) {
            return "#ffffff";
        } else {
            return "#000000";
        }
    }

    loadTags(): void {
        console.log("[tag-list][loadTags]:", this.configService.useVNCdirectoryAuth);
        this.calendarRepository.getTagsList().pipe(takeUntil(this.isAlive$))
            .subscribe(tags => {
                this.tags = tags.map(tag => {
                    if (tag.color && COLOR_LIST[tag.color]) {
                        tag.color = COLOR_LIST[tag.color];
                    }
                    if (tag.rgb) {
                        tag.rgb = tag.rgb.toLowerCase();
                        if (tag.rgb === "#999") {
                            tag.rgb = "#999999";
                        }
                    }
                    return tag;
                });
                this.tags = _.sortBy(this.tags, t => t.name.toLowerCase());
                this.changeDetectionRef.markForCheck();
            });
    }

    enalbledDisabledContextMenu(): void {
        if (this.isDirectoryTag) {
            this.contextMenuActions.isEnableColorControl = false;
            this.contextMenuActions.isEnableDelete = false;
            this.contextMenuActions.isEnableMarkRead = false;
            this.contextMenuActions.isEnableNewTag = false;
            this.contextMenuActions.isEnableRename = false;
        }
    }
}
