
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { ElectronService } from "src/app/services/electron.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-legal-notice-dialog",
  templateUrl: "./legal-notice-dialog.component.html"
})
export class LegalNoticeDialogComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  language: string = "en";
  termsOfUse_en: string;
  termsOfUse_de: string;
  dataPrivacy_en: string;
  dataPrivacy_de: string;
  constructor(
    public dialogRef: MatDialogRef<LegalNoticeDialogComponent>,
    private mailBroadcaster: Broadcaster,
    private ngZone: NgZone,
    private electronService: ElectronService
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_LEGAL_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    const lang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.LANGUAGE_KEY)
        : localStorage.getItem(MailConstants.LANGUAGE_KEY);

    if (lang) {
      this.language = lang;
    }
    this.termsOfUse_en = "https://vnclagoon.com/terms";
    this.dataPrivacy_en = "https://vnclagoon.com/data-privacy-policy/";
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

}
