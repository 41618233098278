
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mail-dialog">
    <div class="mail__dialog-header" layout="row center-center">
        <div class="mobile-close" (click)="close()">
            <mat-icon>chevron_left</mat-icon>
        </div>
        <div class="desktop-header">
            {{ 'SETTINGS_TITLE' | translate }}
        </div>
        <div class="desktop-close" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="language-drop-down">
            <mat-form-field>
                <mat-label>{{ 'LANGUAGE_LBL' | translate }}</mat-label>
                <mat-select [(ngModel)]="language" id="general-lang-select" (selectionChange)="changeLanguage()">
                    <mat-option id="general-lang-eng" [value]="'en'">{{ 'ENGLISH_LBL' | translate }}</mat-option>
                    <mat-option id="general-lang-de" [value]="'de'">{{ 'GERMAN_LBL' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="language-drop-down">
            <mat-form-field>
                <mat-label>{{ 'THEME_LBL' | translate }}</mat-label>
                <mat-select panelClass="theme-mat-selection" id="general-theme-select" [(value)]="currentTheme" (selectionChange)="changeTheme($event)">
                    <mat-option id={{theme.id}} *ngFor="let theme of themes" [value]="theme.value">
                      {{theme.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="dialog-footer">
        <span class="update-settings-text">{{ 'UPDATE_SETTINGS' | translate }}</span>
        <button mat-button (click)="saveChanges()">
            <mat-icon>check</mat-icon>
        </button>
    </div>
</div>