
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CdkTreeModule } from "@angular/cdk/tree";
import { ImageCropperModule } from "ngx-img-cropper";
import { PlatformModule } from "@angular/cdk/platform";
import { UserAvatarComponent } from "./components/user-avtar/user-avatar.component";
import { FormatFileSizePipe } from "./pipes/format-file-size.pipe";
import { AutoFocusDirective, ScrollListDirective, VNCLongPressDirective } from "./directives";
import { DebounceClickDirective } from "./directives/debound-click.directive";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { AvatarDialogComponent } from "./components/avatar-dialog/avatar-dialog.component";
import { AvatarCropperDialogComponent } from "./components/avatar-cropper-dialog/avatar-cropper-dialog.component";
import { AppsDialogComponent } from "./components/apps-dialog/apps-dialog.component";
import { AppSwitcherComponent } from "./components/app-switcher";
import { ContextMenuModule, ContextMenuService } from "ngx-contextmenu";
import { QuillModule } from "ngx-quill";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CustomSpinnerComponent } from "./components/custom-spinner/custom-spinner.component";
import { TagNameSearchPipe } from "./pipes/mail-tag-search.pipe";
import { AutocompleteComponent } from "./components/autocomplete/autocomplete.component";
import { AppointmentReminderDialogComponent } from "./components/appointment-reminder/appointment-reminder.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ShareFolderComponent } from "./components/share-folder/share-folder.component";
import { TagsListComponent } from "./components/tags-list/tags-list.component";
import { CreateTagComponent } from "./components/create-tag/create-tag.component";
import { ColorChromeModule } from "ngx-color/chrome";
import { ShareFolderRevokeComponent } from "./components/share-folder-revoke-dialog/share-folder-revoke-dialog.component";
import { EditFolderPropertiesComponent } from "./components/folder-edit-properties/folder-edit-properties-dialog.component";
import { MoveCalendarDialogComponent } from "./components/move-calendar-dialog/move-calendar-dialog.component";
import { DateRangeSelectDialogComponent } from "./components/date-range-select-dialog/date-range-select-dialog.component";
import {
  ModifyAppointmentConfirmDialogComponent
} from "./components/modify-appointment-confirmation-dialog/modify-confirm-appointment.component";
import { ModifyRecurEventDialogComponent } from "./components/modify-recur-event-dialog/modify-recur-event-dialog.component";
import { RecoverCalendarDeleteComponent } from "./components/recover-calendar-delete/recover-calendar-delete.component";
import {
  ConfirmSendInviteDialogComponent
} from "./components/confirm-delete-send-invite-dialog/confirm-delete-send-invite-dialog.component";
import { MailOperationComposeComponent } from "./components/mail-operation-compose/mail-operation-compose.component";
import { MailOperationButtonComponent } from "./components/mail-operation-button/mail-operation-button.component";
import { MailAttachmentComponent } from "./components/mail-attachment/mail-attachment.component";
import { RemoveAllExceptionDialogComponent } from "./components/remove-all-exception-instance-dialog/remove-all-exception-dialog.component";
import { DeleteSeriesConfirmDialogComponent } from "./components/delete-series-confirm-dialog/delete-series-confirm-dialog.component";
import { CalendarEquipmentDialogComponent } from "./components/calendar-equipment-dialog/calendar-equipment-dialog.component";
import {
  CalendarEquipmentAutoCompleteComponent
} from "./components/calendar-equipment-auto-complete/calendar-equipment-autocomplete.component";
import { ConflictEquipmentDialogComponent } from "./components/conflict-equipment-dialog/conflict-equipment-dialog.component";
import { CalendarPrintDialogComponent } from "./components/calendar-print-dialog/calendar-print-dialog.component";
import { SaveChangeAppointmentDialogComponent } from "./components/save-change-appointment/save-change-appointment.component";
import { CalendarFindLocationDialogComponent } from "./components/calendar-find-location-dialog/calendar-find-location-dialog.component";
import { DesktopChangePasswordComponent } from "./components/desktop-change-password/desktop-change-password.component";
import { MobileChangePasswordComponent } from "./components/mobile-change-password/mobile-change-password.component";
import { AboutDialogComponent } from "./components/about-dialog/about-dialog.component";
import { MobileAboutDialogComponent } from "./components/mobile-about-dialog/mobile-about-dialog.component";
import { HelpFaqDialogComponent } from "./components/help-faq-dialog/help-faq-dialog.component";
import { LegalNoticeDialogComponent } from "./components/legal-notice-dialog/legal-notice-dialog.component";
import { ServicedeskDialogComponent } from "./components/servicedesk-dialog/servicedesk-dialog.component";
import { GeneralSettingsDialogComponent } from "./components/general-settings-dialog/general-settings-dialog.component";
import { MobileSettingsAllDialogComponent } from "./components/mobile-settings-dialog/mobile-settings-dialog.component";
import { SelectAddressesDialogComponent } from "./components/select-addresses-dialog/select-addresses-dialog.component";
import { MobileSelectAddressDialogComponent } from "./components/mobile-select-address-dialog/mobile-select-address-dialog.component";
import { PreferencesDialogComponent } from "./components/preferences-dialog/preferences.component";
import { CustomRepeatDialogComponent } from "./components/custom-repeat-dialog/custom-repeat-dialog.component";
import { DeleteEventNotifyDialogComponent } from "./components/delete-event-notify-user/delete-event-notify-user.component";
import { AddExternalCalendarDialogComponent } from "./components/add-external-calendar/add-external-calendar.component";
import { MobileCalendarContextMenuDialogComponent } from "./components/mobile-calendar-context-menu/mobile-calendar-contextmenu.component";
import { FindShareDialogComponent } from "./components/find-share-dialog/find-share-dialog.component";
import { TooltipDirective } from "./directives/tooltip.directive";
import { AppearanceDialogComponent } from "./components/appearance-dialog/appearance-dialog.component";
import { ResizableModule } from "angular-resizable-element";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { MatDividerModule } from "@angular/material/divider";
import { MatNativeDateModule } from "@angular/material/core";
import { AppointmentInviteReplyDialogComponent } from "./components/event-invite-operation-dialog/event-invite-operation-dialog.component";
import { MobileTagsComponent } from "./components/mobile-tags/mobile-tags.component";
import { MobileTagListOperationComponent } from "./components/tag-list-operation/tag-list-operation.component";
import { TFASettingsComponent } from "./components/tfa-settings/tfa-settings.component";
import { RouteToCreateEventComponent } from "./components/route-to-create-event/route-to-create-event.component";
import { SuggestPreferencesDialogComponent } from "./components/suggest-prefrence-dialog/suggest-preference-dialog.component";
import { EmailNotificationConfigureComponent } from "./components/email-notification-configure/email-notification-configure.component";
import { ContextMenuFixService } from "./context-menu.service";

const ANGULAR_MATERIALS = [
  CdkTreeModule,
  DragDropModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTreeModule,
  QuillModule,
  FlexLayoutModule,
  ContextMenuModule,
  MatTooltipModule,
  ScrollingModule,
  MatSortModule
];

const DIRECTIVES = [
  ScrollListDirective,
  VNCLongPressDirective,
  DebounceClickDirective,
  AutoFocusDirective,
  TooltipDirective
];

const DIALOGUES = [
  ConfirmationDialogComponent,
  AvatarDialogComponent,
  AvatarCropperDialogComponent,
  AppsDialogComponent,
  AppointmentReminderDialogComponent,
  ShareFolderComponent,
  TagsListComponent,
  CreateTagComponent,
  ShareFolderRevokeComponent,
  EditFolderPropertiesComponent,
  MoveCalendarDialogComponent,
  DateRangeSelectDialogComponent,
  ModifyAppointmentConfirmDialogComponent,
  ModifyRecurEventDialogComponent,
  RecoverCalendarDeleteComponent,
  ConfirmSendInviteDialogComponent,
  RemoveAllExceptionDialogComponent,
  DeleteSeriesConfirmDialogComponent,
  CalendarEquipmentDialogComponent,
  CalendarEquipmentAutoCompleteComponent,
  ConflictEquipmentDialogComponent,
  CalendarPrintDialogComponent,
  SaveChangeAppointmentDialogComponent,
  CalendarFindLocationDialogComponent,
  DesktopChangePasswordComponent,
  MobileChangePasswordComponent,
  AboutDialogComponent,
  MobileAboutDialogComponent,
  HelpFaqDialogComponent,
  LegalNoticeDialogComponent,
  ServicedeskDialogComponent,
  GeneralSettingsDialogComponent,
  MobileSettingsAllDialogComponent,
  SelectAddressesDialogComponent,
  MobileSelectAddressDialogComponent,
  CustomRepeatDialogComponent,
  DeleteEventNotifyDialogComponent,
  AddExternalCalendarDialogComponent,
  MobileCalendarContextMenuDialogComponent,
  FindShareDialogComponent,
  AppearanceDialogComponent,
  AppointmentInviteReplyDialogComponent,
  MobileTagsComponent,
  MobileTagListOperationComponent,
  TFASettingsComponent,
  SuggestPreferencesDialogComponent,
  EmailNotificationConfigureComponent
];

const PIPES = [
];

@NgModule({
  imports: [
    CommonModule,
    PlatformModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    ImageCropperModule,
    ColorChromeModule,
    ResizableModule,
    ...ANGULAR_MATERIALS
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ...ANGULAR_MATERIALS,
    // Directives
    ...DIRECTIVES,

    // Pipes
    ...PIPES,
    UserAvatarComponent,
    FormatFileSizePipe,
    TagNameSearchPipe,
    SafeHtmlPipe,
    AppSwitcherComponent,
    CustomSpinnerComponent,
    AutocompleteComponent,
    AppointmentReminderDialogComponent,
    ShareFolderComponent,
    TagsListComponent,
    CreateTagComponent,
    ShareFolderRevokeComponent,
    EditFolderPropertiesComponent,
    MoveCalendarDialogComponent,
    DateRangeSelectDialogComponent,
    ModifyAppointmentConfirmDialogComponent,
    ModifyRecurEventDialogComponent,
    RecoverCalendarDeleteComponent,
    ConfirmSendInviteDialogComponent,
    MailOperationButtonComponent,
    MailOperationComposeComponent,
    MailAttachmentComponent,
    RemoveAllExceptionDialogComponent,
    DeleteSeriesConfirmDialogComponent,
    CalendarEquipmentDialogComponent,
    CalendarEquipmentAutoCompleteComponent,
    ConflictEquipmentDialogComponent,
    CalendarPrintDialogComponent,
    SaveChangeAppointmentDialogComponent,
    CalendarFindLocationDialogComponent,
    DesktopChangePasswordComponent,
    MobileChangePasswordComponent,
    AboutDialogComponent,
    MobileAboutDialogComponent,
    HelpFaqDialogComponent,
    LegalNoticeDialogComponent,
    ServicedeskDialogComponent,
    GeneralSettingsDialogComponent,
    MobileSettingsAllDialogComponent,
    SelectAddressesDialogComponent,
    MobileSelectAddressDialogComponent,
    PreferencesDialogComponent,
    CustomRepeatDialogComponent,
    DeleteEventNotifyDialogComponent,
    AddExternalCalendarDialogComponent,
    MobileCalendarContextMenuDialogComponent,
    FindShareDialogComponent,
    AppearanceDialogComponent,
    AppointmentInviteReplyDialogComponent,
    MobileTagsComponent,
    MobileTagListOperationComponent,
    TFASettingsComponent,
    RouteToCreateEventComponent,
    SuggestPreferencesDialogComponent,
    EmailNotificationConfigureComponent
  ],
  declarations: [
    // Directives
    ...DIRECTIVES,

    // Dialogues
    ...DIALOGUES,

    // Pipes
    ...PIPES,
    VNCLongPressDirective,
    UserAvatarComponent,
    FormatFileSizePipe,
    TagNameSearchPipe,
    SafeHtmlPipe,
    AppSwitcherComponent,
    CustomSpinnerComponent,
    AutocompleteComponent,
    AppointmentReminderDialogComponent,
    ShareFolderComponent,
    TagsListComponent,
    CreateTagComponent,
    ShareFolderRevokeComponent,
    EditFolderPropertiesComponent,
    MoveCalendarDialogComponent,
    DateRangeSelectDialogComponent,
    ModifyAppointmentConfirmDialogComponent,
    ModifyRecurEventDialogComponent,
    RecoverCalendarDeleteComponent,
    ConfirmSendInviteDialogComponent,
    MailOperationButtonComponent,
    MailOperationComposeComponent,
    MailAttachmentComponent,
    RemoveAllExceptionDialogComponent,
    DeleteSeriesConfirmDialogComponent,
    CalendarEquipmentDialogComponent,
    CalendarEquipmentAutoCompleteComponent,
    ConflictEquipmentDialogComponent,
    CalendarPrintDialogComponent,
    SaveChangeAppointmentDialogComponent,
    CalendarFindLocationDialogComponent,
    DesktopChangePasswordComponent,
    MobileChangePasswordComponent,
    AboutDialogComponent,
    MobileAboutDialogComponent,
    HelpFaqDialogComponent,
    LegalNoticeDialogComponent,
    ServicedeskDialogComponent,
    GeneralSettingsDialogComponent,
    MobileSettingsAllDialogComponent,
    SelectAddressesDialogComponent,
    MobileSelectAddressDialogComponent,
    PreferencesDialogComponent,
    CustomRepeatDialogComponent,
    DeleteEventNotifyDialogComponent,
    AddExternalCalendarDialogComponent,
    MobileCalendarContextMenuDialogComponent,
    FindShareDialogComponent,
    AppearanceDialogComponent,
    AppointmentInviteReplyDialogComponent,
    MobileTagsComponent,
    MobileTagListOperationComponent,
    TFASettingsComponent,
    RouteToCreateEventComponent,
    SuggestPreferencesDialogComponent,
    EmailNotificationConfigureComponent
  ],
  providers: [
    VNCLongPressDirective,
    FormatFileSizePipe,
    TagNameSearchPipe,
    {
      provide: ContextMenuService,
      useExisting: ContextMenuFixService
    }
  ],
  entryComponents: [
    ...DIALOGUES
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
      ]
    };
  }
}
