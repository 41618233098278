
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { SearchFolder } from "../shared/models/search-folder";

export class SearchActionTypes {
  static SEARCH_KEYWORD_STORE = "[SEARCH] Store Keyword";
  static LOAD_SEARCHFOLDERS_SUCCESS = "[SEARCH] Load Search Folder Success";
  static DELETE_SEARCHFOLDERS_SUCCESS = "[SEARCH] Delete Search Folder Success";
  static CREATE_SEARCHFOLDERS_SUCCESS = "[SEARCH] Create Search Folder Success";
  static SET_SEARCHFOLDERS_SUCCESS = "[SEARCH] Set Search Folder Success";
}

export class SearchKeywordStore implements Action {
  readonly type = SearchActionTypes.SEARCH_KEYWORD_STORE;

  constructor(public payload: string) {
  }
}

export class SaveSearchFolders implements Action {
  readonly type = SearchActionTypes.LOAD_SEARCHFOLDERS_SUCCESS;
  constructor(public payload: { folders: SearchFolder[]; }) {}
}

export class DeleteSearchFolder implements Action {
  readonly type = SearchActionTypes.DELETE_SEARCHFOLDERS_SUCCESS;
  constructor(public payload: { folder: SearchFolder; }) {}
}

export class CreateSearchFolder implements Action {
  readonly type = SearchActionTypes.CREATE_SEARCHFOLDERS_SUCCESS;
  constructor(public payload: { folder: SearchFolder; }) {}
}

export class SetSearchFolder implements Action {
  readonly type = SearchActionTypes.SET_SEARCHFOLDERS_SUCCESS;
  constructor(public payload: { folder: SearchFolder; }) {}
}
