
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil, take } from "rxjs/operators";
import { ElectronService } from "src/app/services/electron.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { ConfigService } from "src/app/config.service";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { environment } from "src/environments/environment";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-general-settings-dialog",
    templateUrl: "./general-settings-dialog.component.html"
})
export class GeneralSettingsDialogComponent implements OnDestroy {
    private isAlive$ = new Subject<boolean>();
    language: string = "en";
    isThemeChange: boolean = false;
    currentTheme: string = "default";
    themes = [
        { title: "Green", value: "green", id: "general-theme-green" },
        { title: "VNC", value: "default", id: "general-theme-vncmail" }
    ];

    constructor(
        public dialogRef: MatDialogRef<GeneralSettingsDialogComponent>,
        private mailBroadcaster: Broadcaster,
        private ngZone: NgZone,
        private electronService: ElectronService,
        private configService: ConfigService,
        private translate: TranslateService,
        private commonService: CommonService,
        private toastService: ToastService
    ) {
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_GENERAL_SETTINGS).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        let lang = "en";
        if (this.electronService.isElectron) {
            lang = this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE);
        } else {
            lang = localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        }
        if (!!lang && lang !== null) {
            this.language = lang;
        }
        this.currentTheme = localStorage.getItem(MailConstants.THEME) || environment.theme;
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    close(): void {
        this.dialogRef.close();
    }

    changeLanguage(): void {
    }

    saveChanges(): void {
        if (!this.isThemeChange) {
            this.commonService.modifyPrefs([{ key: "zimbraPrefLocale", value: this.language }]).pipe(take(1)).subscribe(res => {
                this.setSelectedLanguage(this.language);
                this.close();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        } else {
            let theme = "harmony";
            if (this.currentTheme === "green") {
                theme = "carbon";
            }
            this.commonService.modifyPrefs(
                [
                    { key: "zimbraPrefLocale", value: this.language },
                    { key: "zimbraPrefSkin", value: theme }
                ]
            ).pipe(take(1)).subscribe(res => {
                this.updateTheme();
                this.close();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        }
    }


    setSelectedLanguage(language: string): void {
        this.configService.setCurrentLanguage(language);
        if (this.electronService.isElectron) {
            this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, language);
        } else {
            localStorage.setItem(MailConstants.MAIL_LANGUAGE, language);
        }
        this.translate.use(language);
        this.translate.reloadLang(language);
    }

    changeTheme(ev): void {
        console.log("[SelectTheme] : ", ev.value);
        this.isThemeChange = true;
        this.currentTheme = ev.value;
    }

    updateTheme(): void {
        localStorage.setItem(MailConstants.THEME, this.currentTheme);
        if (environment.isCordova) {
            const initialHref = window.location.href.split("/www/")[0];
            window.location.href = initialHref + "/www/index.html";
        } else if (environment.isElectron) {
            const initialHref = window.location.href.split("/calendar")[0];
            window.location.href = `${initialHref}/index.html`;
        } else {
            self.location.reload();
        }
    }
}
