
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ENTER, SEMICOLON, FF_SEMICOLON } from "@angular/cdk/keycodes";
import {
  Component, OnInit, ViewChild, ElementRef, Input,
  ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, OnDestroy, Output, EventEmitter
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, takeUntil, filter } from "rxjs/operators";
import { Subject, of } from "rxjs";
import { Utils } from "../../../common";
import { MailConstants } from "../../../common/utils/mail-constants";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { CommonService } from "src/app/services/common.service";
import { EmailInformation } from "src/app/common/models/email-information.model";
import { Store } from "@ngrx/store";
import { RootState, getAllUserContacts } from "src/app/reducers";
import { MatAutocompleteTrigger, MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: "vp-autocomplete",
  templateUrl: "./autocomplete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, SEMICOLON, FF_SEMICOLON];
  emailCtrl = new FormControl();
  filteredEmails: string[];
  emails: any[] = [];
  searchedUsers: any[] = [];
  isMobile: boolean = Utils.isMobileDevice();
  private isAlive$ = new Subject<boolean>();
  allUserContacts: any[] = [];

  @Input() place: string;
  @Input() isFocused: false;
  conflictEmails = {};
  @Input() otherEmails = [];
  @Output() added = new EventEmitter<any>();
  @Output() removed = new EventEmitter<any>();
  @ViewChild("emailInput", {static: false}) emailInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", {static: false}) matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, {static: false}) autocompleteTrigger: MatAutocompleteTrigger;
  constructor(
    // private mailService: MailService,
    private changeDetectionRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    private commonService: CommonService,
    private store: Store<RootState>
  ) {
    this.emailCtrl.valueChanges.pipe(debounceTime(100)).subscribe(value => {
      this.loadEmailSuggestion(value);
    });
  }

  loadEmailSuggestion(query) {
    if (query === null) {
      this.filteredEmails = [];
      this.changeDetectionRef.markForCheck();
      return of(null);
    }
    query = query.trim();
    if (query === "") {
      this.filteredEmails = [];
      this.changeDetectionRef.markForCheck();
      return of(null);
    }
    this.commonService.getAutoCompleteList(query).subscribe(
      res => {
        this.searchedUsers = [];
        if (Array.isArray(res) && res.length > 0) {
          if (res.length > 0) {
            res.forEach(item => {
              this.generateTagList(item);
            });
          } else if (Utils.validateEmail(query)) {
            this.searchedUsers.push({ title: query, name: query, email: query, image: "", checked: false });
          }
        } else if (res.$) {
          this.generateTagList(res);
        }
        this._filter(query);
        this.changeDetectionRef.markForCheck();
      },
      err => {
        if (Utils.isJson(err._body)) {
          // this.mailService.openSnackBar(JSON.parse(err._body).msg);
        }
      }
    );
  }

  ngOnChanges(changes) {
    if (changes && changes.isFocused) {
      if (this.isFocused) {
        setTimeout(() => {
          this.emailInput.nativeElement.focus();
        }, 200);
      }
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || "").trim()) {
      if (Utils.validateEmail(value)) {
        const item = { title: value, name: value, email: value, image: "", checked: false };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
          this.added.emit(value);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  generateTagList(data) {
    const user = {
      title: "",
      name: "",
      email: "",
      image: "",
      checked: false,
      isGroup: false,
      id: "",
      display: false
    };
    if (!data.isGroup) {
      user.title = data.email ? data.email.replace(/"/g, "").trim() : "";
      user.name = data.email ? data.email.substring(data.email.indexOf(""), data.email.indexOf("<") - 1) : "";
      user.name = user.name ? user.name.replace(/"/g, " ").trim() : "";
      user.email = data.email ? data.email.substring(data.email.indexOf("<") + 1, data.email.indexOf(">")) : "";
      user.email = user.email ? user.email.replace(/"/g, " ").trim() : "";
    }
    if (data.isGroup && data.display) {
      user.name = data.display ? data.display.trim() : "";
      user.title = data.display ? data.display.trim() : "";
      user.email = data.display ? data.display.trim() : "";
      user.display = true;
    }
    if (data.isGroup && data.email) {
      user.title = data.email ? data.email.replace(/"/g, "").trim() : "";
      user.name = data.email ? data.email.substring(data.email.indexOf(""), data.email.indexOf("<") - 1) : "";
      user.name = user.name ? user.name.replace(/"/g, " ").trim() : "";
      user.email = data.email ? data.email.substring(data.email.indexOf("<") + 1, data.email.indexOf(">")) : "";
      user.email = user.email ? user.email.replace(/"/g, " ").trim() : "";
    }
    if (data.id) {
      user.id = data.id;
    }
    if (!!data.isGroup) {
      user.isGroup = data.isGroup;
    }
    if (user.name === "") {
      user.name = user.email;
    }
    if (user.name.length > 20) {
      user.name = user.name.substring(0, 20) + "...";
    }
    this.searchedUsers.push(user);
    return user;
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
      this.removed.emit(email);
      this.broadcaster.broadcast("AUTO_COMPLETE_REMOVE_ITEM");
      this.changeDetectionRef.markForCheck();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value.isGroup) {
      if (!this.isEmailExists(event.option.value.email)) {
        this.emails.push(event.option.value);
        this.added.emit(event.option.value);
      }
    } else if (event.option.value.isGroup) {
      if (event.option.value.display) {
        this.addEmailFromContactGroup(event.option.value);
        this.added.emit(event.option.value);
      } else if (!event.option.value.display) {
        this.emails.push(event.option.value);
        this.added.emit(event.option.value);
      }
    }
    this.emailInput.nativeElement.value = "";
    this.broadcaster.broadcast(MailConstants.BROADCAST_OVERFLOW_REDIRECT_DIALOG);
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  private _filter(value: string): void {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      this.filteredEmails = this.searchedUsers.filter(user => user.email.toLowerCase().includes(filterValue));
      this.changeDetectionRef.markForCheck();
    }
  }

  ngOnInit() {
    this.broadcaster.on<any>(MailConstants.CLOSE_AUTO_COMPLETE).pipe(takeUntil(this.isAlive$))
      .subscribe(presence => {
        if (this.autocompleteTrigger.openPanel) {
          this.autocompleteTrigger.closePanel();
          this.changeDetectionRef.markForCheck();
        }
      });

    this.store.select(getAllUserContacts).pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.allUserContacts = res;
      console.log("[getAllCuserContacts] : ", this.allUserContacts);
      this.changeDetectionRef.markForCheck();
    });
  }

  getSelectedEmail(): any[] {
    return this.emails;
  }

  setEmailField(emailInfo: EmailInformation): void {
    const item = { title: emailInfo.a, name: emailInfo.p ? emailInfo.p : emailInfo.d, email: emailInfo.a, image: "", checked: false };
    if (!this.isEmailExists(item.email)) {
      this.emails.push(item);
    }
    this.changeDetectionRef.markForCheck();
  }

  getAvatar(email) {
    return null; // this.convRepository.getAvatar(email);
  }

  resetEmail(): void {
    this.emails = [];
    this.changeDetectionRef.markForCheck();
  }

  onInputEvent(ev): void {
    if (typeof cordova !== "undefined") {
      if (ev.data === ";") {
        const input = ev.target;
        const value = ev.target.value.replace(/;/g, "");
        this.addEmailToChips(input, value);
      }
    }
  }

  addEmailToChips(input: any, value: string) {
    if ((value || "").trim()) {
      if (Utils.validateEmail(value)) {
        const item = { title: value, name: value, email: value, image: "", checked: false };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  onKeyDown(ev) {
    if (typeof cordova !== "undefined") {
      if (ev.key === "Backspace" && ev.target.value === "") {
        this.emails.pop();
        setTimeout(() => {
          this.emailInput.nativeElement.focus();
          this.autocompleteTrigger.closePanel();
        }, 50);
      }
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  isEmailExists(value: string): boolean {
    let exist = this.otherEmails && !!this.otherEmails.find(item => item.email === value);
    exist = exist || !!this.emails.find(item => item.email === value);
    console.log("[isEmailExists]", this.otherEmails, value, exist);
    return exist;
  }

  addEmailFromContactGroup(item: any): void {
    const contact = this.allUserContacts.filter(c => !!c && c.id === item.id)[0];
    if (!!contact && contact !== null) {
      if (!!contact.m && contact.m !== null) {
        const mails = contact.m;
        mails.map( dataItem => {
          if (dataItem.type === "C") {
            const id = dataItem.value;
            if (!!id && id !== null) {
              const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.id === id)[0];
              if (!!contactItem && contactItem !== null) {
                const email = contactItem._attrs.email;
                const fullName = contactItem._attrs.fullName;
                const body = {
                  email: email,
                  name: !!fullName && fullName !== null ? fullName : email
                };
                const user = this.getUserItem(body);
                this.emails.push(user);
              }
            }
          } else if (dataItem.type === "I") {
            const body = {
              email: dataItem.value,
              name: dataItem.value
            };
            const user = this.getUserItem(body);
            this.emails.push(user);
          } else if (dataItem.type === "G") {
            const id = dataItem.value;
            if (!!id && id !== null) {
              const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.value && c.value === id)[0];
              if (!!contactItem && contactItem !== null) {
                const email = contactItem._attrs.email;
                const fullName = contactItem._attrs.fullName;
                const body = {
                  email: email,
                  name: !!fullName && fullName !== null ? fullName : email
                };
                const user = this.getUserItem(body);
                this.emails.push(user);
              }
            }
          }
        });
      }
    }
  }

  getUserItem(contactItem: any): any {
    const user = {
      title: "",
      name: "",
      email: "",
      image: "",
      checked: false,
      isGroup: false,
      id: ""
    };
    user.title = contactItem.name;
    user.name = contactItem.name;
    user.email = contactItem.email;
    return user;
  }

  setConflictEmails(data) {
    this.conflictEmails = data;
    this.changeDetectionRef.markForCheck();
  }
  
  isConflict(email) {
    if (typeof this.conflictEmails[email] === "undefined") {
      return false;
    } else {
      return !this.conflictEmails[email];
    }
  }
}
