
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, NgZone } from "@angular/core";
import { UserProfile } from "../shared/models";
import {
  getUserProfile, getSearchKeyword, getAllSearchFolder,
  getIsLoggedIn, getOnlineStatus
} from "../reducers";
import { AuthService } from "../common/providers/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { ConfigService } from "../config.service";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil, filter, debounceTime } from "rxjs/operators";
import { CommonUtils } from "../common/utils/common-util";
import { BreakpointObserver } from "@angular/cdk/layout";
import { SearchState } from "../reducers/search";
import { environment } from "../../environments/environment";
import { HeaderService } from "../services/header.service";
import { SearchItem } from "../shared/models/search-item";
import { AppState } from "../reducers/app";
import { Logout } from "../actions/app";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { AvatarDialogComponent } from "../shared/components/avatar-dialog/avatar-dialog.component";
import { SearchFolder } from "../shared/models/search-folder";
import { AppsDialogComponent } from "../shared/components/apps-dialog/apps-dialog.component";
import { BroadcastKeys } from "../common/enums/broadcast.enum";
import { ElectronService } from "../services/electron.service";
import { ToastService } from "../common/providers/toast.service";
import { Broadcaster } from "../common/providers/broadcaster.service";
import { MobileChangePasswordComponent } from "../shared/components/mobile-change-password/mobile-change-password.component";
import { DesktopChangePasswordComponent } from "../shared/components/desktop-change-password/desktop-change-password.component";
import { AboutDialogComponent } from "../shared/components/about-dialog/about-dialog.component";
import { GeneralSettingsDialogComponent } from "../shared/components/general-settings-dialog/general-settings-dialog.component";
import { AppearanceDialogComponent } from "../shared/components/appearance-dialog/appearance-dialog.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import { AppService } from "../services/app.service";

@Component({
  selector: "vp-header",
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: UserProfile;
  searchKeywordItem: SearchItem[];
  historyKeywordItem: SearchItem[];
  searchKeyword: string;
  searchMode = false;
  private isAlive$ = new Subject<boolean>();
  showUserMenu: boolean = false;
  profileBackground = "url(" + CommonUtils.getFullUrl(environment.profileBackgroundImage) + ")";
  emailOptionExpanded: boolean = false;
  isSearchQueryExist: boolean = false;
  searchText: string = "";
  siteHeaderMenu = [
    { name: "MAIL", url: "/mail", icon: "email", badge: 0, display: true },
    { name: "CONTACTS", url: "/contact", icon: "person", badge: null, display: false },
    { name: "TASKS", url: "/task", icon: "event_available", badge: 0, display: false },
    { name: "CALENDAR", url: "/calendar", icon: "date_range", badge: 0, display: false },
  ];
  switchApp: boolean = false;
  currentUrl: string = "";
  searchFolders: SearchFolder[];
  isLoggedIn = false;
  isCordovaApp: boolean;
  isCordovaOrElectron: boolean;
  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;
  @ViewChild(MatAutocompleteTrigger, {static: false}) autocomplete: MatAutocompleteTrigger;
  penIcon = CommonUtils.getFullUrl("/assets/img/pen.svg");
  filterIcon = CommonUtils.getFullUrl("/assets/img/filter.svg");
  isMobileScreen: boolean = false;
  textLogo = environment.textLogo;
  appLogo = environment.appLogo;
  brandName = environment.mobileTextLogo;
  avatarURL: string;
  private searchKeyDown = new Subject();
  userManual: string = "https://en.docs.vnc.biz/vncmail/browsermanual/";
  mobileManual: string = "https://en.docs.vnc.biz/vncmail/appmanual/";
  serviceDeskURL: string = "https://vncproject.vnc.biz/helpdesk/incidents/new";
  faqURL: string = "https://portal.vnc.biz/product-area/faq/vncmail-faq";
  termsOfUse: string = "https://vnclagoon.com/terms";
  dataPrivacy: string = "https://vnclagoon.com/data-privacy-policy/";
  isSecurityFeatureEnabled: boolean = false;
  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private headerService: HeaderService,
    private matDialog: MatDialog,
    private broadCaster: Broadcaster,
    private _hotkeysService: NgxHotkeysService,
    private electronService: ElectronService,
    private toastService: ToastService,
    private ngZone: NgZone,
    private hotKeyService: NgxHotkeysService,
    private appService: AppService
  ) {
    console.log("[HeaderComponent] constructor", new Date());
    this.isCordovaApp = environment.isCordova;
    this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
    this.currentUrl = this.router.routerState.snapshot.url;
    this.changeDetectorRef.markForCheck();
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
  }

  ngOnInit() {
    console.log("[HeaderComponent] ngOnInit", new Date());
    this.store.select(getIsLoggedIn).pipe(takeUntil(this.isAlive$)).subscribe(isLoggedIn => {
      if (!!isLoggedIn) {
        this.isLoggedIn = isLoggedIn;
        this.changeDetectorRef.markForCheck();
      }
    });

    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
      let tempProfile = null;
      console.log("[getUserProfile] header", res);
      if (!!res) {
        tempProfile = res;
        tempProfile.email = this.getSingleEmail(res.email);
        this.currentUser = tempProfile;
        this.changeDetectorRef.markForCheck();
      } else {
        const contactUser = localStorage.getItem("profileUser");
        if (!!contactUser) {
          this.currentUser = CommonUtils.parseUserProfile(contactUser);
        }
      }
      let avatarId = "";
      if (!!this.currentUser) {
        if (environment.isElectron) {
          avatarId = this.electronService.md5(this.currentUser.email);
        } else {
          avatarId = md5(this.currentUser.email);
        }
        this.avatarURL = `${this.config.get("avatarURL")}/${avatarId}.jpg`;
      }
      this.changeDetectorRef.markForCheck();
    });


    this.searchKeyDown.pipe(
      debounceTime(500)
    ).subscribe(e => {
      this.searchOnKeyPress();
    });

    this.store.select(getSearchKeyword).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      setTimeout(() => {
        if (res) {
          const parsedQuery = CommonUtils.parseSearchQuery(res);
          this.searchText = parsedQuery.searchText;
          this.isSearchQueryExist = true;
        } else {
          this.isSearchQueryExist = false;
        }
      }, 200);
      this.changeDetectorRef.markForCheck();
    });

    this.router.events.pipe(takeUntil(this.isAlive$)).pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
      this.currentUrl = this.router.routerState.snapshot.url;
      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getAllSearchFolder).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.searchFolders = res;
      this.changeDetectorRef.markForCheck();
    });

    this.broadCaster.on<any>(MailConstants.UPDATE_UNREAD_COUNT).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        if (this.currentUrl.lastIndexOf("mail/tag/") !== -1) {
          this.siteHeaderMenu[0]["badge"] = null;
        } else {
          this.siteHeaderMenu[0]["badge"] = data.unreadCount;
        }
        this.changeDetectorRef.markForCheck();
      });

    this.broadCaster.on<any>(BroadcastKeys.HIDE_SEARCH_PANEL).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.ngZone.run(() => {
          this.searchMode = false;
          this.changeDetectorRef.markForCheck();
        });
      });

    this.broadCaster.on<any>(BroadcastKeys.HIDE_SIDEBAR_DRAWER).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.showUserMenu = false;
        this.updateMailListScrollBroadcast(false);
        this.changeDetectorRef.markForCheck();
      });

    this.broadCaster.on<any>(BroadcastKeys.OPEN_SIBAR_DRAWER).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.changeDetectorRef.markForCheck();
      });

    this.broadCaster.on<any>(BroadcastKeys.OPEN_PREFERENCE_TAB).pipe(takeUntil(this.isAlive$))
      .subscribe(pref_part => {
        if (pref_part && !this.isMobileScreen) {
          const prefrenceTab = this.siteHeaderMenu.find(tab => tab.name === "PREFERENCES_LBL");
          if (prefrenceTab) {
            prefrenceTab.url = "/preferences/" + pref_part;
          } else {
            this.siteHeaderMenu.push({
              name: "PREFERENCES_LBL", url: "/preferences/" + pref_part, icon: "settings_applications",
              badge: 0, display: true
            });
          }
          this.changeDetectorRef.markForCheck();
        }
      });

    this.config.getAllConfig().pipe(debounceTime(2000), takeUntil(this.isAlive$)).subscribe(res => {
        console.log("[HeaderComponent][getAllConfig]: ", res);
        if (this.isSecurityFeatureEnabled !== this.config.two_factor_authentication) {
          this.isSecurityFeatureEnabled = this.config.two_factor_authentication;
          this.changeDetectorRef.markForCheck();
        }
    });

    // this.broadCaster.on<any>(BroadcastKeys.OPEN_BRIEFCASE_TAB).pipe(takeUntil(this.isAlive$))
    //   .subscribe(pref_part => {
    //     if (this.siteHeaderMenu.filter(m => m.name === "BRIEFCASES_LBL").length === 0) {
    //       this.siteHeaderMenu.push({
    //         name: "BRIEFCASES_LBL", url: "/briefcases", icon: "settings_applications",
    //         badge: 0, display: true
    //       });
    //       this.changeDetectorRef.markForCheck();
    //     }
    //   });

    // this.broadCaster.on<any>(BroadcastKeys.REMOVE_BRIEFCASE_TAB).pipe(takeUntil(this.isAlive$))
    //   .subscribe(pref_part => {
    //     this.siteHeaderMenu = this.siteHeaderMenu.filter(m => m.name !== "BRIEFCASES_LBL");
    //     this.changeDetectorRef.markForCheck();
    //   });
  }

  updateMailListScrollBroadcast(isHide) {
    if (CommonUtils.isOnIOS) {
      this.broadCaster.broadcast(BroadcastKeys.HANDLE_MAIL_LIST_SCROLL, isHide);
    }
  }

  getSingleEmail(emails) {
    if (Array.isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
    return null;
  }

  openMenu(): void {
    this.trigger.openMenu();
  }

  countUnread(res): void {
    const arrayLength = res.length;
    for (let i = 0; i < arrayLength; i++) {
      if (res[i].u) {
        this.siteHeaderMenu[0]["badge"] += res[i].u;
      }
      if (res[i].children) {
        this.countUnread(res[i].children);
      }
    }
  }

  searchOnGoogle(): void {
    const url = "https://www.google.com/search?q=" + this.searchKeyword;

    if (this.electronService.isElectron) {
      this.electronService.openExternalUrl(url);
    } else if (typeof device !== "undefined") {
      if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    } else {
      window.open(url);
    }
  }

  toggleMobileSearch(): void {
    this.searchMode = !this.searchMode;
    this.clearSearchText();
  }

  search(): void {
    if (!this.searchKeyword) {
      return;
    }
    this.closeAutoComplete();
    this.broadCaster.broadcast("CALENDAR_SEARCH", {
      search: this.searchKeyword
    });
  }

  closeAutoComplete() {
    this.autocomplete.closePanel();
  }

  searchOnKeyUp() {
    // this.searchKeyDown.next(true);
  }

  searchOnKeyPress(): void {
    // this.searchKeywordItem = [];
    // this.historyKeywordItem = [];
    // if (this.searchKeyword !== "") {
    //   this.headerService.searchRequest(this.searchKeyword, "message").pipe(takeUntil(this.isAlive$)).subscribe((res) => {
    //     const items: SearchItem[] = this.headerService.parseSearchItem(res);
    //     this.searchKeywordItem.push(...items);
    //   }, error => {
    //     console.log("[SearchQuery Response] ", error);
    //     if (error.error.msg) {
    //       this.toastService.showPlainMessage(error.statusText);
    //     }
    //   });
    //   this.searchFolders.map(searchItem => {
    //     if (searchItem.name.indexOf(this.searchKeyword) !== -1) {
    //       const item: SearchItem = { item: searchItem.name, type: "history" };
    //       this.historyKeywordItem.push(item);
    //     }
    //   });
    // }
  }

  saveSearch() {
    this.broadCaster.broadcast(MailConstants.BROADCAST_SAVE_SEARCH, true);
  }

  openProfileDialog() {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      panelClass: "mail__dialog",
    };
    this.matDialog.open(AvatarDialogComponent, dialogArgs);
  }

  routeToSystemFolder(title) {
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    this.changeDetectorRef.markForCheck();
    if (this.router.url === "/mail" + title) {
      this.broadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
    }
    this.router.navigate(["/mail" + title]);
  }

  openPreferenceDialog() {
    this.router.navigate(["/preferences/", "prefOptions"]);
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    this.changeDetectorRef.markForCheck();
  }

  clearSearchText(): void {
    this.searchKeyword = "";
    this.searchOnKeyPress();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  siwtchAppMenuOption() {
    if (this.isMobileScreen) {
      this.showUserMenu = true;
      this.updateMailListScrollBroadcast(true);
    } else {
      this.switchApp = this.switchApp ? false : true;
      this.broadCaster.broadcast("SWITCH_APP_OPEN_CLOSE", {
        switchApp: this.switchApp
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  closeAppAwitcher() {
    this.switchApp = false;
    this.broadCaster.broadcast("SWITCH_APP_OPEN_CLOSE", {
      switchApp: this.switchApp
    });
    this.changeDetectorRef.markForCheck();
  }

  updateEmailExpanderPanel() {
    this.emailOptionExpanded = this.emailOptionExpanded ? false : true;
  }

  selectedOption(event: MatAutocompleteSelectedEvent): void {
    this.searchKeyword = event.option.value as string;
    this.searchOnKeyPress();
  }

  logout(): void {
    this.showUserMenu = false;
    if (environment.isCordova) { // request is sent to backend before we remove the token
      if (typeof window.FirebasePlugin !== "undefined") {
        window.FirebasePlugin.unregister();
        window.FirebasePlugin.clearAllNotifications(() => {
          console.log("[logoutWebAndApp][FirebasePlugin clearAllNotifications] success");
        }, error => {
            console.error("[logoutWebAndApp][FirebasePlugin clearAllNotifications] error", error);
        });
      }
      this.appService.setFirebaseToken().subscribe(res => {
        console.log("removed firebase token");
      });
    }
    this.auth.removeLogin();
    this.store.dispatch(new Logout());
    this.updateMailListScrollBroadcast(false);
    if (this.isCordovaOrElectron) {
      this.auth.cordovaLogout();
      let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/calendar")[0];
      initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref;
      window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
      this.config.loginIframe();
      this.changeDetectorRef.markForCheck();
    } else {
      window.location.href = "/api/call-logout";
    }
  }

  navigatorTo(url: string): void {
    this.router.navigate([url]);
  }

  openAppSwitcherDialog(): void {
    this.matDialog.open(AppsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  changePassword(): void {
    if (CommonUtils.isOnMobileDevice()) {
      this.openMobileChangePasswordDialog("zimbra" , "");
    } else {
      this.openChangePasswordDialog("zimbra" , "");
    }
  }

  openMobileChangePasswordDialog(type: string , changePasswordUrl: string): void {
    const dialogArgs = {
      maxWidth: "100vw",
      autoFocus: false,
      data: { type: type, changePasswordUrl: changePasswordUrl },
      panelClass: "mobile_change_password"
    };
    this.matDialog.open(MobileChangePasswordComponent, dialogArgs);
  }

  openChangePasswordDialog(type: string, changePasswordUrl: string): void {
    const dialogArgs = {
      width: "325px",
      autoFocus: false,
      data: { type: type, changePasswordUrl: changePasswordUrl },
      panelClass: "desktop_change_password"
    };
    this.matDialog.open(DesktopChangePasswordComponent, dialogArgs);
  }

  searchFocus() {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
  }

  searchFocusOut() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  generalSettings() {
    console.log("[generalSettings]");
  }

  aboutDialog(): void {
    this.matDialog.open(AboutDialogComponent, {
      maxWidth: "100%",
      width: "360px",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  openSettingsDialog(): void {
    this.matDialog.open(GeneralSettingsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar-general-settings"
    });
  }

  navigateToPreference(preference: string): void {
    this.router.navigate(["/preferences/" + preference]);
  }

  openAppearanceDialog(): void {
    this.matDialog.open(AppearanceDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "calendar-appearance-dialog"
    });
  }
}
