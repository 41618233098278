
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, NgZone, Component, Inject } from "@angular/core";
import * as moment from "moment-timezone";
import { TranslateService } from "@ngx-translate/core";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-calendar-work-hours-dialog",
    templateUrl: "./work-hours-dialog.component.html"
})
export class WorkHoursDialogComponent implements OnInit {
    changeLog: string = "";
    workHours: string = "";

    sunday: boolean = false;
    monday: boolean = false;
    tuesday: boolean = false;
    wednsday: boolean = false;
    thursday: boolean = false;
    friday: boolean = false;
    saturday: boolean = false;

    sundayStartTime: string = "";
    sundayEndTime: string = "";

    mondayStartTime: string = "";
    mondayEndTime: string = "";

    tuesdayStartTime: string = "";
    tuesdayEndTime: string = "";

    wednsdayStartTime: string = "";
    wednsdayEndTime: string = "";

    thursdayStartTime: string = "";
    thursdayEndTime: string = "";

    fridayStartTime: string = "";
    fridayEndTime: string = "";

    saturdayStartTime: string = "";
    saturdayEndTime: string = "";

    timezone: string = "";

    timeOption: Date[];
    browserLang: string = "en";

    constructor(
        public dialogRef: MatDialogRef<WorkHoursDialogComponent>,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private electronService: ElectronService,
        private translateService: TranslateService
    ) {
        this.workHours = this.data.workingHours;
        this.timezone = this.data.timeZone;
        this.timeOption = this.generateTimePointOptions();
        this.setWorkingHoursData();
    }

    ngOnInit() {
        this.browserLang = this.translateService.getBrowserLang();
        const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
        : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
            this.browserLang = localLang;
        }
        this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
        if (this.browserLang === "de") {
            registerLocaleData(localeDE, this.browserLang);
        } else {
            registerLocaleData(localEN, this.browserLang);
        }
    }

    close() {
        this.dialogRef.close();
    }

    private generateTimePointOptions(date?: Date): Date[] {
        date = date ? new Date(date) : new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        const timeList: Date[] = [];

        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                const newDate = new Date(date);
                newDate.setHours(i);
                newDate.setMinutes(j * 15);
                timeList.push(newDate);
            }
        }

        return timeList;
    }

    setWorkingHoursData(): void {
        const days = this.workHours.split(",");
        if (days.length > 0) {
            days.map(item => {
                const data = item.split(":");
                const d = data[0];
                const enable = data[1];
                const startTime = data[2];
                const endTime = data[3];
                const hourTime = this.getStartEndTimeForHours(startTime, endTime);
                if (d === "1") {
                    if (enable === "Y") {
                        this.sunday = true;
                    }
                    this.sundayStartTime = hourTime.start;
                    this.sundayEndTime = hourTime.end;
                } else if (d === "2") {
                    if (enable === "Y") {
                        this.monday = true;
                    }
                    this.mondayStartTime = hourTime.start;
                    this.mondayEndTime = hourTime.end;
                } else if (d === "3") {
                    if (enable === "Y") {
                        this.tuesday = true;
                    }
                    this.tuesdayStartTime = hourTime.start;
                    this.tuesdayEndTime = hourTime.end;
                } else if (d === "4") {
                    if (enable === "Y") {
                        this.wednsday = true;
                    }
                    this.wednsdayStartTime = hourTime.start;
                    this.wednsdayEndTime = hourTime.end;
                } else if (d === "5") {
                    if (enable === "Y") {
                        this.thursday = true;
                    }
                    this.thursdayStartTime = hourTime.start;
                    this.thursdayEndTime = hourTime.end;
                } else if (d === "6") {
                    if (enable === "Y") {
                        this.friday = true;
                    }
                    this.fridayStartTime = hourTime.start;
                    this.fridayEndTime = hourTime.end;
                } else if (d === "7") {
                    if (enable === "Y") {
                        this.saturday = true;
                    }
                    this.saturdayStartTime = hourTime.start;
                    this.saturdayEndTime = hourTime.end;
                }
            });
        }
    }

    getStartEndTimeForHours(start: string, end: string): any {
        let startHourMinutes: string = "";
        let endHourMinutes: string = "";
        if (!!start && !!end) {
            const m = moment();
            const startHours = start.substr(0, 2);
            const startMinutes = start.substr(2, 3);
            const endHours = end.substr(0, 2);
            const endMinutes = end.substr(2, 3);
            m.set({ hour: startHours, minute: startMinutes });
            startHourMinutes = m.format("hh:mm A");
            m.set({ hour: endHours, minute: endMinutes });
            endHourMinutes = m.format("hh:mm A");
        }
        return { start: startHourMinutes, end: endHourMinutes };
    }

    selectWorkHours(): void {
        const workingHours = "1:" + (this.sunday ? "Y" : "N") + ":" + this.getTime(this.sundayStartTime) + ":" +
        this.getTime(this.sundayEndTime) + "," +
            "2:" + (this.monday ? "Y" : "N") + ":" + this.getTime(this.mondayStartTime) + ":" + this.getTime(this.mondayEndTime) + "," +
            "3:" + (this.tuesday ? "Y" : "N") + ":" + this.getTime(this.tuesdayStartTime) + ":" + this.getTime(this.tuesdayEndTime) + "," +
            "4:" + (this.wednsday ? "Y" : "N") + ":" + this.getTime(this.wednsdayStartTime) + ":" +
            this.getTime(this.wednsdayEndTime) + "," +
            "5:" + (this.thursday ? "Y" : "N") + ":" + this.getTime(this.thursdayStartTime) + ":" +
            this.getTime(this.thursdayEndTime) + "," +
            "6:" + (this.friday ? "Y" : "N") + ":" + this.getTime(this.fridayStartTime) + ":" + this.getTime(this.fridayEndTime) + "," +
            "7:" + (this.saturday ? "Y" : "N") + ":" + this.getTime(this.saturdayStartTime) + ":" + this.getTime(this.saturdayEndTime);
        this.dialogRef.close({ workHours: workingHours });
    }

    getTime(time: string): string {
        const t = moment(time, ["h:mm A"]).format("HHmm");
        return t;
    }
}
