
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { Observable, of, Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../..//environments/environment";
import { map, take } from "rxjs/operators";
import { ConfigService } from "src/app/config.service";
import { MailConstants } from "../utils/mail-constants";
import { ElectronService } from "src/app/services/electron.service";

@Injectable()
export class AuthService {

  private AUTH_TOKEN_KEY: string;
  userRequest: any = null;
  redirectTo: string = null;
  isProfileUpdated$: Subject<boolean> = new Subject<boolean>();
  checkAuthenticationInterval: any;

  authUserChanged$ = new Subject<boolean>();

  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private config: ConfigService,
    private router: Router,
    private http: Http,
    private electronService: ElectronService
    ) {
    this.AUTH_TOKEN_KEY = this.config.get("AUTH_TOKEN_KEY");
  }

  public getProfile() {
    let headers = new Headers({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = this.electronService.isElectron
        ? this.electronService.getFromStorage("token")
        : localStorage.getItem("token");
      headers = new Headers({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.config.API_URL + "/api/profile", { headers: headers }).pipe(map(response => response.json()));
  }

  logout() {
    return this.http.get(this.config.API_URL + "/api/call-logout", {});
  }

  addAuthHeader(headers: Headers): Headers {
    // Appends an authentication header to headers
    const authorization = this.electronService.isElectron
        ? this.electronService.getFromStorage(this.AUTH_TOKEN_KEY)
        : localStorage.getItem(this.AUTH_TOKEN_KEY);
    if (authorization) {
      headers.append("Authorization", "Bearer " + authorization);
    }
    return headers;
  }

  cordovaLogout(): Observable<any> {
    return this.http.get(this.config.API_URL + `/api/cordova-logout`);
  }

  delAuthToken() {
    if (!this.checkAuthenticationInterval) {
      clearInterval(this.checkAuthenticationInterval);
    }

    let serverUrl = null;
    let language = "en";

    if (this.electronService.isElectron) {
      serverUrl = this.electronService.getFromStorage("serverURL");
      language = this.electronService.getFromStorage(MailConstants.LANGUAGE_KEY) || "en";
      localStorage.clear();
      this.electronService.clearStorage();
      localStorage.setItem("serverURL", serverUrl);
      this.electronService.setToStorage("serverURL", serverUrl);
      this.electronService.setToStorage(MailConstants.LANGUAGE_KEY, language);
    } else if (environment.isCordova) {
      if (environment.isCordova && typeof window.FirebasePlugin !== "undefined") {
        console.log("[delAuthToken()] Firebase plugin unregister");
        window.FirebasePlugin.clearAllNotifications();
        window.FirebasePlugin.unregister();
      }
      serverUrl = localStorage.getItem("serverURL");
      language = localStorage.getItem(MailConstants.LANGUAGE_KEY) || "en";
      localStorage.clear();
      localStorage.setItem("serverURL", serverUrl);
      localStorage.setItem(MailConstants.LANGUAGE_KEY, language);
    } else {
      language = localStorage.getItem(MailConstants.LANGUAGE_KEY) || "en";
      localStorage.clear();
      localStorage.setItem(MailConstants.LANGUAGE_KEY, language);
    }
  }

  isLogin(): boolean {
    const zimbraUser = this.electronService.isElectron
      ? this.electronService.getFromStorage(MailConstants.ZIMBRA_USER)
      : localStorage.getItem(MailConstants.ZIMBRA_USER);
    return !!zimbraUser;
  }

  removeLogin(redirect?: boolean): void {
    this.delAuthToken();
    if (environment.isCordova && !!plugins && plugins.appPreferences) {
      plugins.appPreferences.remove(() => {
        console.log("[AppService][removeAuthToken] success");
      }, () => {
        console.log("[AppService][removeAuthToken] failure");
      }, "auth-token");
    }
  }

  getZmAuthToken(): Observable <any> {
    let headers = new Headers({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = this.electronService.isElectron
        ? this.electronService.getFromStorage("token")
        : localStorage.getItem("token");
      headers = new Headers({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.config.API_URL + "/api/zimbraAuthToken", { headers: headers }).pipe(map(response => response.json()));
  }

  refreshZimbraAuth(): void {
    const timeExpiry = 3600000;
    if (!this.checkAuthenticationInterval) {
      this.checkAuthenticationInterval = setInterval(() => {
        const zimbra_token = this.electronService.isElectron
          ? this.electronService.getFromStorage("ZM_AUTH_TOKEN")
          : localStorage.getItem("ZM_AUTH_TOKEN");
        if (zimbra_token && zimbra_token !== undefined && zimbra_token !== "undefined") {
          this.generateNewToken();
        }
      }, timeExpiry);
    }
  }

  generateNewToken(): void {
    let headers = new Headers({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = this.electronService.isElectron
        ? this.electronService.getFromStorage("token")
        : localStorage.getItem("token");
      headers = new Headers({ "Content-Type": "application/json", "Authorization": token });
    }
    this.http.get(this.config.API_URL + "/api/refreshZimbraAuth", { headers: headers })
      .pipe(map(response => response.json())).pipe(take(1)).subscribe(res => {
        if (!!res && res.authToken) {
          if (this.electronService.isElectron) {
            this.electronService.setToStorage(MailConstants.ZM_AUTH_TOKEN, res.authToken);
            this.electronService.setToStorage("lastTokenGenerationTime", new Date().getTime());
          } else {
            localStorage.setItem(MailConstants.ZM_AUTH_TOKEN, res.authToken);
            localStorage.setItem("lastTokenGenerationTime", JSON.stringify(new Date().getTime()));
          }
        }
      });
  }

}
