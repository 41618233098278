
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<h2 class="preference-title">
    {{ preferenceTitle | translate }}
</h2>
<div class="security-section" *ngIf="isSupport2FA">
    <div fxLayout="row">
        <div fxLayoutAlign="start center" class="security-options">
            <mat-checkbox id="enable-2fa-check" [(ngModel)]="use2FA" class="filter-checkbox-header">{{ 'ENABLE_TWO_FACTOR_AUTHENTICATION' | translate }}</mat-checkbox>
        </div>
    </div>
    <div fxLayout="row" class="start-authenticator-button" *ngIf="use2FA && !is2FAEnabled">
        <button class="save" (click)="setAuthenticationApp()">{{'START_AUTHENTICATOR_APP' | translate }}</button>
    </div>
</div>

<div fxLayout="row" class="button_space" *ngIf="isSupport2FA">
    <div class="button-actions footer-actions">
        <button class="save" (click)="savePreferences()" [disabled]="use2FA || !is2FAEnabled">{{'COMMON.SAVE'|translate}}</button>
        <button class="cancel" (click)="cancelPreferences()">{{'CANCEL'|translate}}</button>
    </div>
</div>