
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-delete-calendar-event-series-confirm-dialog",
    templateUrl: "./delete-series-confirm-dialog.component.html"
})
export class DeleteSeriesConfirmDialogComponent implements OnInit, OnDestroy {
    deleteType: string = "occurence";
    constructor(
        public dialogRef: MatDialogRef<DeleteSeriesConfirmDialogComponent>
    ) {
    }

    ngOnInit() { }

    ngOnDestroy() { }

    close(): void {
        this.dialogRef.close();
    }

    closeDialog(closeType: string): void {
        if (closeType === "yes") {
            this.dialogRef.close({ value: this.deleteType });
        } else {
            this.close();
        }
    }
}
