
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, OnDestroy, NgZone } from "@angular/core";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ConfirmationData {
    action: string;
    titleKey: string;
    titleParam?: any;
    contentKey: string;
    contentParam?: any;
    extraClass?: string;
    actionKey: string;
    cancelKey?: string;
}

@Component({
  selector: "vp-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html"
})
export class ConfirmationDialogComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private broadcaster: Broadcaster,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) {
      this.broadcaster.on<any>(BroadcastKeys.HIDE_GENERAL_CONFIRM_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        this.ngZone.run(() => {
          this.cancel();
        });
      });
    }

    ok(): void {
        this.dialogRef.close({confirmed: true});
    }

    cancel(): void {
        this.dialogRef.close({confirmed: false});
    }

    ngOnDestroy() {
      this.isAlive$.next(false);
      this.isAlive$.unsubscribe();
    }
}
