
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, NgZone, OnInit, OnDestroy } from "@angular/core";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take, takeUntil } from "rxjs/operators";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";

@Component({
  selector: "vp-apps-dialog",
  templateUrl: "./apps-dialog.html"
})
export class AppsDialogComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  @Output() closeSwitch = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<AppsDialogComponent>,
    private broadcaster: Broadcaster,
    private ngZone: NgZone,
  ) {
    this.broadcaster.on<any>(BroadcastKeys.HIDE_APP_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
  }

  close(): void {
    this.dialogRef.close();
    this.closeSwitch.emit(false);
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.isAlive$.complete();
    this.isAlive$.next(false);
  }
}
