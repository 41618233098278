
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mail_edit_folder_properties_dialog mail-dialog">
    <div class="folder_properties__dialog-header mail__dialog-header mobile_header" layout="row center-center">
        <h4 class="heading_header" *ngIf="moduleType==='mail'">{{ 'EDIT_FOLDER' | translate}}</h4>
        <h4 class="heading_header" *ngIf="moduleType==='briefcase' || moduleType==='calendar'">{{ 'EDIT_PROPERTIES_LBL' | translate}}</h4>
        <div class="desktop-close-button" [fxHide.xs]="true">
            <button class="brand-color mat-button" (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mobile_heading">
        <h4 class="header" *ngIf="moduleType==='mail'"> {{ 'EDIT_FOLDER' | translate}}</h4>
        <h4 class="header" *ngIf="moduleType==='briefcase' || moduleType==='calendar'" id="edit-property-dialog-edit-header"> {{ 'EDIT_PROPERTIES_LBL' | translate}}</h4>
    </div>
    <div class="dialog_body mail__dialog-body mail__dialog-body--pad">

        <div class="content">
            <div>
                <mat-form-field>
                    <input [(ngModel)]="folderTitle" #folderNameInput matInput
                        placeholder="{{ 'FOLDER_NAME_LBL'| translate }}" (keyup)="changeText($event)" autocomplete="off"
                        autocorrect="off" autocapitalize="off" spellcheck="false" [maxLength]="maxFolderTitleLength"
                        [disabled]="data.targetFolder.id === '2' || data.targetFolder.id === '6' || data.targetFolder.id === '5' || data.targetFolder.id === '10'">
                    <mat-hint>{{folderNameInput.value.length}}/{{maxFolderTitleLength}}</mat-hint>
                </mat-form-field>
            </div>
            <ng-container *ngIf="moduleType === 'mail'">
            <div class="info">
                <div class="lbl"> {{ 'PREFERENCES.TYPE' | translate }} :</div>
                <div class="item"> {{ 'MAIL_FOLDER_LBL' | translate }} </div>
            </div>
            <div class="info">
                <div class="lbl">{{ 'MESSAGES' | translate }} :</div>
                <div class="item">{{ data.targetFolder.n ? data.targetFolder.n : 0 }} </div>
            </div>
            <div class="info" *ngIf="data.targetFolder.u">
                <div class="lbl"> {{ 'SELECT_UNREAD' | translate }} :</div>
                <div class="item">{{data.targetFolder.u}} </div>
            </div>
            <div class="info" *ngIf="data.targetFolder.s">
                <div class="lbl"> {{ 'SIZE' | translate }} :</div>
                <div class="item">{{data.targetFolder.s| vpFormatFileSize:false}} </div>
            </div>
            </ng-container>

            <ng-container *ngIf="moduleType === 'briefcase'">
                <div class="info">
                    <div class="lbl"> {{ 'SHARING_FOLDER_TYPE' | translate }} :</div>
                    <div class="item"> {{ 'BRIEFCASE_TITLE' | translate }} </div>
                </div>
            </ng-container>
            <div *ngIf="data.targetFolder.perm">
                <div class="info">
                    <div class="lbl"> {{ 'OWNER_LBL' | translate }} : </div>
                    <div class="item">{{data.targetFolder.owner}} </div>
                </div>
                <div class="info">
                    <div class="lbl">{{ 'PREFERENCES.PERMISSIONS' | translate }} : </div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='r'">{{ "VIEWER_VIEW" | translate }}</div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='rwidxc'">{{ "MANAGER_VIEW" | translate }}</div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='rwidxa'">{{ "ADMIN_VIEW" | translate }}</div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='rwidxac'">{{ "ADMIN_VIEW" | translate }}</div>
                </div>
            </div>

            <div class="color-action">
                <mat-radio-group class="create-folder-color-selection" (change)="updateDefaultColor($event)"
                    [(ngModel)]="folderColor">
                    <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
                    <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
                    <mat-radio-button class="blue-radio" value="#20ae80"></mat-radio-button>
                    <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
                    <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
                    <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
                    <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
                </mat-radio-group>
                <button (click)="openColorDialog()" class="brand-color mat-button">
                    <mat-icon class="disable-select">more_horiz</mat-icon>
                </button>
            </div>
            <div class="acl" *ngIf="data.targetFolder && data.targetFolder.acl && data.targetFolder.acl.grant && isSharingFeatureEnabled">
                <div class="heading">{{ 'SHARING_FOR_THIS_FOLDER' | translate }}</div>
                <div class="scroll_div">
                    <div *ngFor="let grantItem of grant;let i =index;" class="grantee">
                        <div class="desktop">
                            <div class="name">
                                <mat-icon>remove_red_eye</mat-icon>
                                <span *ngIf="grantItem.gt=== 'guest'">{{ grantItem.zid }}</span>
                                <span *ngIf="grantItem.gt=== 'pub'">{{ 'PUBLIC' | translate }}</span>
                                <span *ngIf="grantItem.gt=== 'grp' || grantItem.gt=== 'usr'">{{ grantItem.d }}</span>
                            </div>
                            <div class="btn">
                                <mat-icon class="operation" (click)="edit(grantItem)" *ngIf="grantItem.gt !== 'pub'">edit</mat-icon>
                                <mat-icon class="operation" (click)="revoke(grantItem, i)">close</mat-icon>
                                <mat-icon class="operation" (click)="resend(grantItem)" *ngIf="grantItem.gt !== 'pub'">refresh</mat-icon>
                            </div>
                        </div>
                        <span class="mobile">
                            <div class="name">
                                <span *ngIf="grantItem.gt=== 'guest'">{{ grantItem.zid }}</span>
                                <span *ngIf="grantItem.gt=== 'pub'">{{ 'PUBLIC' | translate }}</span>
                                <span *ngIf="grantItem.gt=== 'grp' || grantItem.gt=== 'usr'">{{ grantItem.d }}</span>
                            </div>
                            <div class="more" [matMenuTriggerFor]="granteeOperation"
                                [matMenuTriggerData]="{grantItem:grantItem}">
                                <mat-icon>more_vert</mat-icon>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="actions">
                <div *ngIf="isSharingFeatureEnabled">
                    <a (click)="shareDialog()" [hidden]="data.targetFolder.perm && data.targetFolder.perm==='r'" id="edit-property-dialog-add-share">{{
                        'ADD_SHARE' | translate }}</a>
                </div>
                <span *ngIf="moduleType==='mail'">
                <div class="retention_header_lbl">
                    {{ 'RETENTION_LBL_MSG' | translate }}
                </div>
                <div *ngIf="showRetention">

                    <div class="keep">
                        <mat-checkbox [(ngModel)]="enableRention" (change)="changeRetensionBox($event)">{{ 'ENABLE_MSG_RETENTION' | translate }}</mat-checkbox>
                        <div class="msg">
                            {{ 'RETENTION_MSG_RETENTION' | translate }}
                        </div>
                        <div class="settings">
                            <span>{{ 'RETENTION_RANGE' | translate }}:</span>
                            <span>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="custom" [disabled]="!enableRention">
                                        <mat-option [value]="'custom'">
                                            {{ 'CUSTOM' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput autocomplete="off" [disabled]="!enableRention" [(ngModel)]="retensionValue" type="number" min="1" onkeypress="return event.charCode >= 48">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="rangeUnit" [disabled]="!enableRention">
                                        <mat-option [value]="'d'">
                                            {{ 'DAYS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'w'">
                                            {{ 'WEEK_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'m'">
                                            {{ 'MONTHS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'y'">
                                            {{ 'YEARS_LBL' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                        </div>
                    </div>

                    <div class="purge">
                        <mat-checkbox [(ngModel)]="enableDisposal" (change)="changeDisposalBox($event)">{{ 'ENABLE_MSG_DISPOSAL' | translate }}</mat-checkbox>
                        <div class="msg">
                            {{ 'DISPOSAL_MSG_MSG' | translate }}
                        </div>
                        <div class="settings">
                            <span>{{ 'DISPOSAL_THRESHOLD' | translate }}:</span>
                            <span>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="custom" [disabled]="!enableDisposal">
                                        <mat-option [value]="'custom'">
                                            {{ 'CUSTOM' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput autocomplete="off" [disabled]="!enableDisposal" [(ngModel)]="thresholdValue" type="number" min="1" onkeypress="return event.charCode >= 48">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="thresholdUnit" [disabled]="!enableDisposal">
                                        <mat-option [value]="'d'">
                                            {{ 'DAYS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'w'">
                                            {{ 'WEEK_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'m'">
                                            {{ 'MONTHS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'y'">
                                            {{ 'YEARS_LBL' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                        </div>
                    </div>

                </div>
            </span>
            </div>
        </div>
    </div>
    <div class="footer_save_cancel">
        <span>{{ 'EDIT_FOLDER_LBL' | translate }}</span>
        <button mat-button (click)="folderAction()" [class.action-disabled]="folderTitle.length < 1">
            <mat-icon class="disable-select">check</mat-icon>
        </button>
    </div>
    <div class="footer_save_cancel_mobile">
        <button mat-button class="cancel_btn" (click)="closeDialog()" id="edit-property-dialog-cancel-btn">{{ "CANCEL_MAIL_LABEL" | translate }}</button>
        <button mat-button class="save_btn" [class.action-disabled]="folderTitle.length < 1"
            (click)="folderAction()" id="edit-property-dialog-save-btn">{{ "COMMON.SAVE" | translate }}</button>
    </div>
    <mat-menu #granteeOperation="matMenu" [class]="'edit-properties-operation-menu'">
        <ng-template matMenuContent let-grantItem="grantItem">
            <button mat-menu-item *ngIf="grantItem.gt !== 'pub'" (click)="edit(grantItem)">
                <mat-icon>edit</mat-icon>
                <span>{{'SHARE_EDIT'|translate}}</span>
            </button>
            <button mat-menu-item (click)="revoke(grantItem)">
                <mat-icon>close</mat-icon>
                <span>{{'SHARE_REVOKE'|translate}}</span>
            </button>
            <button mat-menu-item (click)="resend(grantItem)" *ngIf="grantItem.gt !== 'pub'">
                <mat-icon>refresh</mat-icon>
                <span>{{'SHARE_RESEND'|translate}}</span>
            </button>
        </ng-template>
    </mat-menu>
</div>
