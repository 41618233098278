
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, NgZone } from "@angular/core";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { ElectronService } from "src/app/services/electron.service";
import { CommonService } from "src/app/services/common.service";
import { take, takeUntil } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { Subject } from "rxjs";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";

@Component({
    selector: "vp-calendar-appearance-dialog",
    templateUrl: "./appearance-dialog.component.html"
})
export class AppearanceDialogComponent implements OnInit, OnDestroy {
    themes = [
        { title: "blue", value: "default", id: "general-theme-vncmail", selected: false, name: "Blue Ocean" },
        { title: "green", value: "green", id: "general-theme-green", selected: false, name: "Nature Green" }
    ];
    isThemeChange: boolean = false;
    currentUserTheme: any;
    isCordovaOrElectron: boolean;
    todayDate: Date;
    language: string = "en";
    private isAlive$ = new Subject<boolean>();

    constructor(
        public dialogRef: MatDialogRef<AppearanceDialogComponent>,
        private route: Router,
        private electronService: ElectronService,
        private commonService: CommonService,
        private toastService: ToastService,
        private broadcaster: Broadcaster,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        let lang = "en";
        if (this.electronService.isElectron) {
            lang = this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE);
        } else {
            lang = localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        }
        if (!!lang && lang !== null) {
            this.language = lang;
        }
        if (this.language === "en") {
            registerLocaleData(localEN);
        } else {
            registerLocaleData(localeDE);
        }
        this.todayDate = new Date();
        const currentTheme = localStorage.getItem(MailConstants.THEME) || environment.theme;
        this.currentUserTheme = currentTheme;
        this.selectTheme(currentTheme);
        this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
        this.broadcaster.on<any>(BroadcastKeys.HIDE_APPEARANCE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.complete();
        this.isAlive$.next(false);
    }

    close(): void {
        this.dialogRef.close();
    }

    selectTheme(currentTheme: string): void {
        this.themes.map(item => {
            if (item.value === currentTheme) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
    }

    themeChange(theme: any): void {
        this.currentUserTheme = theme;
        const themeValue = theme.value;
        this.isThemeChange = true;
        this.selectTheme(themeValue);
    }

    changeThemeUpdate(): void {
        let theme = "harmony";
        if (this.currentUserTheme.value === "green") {
            theme = "carbon";
        }
        this.commonService.modifyPrefs(
            [
                { key: "zimbraPrefSkin", value: theme }
            ]
        ).pipe(take(1)).subscribe(res => {
            this.updateTheme();
            this.close();
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    updateTheme(): void {
        if (this.isCordovaOrElectron) {
            this.navigateToLastTab();
        }
        setTimeout(() => {
            this.close();
            localStorage.setItem(MailConstants.THEME, this.currentUserTheme.value);
            if (environment.isCordova) {
                const initialHref = window.location.href.split("/www/")[0];
                window.location.href = initialHref + "/www/index.html";
            } else if (environment.isElectron) {
                const initialHref = window.location.href.split("/calendar")[0];
                window.location.href = `${initialHref}/index.html`;
            } else {
                self.location.reload();
            }
        }, 2000);
    }

    navigateToLastTab() {
        this.route.navigate(["/"]);
    }
}
