
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, pipe } from "rxjs";
import { SetFirebaseToken, SetProps, SetIncludeShared } from "../actions/app";
import { CommonUtils } from "../common/utils/common-util";
import { RootState, getProps, getIsDeviceReady } from "../reducers";
import { Store } from "@ngrx/store";
import { ConfigService } from "../config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { take, filter } from "rxjs/operators";
import { MailConstants } from "../common/utils/mail-constants";
import { CommonService } from "./common.service";

@Injectable()
export class AppService {

    private isCordovaOrElectron = environment.isCordova || environment.isElectron;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private store: Store<RootState>,
        private commonService: CommonService
    ) {
        this.pullFirebaseTokenFromStorage();
    }

    setFirebaseToken(token?: string) {
        let data = [];
        let fcm = {};
        this.store.select(getProps).pipe(take(1)).subscribe(props => {
            if (!!props) {
                data = props.filter(p => p.zimlet === "vncmail" && p.name === "calfcm");
                if (data.length > 0) {
                    try {
                        fcm = JSON.parse(data[0]["_content"]);
                    } catch (ex) {
                        console.log("parse fcm error", ex);
                    }
                }
            }
        });
        if (!!token) {
            fcm[device.uuid] = token;
        } else {
            delete fcm[device.uuid];
        }
        const subject = new Subject<any>();
        const request = {
            "ModifyPropertiesRequest": {
                "@": { "xmlns": "urn:zimbraAccount" },
                "prop": [{
                    "@": { "zimlet": "vncmail", "name": "calfcm" },
                    "#": JSON.stringify(fcm)
                }]
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.ModifyPropertiesResponse) {
                    subject.next(token);
                    this.pushFirebaseTokenToStore(token);
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    private pullFirebaseTokenFromStorage() {
        const token = localStorage.getItem("KEY_FIREBASE_TOKEN");

        if (token) {
            this.store.dispatch(new SetFirebaseToken(token));
        }
    }

    private pushFirebaseTokenToStore(token: string) {
        this.store.dispatch(new SetFirebaseToken(token));
        localStorage.setItem("KEY_FIREBASE_TOKEN", token);
    }

    private setAppBadgeCounter(counter) {
        console.log("[RootComponent][setAppBadgeCounter]", counter);
        if (CommonUtils.isOnIOS()) {
            // for iOS we get a badge counter from push notification when in background,
            // so from the app we update iy only when on foreground
            if (!window.appInBackground) {
                window.FirebasePlugin.setBadgeNumber(counter);
            }
        } else {
            window.FirebasePlugin.setBadgeNumber(counter);
        }
    }

    getProperties(): Observable<any> {
        const subject = new Subject<any>();
        const request = {
            GetInfoRequest: {
                "@": {
                    "xmlns": "urn:zimbraAccount",
                    "sections": "props"
                }
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.GetInfoResponse && res.GetInfoResponse[0] && res.GetInfoResponse[0].props && res.GetInfoResponse[0].props.prop) {
                    subject.next(res.GetInfoResponse[0].props.prop);
                    this.store.dispatch(new SetProps(res.GetInfoResponse[0].props.prop));
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    saveAuthToken(token) {
      console.log("[AppService][saveAuthToken]", environment.isCordova, token);

      if (environment.isCordova && token) {
        this.store.select(getIsDeviceReady).pipe(filter(v => !!v), take(1)).subscribe(isReady => {
          console.log("[AppService][saveAuthToken] getIsDeviceReady", isReady);
          if (!!plugins && plugins.appPreferences) {
            plugins.appPreferences.store(() => {
              console.log("[AppService][saveAuthToken] success");
            }, () => {
              console.error("[AppService][saveAuthToken] failure");
            }, "auth-token", token);
          }
        });
      }
    }

    removeAuthToken() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeAuthToken] success");
        }, () => {
          console.log("[AppService][removeAuthToken] failure");
        }, "auth-token");
      }
    }

    saveCurrentUserAddressAndDisplayName(address, displayName) {
      console.log("[AppService][saveCurrentUserAddressAndDisplayName]", address, displayName);

      if (environment.isCordova) {
        this.store.select(getIsDeviceReady).pipe(filter(v => !!v), take(1)).subscribe(isReady => {
          if (!!plugins && plugins.appPreferences) {

            plugins.appPreferences.store(() => {
              console.log("[AppService][saveCurrentUserAddressAndDisplayName] address success");
            }, () => {
              console.error("[AppService][saveCurrentUserAddressAndDisplayName] address failure");
            }, "mailCurrentUserAddress", address);

            plugins.appPreferences.store(() => {
              console.log("[AppService][saveCurrentUserAddressAndDisplayName] displayName success");
            }, () => {
              console.error("[AppService][saveCurrentUserAddressAndDisplayName] displayName failure");
            }, "mailCurrentUserDisplayName", displayName);
          }
        });
      }
    }

    saveApiUrl() {
      console.log("[AppService][saveApiUrl]", this.configService.API_URL);

      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.store(() => {
          console.log("[AppService][saveApiUrl] success");
        }, () => {
          console.log("[AppService][saveApiUrl] failure");
        }, "apiUrl", this.configService.API_URL + "/api");
      }
    }

    removeApiUrl() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeApiUrl] success");
        }, () => {
          console.log("[AppService][removeApiUrl] failure");
        }, "apiUrl");
      }
    }

    removeReplyMessages() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeReplyMessages] success");
        }, () => {
          console.log("[AppService][removeReplyMessages] failure");
        }, "replyMessages"); // this constant comes from 'cordova-plugin-firebase'
      }
    }

    fetchReplyMessages(): Observable<any[]> {
      const response = new Subject<any[]>();
      if (environment.isCordova && !!plugins && plugins.appPreference) {
        plugins.appPreferences.fetch((data) => {
          console.log("[AppService][fetchReplyMessages] success", data);
          if (data && CommonUtils.isOnAndroid()) {
            data = JSON.parse(data);
          }
          response.next(data || []);
        }, () => {
          console.log("[AppService][fetchReplyMessages] failure");
          response.next([]);
        }, "replyMessages"); // this constant comes from 'cordova-plugin-firebase'
      }
      return response.asObservable().pipe(take(1));
    }

    removeMarkAsReadFailedMessage() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeMarkAsReadFailedMessage] success");
        }, () => {
          console.log("[AppService][removeMarkAsReadFailedMessage] failure");
        }, "markAsReadFailedRequests"); // this constant comes from 'cordova-plugin-firebase'
      }
    }

    fetchMarkAsReadFailedMessage(): Observable<any[]> {
      const response = new Subject<any[]>();
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.fetch((data) => {
          console.log("[AppService] fetchMarkAsReadFailedMessage success", data);
          if (data && CommonUtils.isOnAndroid()) {
            data = JSON.parse(data);
          }
          response.next(data || []);
        }, () => {
          console.log("[AppService] fetchMarkAsReadFailedMessage failure");
          response.next([]);
        }, "markAsReadFailedRequests"); // this constant comes from 'cordova-plugin-firebase'
      }
      return response.asObservable().pipe(take(1));
    }

    /** mailid: is a mail id of mail
     *  verb: is string has three static value which needs to pass is as follow
     *  1) ACCEPT => Accept the event
     *  2) DECLINE => Decline the event
     *  3) TENTATIVE => Tentative the event
    */
    sendReplyRequest(mailid: string, verb: string): Observable<any> {
      const subject = new Subject<any>();
      const body: any = {
          id: mailid,
          verb: verb,
          updateOrganizer: "TRUE",
      };
      this.commonService.sendInviteReply(body).pipe(take(1)).subscribe(res => {
        subject.next(res);
      });
      return subject.asObservable();
    }

    getDirectoryTags(offset: number, limit: number): Observable<any> {
      let headers = new HttpHeaders();
      if (this.isCordovaOrElectron) {
          const token = localStorage.getItem("token");
          headers = new HttpHeaders({ "Authorization": token });
      }
      return this.http.get(this.configService.API_URL + "/api/directory-tags?offset=" + offset + "&limit=" + limit, {
          headers: headers
      });
    }

    createDirectoryTag(name: string, color: string): Observable<any> {
      let headers = new HttpHeaders();
      if (this.isCordovaOrElectron) {
          const token = localStorage.getItem("token");
          headers = new HttpHeaders({ "Authorization": token });
      }
      const setColor = color === undefined || color === "" ? MailConstants.DEFAULT_COLOR : color;
      const body = {
        "tag": {
          "name": name,
          "configs" : {"color_hex" : setColor }
        }
      };
      return this.http.post(this.configService.API_URL + "/api/create-directory-tag", body , {
          headers: headers
      });
    }

    public getMyProducts(): Observable<any> {
      let headers = new HttpHeaders();
      if (this.isCordovaOrElectron) {
          const token = localStorage.getItem("token");
          headers = new HttpHeaders({ "Authorization": token });
      }
      return this.http.get(this.configService.API_URL + "/api/my-products", {
          headers: headers
      });
    }
}
