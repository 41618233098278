
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export enum BroadcastKeys {
  HIDE_GENERAL_SETTINGS = "HIDE_GENERAL_SETTINGS",
  HIDE_CREATE_FOLDER_MODAL = "HIDE_CREATE_FOLDER_MODAL",
  HIDE_FOLDER_MODAL = "HIDE_FOLDER_MODAL",
  HIDE_COMPOSE_MAIL = "HIDE_COMPOSE_MAIL",
  BACK_FROM_MAIL_DETAIL = "BACK_FROM_MAIL_DETAIL",
  BACK_FROM_PREFERENCES = "BACK_FROM_PREFERENCES",
  HIDE_CONFIRM_MAIN_DIALOG = "HIDE_MAIN_CONFIRM_DIALOG",
  HIDE_MOVE_DIALOG = "HIDE_MOVE_DIALOG",
  HIDE_PROFILE_DIALOG = "HIDE_PROFILE_DIALOG",
  HIDE_AVATAR_CROPPER_DIALOG = "HIDE_AVATAR_CROPPER_DIALOG",
  HIDE_APP_SWITCHER = "HIDE_APP_SWITCHER",
  HIDE_REFINE_SEARCH = "HIDE_REFINE_SEARCH",
  HIDE_PREFERENCE_DIALOG = "HIDE_PREFERENCE_DIALOG",
  HIDE_GENERAL_CONFIRM_DIALOG = "HIDE_GENERAL_CONFIRM_DIALOG",
  HIDE_FOLDER_OPERATION_DIALOG = "HIDE_FOLDER_OPERATION_DIALOG",
  HIDE_COLOR_DIALOG = "HIDE_COLOR_DIALOG",
  HANDLE_BACK_BUTTON = "HANDLE_BACK_BUTTON",
  HIDE_ADVANCED_SEARCH_DIALOG = "HIDE_ADVANCED_SEARCH_DIALOG",
  HIDE_APP_DIALOG = "HIDE_APP_DIALOG",
  HIDE_SERVICEDESK = "HIDE_SERVICEDESK",
  HIDE_FAQ_DIALOG = "HIDE_FAQ_DIALOG",
  HIDE_ABOUT_DIALOG = "HIDE_ABOUT_DIALOG",
  HIDE_VERSION_DIALOG = "HIDE_VERSION_DIALOG",
  HIDE_MAIL_FOLDERS_DIALOG = "HIDE_MAIL_FOLDERS_DIALOG",
  HIDE_LEGAL_DIALOG = "HIDE_LEGAL_DIALOG",
  HIDE_SWIPE_DIALOG = "HIDE_SWIPE_DIALOG",
  HIDE_SWIPE_ACTIONS_DIALOG = "HIDE_SWIPE_ACTIONS_DIALOG",
  HIDE_SEARCH_PANEL = "HIDE_SEARCH_PANEL",
  HIDE_MAIL_SELECTION_PANEL = "HIDE_MAIL_SELECTION_PANEL",
  HIDE_SIDEBAR_DRAWER = "HIDE_SIDEBAR_DRAWER",
  OPEN_SIBAR_DRAWER = "OPEN_SIBAR_DRAWER",
  HIDE_CHANGELOG_DIALOG = "HIDE_CHANGELOG_DIALOG",
  HIDE_TAG_CREATE_DIALOG = "HIDE_TAG_CREATE_DIALOG",
  HIDE_FILTER_CREATE_DIALOG = "HIDE_FILTER_CREATE_DIALOG",
  HIDE_SHARE_FOLDER_DIALOG = "HIDE_SHARE_FOLDER_DIALOG",
  HIDE_REDIRECT_DIALOG = "HIDE_REDIRECT_DIALOG",
  OPEN_PREFERENCE_TAB = "OPEN_PREFERENCE_TAB",
  BRIEFCASE_TAB_SELECTED = "BRIEFCASE_TAB_SELECTED",
  HIDE_BRIEFCASE_MODAL = "HIDE_BRIEFCASE_MODAL",
  HIDE_TAG_DIALOG = "HIDE_TAG_DIALOG",
  HANDLE_MAIL_LIST_SCROLL = "HANDLE_MAIL_LIST_SCROLL",
  HIDE_MOBILE_PREFERENCE_SAVE_FOOTER = "HIDE_MOBILE_PREFERENCE_SAVE_FOOTER",
  HIDE_REVOKE_SHARE_DIALOG = "HIDE_REVOKE_SHARE_DIALOG",
  HIDE_EDIT_FOLDER_PROPERTIES_DIALOG = "HIDE_EDIT_FOLDER_PROPERTIES_DIALOG",
  HIDE_TAG_OPERATION_DIALOG = "HIDE_TAG_OPERATION_DIALOG",
  HIDE_READ_RECEIPT_DIALOG = "HIDE_READ_RECEIPT_DIALOG",
  HIDE_CHANGE_PASSWORD_DIALOG = "HIDE_CHANGE_PASSWORD_DIALOG",
  EMAIL_TEMPLATE_FOLDER = "EMAIL_TEMPLATE_FOLDER",
  HIDE_MAIL_HISTORY_DIALOG = "HIDE_MAIL_HISTORY_DIALOG",
  HIDE_ACCEPT_FOLDER_DIALOG = "HIDE_ACCEPT_FOLDER_DIALOG",
  HIDE_ATTACH_EMAIL_DIALOG = "HIDE_ATTACH_EMAIL_DIALOG",
  UPDATE_TAGS = "UPDATE_TAGS",
  HIDE_MOBILE_ADDRESS_SELECT_DIALOG = "HIDE_MOBILE_ADDRESS_SELECT_DIALOG",
  HIDE_BRIEFCASE_LIST = "HIDE_BRIEFCASE_LIST",
  HIDE_BRIEFCASE_UPLOAD_DIALOG = "HIDE_BRIEFCASE_UPLOAD_DIALOG",
  HIDE_BRIEFCASE_RENAME_DIALOG = "HIDE_BRIEFCASE_RENAME_DIALOG",
  HIDE_BRIEFCASE_SHARE_LINK_CONFIRM_DIALOG = "HIDE_BRIEFCASE_SHARE_LINK_CONFIRM_DIALOG",
  BRIEFCASE_UPLOAD_CONFLICT_DIALOG = "BRIEFCASE_UPLOAD_CONFLICT_DIALOG",
  HIDE_BRIEFCASE_SHORTING_DIALOG = "HIDE_BRIEFCASE_SHORTING_DIALOG",
  HIDE_BRIEFCASE_MOVE_DIALOG = "HIDE_BRIEFCASE_MOVE_DIALOG",
  HIDE_BRIEFCASE_SUB_FOLDER_SELECT_DIALOG = "HIDE_BRIEFCASE_SUB_FOLDER_SELECT_DIALOG",
  HIDE_MOBILE_SUB_FOLDER_OPERATION_DIALOG = "HIDE_MOBILE_SUB_FOLDER_OPERATION_DIALOG",
  HIDE_EDIT_EVENT_DIALOG = "HIDE_EDIT_EVENT_DIALOG",
  HIDE_SAVE_APPOINTMENT_CONFIRM_DIALOG = "HIDE_SAVE_APPOINTMENT_CONFIRM_DIALOG",
  HIDE_SEND_UPDATE_ATTENDEE_DIALOG = "HIDE_SEND_UPDATE_ATTENDEE_DIALOG",
  HIDE_EQUIPMENT_DIALOG = "HIDE_EQUIPMENT_DIALOG",
  HIDE_EQUIPMENT_CONFLICT_DIALOG = "HIDE_EQUIPMENT_CONFLICT_DIALOG",
  HIDE_APPOINTMENT_SAVE_CHANGE_DIALOG = "HIDE_APPOINTMENT_SAVE_CHANGE_DIALOG",
  HIDE_FIND_LOCATION_DIALOG = "HIDE_FIND_LOCATION_DIALOG",
  HIDE_SELECT_ADDRESS_DIALOG = "HIDE_SELECT_ADDRESS_DIALOG",
  HIDE_CUSTOM_REPEAT_DIALOG = "HIDE_CUSTOM_REPEAT_DIALOG",
  HIDE_CREATE_NEW_CALENDAR_FOLDER = "HIDE_CREATE_NEW_CALENDAR_FOLDER",
  HIDE_EXTERNAL_CALENDAR_DIALOG = "HIDE_EXTERNAL_CALENDAR_DIALOG",
  HIDE_FIND_SHARE_DIALOG = "HIDE_FIND_SHARE_DIALOG",
  HIDE_MOBILE_CALEDAR_LIST = "HIDE_MOBILE_CALEDAR_LIST",
  HIDE_APPEARANCE_DIALOG = "HIDE_APPEARANCE_DIALOG",
  HIDE_PRINT_EVENT_DIALOG = "HIDE_PRINT_EVENT_DIALOG",
  HIDE_MOVE_CALENDAR_DIALOG = "HIDE_MOVE_CALENDAR_DIALOG",
  HIDE_MOBILE_CALENDAR_OPERATION_DIALOG = "HIDE_MOBILE_CALENDAR_OPERATION_DIALOG",
  HIDE_PREVIE_EVENT_DIALOG = "HIDE_PREVIE_EVENT_DIALOG",
  HIDE_MOBILE_CONTEXT_MENU_DIALOG = "HIDE_MOBILE_CONTEXT_MENU_DIALOG",
  HIDE_DATE_RANGE_DIALOG = "HIDE_DATE_RANGE_DIALOG",
  HIDE_QUICK_EVENT_CREATE_DIALOG = "HIDE_QUICK_EVENT_CREATE_DIALOG",
  HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION = "HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION",
  HIDE_TFA_SETTING_DIALOG = "HIDE_TFA_SETTING_DIALOG",
  CREATE_NEW_EVENT_APPOINTMENT = "CREATE_NEW_EVENT_APPOINTMENT",
  HIDE_SUGGEST_PREFERENCES_DIALOG = "HIDE_SUGGEST_PREFERENCES_DIALOG",
  HIDE_EMAIL_NOTIFICATION_CONFIGURE = "HIDE_EMAIL_NOTIFICATION_CONFIGURE"
}
