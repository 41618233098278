
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-preferences-component">
  <mat-list>
    <mat-list-item id="mobile_sidebar_about_version" (click)="openProfileDialog()">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account"></mat-icon>
      <span class="disable-select">{{ 'PROFILE' | translate }}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_about_version" (click)="openGeneralSettingsDialog()">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-settings"></mat-icon>
      <span class="disable-select">{{ 'SETTINGS_TITLE' | translate }}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_appearance" (click)="openAppearanceDialog()">
        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-brush"></mat-icon>
        <span class="disable-select">{{ 'APPEARANCE' | translate }}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_preference_main_pref" (click)="navigate('general')">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-settings-box"></mat-icon>
      <span>{{'MAIN_PREFERENCES'| translate}}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_preference_main_pref" (click)="navigate('work-week-hours')">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-clock"></mat-icon>
      <span>{{'PREFERENCES.WORK_WEEK_AND_HOURS'| translate}}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_preference_main_pref" (click)="navigate('create-appointment-setting')">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-check"></mat-icon>
      <span>{{'PREFERENCES.CREATING_APPOINTMENTS'| translate}}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_preference_main_pref" (click)="navigate('permissions-setting')">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-lock"></mat-icon>
      <span>{{'PREFERENCES.PERMISSIONS'| translate}}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_preference_main_pref" (click)="navigate('apple-ical')">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-apple"></mat-icon>
      <span>{{'PREFERENCES.APPLE_ICAL'| translate}}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_preference_main_pref" (click)="navigate('notifications')">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-bell"></mat-icon>
      <span>{{'NOTIFICATIONS_LBL'| translate}}</span>
    </mat-list-item>
    <mat-list-item id="mobile_sidebar_about_version" (click)="aboutDialog()">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-help-circle-outline"></mat-icon>
      <span class="disable-select">{{ 'HELP_LABEL' | translate }}</span>
    </mat-list-item>
  </mat-list>
</div>