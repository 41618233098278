
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { map, catchError, take, tap } from "rxjs/operators";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ConfigService } from "src/app/config.service";
import { Store } from "@ngrx/store";
import { Observable, of, forkJoin, Subject } from "rxjs";
import { throwError } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { Preference } from "src/app/preference/shared/models";
import { CommonUtils } from "src/app/common/utils/common-util";
import { ElectronService } from "src/app/services/electron.service";
import { AppState } from "../reducers/app";
import { AuthService } from "../common/providers/auth.service";
import { UserProfile } from "../shared/models";
import { SetUserProfile } from "../actions/app";
import { SearchFolder } from "../shared/models/search-folder";
import { SearchRequest } from "../common/models/search-request.model";
import { Contact } from "../common/models/contact";
import { CalendarFolder } from "../common/models/calendar.model";
import { MailTag } from "../common/models/mail-tag.model";
import { Broadcaster } from "../common/providers/broadcaster.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class CommonService {

  public allFolders: any = [];
  public attachmentInfo: any;
  public alarmEventsActive: any [] = [];
  public isEventReminderActivated: boolean = false;
  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private snackBar: MatSnackBar,
    public appStore: Store<AppState>,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private electronService: ElectronService,
    private broadcaster: Broadcaster) { }

  getPreferences(): Observable<Preference[]> {
    return this.http.post(this.configService.API_URL + "/api/getPrefs", {},
      { headers: this.getZimbraHeader() }).pipe(take(1), map((res: any) => {
        const preferences: Preference[] = [];
        for (const key in res._attrs) {
          if (res._attrs.hasOwnProperty(key)) {
            const preference: Preference = {
              key: key,
              value: res._attrs[key]
            };
            preferences.push(preference);
          }
        }
        return preferences;
      }));
  }

  private getZimbraHeader(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();
    if (this.isCordovaOrElectron) {
      const token = this.electronService.isElectron
        ? localStorage.getItem(MailConstants.ZM_AUTH_TOKEN)
        : localStorage.getItem(MailConstants.ZM_AUTH_TOKEN);
      return headers.set("Authorization", token).set("Content-Type", "application/json").set("Accept", "application/json");
    }
    return headers.set("Content-Type", "application/json").set("Accept", "application/json");
  }

  private handleErrorObservable(error: Response | any) {
    console.log("[handleErrorObservable] isCordova", !!environment.isCordova);
    console.log("[handleErrorObservable] isElectron", !!environment.isElectron);
    console.log("[handleErrorObservable] Error", error);

    if (error.error && error.error.msg === "no valid authtoken present") {
      setTimeout(() => {
        if (environment.isCordova) {
          this.http.get(this.configService.API_URL + `/api/cordova-logout`);
          let initialHref = window.location.href.split("/www/")[0];
          initialHref = initialHref.split("/mail")[0];
          window.location.href = initialHref + "/www/index.html";
        } else if (this.electronService.isElectron) {
          this.http.get(this.configService.API_URL + `/api/cordova-logout`);
          const initialHref = window.location.href.includes("/index.html")
            ? window.location.href.split("/index.html")[0]
            : window.location.href.split("/mail")[0];
          window.location.href = `${initialHref}/index.html`;
        } else {
          window.location.href = "/api/call-logout";
        }
      }, 1500);
    }
    if (error.error && error.error.msg === MailConstants.TOKENERROR) {
      if (!this.isCordovaOrElectron && this.authService) {
        this.authService.generateNewToken();
      }
    }
    if (error.error && error.error.msg) {
      return throwError(error.error.msg);
    } else if (error._body && CommonUtils.isJson(error._body)) {
      return throwError(error.error.msg || error);
    } else if (error.error && error.error instanceof String) {
      if (error.error.indexOf("RangeError: Maximum call stack size exceeded") !== -1) {
        return throwError("RangeError: Maximum call stack size exceeded");
      } else {
        return throwError(error.error);
      }
    } else {
      return throwError(error);
    }
  }

  getAutoCompleteList(inputValue: string): Observable<any> {
    const body = { name: inputValue };
    return this.http.post(
      this.configService.API_URL + "/api/autocomplete",
      body,
      {
        headers: this.configService.useVNCdirectoryAuth ? this.getAuthorizationToken() : this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getApiUrl(): string {
    return this.configService.API_URL;
  }

  getSignatureId(prefrenceName: string): Observable<any> {
    const body = { prefName: prefrenceName };
    return this.http.post(
      this.configService.API_URL + "/api/getPrefs",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getAllSignature(): Observable<any> {
    const body = {
      "GetSignaturesRequest":
      {
        "@": {
          "xmlns": "urn:zimbraAccount", "requestId": "0"
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }
  uploadAttachment(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      this.configService.API_URL + "/api/upload",
      formData,
      {
        headers: this.getZimbraHeader().delete("Content-Type").delete("Accept"),
        responseType: "text"
      });
  }

  createCurrentUserContact(user: any) {
    let userEmail: string = "";
    if (Array.isArray(user.email)) {
      userEmail = user.email[0];
    } else {
      userEmail = user.email;
    }
    const body = {
      sortBy: "nameAsc",
      offset: 0,
      limit: 100,
      fetch: 1,
      needExp: 1,
      query: userEmail + " in: Contacts",
      types: "contact"
    };
    this.profileManagerSearchRequest(body).subscribe(resContacts => {
      if (resContacts.cn) {
        const userProfile = CommonUtils.getUserProfile(resContacts.cn[0], this.configService.API_URL);
        this.setUserProfile(userProfile, this);
      } else {
        const userBody = { "contactAttrs": this.getContactAttributes(user), folderId: MailConstants.PROFILE_CONTACT_FOLDER_ID, id: "" };
        this.createContactInProfileManager(userBody).subscribe(res => {
          const userProfile = CommonUtils.getUserProfile(res.cn[0], this.configService.API_URL);
          this.setUserProfile(userProfile, this);
        });
      }
    });
  }

  createContactInProfileManager(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createContactInProfileManager", body,
      { headers: this.getProfileManagerHeader() }).pipe(
        catchError(this.handleErrorObservable));
  }

  profileManagerSearchRequest(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/profileManagerSearchRequest", body,
      { headers: this.getProfileManagerHeader() }).pipe(
        catchError(this.handleErrorObservable));
  }

  modifyAvtar(body): Observable<any> {
    body.folderId = MailConstants.PROFILE_CONTACT_FOLDER_ID;
    return this.http.post(this.configService.API_URL + "/api/modifyProfileManagerContact", body,
      { headers: this.getProfileManagerHeader() }).pipe(map((res: any) => {
        const userProfile = CommonUtils.getUserProfile(res.cn[0], this.configService.API_URL);
        this.setUserProfile(userProfile, this);
      }), catchError(this.handleErrorObservable));
  }

  uploadAvatarToProfileManager(files): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      observe: "response"
    };
    return this.http.post(this.configService.API_URL + "/api/uploadAvatarToProfileManager", files,
      {
        headers: new HttpHeaders(),
        responseType: "text"
      }).pipe(
        catchError(this.handleErrorObservable));
  }

  private setUserProfile(userProfile: UserProfile, currentService: any) {
    if (userProfile.avtarUrl) {
      this.getContactBlobAvtarForProfile(userProfile.avtarUrl).subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          userProfile.imageData = reader.result;
          if (userProfile.firstName !== undefined) {
            if (this.electronService.isElectron) {
              this.electronService.setToStorage("profileUser", userProfile);
            } else {
              localStorage.setItem("profileUser", JSON.stringify(userProfile));
            }
          }
          currentService.appStore.dispatch(new SetUserProfile(userProfile));
        };
        reader.readAsDataURL(res);
      }, catchError(this.handleErrorObservable));
    } else {
      if (userProfile.firstName !== undefined) {
        if (this.electronService.isElectron) {
          this.electronService.setToStorage("profileUser", userProfile);
        } else {
          localStorage.setItem("profileUser", JSON.stringify(userProfile));
        }
      }
      this.appStore.dispatch(new SetUserProfile(userProfile));
    }
  }

  getContactBlobAvtarForProfile(avtarUrl: string): Observable<Blob> {
    return this.http.get(avtarUrl, {
      responseType: "blob"
    }).pipe(catchError(this.handleErrorObservable)
    );
  }

  private getContactAttributes(user: any): any[] {
    const contactAttrs: any[] = [];
    let email: string;
    if (Array.isArray(user.email)) {
      email = user.email[0];
    } else { email = user.email; }
    if (user.firstName !== undefined) {
      contactAttrs.push({ "key": "firstName", "value": user.firstName });
    }
    if (user.lastName !== undefined) {
      contactAttrs.push({ "key": "lastName", "value": user.lastName });
    }
    if (user.email) {
      contactAttrs.push({ "key": "email", "value": email });
    }
    return contactAttrs;
  }

  createFolder(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createFolder", body, { headers: this.getZimbraHeader() }).
      pipe(map((res: any) => {
        return res.folder[0];
      }), catchError(this.handleErrorObservable));
  }


  searchRequest(body: SearchRequest): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/searchRequest", body, { headers: this.getZimbraHeader() });
  }

  getContactAvtar(contacts: Contact[]) {
    const requests: Observable<any>[] = [];
    for (let i = 0; i < contacts.length; i++) {
      requests.push(this.http.get(contacts[i].avatarUrl, {
        responseType: "blob"
      }).pipe(catchError(this.handleErrorObservable)
      ));
    }
    forkJoin(requests).subscribe(results => {
      for (let j = 0; j < results.length; j++) {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          const data: any = reader.result;
          contacts[j].blobImage = this.domSanitizer.bypassSecurityTrustUrl(data);
        };
        reader.readAsDataURL(results[j]);
      }
      // this.appStore.dispatch(new SaveContactProfiles(contacts));
    });
  }
  getAllContacts(): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/getContacts", {}, { headers: this.getZimbraHeader() }).
      pipe(catchError(this.handleErrorObservable));
  }

  removeAttachment(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/removeAttachments", body, { headers: this.getZimbraHeader() });
  }

  shareFolder(options, users): Observable<any> {
    const folderActionRequest = [];
    let i = 0;
    for (const user of users) {
      folderActionRequest.push({
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": i
        },
        "action": {
          "@": {
            "op": options.op,
            "id": options.id,
            "zid": options.zid
          },
          "grant": {
            "@": {
              "gt": options.gt,
              "inh": options.inh,
              "d": user,
              "perm": options.perm || "",
              "pw": options.pw || ""
            }
          }
        }
      });
      i++;
    }
    const request = {
      FolderActionRequest: folderActionRequest
    };

    return this.http.post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() }).
      pipe(catchError(this.handleErrorObservable.bind(this)));
  }

  sendShareFolderRequest(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/sendShareNotification", body, { headers: this.getZimbraHeader() }).
      pipe(catchError(this.handleErrorObservable));
  }

  createMountPoint(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createMountpoint", body, { headers: this.getZimbraHeader() }).
      pipe(catchError(this.handleErrorObservable));
  }

  printMessage(msgIds) {
    if (typeof cordova !== "undefined") {
      this.getPrintMessageData(msgIds).subscribe(res => {
        cordova.plugins.printer.print(res, { duplex: "long" }, function (response) {
        });
      }, catchError(this.handleErrorObservable));
    } else {
      window.open(this.configService.API_URL + "/api/printMessage?id=" + msgIds + "&type=message");
    }
  }

  getPrintMessageData(msgIds: any): Observable<any> {
    return this.http.get(this.configService.API_URL + "/api/printMessage?id=" + msgIds + "&type=message",
      { headers: this.getZimbraHeader(), responseType: "text" }
    ).pipe(catchError(this.handleErrorObservable.bind(this)));
  }

  getConfiguration(url: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get(url + "/api/config", { headers: headers }).pipe(map(this.extractData), catchError(this.handleErrorObservable));
  }

  /**
   * Get All Folder
   *
   */
  getAllFolders(): Observable<any> {
    const request = {
      GetFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail",
          requestId: 0
        }
      }
    };
    return this.http.post(this.configService.API_URL + "/api/batchRequest2", request, { headers: this.getZimbraHeader() }).
      pipe(catchError(this.handleErrorObservable));
  }

  getSavedSearchFolder(): Observable<SearchFolder[]> {
    const request = {
      GetSearchFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        }
      }
    };
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(
        map(res => {
          const mailFolderResponse = CommonUtils.parseSearchFoders(res);
          return mailFolderResponse;
        }),
        catchError(this.handleErrorObservable)
      );
  }

  saveSearchFolder(options): Observable<SearchFolder> {
    const request = {
      CreateSearchFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        search: {
          "@": {
            name: options.name,
            query: options.query,
            l: options.folderId || 1,
            types: options.types || "conversation"
          }
        }
      }
    };
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(
        map(res => {
          return CommonUtils.parseCreateSearchResponse(res);
        })
      );
  }

  private getProfileManagerHeader() {
    const headers: HttpHeaders = new HttpHeaders();
    return headers.set("Content-Type", "application/json").set("Accept", "application/json");
  }

  private extractData(res: Response) {
    const body = res.json() || {};
    return body;
  }

  private returnResponse(res: Response): Response {
    return res;
  }

  openAttachment(id: string, part: string): void {
    let authorization = null;
    let queryParams = "id=" + id + "&part=" + part + "&disp=a";
    if (this.isCordovaOrElectron) {
      authorization = this.electronService.isElectron
        ? btoa(this.electronService.getFromStorage(MailConstants.ZM_AUTH_TOKEN).trim())
        : btoa(localStorage.getItem(MailConstants.ZM_AUTH_TOKEN).trim());
      queryParams += "&passport=" + authorization;
    }
    const url = this.configService.API_URL + "/api/getAttachment/?" + queryParams;
    if (environment.isElectron) {
      ElectronService.downloadFile(url, part);
    } else {
      window.open(url, "_system");
    }
  }

  downloadAllAttachment(ids: string, mailId: string, fileName: string): void {
    let authorization = null;
    let queryParams = "id=" + mailId + "&part=" + ids + "&filename=" + fileName;
    if (this.isCordovaOrElectron) {
      authorization = this.electronService.isElectron
        ? btoa(this.electronService.getFromStorage(MailConstants.ZM_AUTH_TOKEN).trim())
        : btoa(localStorage.getItem(MailConstants.ZM_AUTH_TOKEN).trim());
      queryParams += "&passport=" + authorization;
    }
    const url = this.configService.API_URL + "/api/downloadAttachments/?" + queryParams;
    if (environment.isElectron) {
      ElectronService.downloadFile(url, fileName);
    } else {
      window.open(url, "_system");
    }
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, "", {
      duration: 2000,
      panelClass: "mobile_snackbar"
    });
  }

  setAttachmentDocument(info: any) {
    this.attachmentInfo = info;
  }

  getAttachmentDocument() {
    return this.attachmentInfo;
  }

  sendInviteReply(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/sendInviteReply", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  itemAction(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/itemAction", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  getAttributes(section: string): Observable<any> {
    return this.http.get(this.configService.API_URL + "/api/getInfo?sections=" + section, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  createBatchRequest(request: any): Observable<any> {
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  checkSpelling(request: any): Observable<any> {
    return this.http
      .post(this.configService.API_URL + "/api/checkSpelling", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  sendDeliveryReport(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/sendDeliveryReport", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  searchGalRequest(body: any): Observable<any> {
    return this.http
      .post(this.configService.API_URL + "/api/searchGal", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  getMailBoxMetaData(): Observable<any> {
    const request = {
      GetMailboxMetadataRequest: {
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": "0"
        },
        "meta": {
          "@": {
            "section": "zwc:implicit"
          }
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getAppoinementById(id: any): Observable<any> {
    const request = {
      GetAppointmentRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        includeContent: 1,
        id: id
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  cancelAppointment(ms: any, id: any, name: any): Observable<any> {
    const request = {
      CancelAppointmentRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "ms": ms,
        "rev": ms,
        "id": id,
        "comp": "0",
        "m": {
          "e": [],
          "su": "Cancelled: " + name,
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  createAppointment(email: any, startDate: any, endDate: any, name: any, timeZone: string): Observable<any> {
    const request = {
      "CreateAppointmentRequest": {
        "@": {
          "xmlns": "urn:zimbraMail"
        },
        "m": {
          "l": 10,
          "inv": {
            "comp": [{
              "at": [],
              "status": "CONF",
              "fb": "O",
              "class": "PUB",
              "transp": "O",
              "draft": 0,
              "allDay": "1",
              "s": {
                "d": startDate,
                "tz": timeZone
              },
              "e": {
                "d": endDate,
                "tz": timeZone
              },
              "name": name || "Out of Office",
              "loc": "",
              "or": {
                "a": email
              }
            }]
          },
          "e": [],
          "su": name || "Out of Office",
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  createAppointmentWithRequest(request: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  setMailBoxMetaData(metaData: any): Observable<any> {
    const a = [];
    for (const key of Object.keys(metaData)) {
      a.push({
        "@": {
          n: key
        },
        "#": metaData[key]
      });
    }

    const request = {
      SetMailboxMetadataRequest: {
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": "0"
        },
        "meta": {
          "@": {
            "section": "zwc:implicit"
          },
          a
        }
      }
    };

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getZimbraVersion(): Observable<any> {
    const request = {
      GetVersionInfoRequest: {
        "@": {
          xmlns: "urn:zimbraAccount"
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getAccountInformation(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/getAcountInformation", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  changePassword(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/changePassword", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  getChangePasswordData(): Promise<any> {
    return new Promise(resolve => {
      const changePasswordType = localStorage.getItem("changePasswordType") === null ? "zimbra" :
        localStorage.getItem("changePasswordType");
      const sectionAttributes = localStorage.getItem("sectionAttributes");
      resolve({ changePasswordType: changePasswordType, sectionAttributes: sectionAttributes });
    });
  }

  getMailHistory(msgId: string): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/getMailHistory?id=" + msgId, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  getUserCalendarFolders(): Observable<any> {
    const request = {
      view: "appointment"
    };

    return this.http.post(this.configService.API_URL + "/api/getFolderList", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }

  getAppointmentList(request): Observable<any> {
    request.types = "appointment";
    return this.http.post(this.configService.API_URL + "/api/searchRequest", request, { headers: this.getZimbraHeader() })
    .pipe(catchError(this.handleErrorObservable));
  }

  deleteAppointment(request: any): Observable<any> {
    return this.itemAction(request);
  }

  deleteInstanceAppointment(request: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/cancelAppointment", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.handleErrorObservable));
  }


  noOpRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/noOp", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][noOp] took ${performance} s for API call`);
    }));
  }

  getConfigurations(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    const api = this.configService.API_URL || "";
    return this.http.get(api + "/api/config", { headers: headers });
  }

  getMsgRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getMsgRequest", body , { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][getMsgRequest] took ${performance} s for API call`, body);
    }));
  }

  modifyAppointment(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/modifyAppointment", body,
      { headers: this.getZimbraHeader() }).pipe(
        catchError(this.handleErrorObservable));
  }

  createAppointmentException(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createAppointmentException", body,
      { headers: this.getZimbraHeader() }).pipe(
        catchError(this.handleErrorObservable));
  }

  createNewAppointment(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createAppointment", body,
      { headers: this.getZimbraHeader() }).pipe(
        catchError(this.handleErrorObservable));
  }

  getMobileAppointmentPrintData(id: string, timezone: string): Observable<any> {
    return this.http.get(this.configService.API_URL + "/api/printAppointment?id=" + id + "&tz=" + timezone,
      { headers: this.getZimbraHeader(), responseType: "text" }
    ).pipe(catchError(this.handleErrorObservable.bind(this)));
  }

  dismissApptRequest(appointmentId: string, dismissTime: number): Observable<any> {
    const request = {
      DismissCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": {
          id: appointmentId,
          dismissedAt: dismissTime
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getAllLocationList(): Observable<any> {
    const body: any = {
      value: "Location"
    };
    return this.http.post(
      this.configService.API_URL + "/api/searchCalendarResourcesRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  snozeApptRequest(appointmentId: string, snoozTime: number): Observable<any> {
    const request = {
      SnoozeCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": {
          id: appointmentId,
          until: snoozTime
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getAutoCompleteGalList(inputValue: string): Observable<any> {
    const body = { name: inputValue, type: "resource" };
    return this.http.post(
      this.configService.API_URL + "/api/autocompleteGalRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getCalendarFolders(body: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/getFolderList",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getTags(): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.get(this.configService.API_URL + "/api/getTagList", { headers: this.getZimbraHeader() }).pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][getTagList] took ${performance} s for API call`);
    }),
    map((res: any) => {
      if (res.GetTagResponse && res.GetTagResponse.tag) {
        const tags = Array.isArray(res.GetTagResponse.tag) ? res.GetTagResponse.tag : [res.GetTagResponse.tag];
        return tags.map(t => {
          return t as MailTag;
        });
      }
      return [];
    }));
  }

  createTag(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/createTag", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][createTag] took ${performance} s for API call`);
    }));
  }

  tagAction(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/tagAction", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][tagAction] took ${performance} s for API call`);
    }));
  }

  folderAction(body): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/folderAction", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][folderAction] took ${performance} s for API call`, body);
        this.broadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
      }),
      catchError(this.handleErrorObservable));
  }

  cancelCreateAppointment(ms: any, id: any, name: any, e: any, inst?: any): Observable<any> {
    const request = {
      CancelAppointmentRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "ms": ms,
        "rev": ms,
        "id": id,
        "comp": "0",
        "m": {
          "e": e,
          "su": "Cancelled: " + name,
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };
    if (inst) {
      request.CancelAppointmentRequest["inst"] = inst;
    }

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getSignatures(): Observable<any[]> {
    const request = {
        GetSignaturesRequest: {
            "@": {
                "xmlns": "urn:zimbraAccount",
                "requestId": "0"
            }
        }
    };
    const t1 = new Date().getTime();
    return this.createBatchRequest(request).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getSignatures] took ${performance} s for API call`);
      }),
      map(res => {
        if (res.GetSignaturesResponse && res.GetSignaturesResponse[0].signature) {
            let signatures = res.GetSignaturesResponse[0].signature;
            signatures = Array.isArray(signatures) ? signatures : [signatures];
            return signatures.map(signature => {
                const data: any = {
                    id: signature.id,
                    name: signature.name,
                    content: signature.content[0]._content.replace(/(?:\r\n|\r|\n)/g, "<br />").replace(/\t/g, "    ").
                    replace(/                    /ig, ""),
                    type: signature.content[0].type
                };
                return data;
            });
        } else {
            return of([]);
        }
    }));
  }

  saveMailAsDraft(saveSendMessage: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(saveSendMessage);
    return this.http.post(
      this.configService.API_URL + "/api/saveDraft",
      saveSendMessage,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][saveDraft] took ${performance} s for API call`);
      }),
      catchError(this.handleErrorObservable));
  }

  sendEmail(saveSendMessage: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(saveSendMessage);
    return this.http.post(
      this.configService.API_URL + "/api/sendEmail",
      saveSendMessage,
      {
        headers: this.getZimbraHeader()
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][sendEmail] took ${performance} s for API call`);
      }),
      map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  snoozeAllApptRequest(idUntilItem: any): Observable<any> {
    const request = {
      SnoozeCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": idUntilItem
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  dismissAllApptRequest(item: any): Observable<any> {
    const request = {
      DismissCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": item
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  forwardAppointment(body: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/forwardAppointment",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  deleteAppointmentEvent(body: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/cancelAppointment",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getContactFolders(body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http
      .post(this.configService.API_URL + "/api/getFolderList", body, { headers: this.getZimbraHeader() })
      .pipe(
        tap(res => {
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
          console.log(`[API][getContactFolders] took ${performance} s for API call`);
        }),
        map(res => {
          return res;
        }),
        catchError(this.handleErrorObservable)
      );
  }

   modifyPrefs(changes: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/modifyPrefs",
      { prefs: changes },
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  uploadAvatar(files): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/upload_avatar", files,
      {
        headers: this.getAuthorizationToken(),
        responseType: "text"
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][uploadAvatar] took ${performance} s for API call`);
      }));
  }

  private getAuthorizationToken(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        return headers.set("Authorization", token);
    }
    return headers;
  }

  createDataSourceRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/createDataSourceRequest", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][tagAction] took ${performance} s for API call`);
    }));
  }

  zimbraPrefSpellIgnoreWord(word: string): Observable<any> {
    const body = {
      "ModifyPrefsRequest":
      {
        "@": {
          "xmlns": "urn:zimbraAccount",
          "requestId": "0"
        },
        "pref": {
          "@": {
            "name": "+zimbraPrefSpellIgnoreWord"
        },
        "#": word
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getAllAutoCompleteList(body: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/autocomplete",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  getAllUserContacts(): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getAllUserContacts", {}, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getAllUserContacts] took ${performance} s for API call`);
      }),
      catchError(this.handleErrorObservable));
  }

  getMailBoxLocationMetaData(): Observable<any> {
    const request = {
      GetMailboxMetadataRequest: {
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": "0"
        },
        "meta": {
          "@": {
            "section": "zwc:MD_LOCATION_SEARCH_PREF"
          }
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  setMailBoxLocationMetaData(metaData: any): Observable<any> {
    const a = [];
    for (const key of Object.keys(metaData)) {
      a.push({
        "@": {
          n: key
        },
        "#": metaData[key]
      });
    }

    const request = {
      SetMailboxMetadataRequest: {
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": "0"
        },
        "meta": {
          "@": {
            "section": "zwc:MD_LOCATION_SEARCH_PREF"
          },
          a
        }
      }
    };

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable));
  }

  removeAvatar(): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/remove_avatar", {"delete": "true"},
      {
        headers: this.getAuthorizationToken(),
        responseType: "text"
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][removeAvatar] took ${performance} s for API call`);
      }));
  }

  getFreeBusy(request: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/getFreeBusy",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable.bind(this)));
  }

  getWorkingHours(request: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/getWorkingHours",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.handleErrorObservable.bind(this)));
  }

}
