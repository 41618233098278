
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vnc-notification" [@enterLeave]="item.state" (click)="onClick($event)" [class]="theClass" (mouseenter)="onEnter()"
  (mouseleave)="onLeave()">
  <div [attr.id]="item.id" *ngIf="!item.html" class="vnc-notification-container {{ item.type }}">
    <div class="vnc-notification-top {{ item.type }}" [ngClass]="{'dfb-notification-top':theme === 'dfb'}">

      <div class="vnc-notification-content-wrapper" *ngIf="theme==='default'">
        <div class="vnc-notification-title">
          <span class="notification-title-content" *ngIf="item.title">{{ item.title }}</span>
          <span class="notification-title-content" *ngIf="!item.title">{{ 'NO_SUBJECT' | translate }}</span>
        </div>
        <div class="vnc-notification-content" *ngIf="item.content" [innerHTML]="item.content"></div>
        <div class="vnc-notification-content" *ngIf="!item.content">{{ 'NO_CONTENT' | translate }}</div>
      </div>

      <div class="vnc-notification-content-wrapper dfb-content" *ngIf="theme==='dfb'">
         <div class="vnc-notification-title dfb-title">
          <mat-icon>mail</mat-icon>
          <span class="notification-title-content dfb-title-content" *ngIf="item.title">{{ item.title }}</span>
          <span class="notification-title-content dfb-title-content" *ngIf="!item.title">{{ 'NO_SUBJECT' | translate }}</span>
        </div>
        <div class="vnc-notification-content dfb-notification-content" *ngIf="item.content" [innerHTML]="item.content"></div>
        <div class="vnc-notification-content dfb-notification-content" *ngIf="!item.content">{{ 'NO_CONTENT' | translate }}</div>
      </div>

    </div>
  </div>
  <div *ngIf="item.html" class="vnc-notification-html vnc-notification-content-wrapper {{ item.type }}">
    <div *ngIf="item.title" class="vnc-notification-title">{{ item.title }}</div>
    <div class="vnc-notification-content" [innerHTML]="item.html"></div>
  </div>
</div>