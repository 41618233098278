
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";


@Injectable()
export class FilesStorageService {
  private storageLocation;

  constructor() {
    document.addEventListener("deviceready", this.deviceReady.bind(this), false);
  }

  private deviceReady() {
    console.log("[FilesStorageService] deviceReady");

    // for iOS it's Library/NoCloud
    this.storageLocation = cordova.file.dataDirectory;
  }

  filePathOnDisc(fileName: string) {
    return this.storageLocation + fileName;
  }

  saveBlobToDisc(blob: Blob, fileName: string): Observable<string> {
    const response = new Subject<string>();

    window.resolveLocalFileSystemURL(this.storageLocation, (dir: any) => {
      dir.getFile(fileName, { create: true, exclusive: false},  (file) => {
        file.createWriter( (fileWriter) => {
          fileWriter.write(blob);
          fileWriter.onwriteend = () => {
            const localFileUrl = this.filePathOnDisc(fileName);
            console.log("[FilesStorageService] saveBlobToDisc success", fileName, localFileUrl);
            response.next(localFileUrl);
          };
          fileWriter.onerror = (err) => {
            console.log("[FilesStorageService] saveBlobToDisc error1", err);
            response.error(err);
            file.remove( () => {}, () => {}, () => {});
          };
        }, (err)  => {
          console.log("[FilesStorageService] saveBlobToDisc error2", err);
          response.error(err);
        });
      });
    }, (err) => {
      console.log("[FilesStorageService] saveBlobToDisc error3", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  /*
  readBlobFromDisc(fileName: string): Observable<Blob> {
    const response = new Subject<Blob>();

    const localFileUrl = this.filePathOnDisc(fileName);

    window.resolveLocalFileSystemURL(localFileUrl,  (fileEntry: any) => {
      fileEntry.file((file) => {
        const reader = new FileReader();
        reader.onloadend = function (e) {
          const blob = new Blob([new Uint8Array(this.result)]);
          // var blob = new Blob([new Uint8Array(this.result)], { type: "image/png" });

          response.next(blob);
        };
        reader.readAsArrayBuffer(file);
      });
    }, (err) => {
      console.log("[FilesStorageService] readBlobFromDisc error", err);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }
*/

  downloadFileAsBlob(serverUrl): Observable<Blob> {
    console.log("[FilesStorageService] downloadFileAsBlob", serverUrl);

    const response = new Subject<Blob>();

    const xhr = new XMLHttpRequest();
    xhr.open("GET", serverUrl);
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onreadystatechange =  () => {
      if (xhr.status === 0) {
        response.error(new Error("Error in file download: internet is not available"));
        return;
      }

      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 304) {
          const blob = xhr.response; // xhr.response is now a blob object
          response.next(blob);
        } else if (xhr.status >= 400) {
          response.error(new Error("Error in file download: status" + xhr.status));
        }
      }
    };

    xhr.send();

    return response.asObservable().pipe(take(1));
  }

  // Save to Downloads (Android) & to gallery (iOS)

  saveBlobToAndroidDownloadFolder(blob: Blob, fileName: string): Observable<string> {
    const response = new Subject<string>();

    const fileUrl = this.filePathInAndroidDownloadFolder(fileName);

    window["requestFileSystem"](window["PERSISTENT"], blob.size, function (fs) {
      fs.root.getFile(fileUrl, { create: true, exclusive: false }, (fileEntry) => {
        fileEntry.createWriter((fileWriter) => {
          fileWriter.onwriteend = () => {
            console.log("[FilesStorageService] saveBlobToAndroidDownloadFolder success", fileUrl);
            response.next(fileUrl);
          };
          fileWriter.onerror = function (e) {
            response.error(e);
          };
          fileWriter.write(blob);
        });
      }, (err) => {
        console.log("[FilesStorageService] saveBlobToAndroidDownloadFolder error1", err);
        response.error(err);
      });
    }, (err) => {
      console.log("[FilesStorageService] saveBlobToAndroidDownloadFolder error2", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  private filePathInAndroidDownloadFolder(fileName: string) {
    return `Download/${fileName}`;
  }

}
