
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="tags-list">
    <div class="tags-list__heading">
        {{ 'TAGS' | translate }}
        <div class="tag-action">
            <mat-icon (click)="createNewTag()" class="tag-icon mdi-18px" fontSet="mdi" fontIcon="mdi-tag-plus"
                matTooltip="{{'CREATE_NEW_TAG' | translate }}"></mat-icon>
            <mat-icon (click)="expanded=false" *ngIf="expanded">expand_less</mat-icon>
            <mat-icon (click)="expanded=true" *ngIf="!expanded">expand_more</mat-icon>
        </div>
    </div>
    <div class="tags-list__body">
        <cdk-virtual-scroll-viewport itemSize="20"  *ngIf="expanded" class="tags-viewport">
            <div class="tag-item" (click)="openCalendarList(tag)" [contextMenu]="tagMenu"
                (contextmenu)="onContextMenu($event, tag)"
                [style.backgroundColor]="tag.rgb || tag.color || defaultColor" 
                *cdkVirtualFor="let tag of tags"
                [style.color]="getTagColor(tag.rgb || tag.color || defaultColor)">
                <span class="no-select" matTooltip="{{ tag.name }}">{{ tag.name }}</span>
                <mat-icon (click)="$event.stopPropagation();deleteTag(tag)" *ngIf="!isDirectoryTag">close</mat-icon>
            </div>
        </cdk-virtual-scroll-viewport>

    </div>
</div>

<context-menu #tagMenu id="tagMenu"> 
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableNewTag" (execute)="createNewTag()">
        <div class="context-item">
            <span class="context-item-icon">
                <mat-icon class="material-item-icon">local_offer</mat-icon>
            </span>
            <span class="context-item-title">{{ 'NEW_TAG_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableRename" (execute)="renameTag()">
        <div class="context-item">
            <span class="context-item-icon">
                <mat-icon class="material-item-icon">edit</mat-icon>
            </span>
            <span class="context-item-title">{{ 'RENAME_TAG_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableDelete" (execute)="deleteSelectedTag()">
        <div class="context-item">
            <span class="context-item-icon">
                <mat-icon class="material-item-icon">delete</mat-icon>
            </span>
            <span class="context-item-title">{{ 'DELETE_TAG_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [subMenu]="colorSubMenu" *ngIf="!isDirectoryTag">
        <mat-radio-group class="color-selection-radio" (change)="updateDefaultColor()" [(ngModel)]="tagColor">
            <mat-radio-button class="grey-radio" value="#607d8b" (click)="updateDefaultColor('#607d8b')">
            </mat-radio-button>
            <mat-radio-button class="sky-radio" value="#00b8d4" (click)="updateDefaultColor('#00b8d4')">
            </mat-radio-button>
            <mat-radio-button class="blue-radio" value="#0071bb" (click)="updateDefaultColor('#0071bb')">
            </mat-radio-button>
            <mat-radio-button class="navy-radio" value="#6200ea" (click)="updateDefaultColor('#6200ea')">
            </mat-radio-button>
            <mat-radio-button class="red-radio" value="#d50000" (click)="updateDefaultColor('#d50000')">
            </mat-radio-button>
            <mat-radio-button class="orange-radio" value="#fd8100" (click)="updateDefaultColor('#fd8100')">
            </mat-radio-button>
            <mat-radio-button class="green-radio" value="#39b54a" (click)="updateDefaultColor('#39b54a')">
            </mat-radio-button>
        </mat-radio-group>
    </ng-template>
</context-menu>

<context-menu #colorSubMenu id="colorSubMenu" class="color-contextmenu">
    <ng-template contextMenuItem>
        <color-chrome [color]="tagColor" (onChange)="handleColorChange($event)"></color-chrome>
    </ng-template>
</context-menu>