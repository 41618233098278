
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<mat-list [ngClass]="{'mat-list-disabled':iscontextMenuActionsDisabled}">
    <mat-list-item (click)="renameTag()" class="disable-select">
        <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
        <span class="disable-select">{{ 'EDIT_TAG_LBL' | translate }}</span>
    </mat-list-item>
    <mat-list-item (click)="deleteTag()">
        <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
        <span class="disable-select">{{ 'DELETE_TAG_ACTION' | translate }}</span>
    </mat-list-item>
    <mat-list-item (click)="close()" class="disable-select">
        <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        <span class="disable-select">{{'CANCEL'| translate }}</span>
    </mat-list-item>
</mat-list>