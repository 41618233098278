
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import {
    PreferenceRootState,
    getPreferences,
    getIsPreferencesLoading,
    getPreferencesByIds,
    getPreferenceById,
    getSignatures,
    getSelectedAccount,
    getIdentity,
    getAttributes} from "../store/selectors";
import { Observable } from "rxjs";
import { Preference, Signature, DataSource } from "../shared/models";
import {
    IsPreferenceLoadingAction,
    LoadPreferenceSuccessAction,
    LoadPreferenceFailedAction,
    RemovePreferencesAction,
    ResetSignatures,
    RemoveSignatures,
    UpdateSignatures,
    AddSignature,
    UpdateManyPreferencesSuccess,
    SelectAccount,
    SetIdentityAction
} from "../store/actions";
import { Identity } from "../shared/models/identity.model";
import { Attribute } from "../shared/models/attribute.model";
import { filter } from "rxjs/operators";
import { ConfigService } from "src/app/config.service";
import { SetPollingInterval } from "src/app/actions/app";
import { CommonUtils } from "src/app/common/utils/common-util";

@Injectable()
export class PreferenceRepository {


    constructor(
        private configService: ConfigService,
        private store: Store<PreferenceRootState>) {
    }

    getPreferences(): Observable<Preference[]> {
        return this.store.select(getPreferences);
    }

    getPreferencesByIds(ids: string[]): Observable<Preference[]> {
        return this.store.pipe(select(state => getPreferencesByIds(state, ids)), filter(v => !!v));
    }

    getPreferenceById(id: string): Observable<Preference> {
        return this.store.select(state => getPreferenceById(state, id));
    }

    getSelectedAccount(): Observable<DataSource> {
        return this.store.select(getSelectedAccount);
    }

    getIdentity(): Observable<Identity> {
        return this.store.select(getIdentity);
    }

    getAttributes(): Observable<Attribute[]> {
        return this.store.select(getAttributes);
    }

    selectAccount(account: DataSource): void {
        return this.store.dispatch(new SelectAccount(account));
    }

    setPreferenceIsLoading(): void {
        this.store.dispatch(new IsPreferenceLoadingAction());
    }

    getIsPreferencesLoading(): Observable<boolean> {
        return this.store.select(getIsPreferencesLoading);
    }

    setPreferenceLoadedSuccess(preferences: Preference[]): void {
        this.store.dispatch(new LoadPreferenceSuccessAction(preferences));
    }

    setIdentity(identity: Identity): void {
        this.store.dispatch(new SetIdentityAction(identity));
    }

    setPreferenceLoadedFail(): void {
        this.store.dispatch(new LoadPreferenceFailedAction());
    }

    removePreferences(): void {
        this.store.dispatch(new RemovePreferencesAction());
    }

    updatePreferences(preferences: Preference[]): void {
        this.store.dispatch(new UpdateManyPreferencesSuccess(preferences));
        const config: any = {};
        preferences.forEach(pref => {
          config[pref.key] = pref.value;
          if (pref.key === "zimbraPrefMailPollingInterval") {
            this.store.dispatch(new SetPollingInterval(CommonUtils.getPollingInterval(pref.value, 3000)));
          }
        });
        this.configService.setPreferences(config);
    }

    getSignatures(): Observable<Signature[]> {
        return this.store.select(getSignatures);
    }

    updateSignatures(signatures: Signature[]): void {
        this.store.dispatch(new UpdateSignatures(signatures));
    }

    addSignatures(signature: Signature): void {
        this.store.dispatch(new AddSignature(signature));
    }

    removeSignatures(ids: string[]): void {
        this.store.dispatch(new RemoveSignatures(ids));
    }

    resetSignatures(): void {
        this.store.dispatch(new ResetSignatures());
    }
}
