
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, OnInit, OnDestroy, Component, Inject, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { take, filter, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { ToastService } from "src/app/common/providers/toast.service";
import { CommonService } from "src/app/services/common.service";
import { AuthService } from "src/app/common/providers/auth.service";
import { SearchState } from "src/app/reducers/search";
import { AppState } from "src/app/reducers/app";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ElectronService } from "src/app/services/electron.service";
import { ConfigService } from "src/app/config.service";
import { getUserProfile } from "src/app/reducers";
import { MailUtils } from "src/app/common/utils/mail-utils";
import { environment } from "src/environments/environment";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Logout } from "src/app/actions/app";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-desktop-change-password",
    templateUrl: "./desktop-change-password.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopChangePasswordComponent implements OnInit, OnDestroy {
    changePasswordType = "zimbra";
    urlSafe: SafeResourceUrl;
    type: string;
    oldPassword: boolean = false;
    newPassword: boolean = false;
    confirmPassword: boolean = false;
    oldPasswordInput: string = "";
    newPasswordInput: string = "";
    confirmPasswordInput: string = "";
    private isAlive$ = new Subject<boolean>();
    userEmail: string = "";
    isCordovaOrElectron: boolean;
    constructor(
        private dialogRef: MatDialogRef<DesktopChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        public sanitizer: DomSanitizer,
        private changeDetectionRef: ChangeDetectorRef,
        private toastService: ToastService,
        private commonService: CommonService,
        private auth: AuthService,
        private store: Store<SearchState | AppState>,
        private mailBroadcast: Broadcaster,
        private electronService: ElectronService,
        private configService: ConfigService
    ) {
        this.urlSafe = data.changePasswordUrl;
        if (data.changePasswordUrl !== "") {
            this.urlSafe = "";
        }
        this.type = data.type;
        this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
            if (res.email) {
                this.userEmail =  MailUtils.checkEmailArray(res.email);
                this.changeDetectionRef.markForCheck();
            } else {
                const profileUser = MailUtils.getProfileFromStorage();
                this.userEmail = MailUtils.checkEmailArray(profileUser.email);
                this.changeDetectionRef.markForCheck();
            }
        });
        this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
        this.changeDetectionRef.markForCheck();
    }

    close() {
        this.dialogRef.close();
    }

    ngOnInit() {
        if (localStorage.getItem("changePasswordType") !== null) {
            this.changePasswordType = localStorage.getItem("changePasswordType");
        }
        this.changeDetectionRef.markForCheck();
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    toggleOldPassword() {
        if (this.oldPassword) {
            this.oldPassword = false;
        } else {
            this.oldPassword = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    toggleNewPassword() {
        if (this.newPassword) {
            this.newPassword = false;
        } else {
            this.newPassword = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    toggleConfirmPassword() {
        if (this.confirmPassword) {
            this.confirmPassword = false;
        } else {
            this.confirmPassword = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    changePassword(): void {
        if (this.oldPasswordInput === "" || this.newPasswordInput === "" || this.confirmPasswordInput === "") {
            this.toastService.show(MailConstants.BLANK_FIELD);
            return;
        } else if (this.newPasswordInput !== this.confirmPasswordInput) {
            this.toastService.show(MailConstants.MATCH_PASSWORD_ERROR);
            return;
        }
        const body = {
            oldPassword: this.oldPasswordInput,
            password: this.newPasswordInput,
            email: this.userEmail
        };
        this.commonService.changePassword(body).pipe(take(1)).subscribe(res => {
            this.toastService.show(MailConstants.PASSWORD_CHANGE_SUCCESS);
            this.mailBroadcast.broadcast(BroadcastKeys.HIDE_PROFILE_DIALOG);
            this.close();
            this.logout();
        }, err => {
            this.toastService.show(MailConstants.CURRENT_PASSWORD_WRONG);
        });
    }

    logout(): void {
        this.auth.removeLogin();
        this.store.dispatch(new Logout());
        if (this.electronService.isElectron) {
            this.auth.cordovaLogout();
            let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
            initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref;
            window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
            this.configService.loginIframe();
            this.changeDetectionRef.markForCheck();
          } else {
            window.location.href = "/api/call-logout";
          }
    }
}
