
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Input, Renderer2, HostListener, Directive, ElementRef,
    TemplateRef, ViewContainerRef, ContentChild
} from "@angular/core";
import { Utils } from "src/app/common";

@Directive({
    selector: "[vpTooltipDirective]"
})

export class TooltipDirective {

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef
    ) { }

    @Input() parametroPlantilla: TemplateRef<any>;

    @ContentChild("tooltipTemplate", { static: false }) private tooltipTemplateRef: TemplateRef<any>; // tslint:disable-line no-input-rename

    @HostListener("mouseenter") onMouseEnter(): void {
        if (Utils.isMobileDevice()) {
            return;
        }
        this.showTooltip();
    }

    @HostListener("mouseleave") onMouseLeave(): void {
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
        }
    }

    @HostListener("click") onClickListner(): void {
        if (Utils.isMobileDevice()) {
            this.showTooltip();
        }
    }

    showTooltip(): void {
        const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
        view.rootNodes.forEach(node =>
            this.renderer.appendChild(this.elementRef.nativeElement, node)
        );
        const hostPos = this.elementRef.nativeElement.getBoundingClientRect();
        const element = <HTMLElement>view.rootNodes[0];
        if (element !== null && !!element) {
            const top = hostPos.bottom + 10;
            const left = hostPos.left;
            this.renderer.setStyle(element, "top", `${top + 5}px`);
            this.renderer.setStyle(element, "left", `${left - 90}px`);
        }
    }
}
