
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule, Injectable } from "@angular/core";
import { RouterModule, Routes, CanLoad, Route, PreloadAllModules } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigService } from "./config.service";
import { AuthService } from "./common/providers/auth.service";
import { environment } from "src/environments/environment";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { MailConstants } from "./common/utils/mail-constants";
import { Store } from "@ngrx/store";
import { SetUserProfile } from "./actions/app";
import { ElectronService } from "./services/electron.service";
import { AppState } from "./reducers/app";

@Injectable()
export class AuthGuard implements CanLoad {
  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(
    private configService: ConfigService,
    public appStore: Store<AppState>,
    private authService: AuthService,
    private electronService: ElectronService) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getProfile().pipe(
      map(profile => {
        if (!profile) {
          if (this.electronService.isElectron) {
            this.electronService.deleteFromStorage(MailConstants.ZM_AUTH_TOKEN);
          } else {
            localStorage.removeItem(MailConstants.ZM_AUTH_TOKEN);
          }
          if (!this.isCordovaOrElectron) {
            window.location.href = this.configService.API_URL + "/api/login";
          }
        }
        const userProfile = {
          firstName: profile.user.firstName,
          lastName: profile.user.lastName,
          email: profile.user.email,
          imageData: profile.user.imageData
        };
        // Fix: routing from mail module to preference removing avtar, so added fix here
        if (route.path === "calendar") {
          if (this.electronService.isElectron) {
            this.electronService.setToStorage("profileUser", userProfile);
          } else {
            localStorage.setItem("profileUser", JSON.stringify(userProfile));
          }
          this.appStore.dispatch(new SetUserProfile(userProfile));
        }
        return !!profile;
      }));
  }
}

const routes: Routes = [
  {
    path: "",
    redirectTo: "calendar",
    pathMatch: "full"
  },
  {
    path: "calendar",
    canLoad: [AuthGuard],
    data: { appName: "VNCcalendar", sidebar: false, fullWidth: true, path: "/calendar" },
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: "preferences",
    canLoad: [AuthGuard],
    data: {appName: "VNCcalendar", sidebar: true, fullWidth: true, path: "/preferences"},
    loadChildren: () => import('./preference/preference.module').then(m => m.PreferenceModule)
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Tell the router to use the HashLocationStrategy.
    useHash: false,
    enableTracing: false,

    // This will tell Angular to preload the lazy-loaded routes after the
    // application has been bootstrapped. This will extend to both top-level
    // and nested lazy-loaded modules.
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
