
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { environment } from "../../../environments/environment";
import { HttpHeaders } from "@angular/common/http";
import { MailConstants } from "./mail-constants";
import { ElectronService } from "src/app/services/electron.service";
import { UserProfile } from "src/app/shared/models";
import * as moment from "moment";
import * as _ from "lodash";
import { SearchFolder } from "src/app/shared/models/search-folder";

export class CommonUtils {

  static getBaseUrl() {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
      return baseUrl.split("/www/")[0] + "/www";
    } else if (environment.isElectron) {
      return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/mail")[0];
    } else {
      return "";
    }
  }

  static getFullUrl(url: string) {
    return CommonUtils.getBaseUrl() + url;
  }

  static getZimbraHeader(): HttpHeaders {
    const electronService: ElectronService = new ElectronService();
    const headers: HttpHeaders = new HttpHeaders();
    if (environment.isCordova || environment.isElectron) {
      const token = electronService.isElectron
        ? localStorage.getItem(MailConstants.ZM_AUTH_TOKEN)
        : localStorage.getItem(MailConstants.ZM_AUTH_TOKEN);
      return headers.set("Authorization", token).set("Content-Type", "application/json").set("Accept", "application/json");
    }
    return headers.set("Content-Type", "application/json").set("Accept", "application/json");
  }

  static htmlEscape(str: string): string {
    return str
      .replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  static isOnMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static isOnIOSMobile() {
    return /iPhone|iPad|iPod/i
      .test(navigator.userAgent);
  }

  static isOnIOS() {
    return typeof device !== "undefined" && device.platform.toUpperCase() === "IOS";
  }

  static isOnAndroid() {
    return typeof device !== "undefined" && device.platform.toUpperCase() === "ANDROID";
  }

  static isOnNativeMobileDevice() {
    return CommonUtils.isOnIOS() || CommonUtils.isOnAndroid();
  }

  static isInsideIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static escapeHTMLString(unescapedString: string): string {
    if (!unescapedString) {
      return "";
    }
    return unescapedString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  static unEscapeHTMLString(str: string): string {
    if (!str) {
      return "";
    }
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  static copyToClipboard(stringArray: String[]) {
    const str = stringArray.join("\n");
    if (environment.isCordova) {
      cordova.plugins.clipboard.copy(str);
    } else {
      // const event = (e: ClipboardEvent) => {
      //   e.clipboardData.setData("text/plain", str);
      //   e.preventDefault();
      //   document.removeEventListener("copy", event);
      // };
      // document.addEventListener("copy", event);
      // document.execCommand("copy");
      window.Clipboard = (function (window, document, navigator) {
        let textArea,
          copy;

        function isOS() {
          return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
          textArea = document.createElement("textArea");
          textArea.value = text;
          document.body.appendChild(textArea);
        }

        function selectText() {
          let range,
            selection;

          if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }
        }

        function copyToClipboard() {
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }

        copy = function (text) {
          createTextArea(text);
          selectText();
          copyToClipboard();
        };

        return {
          copy: copy
        };
      })(window, document, navigator);
      window.Clipboard.copy(str);
    }
  }

  static escapeCarriagesAndNewline(unescapedString: string): string {
    return unescapedString.replace(/\r?\n/g, " ");
  }

  static getIcon(appName: string): string {
    let icon = "";
    switch (appName.toLowerCase()) {
      case "vnctalk": icon = CommonUtils.getFullUrl("/assets/images/vnctalk.svg"); break;
      case "vncmail": icon = CommonUtils.getFullUrl("/assets/images/Outlook.svg"); break;
      case "vnctask": icon = CommonUtils.getFullUrl("/assets/images/VNCtask.svg"); break;
      case "vnccontacts": icon = CommonUtils.getFullUrl("/assets/images/VNCcontactsplus.svg"); break;
      case "vnccalendar": icon = CommonUtils.getFullUrl("/assets/images/VNCcalendar.svg"); break;
      case "vnccommander": icon = CommonUtils.getFullUrl("/assets/images/Collaboard.svg"); break;
      case "vncdirectory": icon = CommonUtils.getFullUrl("/assets/images/directory-new.svg"); break;
      case "vncchannels" : icon = CommonUtils.getFullUrl("/assets/images/ELO.svg"); break;
      case "vncgitlab" : icon = CommonUtils.getFullUrl("/assets/images/Confluence.svg"); break;
      case "vncproject" : icon = CommonUtils.getFullUrl("/assets/images/Jira.svg"); break;
      case "vncsafe" : icon = CommonUtils.getFullUrl("/assets/images/owncloud-simplified.svg"); break;
    }
    return icon;
  }

  static getPackageName(appName: string): string {
    const packageName = `biz.vnc.${appName.toLowerCase()}`;
    console.log("getPackageName", appName, packageName);
    return packageName;
  }

  static getAppUrl(appName: string): string {
    const availableiOSApps = {
      "vncmail": "",
      "vnctask": "itms-apps://itunes.apple.com/app/id1423089930",
      "vnccontacts": ""
    };
    const availableAndroidApps = {
      "vncmail": "",
      "vnctask": "market://details?id=biz.vnc.vnctask",
      "vnccontacts": ""
    };
    if (!CommonUtils.isOnAndroid()) {
      return availableiOSApps[appName.toLowerCase()];
    }
    return availableAndroidApps[appName.toLowerCase()];
  }

  static requestPermissions(): void {
    if (!cordova.plugins.permissions) {
      return;
    }
    const permissions = cordova.plugins.permissions;
    const checkVideoPermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("[root.component]  Camera permission is not turned on");
        };
        permissions.requestPermission(
          permissions.CAMERA,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkAudioPermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("[root.component] Audio permission is not turned on");
        };
        permissions.requestPermission(
          permissions.RECORD_AUDIO,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkWriteExternalStoragePermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("[root.component] Write external storage permission is not turned on");
        };
        permissions.requestPermission(
          permissions.WRITE_EXTERNAL_STORAGE,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    permissions.checkPermission(permissions.CAMERA, checkVideoPermissionCallback, null);
    permissions.checkPermission(permissions.RECORD_AUDIO, checkAudioPermissionCallback, null);
    permissions.checkPermission(permissions.WRITE_EXTERNAL_STORAGE, checkWriteExternalStoragePermissionCallback, null);
  }
  static parseUserProfile(contact) {
    const contactUser = typeof contact === "string"
      ? JSON.parse(contact)
      : contact;
    const user: UserProfile = {};
    user.firstName = contactUser.firstName;
    user.lastName = contactUser.lastName;
    if (Array.isArray(contactUser.email)) {
      user.email = contactUser.email[0];
    } else {
      user.email = contactUser.email;
    }
    user.avtarUrl = contactUser.avtarUrl;
    user.fullName = contactUser.fullName;
    user.firstLastCharacters = contactUser.firstLastCharacters;
    user.contactId = contactUser.contactId;
    user.imageData = contactUser.imageData;
    return user;
  }
  static checkEmailArray(emails): string {
    if (Array.isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
  }

  static getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return "#" + ("000000" + color).slice(-6);
  }
  static isToday(momentDate): boolean {
    const REFERENCE = moment();
    const TODAY = REFERENCE.clone().startOf("day");
    return momentDate.isSame(TODAY, "d");
  }
  static isYesterday(momentDate): boolean {
    const REFERENCE = moment();
    const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
    return momentDate.isSame(YESTERDAY, "d");
  }

  static isWithinAWeek(momentDate): boolean {
    const REFERENCE = moment();
    const A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");
    return momentDate.isAfter(A_WEEK_OLD);
  }

  static isWithinTwoWeeks(momentDate): boolean {
    const REFERENCE = moment();
    const TWO_WEEKS_OLD = REFERENCE.clone().subtract(17, "days").startOf("day");
    return momentDate.isAfter(TWO_WEEKS_OLD);
  }

  static isMoreThanTwoWeeks(momentDate): boolean {
    return !this.isWithinTwoWeeks(momentDate);
  }
  static plainTextToHTML(str: string): string {
    if (str !== undefined && str !== null) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    } else {
      return "";
    }
  }
  static copyClipborad(): void {
    if (typeof cordova !== "undefined") {
      cordova.plugins.clipboard.copy(window.getSelection().toString());
    } else {
      document.execCommand("copy");
    }
  }

  static selectAllText(selector: any): void {
    window.getSelection().selectAllChildren(selector);
  }

  static cutClipboard(): void {
    if (window.getSelection) {
      this.copyClipborad();
      if (window.getSelection().deleteFromDocument) {
        window.getSelection().deleteFromDocument();
      }
    }
  }

  static pasteClipboard(): void {
    if (typeof cordova !== "undefined") {
      cordova.plugins.clipboard.paste((text) => {
        if (window.getSelection().toString() !== "") {
          const sel = window.getSelection();
          if (sel.rangeCount) {
            const range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(document.createTextNode("ded"));
          }
        }
      });
    }
  }

  static clipboardPasteData(): Promise<any> {
    return new Promise(resolve => {
      if (typeof cordova !== "undefined") {
        cordova.plugins.clipboard.paste((text) => {
          resolve(text);
        });
      } else {
        resolve("");
      }
    });
  }

  static parseSearchQuery(query): any {
    const parsedQuery = {
      searchText: "",
      mailFrom: "",
      mailTo: "",
      date: null,
      hasAttchement: false
    };
    const queryData: string[] = query.split(" ");
    if (queryData.length > 0) {
      const fromMailquery = queryData.find(q => q.includes("from:"));
      parsedQuery.mailFrom = fromMailquery ? fromMailquery.split(":")[1] : "";

      parsedQuery.searchText = queryData.find(q => !q.includes(":"));

      const toMailquery = queryData.find(q => q.includes("to:"));
      parsedQuery.mailTo = toMailquery ? toMailquery.split(":")[1] : "";

      const dateMailquery = queryData.find(q => q.includes("date:"));
      parsedQuery.date = dateMailquery ? new Date(dateMailquery.split(":")[1]) : null;

      const attachmentMailquery = queryData.find(q => q.includes("has:"));
      parsedQuery.hasAttchement = attachmentMailquery ? true : false;
    }
    return parsedQuery;
  }

  static getPollingInterval(interval: string | number, minInterval: number): number {
    const MIN_INTERVAL = 3000;
    let newInterval = MIN_INTERVAL;
    if (interval === "5m") {
      newInterval = 300 * 1000;
    } else if ((+interval % 60) !== 0) {
      newInterval = +interval;
    } else {
      newInterval = +interval * 1000;
    }
    if (newInterval < MIN_INTERVAL || isNaN(newInterval)) {
      newInterval = MIN_INTERVAL;
    }
    return newInterval;
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  static getUserProfile(contactDetail: any, serverUrl): UserProfile {
    const contact: any = {};
    contact.id = contactDetail.id;
    contact.parentId = contactDetail.l;
    let contactDetailMap = {};
    if (contactDetail._attrs) {
      contactDetailMap = contactDetail._attrs;
    }
    contact.firstName = contactDetailMap["firstName"];
    contact.lastName = contactDetailMap["lastName"];

    if (contact.firstName && contact.firstName !== undefined) { contact.firstName = contact.firstName.trim(); }
    if (contact.lastName && contact.lastName !== undefined) { contact.lastName = contact.lastName.trim(); }
    if (contact.nickname && contact.nickname !== undefined) { contact.nickname = contact.nickname.trim(); }
    contact.fullName = this.getFullName(contact.firstName, contact.lastName);
    if (contact.fullName) {
      contact.firstLastCharacters = this.getCharacters(contact.firstName, contact.lastName);
    } else {
      contact.firstLastCharacters = "NL";
    }

    if (contactDetailMap.hasOwnProperty("image")) {
      contact.image = serverUrl + "/api/getAvatarProfileManager?user_id=" + contact.id;
    }
    contact.email = this.getEmailAddress(contactDetailMap);

    const firtsName = contact.firstName ? contact.firstName : "";
    const lastName = contact.lastName ? contact.lastName : "";
    const fullName = firtsName + " " + lastName;
    const profile: UserProfile = {
      contactId: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email[0],
      avtarUrl: contact.image,
      fullName: fullName,
      firstLastCharacters: contact.firstLastCharacters
    };

    return profile;
  }

  private static getFullName(firstName: string, lastName: string): string {
    if (firstName && lastName) {
      return firstName.trim() + " " + lastName.trim();
    } else {
      if (firstName && firstName !== undefined) {
        return firstName.trim();
      } else if (lastName && lastName !== undefined) {
        return lastName.trim();
      }
    }
  }

  private static getEmailAddress(contactDetailMap: any): string[] {
    const emailAddress: string[] = [];
    for (const key in contactDetailMap) {
      if (contactDetailMap[key] !== undefined && contactDetailMap[key] !== "" && (key.indexOf("email") > -1)) {
        emailAddress.push(contactDetailMap[key]);
      }
    }
    return emailAddress;
  }

  private static getCharacters(firstName, lastName) {
    if (firstName && firstName !== undefined && lastName && lastName !== undefined) {
      const fName: string = firstName.trim();
      const lName: string = lastName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return (chr1 + chr2).trim();
    } else if (firstName && firstName !== undefined) {
      const fName: string = firstName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      return chr1;
    } else if (lastName && lastName !== undefined) {
      const lName: string = lastName.trim();
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return chr2;
    } else {
      return "NA";
    }
  }

  static parseSearchFoders(res): SearchFolder[] {
    const searchFolders: SearchFolder[] = [];
    if (res && res.GetSearchFolderResponse && res.GetSearchFolderResponse[0].search) {
      if (Array.isArray(res.GetSearchFolderResponse[0].search)) {
        res.GetSearchFolderResponse[0].search.forEach(element => {
          if (element.types === "conversation") {
            searchFolders.push(element as SearchFolder);
          }
        });
      } else {
        searchFolders.push(res.GetSearchFolderResponse[0].search as SearchFolder);
      }
    }
    return searchFolders;
  }

  static parseCreateSearchResponse(res) {
    if (res && res.CreateSearchFolderResponse) {
      return res.CreateSearchFolderResponse[0].search[0] as SearchFolder;
    } else if (res.Fault && res.Fault[0].Reason) {
      throw Error(res.Fault[0].Reason.Text);
    }
    return null;
  }


  static getCurrentLanguage(language: string): string {
    let lang = "en";
    if (!!language) {
        if (language === "en_US" || language === "en_AU" || language === "en_GB" || language === "en") {
            lang = "en";
        } else if (language === "de") {
            lang = "de";
        } else {
            lang = "en";
        }
    }
    return lang;
  }

  static getAttachments(mailParts): any[] {
    let results: any[] = [];
    if (mailParts) {
        if (Array.isArray(mailParts)) {
            for (const part of mailParts) {
                const data = this.getAttachments(part);
                data.forEach(item => {
                    if (item.filename) {
                        results.push(item);
                    }
                });
            }
        } else {
            if (mailParts && (mailParts.filename)) {
                results.push(mailParts);
            } else if (mailParts.mp) {
                results = results.concat(this.getAttachments(mailParts.mp));
            }
        }
    }
    return results;
}

static getPlainText(str: string): string {
  if (str !== undefined && str !== null) {
      return str.replace(/<\/div>/ig, "\n")
      .replace(/<\/p>/ig, "\n")
      .replace(/<br \/>/ig, "\n")
      // .replace(/<br>/ig, "\n")
      .replace(/<[^>]+>/ig, "");
  } else {
      return "";
  }
}

static addTokenToRequest(url: string): string {
  if (environment.isCordova || environment.isElectron) {
    const token = localStorage.getItem("token");
    url += `&token=${token}`;
  }
  return url;
 }

}
