
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ENTER, SEMICOLON, FF_SEMICOLON } from "@angular/cdk/keycodes";
import {
    Component, OnInit, ViewChild, ElementRef, Input,
    ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, OnDestroy, Output, EventEmitter
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { CommonService } from "src/app/services/common.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Utils } from "src/app/common";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { MatAutocompleteTrigger, MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
    selector: "vp-calendar-equipment-autocomplete",
    templateUrl: "./calendar-equipment-autocomplete.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class CalendarEquipmentAutoCompleteComponent implements OnChanges, OnDestroy {
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes: number[] = [ENTER, SEMICOLON, FF_SEMICOLON];
    emailCtrl = new FormControl();
    filteredEmails: string[];
    emails: any[] = [];
    searchedUsers: any[] = [];
    private isAlive$ = new Subject<boolean>();

    @Input() place: string;
    @Input() id: string;
    @Input() type: string = "Equipment";
    @ViewChild("emailInput", { static: false }) emailInput: ElementRef<HTMLInputElement>;
    @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
    @Output() added = new EventEmitter<any>();
    @Output() removed = new EventEmitter<any>();
    conflictEmails = {};
    constructor(
        // private mailService: MailService,
        private changeDetectionRef: ChangeDetectorRef,
        private broadcaster: Broadcaster,
        private commonService: CommonService
    ) {
        this.emailCtrl.valueChanges.pipe(debounceTime(100)).subscribe(value => {
            if (CommonUtils.isJson(value)) {
                return;
            }
            this.loadEmailSuggestion(value);
        });
    }

    loadEmailSuggestion(query) {
        if (query === "" || /\s/g.test(query)) {
            this.filteredEmails = [];
            this.changeDetectionRef.markForCheck();
            return;
        }
        this.commonService.getAutoCompleteGalList(query).subscribe(
            res => {
                this.searchedUsers = [];
                if (Array.isArray(res) && res.length > 0) {
                    if (res.length > 0) {
                        res.forEach(item => {
                            const data: any = {
                                email: item._attrs.email,
                                name: item._attrs.fullName
                            };
                            if (item._attrs.zimbraCalResType === this.type) {
                                this.generateTagList(data);
                            }
                        });
                    } else if (Utils.validateEmail(query)) {
                        this.searchedUsers.push({ title: query, name: query, email: query, image: "", checked: false });
                    }
                }
                this._filter(query);
                this.changeDetectionRef.markForCheck();
            },
            err => {
                if (Utils.isJson(err._body)) {
                    // this.mailService.openSnackBar(JSON.parse(err._body).msg);
                }
            }
        );
    }

    ngOnChanges(changes) { }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || "").trim()) {
            if (Utils.validateEmail(value)) {
                const item = { title: value, name: value, email: value, image: "", checked: false };
                this.emails.push(item);
                this.added.emit(value);
                this.autocompleteTrigger.closePanel();
                this.changeDetectionRef.markForCheck();
            }
        }
        if (input) {
          input.value = "";
        }
        this.emailCtrl.setValue(null);
        this.changeDetectionRef.markForCheck();
    }

    generateTagList(data) {
        const user = {
            title: "",
            name: "",
            email: "",
            image: "",
            checked: false
        };
        user.title = data.name;
        user.email = data.email;
        user.name = data.name;
        if (user.name === "") {
            user.name = user.email;
        }
        if (user.name.length > 20) {
            user.name = user.name.substring(0, 20) + "...";
        }
        this.searchedUsers.push(user);
        this.changeDetectionRef.markForCheck();
        return user;
    }

    remove(v: any): void {
        const index = this.emails.indexOf(v);
        if (index >= 0) {
            this.emails.splice(index, 1);
            this.removed.emit(v.email);
            this.broadcaster.broadcast("AUTO_COMPLETE_REMOVE_ITEM");
            this.changeDetectionRef.markForCheck();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.emails.find(v => v.email === event.option.value.email)) {
            this.added.emit(event.option.value.email);
            this.emails.push(event.option.value);
        }
        this.emailInput.nativeElement.value = "";
        this.emailCtrl.setValue(null);
        this.broadcaster.broadcast(MailConstants.BROADCAST_OVERFLOW_REDIRECT_DIALOG);
        this.changeDetectionRef.markForCheck();
    }

    private _filter(value: string): void {
        if (value !== null) {
            this.filteredEmails = this.searchedUsers.filter(user => !this.emails.includes(user.email));
            this.changeDetectionRef.markForCheck();
        }
    }

    getSelectedEmail(): any[] {
        return this.emails;
    }

    getInputValue(): string {
        return this.emailInput.nativeElement.value;
    }

    setEmailField(emailInfo: any): void {
        if (!this.emails.find(v => v.email === emailInfo.email)) {
            const item = { title: emailInfo.fullName, name: emailInfo.fullName, email: emailInfo.email, image: "", checked: false };
            this.emails.push(item);
            this.added.emit(emailInfo.email);
            this.changeDetectionRef.markForCheck();
        }
    }

    getAvatar(email) {
        return null; // this.convRepository.getAvatar(email);
    }

    resetEmail(): void {
        this.emails = [];
        this.changeDetectionRef.markForCheck();
    }

    resetInputValue(): void {
        this.emailInput.nativeElement.value = "";
        this.changeDetectionRef.markForCheck();
    }

    onInputEvent(ev): void {
        if (typeof cordova !== "undefined") {
            if (ev.data === ";") {
                const input = ev.target;
                const value = ev.target.value.replace(/;/g, "");
                this.addEmailToChips(input, value);
            }
        }
    }

    addEmailToChips(input: any, value: string) {
        if ((value || "").trim()) {
            if (Utils.validateEmail(value)) {
                const item = { title: value, name: value, email: value, image: "", checked: false };
                this.autocompleteTrigger.closePanel();
                this.changeDetectionRef.markForCheck();
            }
        }
        if (input) {
            input.value = "";
        }
        this.emailCtrl.setValue(null);
        this.changeDetectionRef.markForCheck();
    }

    onKeyDown(ev) {
        if (typeof cordova !== "undefined") {
            if (ev.key === "Backspace" && ev.target.value === "") {
                this.emails.pop();
                setTimeout(() => {
                    this.emailInput.nativeElement.focus();
                    this.autocompleteTrigger.closePanel();
                }, 50);
            }
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    setConflictEmails(data) {
        this.conflictEmails = data;
        this.changeDetectionRef.markForCheck();
    }

    isConflict(email) {
        if (typeof this.conflictEmails[email] === "undefined") {
          return false;
        } else {
          return !this.conflictEmails[email];
        }
    }

    setInputBox(str: any): void {
        this.emailInput.nativeElement.value = str;
    }
}
