
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  NgZone
} from "@angular/core";
import { UserProfile } from "../../models";
import { Store } from "@ngrx/store";
import { getUserProfile } from "../../../reducers";
import { filter, takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { AvatarCropperDialogComponent } from "../avatar-cropper-dialog/avatar-cropper-dialog.component";
import { ToastService } from "../../../common/providers/toast.service";
import { AppState } from "../../../reducers/app";
import { StartProcessing, StopProcessing, SetLastPhotoUpdate } from "../../../actions/app";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { ElectronService } from "src/app/services/electron.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MobileChangePasswordComponent } from "../mobile-change-password/mobile-change-password.component";
import { DesktopChangePasswordComponent } from "../desktop-change-password/desktop-change-password.component";
import { CommonService } from "src/app/services/common.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { MailUtils } from "src/app/common/utils/mail-utils";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-avatar-dialog",
  templateUrl: "./avatar-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarDialogComponent implements OnDestroy {
  currentUser: UserProfile;
  private isAlive$ = new Subject<boolean>();
  isMobileScreen: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AvatarDialogComponent>,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private broadcaster: Broadcaster,
    private tostService: ToastService,
    private appStore: Store<AppState>,
    private ngZone: NgZone,
    private changeDetectionRef: ChangeDetectorRef,
    private electronService: ElectronService,
    private breakpointObserver: BreakpointObserver,
    private commonService: CommonService,
    private configService: ConfigService
  ) {
    this.store
      .select(getUserProfile)
      .pipe(
        filter(v => !!v),
        takeUntil(this.isAlive$)
      )
      .subscribe(res => {
        if (res.fullName) {
          this.currentUser = res;
          this.changeDetectionRef.markForCheck();
        } else {
          const contactUser = this.electronService.isElectron
              ? this.electronService.getFromStorage("profileUser")
              : localStorage.getItem("profileUser");
          if (
            contactUser &&
            contactUser !== undefined &&
            contactUser !== "undefined"
          ) {
            this.currentUser = CommonUtils.parseUserProfile(contactUser);
          }
        }
      });

    this.broadcaster
      .on<any>("profileAvtarUpload")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        if (data) {
          if (!MailUtils.isIE()) {
            this.avatarUpload(this.blobToFile(data.operation, "avatar.png"));
          } else {
            this.avatarUpload(data.operation);
          }
        }
      });

    this.broadcaster
      .on<any>("profileRemoveAvtarUpload")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.removeAvtar();
      });
    this.broadcaster.on<any>(BroadcastKeys.HIDE_PROFILE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
  }

  avatarUpload(file) {
    console.log("avatarUpload", file);
    const formData = new FormData();
    formData.append("file", file);
    console.log("avatarUpload", formData);
    // this.appStore.dispatch(new StartProcessing());
    this.commonService
      .uploadAvatar(formData)
      .pipe(take(1))
      .subscribe(res => {
       // this.appStore.dispatch(new StopProcessing());
        const avatarURL = this.configService.get("avatarURL");
        const currentTime = new Date().getTime();
        this.store.dispatch(new SetLastPhotoUpdate({email: this.currentUser.email, timestamp: currentTime}));
        const id = res.replace(/"/g, "");
        this.currentUser.imageData = `${avatarURL}/${id}.jpg?v=${currentTime}`;
        this.changeDetectionRef.markForCheck();
      }, err => {
        this.tostService.show(MailConstants.FAIL_AVTAR_UPLOADED_MSG);
        // this.appStore.dispatch(new StopProcessing());
      });
  }


  removeAvtar() {
    this.commonService
      .modifyAvtar({
        id: this.currentUser.contactId,
        folderId: "",
        contactAttrs: [{ key: "image", value: "" }]
      })
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.tostService.show(MailConstants.AVTAR_REMOVED_MSG);
        this.appStore.dispatch(new StopProcessing());
      }, err => {
        this.tostService.show(MailConstants.FAIL_AVTAR_REMOVE_MSG);
        this.appStore.dispatch(new StopProcessing());
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  openCropperDialog() {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      data: { userProfile: this.currentUser },
      panelClass: "mail__dialog"
    };
    this.matDialog.open(AvatarCropperDialogComponent, dialogArgs);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  changePassword(): void {
    if (CommonUtils.isOnMobileDevice()) {
      this.openMobileChangePasswordDialog("zimbra" , "");
    } else {
      this.openChangePasswordDialog("zimbra" , "");
    }
  }

  openChangePasswordDialog(type: string , changePasswordUrl: string ): void {
    const dialogArgs = {
      width: "325px",
      autoFocus: false,
      data: { type: type, changePasswordUrl: changePasswordUrl },
      panelClass: "desktop_change_password"
    };
    this.matDialog.open(DesktopChangePasswordComponent, dialogArgs);
  }

  openMobileChangePasswordDialog(type: string , changePasswordUrl: string): void {
    const dialogArgs = {
      maxWidth: "100vw",
      autoFocus: false,
      data: { type: type, changePasswordUrl: changePasswordUrl },
      panelClass: "mobile_change_password"
    };
    this.matDialog.open(MobileChangePasswordComponent, dialogArgs);
  }

  public blobToFile = (blob: Blob, fileName: string): File => {
    const date = new Date().getTime();
    let file = new File([blob], fileName, {lastModified: date});
    if (typeof cordova !== "undefined") {
      file = new FileObject([blob], fileName, {lastModified: date});
    }
    return file;
  }

}
