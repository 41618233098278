
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { UserProfile } from "../shared/models";

export class Utils {
  static escapeRegExpStr(str: string) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  static insertAt(base: string, insert: string, index: number) {
    return base.slice(0, index) + insert + base.slice(index);
  }

  static isAndroid() {
    return /android/i.test(navigator.userAgent || navigator.vendor) ? true : false;
  }

  static isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static parseUserProfile(contact) {
    const contactUser = typeof contact === "string"
        ? JSON.parse(contact)
        : contact;
    const user: UserProfile = {};
    user.firstName = contactUser.firstName;
    user.lastName = contactUser.lastName;
    user.email = contactUser.email;
    user.avtarUrl = contactUser.avtarUrl;
    user.fullName = contactUser.fullName;
    user.firstLastCharacters = contactUser.firstLastCharacters;
    user.contactId = contactUser.contactId;
    user.imageData = contactUser.imageData;
    user.app = "mail";
    return user;
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true);
    }
    return (false);
  }

  static replacePtoDiv(str: string): string {
    if (str !== undefined && str !== null) {
      str =  str.replace(/<p>/g, "<div>").replace(/<\/p>/g, "</div>");
      str =  str.replace(/<signature>/g, "<div data-marker=\"__SIG_PRE__\">").replace(/<\/signature>/g, "</div>");
      return str;
    }
    return "";
  }

}
