
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header">
        <div class="header-text"> {{ 'CALENDARS.CUSTOM_REPEAT_LBL' | translate }} </div>
    </div>
    <div class="scroll-div-item">

        <div class="repeat-div">
            <div class="caption">{{ 'CALENDARS.REPEAT_DLG_LBL' | translate }}</div>
            <mat-form-field>
                <mat-select [(ngModel)]="repeatType">
                    <mat-option value="NONE">{{ 'CALENDARS.NONE_LBL' | translate }}</mat-option>
                    <mat-option value="DAI">{{ 'CALENDARS.DAILY_LBL' | translate }}</mat-option>
                    <mat-option value="WEE">{{ 'CALENDARS.WEEKLY_LBL' | translate }}</mat-option>
                    <mat-option value="MON">{{ 'CALENDARS.MONTLY_LBL' | translate }}</mat-option>
                    <mat-option value="YEA">{{ 'CALENDARS.YEARLY_LBL' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="repeatType === 'DAI'">
                <mat-radio-group [(ngModel)]="dayType" (change)="changeDaily($event)">
                    <mat-radio-button value="everyday">
                        {{ 'CALENDARS.EVERYDAY_LBL' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="everyweekday" class="every-week">
                        {{ 'CALENDARS.EVERY_WEEKDAY_LBL' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="every">
                        {{ 'CALENDARS.EVERY_LBL' | translate }}
                        <mat-form-field>
                            <input matInput matInput (click)="dayEveryWeekInputPress()" [(ngModel)]="everyDayInput">
                        </mat-form-field>
                        {{ 'CALENDARS.DAYS_LBL' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="repeatType === 'WEE'">
                <mat-radio-group [(ngModel)]="weekType">
                    <mat-radio-button value="everyDayItem">
                        {{ 'CALENDARS.EVERY_LBL' | translate }}

                        <mat-form-field>
                            <mat-select [formControl]="weekItems" multiple #multipleSelectItem (selectionChange)="changeMultipleValue()">
                                <mat-select-trigger>
                                    {{weekItems.value?.length}} {{'SELECTED' | translate }}
                                </mat-select-trigger>
                                <mat-option *ngFor="let week of weekList" [value]="week">
                                    <span *ngIf="week === 'SU'">{{ 'PREFERENCES.SUN' | translate }}</span>
                                    <span *ngIf="week === 'MO'">{{ 'PREFERENCES.MON' | translate }}</span>
                                    <span *ngIf="week === 'TU'">{{ 'PREFERENCES.TUE' | translate }}</span>
                                    <span *ngIf="week === 'WE'">{{ 'PREFERENCES.WED' | translate }}</span>
                                    <span *ngIf="week === 'TH'">{{ 'PREFERENCES.THU' | translate }}</span>
                                    <span *ngIf="week === 'FR'">{{ 'PREFERENCES.FRI' | translate }}</span>
                                    <span *ngIf="week === 'SA'">{{ 'PREFERENCES.SAT' | translate }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span *ngIf="showShortcutSelection !== ''"> {{showShortcutSelection}}</span>
                    </mat-radio-button>
                    <mat-radio-button value="every">
                            {{ 'CALENDARS.EVERY_LBL' | translate }}
                        <mat-form-field>
                            <input matInput matInput [(ngModel)]="everyWeekInput" (click)="weekInputEvery()"> {{ 'CALENDARS.WEEKS_ON_LBL' | translate }}
                        </mat-form-field>
                    </mat-radio-button>
                </mat-radio-group>
                <div class="day-selection">
                    <mat-checkbox [(ngModel)]="sunday">{{ 'PREFERENCES.SUN' | translate }}</mat-checkbox>
                    &nbsp;
                    <mat-checkbox [(ngModel)]="monday">{{ 'PREFERENCES.MON' | translate }}</mat-checkbox>
                    &nbsp;
                    <mat-checkbox [(ngModel)]="tuesday">{{ 'PREFERENCES.TUE' | translate }}</mat-checkbox>
                    &nbsp;
                    <mat-checkbox [(ngModel)]="wedensDay">{{ 'PREFERENCES.WED' | translate }}</mat-checkbox>
                    &nbsp;
                    <mat-checkbox [(ngModel)]="thursday">{{ 'PREFERENCES.THU' | translate }}</mat-checkbox>
                    &nbsp;
                    <mat-checkbox [(ngModel)]="friday">{{ 'PREFERENCES.FRI' | translate }}</mat-checkbox>
                    &nbsp;
                    <mat-checkbox [(ngModel)]="saturday">{{ 'PREFERENCES.SAT' | translate }}</mat-checkbox>
                </div>
            </div>

            <div *ngIf="repeatType === 'MON'">
                <mat-radio-group [(ngModel)]="monthlyType">
                    <mat-radio-button value="day">
                        {{ 'CALENDARS.DAY_LBL' | translate }}
                        <mat-form-field>
                            <input matInput matInput (click)="montlyDayInputClick()" [(ngModel)]="monthlyDayInput">
                        </mat-form-field>
                        {{ 'CALENDARS.OF_EVERY_LBL' | translate }}
                        <mat-form-field>
                            <input matInput matInput [(ngModel)]="monthlyDayMonthInput" (click)="montlyDayInputClick()">
                        </mat-form-field>
                        {{ 'CALENDARS.MONTH_LBL' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="the">
                        {{ 'CALENDARS.THE_LBL' | translate }}
                        <mat-form-field>
                            <mat-select [(ngModel)]="monthlyDayWeek">
                                <mat-option value="1">{{ 'CALENDARS.FIRST_LBL' | translate }}</mat-option>
                                <mat-option value="2">{{ 'CALENDARS.SECOND_LBL' | translate }}</mat-option>
                                <mat-option value="3">{{ 'CALENDARS.THIRD_LBL' | translate }}</mat-option>
                                <mat-option value="4">{{ 'CALENDARS.FOURTH_LBL' | translate }}</mat-option>
                                <mat-option value="-1">{{ 'CALENDARS.LAST_LBL' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-select [(ngModel)]="monthlyWeekDay">
                                <mat-option value="-3">{{ 'CALENDARS.WEEK_DAY_LBL' | translate }}</mat-option>
                                <mat-option value="-2">{{ 'CALENDARS.DAY_DROPDOWN_LBL' | translate }}</mat-option>
                                <mat-option value="-1">{{ 'CALENDARS.WEEKEND_DAY_LBL' | translate }}</mat-option>
                                <mat-option value="0">{{ 'SUNDAY' | translate }}</mat-option>
                                <mat-option value="1">{{ 'MONDAY' | translate }}</mat-option>
                                <mat-option value="2">{{ 'TUESDAY' | translate }}</mat-option>
                                <mat-option value="3">{{ 'WEDNESDAY' | translate }}</mat-option>
                                <mat-option value="4">{{ 'THURSDAY' | translate }}</mat-option>
                                <mat-option value="5">{{ 'FRIDAY' | translate }}</mat-option>
                                <mat-option value="6">{{ 'SATURDAY' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        {{ 'CALENDARS.OF_EVERY_LBL' | translate }}
                        <mat-form-field>
                            <input matInput matInput (click)="evertMonthsInputClick()" [(ngModel)]="everyMonthsInput">
                        </mat-form-field>
                        {{ 'CALENDARS.MONTHS_LBL' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>



            <div *ngIf="repeatType === 'YEA'">
                <mat-radio-group [(ngModel)]="yearlyType">
                    <mat-radio-button value="every">
                        {{ 'CALENDARS.EVERY_YEAR_ON_LBL' | translate }}
                        <mat-form-field>
                            <mat-select [(ngModel)]="everyYearMonth">
                                <mat-option value="1">{{ 'CALENDARS.JANUARY_LBL' | translate }}</mat-option>
                                <mat-option value="2">{{ 'CALENDARS.FABRUARY_LBL' | translate }}</mat-option>
                                <mat-option value="3">{{ 'CALENDARS.MARCH_LBL' | translate }}</mat-option>
                                <mat-option value="4">{{ 'CALENDARS.APRIL_LBL' | translate }}</mat-option>
                                <mat-option value="5">{{ 'CALENDARS.MAY_LBL' | translate }}</mat-option>
                                <mat-option value="6">{{ 'CALENDARS.JUNE_LBL' | translate }}</mat-option>
                                <mat-option value="7">{{ 'CALENDARS.JULY_LBL' | translate }}</mat-option>
                                <mat-option value="8">{{ 'CALENDARS.AUGUST_LBL' | translate }}</mat-option>
                                <mat-option value="9">{{ 'CALENDARS.SEPTEMBER_LBL' | translate }}</mat-option>
                                <mat-option value="10">{{ 'CALENDARS.OCTOBER_LBL' | translate }}</mat-option>
                                <mat-option value="11">{{ 'CALENDARS.NOVEMBER_LBL' | translate }}</mat-option>
                                <mat-option value="12">{{ 'CALENDARS.DECEMBER_LBL' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput matInput [(ngModel)]="everyYearInput" (click)="yearlyInputClick()">
                        </mat-form-field>
                    </mat-radio-button>
                    <mat-radio-button value="the">
                        {{ 'CALENDARS.THE_LBL' | translate }}
                        <mat-form-field>
                            <mat-select [(ngModel)]="yealyDayWeek">
                                <mat-option value="1">{{ 'CALENDARS.FIRST_LBL' | translate }}</mat-option>
                                <mat-option value="2">{{ 'CALENDARS.SECOND_LBL' | translate }}</mat-option>
                                <mat-option value="3">{{ 'CALENDARS.THIRD_LBL' | translate }}</mat-option>
                                <mat-option value="4">{{ 'CALENDARS.FOURTH_LBL' | translate }}</mat-option>
                                <mat-option value="-1">{{ 'CALENDARS.LAST_LBL' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-select [(ngModel)]="yearlyWeekDay">
                                <mat-option value="-3">{{ 'CALENDARS.WEEK_DAY_LBL' | translate }}</mat-option>
                                <mat-option value="-2">{{ 'CALENDARS.DAY_DROPDOWN_LBL' | translate }}</mat-option>
                                <mat-option value="-1">{{ 'CALENDARS.WEEKEND_DAY_LBL' | translate }}</mat-option>
                                <mat-option value="0">{{ 'SUNDAY' | translate }}</mat-option>
                                <mat-option value="1">{{ 'MONDAY' | translate }}</mat-option>
                                <mat-option value="2">{{ 'TUESDAY' | translate }}</mat-option>
                                <mat-option value="3">{{ 'WEDNESDAY' | translate }}</mat-option>
                                <mat-option value="4">{{ 'THURSDAY' | translate }}</mat-option>
                                <mat-option value="5">{{ 'FRIDAY' | translate }}</mat-option>
                                <mat-option value="6">{{ 'SATURDAY' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        {{ 'CALENDARS.OF_EVERY_LBL' | translate }}
                        <mat-form-field>
                            <mat-select [(ngModel)]="theYearMonth">
                                <mat-option value="1">{{ 'CALENDARS.JANUARY_LBL' | translate }}</mat-option>
                                <mat-option value="2">{{ 'CALENDARS.FABRUARY_LBL' | translate }}</mat-option>
                                <mat-option value="3">{{ 'CALENDARS.MARCH_LBL' | translate }}</mat-option>
                                <mat-option value="4">{{ 'CALENDARS.APRIL_LBL' | translate }}</mat-option>
                                <mat-option value="5">{{ 'CALENDARS.MAY_LBL' | translate }}</mat-option>
                                <mat-option value="6">{{ 'CALENDARS.JUNE_LBL' | translate }}</mat-option>
                                <mat-option value="7">{{ 'CALENDARS.JULY_LBL' | translate }}</mat-option>
                                <mat-option value="8">{{ 'CALENDARS.AUGUST_LBL' | translate }}</mat-option>
                                <mat-option value="9">{{ 'CALENDARS.SEPTEMBER_LBL' | translate }}</mat-option>
                                <mat-option value="10">{{ 'CALENDARS.OCTOBER_LBL' | translate }}</mat-option>
                                <mat-option value="11">{{ 'CALENDARS.NOVEMBER_LBL' | translate }}</mat-option>
                                <mat-option value="12">{{ 'CALENDARS.DECEMBER_LBL' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="end-div" *ngIf="repeatType !== 'NONE'">
            <div class="caption">{{ 'CALENDARS.END_LBL' | translate }}</div>
            <div>
                <mat-radio-group [(ngModel)]="repeatEndType">
                    <mat-radio-button value="N">{{ 'CALENDARS.NO_END_DATE_LBL' | translate }}</mat-radio-button>
                    <mat-radio-button value="A">
                        {{ 'CALENDARS.END_AFTER' | translate }}
                        <mat-form-field>
                            <input matInput matInput [(ngModel)]="repeatEndCount" (click)="repeatEndClick()">
                        </mat-form-field>
                        {{ 'CALENDARS.OCCURENCES_LBL' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="D">
                        <mat-form-field class="date-field">
                            <input matInput (click)="pickerInputClick()" [matDatepicker]="picker1" placeholder="{{ 'CALENDARS.END_BY_LBL' | translate }}" [formControl]="date">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 (opened)="openDatepicker($event)"></mat-datepicker>
                        </mat-form-field>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="footer-repeat">
        <button mat-button (click)="submit()">{{'COMMON.OK' | translate }}</button>
        <button mat-button (click)="close()">{{'COMMON.CANCEL' | translate }}</button>
    </div>
</div>