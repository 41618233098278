
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { CalendarRootState, getCalendarFoldersState, getCalendarEventsByIds, getCalendarFolders } from "../store/selectors";
import {
  LoadCalendarEventsSuccessAction,
  LoadMoreCalendarEventsSuccessAction,
  CreateCalendarEventSuccessAction,
  UpdateCalendarEventSuccessAction,
  UpdateCalendarEventsSuccessAction,
  DeleteCalendarEventSuccessAction,
  DeleteCalendarEventsSuccessAction,
  ResetCalendarEventsAction,
  SetCalendarFolders, SetCalendarEvents,
  SetCalendarEvent, RemoveCalendarEvent,
  UpdateCalendarEvent,
  CreateCalendarFolderSuccess,
  UpdateCalendarFolderSuccess
} from "../store/actions";
import { CommonService } from "../../services/common.service";
import { CalendarView, CalendarUtils } from "../vp-calendar/common/calendar-common.module";
import {
  Appointment, AppointmentRequest, AppointmentInCompose
} from "../../common/models/appoinment.model";
import {
  CalendarFolder, CalendarEvent, CalendarComposeViewDefaultControl, KeyValue, SelectingCalendarData, FolderLink
} from "../../common/models/calendar.model";
import jstimezonedetect from "jstimezonedetect";
import { isArray } from "util";
import { UserProfile } from "src/app/shared/models";
import * as moment from "moment";
import { app } from "electron";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { CalenderUtils } from "../utils/calender-utils";
import { ToastService } from "src/app/common/providers/toast.service";
import {
  LoadMailTags,
  LoadMailTagsSuccess,
  LoadMailTagsFail,
  CreateMailTag,
  CreateMailTagSuccess,
  CreateMailTagFail,
  UpdateMailTag,
  UpdateMailTagSuccess,
  UpdateMailTagFail,
  DeleteMailTag,
  DeleteMailTagSuccess,
  DeleteMailTagFail
} from "src/app/actions/mail-tag.action";
import { RootState, getAllMailTag, getCalendarSelectTag, getAllDirectoryTags, getMailTags } from "src/app/reducers";
import { Observable, Subject } from "rxjs";
import { MailTag } from "src/app/common/models/mail-tag.model";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { TranslateService } from "@ngx-translate/core";
import { ElectronService } from "src/app/services/electron.service";
import { Router } from "@angular/router";
import { AppService } from "src/app/services/app.service";
import { CreateDirectoryTag, CreateDirectoryTagFail, CreateDirectoryTagSuccess } from "src/app/actions/directory-tag.action";
import { DirectoryTag } from "src/app/common/models/directory-tag.model";
import { CommonUtils } from "src/app/common/utils/common-util";

const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3"
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF"
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA"
  }
};
const CALENDARCONSTANT: any = {
  DEFAULT_FOLDER_COLOR: 1,
  COLOR_CODES: [
    null,
    "BLUE",
    "CYAN",
    "GREEN",
    "PURPLE",
    "RED",
    "YELLOW",
    "PINK",
    "GRAY",
    "ORANGE"
  ],
  RGB_CODES: [
    "#999999", // GRAY
    "#42a5f6", // BLUE
    "#00BCD4", // CYAN
    "#66bb6a", // GREEN
    "#5B69C3", // PURPLE
    "#F1524A", // RED
    "#ef6c00", // YELLOW
    "#E91E63", // PINK
    "#999999", // GRAY
    "#ffa500"  // ORANGE
  ]
};

@Injectable()
export class CalendarRepository {
  calendarComposeViewDefaultControl: CalendarComposeViewDefaultControl;
  appointmentInComposeModel: AppointmentInCompose;
  userProfile: UserProfile;

  totalCalendarEvents: CalendarEvent[] = [];
  selectingCalendar: SelectingCalendarData = <SelectingCalendarData>{};
  selectingCalendarView: CalendarView = CalendarView.Day;

  private calendarFolders: CalendarFolder[];
  private events: CalendarEvent[];
  private appointment: Appointment;

  private currentEventsFromDate: any = moment();
  private currentEventsToDate: any = moment();

  private isTrashFolderSelected: boolean = false;
  private isInitializingData: boolean = false;

  private isGetFullMonth: boolean = false;
  private isMobileScreen: boolean = false;
  public miniCalSelectedDate: Date;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private service: CommonService,
    private store: Store<CalendarRootState | RootState>,
    private configService: ConfigService,
    private commonService: CommonService,
    private toastService: ToastService,
    private broadCaster: Broadcaster,
    private translate: TranslateService,
    private electronService: ElectronService,
    private router: Router,
    private broadcaster: Broadcaster,
    private appService: AppService
    ) {
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.calendarFolders = [];
    this.calendarComposeViewDefaultControl = <CalendarComposeViewDefaultControl>{};
    this.appointmentInComposeModel = this.getEmptyAppointmentModel();
    this.store.select(getCalendarFolders).subscribe(folders => {
      this.initCalendarComposeViewForFolderOptions(folders);
      this.generateCalendarFolderColor(folders);
    });
  }

  initCalendarEvent(currentDate?: Date): void {
    this.isGetFullMonth = true;
    // check if calendar folders is loaded
    if (this.calendarFolders.length === 0) {
      this.isInitializingData = true;
      this.getUserCalendarFolders(currentDate);
    } else {
      this.getCalendarEvents(this.isGetFullMonth, currentDate);
    }
  }

  initCalendarComposeView(): void {
    this.calendarComposeViewDefaultControl.repeatOptions = this.repeatOptions();
    this.calendarComposeViewDefaultControl.alarmOptions = this.reminderOptions();
    this.calendarComposeViewDefaultControl.briefcaseDisplayOptions = this.displayOptions();
  }

  initCalendarComposeViewForTimePoint(date: Date) {
    this.calendarComposeViewDefaultControl.startTimePointOptions = this.generateTimePointOptions(date);
    this.calendarComposeViewDefaultControl.endTimePointOptions = this.generateTimePointOptions(date);
  }

  initCalendarComposeViewForFolderOptions(folders: CalendarFolder[]): void {
    const foldersWithoutTrash: CalendarFolder[] = [];
    folders.forEach((folder: CalendarFolder) => {
      if (folder.id !== "3" && folder.name !== "Trash" && !folder.url) {
        foldersWithoutTrash.push(folder);
        if (folder.perm && (folder.perm === "r" || folder.perm === "rp")) {
          foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
        }
        const childFolders = CalenderUtils.getChildFolders([folder]);
        if (childFolders.length > 0) {
          childFolders.map( f => {
            foldersWithoutTrash.push(f);
            if (folder.perm && (folder.perm === "r" || folder.perm === "rp")) {
              foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
            }
          });
        }
      }
    });

    this.calendarComposeViewDefaultControl.calendarFolderOptions = foldersWithoutTrash;
  }

  initDefaultComposeAppointmentData(requestAppointment: AppointmentInCompose): AppointmentInCompose {
    requestAppointment.subject = "";
    requestAppointment.location = "";

    // Set default calendar folder
    const calendarFolder = this.setDefaultComposeAppointment(this.calendarFolders);
    requestAppointment.calendarFolder = !!calendarFolder ? calendarFolder : this.calendarFolders[0];
    // Set default event display
    requestAppointment.calendarDisplay = this.calendarComposeViewDefaultControl.briefcaseDisplayOptions[2];

    // Set default event repeat
    requestAppointment.calendarRepeat = this.calendarComposeViewDefaultControl.repeatOptions[0];

    // Set default event alarm
    requestAppointment.calendarAlarm = this.calendarComposeViewDefaultControl.alarmOptions[2];

    requestAppointment.calendarTimeSpanDuration = 30;

    if (requestAppointment.isAllDay) {
      requestAppointment.endDate = requestAppointment.startDate;
    }

    return requestAppointment;
  }

  addAllCalendarEvents(calendarEvents: CalendarEvent[]): void {
    this.store.dispatch(new LoadCalendarEventsSuccessAction({ events: calendarEvents }));
  }

  addCalendarEvents(calendarEvents: CalendarEvent[]): void {
    this.store.dispatch(new LoadMoreCalendarEventsSuccessAction({ events: calendarEvents }));
  }

  addCalendarEvent(calendarEvent: CalendarEvent): void {
    this.store.dispatch(new CreateCalendarEventSuccessAction({ event: calendarEvent }));
  }

  updateCalendarEvents(calendarEvents: CalendarEvent[]): void {
    this.store.dispatch(new UpdateCalendarEventsSuccessAction(calendarEvents));
  }

  updateCalendarEvent(calendarEvent: CalendarEvent): void {
    this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: calendarEvent.eventId, changes: calendarEvent }));
  }

  deleteCalendarEvents(calendarEvent: CalendarEvent): void {
    let eventIds = [];
    eventIds = this.totalCalendarEvents.filter((event: CalendarEvent) => event.id === calendarEvent.id).map(event => event.eventId);
    this.store.dispatch(new DeleteCalendarEventsSuccessAction({ eventIds: eventIds }));
  }

  deleteCalendarEvent(calendarEvent: CalendarEvent): void {
    this.store.dispatch(new DeleteCalendarEventSuccessAction({ event: calendarEvent }));
  }

  updateTimePointOptions(date: Date, isStartTime: boolean = true): void {
    if (isStartTime) {
      this.calendarComposeViewDefaultControl.startTimePointOptions = this.generateTimePointOptions(date);
    } else {
      this.calendarComposeViewDefaultControl.endTimePointOptions = this.generateTimePointOptions(date);
    }
  }

  checkAndUpdateCalendarEvents(calendarEvents: CalendarEvent[]): void {
    if (calendarEvents.length > 0) {
      let existingCalendarEventIds = [];
      let newCalendarEvents: CalendarEvent[];
      let existingCalendarEvents: CalendarEvent[];

      this.store.select(state => getCalendarEventsByIds(state, calendarEvents.map(c => c.eventId)))
        .pipe(take(1)).subscribe(events => {
          existingCalendarEventIds = events.filter(c => !!c).map(c => c.eventId);
        });

      newCalendarEvents = calendarEvents.filter(c => existingCalendarEventIds.indexOf(c.eventId) === -1);
      existingCalendarEvents = calendarEvents.filter(c => existingCalendarEventIds.indexOf(c.eventId) > -1);

      if (newCalendarEvents.length > 0) {
        this.addCalendarEvents(newCalendarEvents);
      }

      if (existingCalendarEvents.length > 0) {
        this.updateCalendarEvents(existingCalendarEvents);
      }
    }
  }

  getUserCalendarFolders(currentDate?: Date): void {
    // if (calendarFolders) {
    //   this.generateCalendarFolderColor(JSON.parse(calendarFolders));
    //   this.store.dispatch(new SetCalendarFolders(this.calendarFolders));
    //   this.getCalendarEvents(this.isGetFullMonth, currentDate);
    // } else {
      this.service.getUserCalendarFolders().subscribe(data => {
        let folders: CalendarFolder[] = [];
        folders = data.folder[0].folder;
        if (data.folder[0].link) {
         folders = folders.concat(data.folder[0].link);
        }
        this.generateCalendarFolderColor(folders);
        this.store.dispatch(new SetCalendarFolders(this.calendarFolders));
        // store new data for calendar folders
        if (this.isInitializingData) {
          this.getCalendarEvents(this.isGetFullMonth, currentDate);
        }
      });
    // }
  }

  getCalendarEvents(isGetFullMonth: boolean = false, date?: Date, addMorePreDate: number = 0, addMoreDate: number = 0): void {
    this.isGetFullMonth = isGetFullMonth;
    const currentDate = moment(date) || moment();

    if (isGetFullMonth) {
      // get full event for a month, with expand 12 days in pre/next month
      this.currentEventsFromDate = currentDate.clone().startOf("month").subtract(6, "day");
      this.currentEventsToDate = currentDate.clone().endOf("month").add(6, "day");
    } else {
      // get events for current Date from 00:00 to 23:59
      this.currentEventsFromDate = currentDate.clone().subtract(addMorePreDate, "day").hours(0).minutes(0).seconds(0).millisecond(0);
      this.currentEventsToDate = currentDate.clone().add(addMoreDate, "day").hours(23).minutes(59).seconds(59).millisecond(999);
    }

    // prepare request for getting appointment
    const request: AppointmentRequest = this.generateAppointmentRequest(
      this.currentEventsFromDate.format("x"), this.currentEventsToDate.format("x"));

    // fetch all appointments if trash folder is selected!
    if (this.isTrashFolderSelected) {
      delete request.calExpandInstStart;
      delete request.calExpandInstEnd;
    }

    // Prepare query list for getting appointment list
    // Need to get appointment based on the selected folder from sidebar, but that feature is not implemented so just noted here.
    // if (request.query === "") {
      // for (const folder of this.calendarFolders) {
      //   if (folder.id !== "3" || folder.name !== "Trash") {
      //     if (folder.f && folder.f.indexOf("#") !== -1) {
      //       request.query += "inid:\"" + folder.id + "\"";
      //       if (this.calendarFolders.indexOf(folder) > 0) {
      //         request.query += " OR ";
      //       }
      //     }
      //   }
      // }
      // request.query += "is:local";
      let query = this.getShareFolderQuery(this.calendarFolders);
      this.store.select(getCalendarSelectTag).pipe(take(1)).subscribe(tagName => {
        if (tagName !== "") {
          this.addAllCalendarEvents([]);
          query = `tag:"${tagName}" (${query})`;
        }
      });
      console.log("[Query]: ", query);
      request.query = query;
      if (request.query === "") {
        if (this.isGetFullMonth) {
          this.addAllCalendarEvents([]);
        } else {
          this.checkAndUpdateCalendarEvents([]);
        }
        return;
      }
    // }

    // if (this.searchString !== null && angular.isDefined(this.searchString) && this.searchString !== '') {
    //   request.query = this.searchString + ' (' + request.query + ')';
    // }

    this.service.getAppointmentList(request).pipe(take(1)).subscribe((data: any) => {
      if (data.appt) {
        const events = this.mappingAppointmentToEvents(data.appt);
        // console.log("date & appts: ", { date: this.currentEventsFromDate, appts: data.appt });
        // console.log("date & events: ", { date: this.currentEventsFromDate, events: events });
        if (this.isGetFullMonth) {
          this.addAllCalendarEvents(events);
        } else {
          // this.checkAndUpdateCalendarEvents(events);
          this.addAllCalendarEvents(events);
        }
      }
    });
  }

  getShareFolderQuery(folders: CalendarFolder[]): string {
    const ids: string [] = [];
    const allFolders = [...folders , ...CalenderUtils.getChildFolders(folders)];
    folders = allFolders;
    folders.filter(folder => folder.id !== "3").filter(folder => folder.f && folder.f.indexOf("#") !== -1).map( f => {
      if (f.perm) {
        if (f.id.indexOf(":") !== -1 ) {
          ids.push("inid:" + "\"" + f.id + "\"");
        } else {
          ids.push("inid:" + f.id);
        }
      } else {
        ids.push("inid:" + "\"" + f.id + "\"");
      }
    });
    return ids.join(",").replace(/,/g, " OR ");
  }

  getCalendarTimelinePosition(haftHourRatio: number): object {
    const now = moment();
    return {
      position: ((now.hour() * 60) + now.minute()) * (haftHourRatio * 4) / 60,
      label: now.format("hh:mm a")
    };
  }

  genNewEventAndUpdateChangeInCalendar(
    requestAppointment: AppointmentInCompose,
    isAddingNew: boolean = true): void {
    const event = this.generateNewEvent(requestAppointment);
    if (isAddingNew) {
      this.addCalendarEvent(event);
    } else {
      this.deleteCalendarEvent(event);
    }
  }

  createNewAppointment(requestAppointment: AppointmentInCompose): void {
    const options = this.prepareRequestForNewAppointment(requestAppointment);

    this.service.createAppointmentWithRequest(options).subscribe(
      (response) => {
        this.updateNewCalendarEvent(requestAppointment, response, true);
      },
      (err) => {
      });
  }

  deleteAppointment(event: CalendarEvent, isInstanced: boolean = false): void {
    let option: any = {};

    if (isInstanced) {
      option = {
        compNum: 0,
        id: event.invId,
        inst: {
          d: moment(event.inst[0].ridZ).format("YYYYMMDDTHHmmss"),
          tz: jstimezonedetect.determine().name()
        },
        emailInfo: {
          e: [
            {
              a: this.userProfile.email,
              t: "f"
            }
          ],
          mp: {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          },
          su: "Cancelled: " + event.title
        },
        s: event.s,
        ms: event.ms,
        rev: event.rev
      };

      this.service.deleteInstanceAppointment(option).subscribe(res => {
        this.deleteCalendarEvent(event);
      }, err => {
        console.log("delete event err log: ", err);
      });
    } else {
      option = {
        op: "trash",
        id: event.apptId
      };

      this.service.deleteAppointment(option).subscribe(res => {
        this.deleteCalendarEvents(event);
      }, err => {
        console.log("delete event err log: ", err);
      });
    }
  }

  updateNewCalendarEvent(requestAppointment: AppointmentInCompose, eventExtraOptions?: any, isEventSubmitted: boolean = false): void {
    const existingNewEvent = this.generateNewEvent(requestAppointment);
    // const event: CalendarEvent = this.generateNewEvent(requestAppointment, eventExtraOptions);
    if (isEventSubmitted) {
      const moreDate = this.getExtraDateBasedOnCalendarView();
      this.deleteCalendarEvent(existingNewEvent);
      // this.getCalendarEvents(false, existingNewEvent.start, moreDate.extraPre, moreDate.extraNext);
      this.getAllEvents();
    } else {
      this.updateCalendarEvent(existingNewEvent);
    }
  }

  refreshCalendarEvents(currentDate: Date) {
    // clear folder cache in local storage
    this.calendarFolders = [];

    this.store.dispatch(new ResetCalendarEventsAction());
    this.initCalendarEvent(currentDate);
  }

  private getExtraDateBasedOnCalendarView(): { extraPre: number, extraNext: number } {
    let addMoreDate = 0;
    let addMorePreDate = 0;
    switch (this.selectingCalendarView) {
      case CalendarView.Day: {
        addMoreDate = 0;
      } break;
      case CalendarView.DayThree: {
        addMoreDate = 2;
      } break;
      case CalendarView.Week: {
        addMoreDate = 6;
        addMorePreDate = 6;
      } break;
      case CalendarView.Workweek: {
        addMoreDate = 4;
        addMorePreDate = 4;
      } break;
      case CalendarView.Month: {
        addMoreDate = 37;
        addMorePreDate = 6;
      } break;
      default: addMoreDate = 0; break;
    }

    return { extraPre: addMorePreDate, extraNext: addMoreDate };
  }

  private prepareRequestForNewAppointment(requestAppointment: AppointmentInCompose): object {
    const timezone = jstimezonedetect.determine().name();
    const startTimeStamp = moment(requestAppointment.startDate).format("YYYYMMDDTHHmmss");
    const endTimeStamp = moment(requestAppointment.endDate).format("YYYYMMDDTHHmmss");
    let calenderId = "";
    calenderId = requestAppointment.calendarFolder.id;
    const requestObject = {
      "CreateAppointmentRequest": {
        "@": {
          "xmlns": "urn:zimbraMail"
        },
        "m": {
          "l": calenderId,
          "inv": {
            "comp": [{
              "at": [],
              "status": "CONF",
              "fb": requestAppointment.calendarDisplay.value,
              "fba": requestAppointment.calendarDisplay.value,
              "class": requestAppointment.isPrivate ? "PRI" : "PUB",
              "transp": "T",
              "draft": 0,
              "allDay": requestAppointment.isAllDay ? "1" : "0",
              "s": {
                "d": startTimeStamp,
                "tz": timezone
              },
              "e": {
                "d": endTimeStamp,
                "tz": timezone
              },
              "name": requestAppointment.subject,
              "loc": requestAppointment.location,
              "or": { }
            }]
          },
          "e": [],
          "su": requestAppointment.subject,
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };
    requestObject.CreateAppointmentRequest.m.inv.comp[0].or = { "a": this.userProfile.email};
    if (requestAppointment.calendarFolder.perm) {
      const rootFolder = this.getRootFolder(requestAppointment.calendarFolder);
      requestObject.CreateAppointmentRequest.m.inv.comp[0].or = {
        a: rootFolder.owner,
        sentBy: this.userProfile.email,
      };
      if (requestAppointment.calendarFolder.owner) {
        requestObject.CreateAppointmentRequest.m.e = [{
          a: rootFolder.owner,
          t: "f"
        }, {
          a: this.userProfile.email,
          t: "s"
        }];
      } else {
        requestObject.CreateAppointmentRequest.m.e = [{
          a: this.userProfile.email,
          t: "f"
        }, {
          a: this.userProfile.email,
          t: "s"
        }];
      }
      requestObject.CreateAppointmentRequest.m.inv.comp[0].transp = "O";
    }
    // check appointment reminder
    if (requestAppointment.calendarAlarm.value !== "" && requestAppointment.calendarAlarm.value !== 0) {
      requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"] = this.prepareAlarmInfo(requestAppointment);
      if (requestAppointment.isPrefCalendarReminderEmail === true) {
        requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"].push(this.prepareEmailAlarmInfo(requestAppointment)[0]);
      }
    } else if (requestAppointment.calendarAlarm.value === 0) {
      requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"] = [{
        "action": "DISPLAY",
        "trigger": {
          "rel": {
            "m": 0,
            "related": "START",
            "neg": "1"
          }
        }
      }];
      if (requestAppointment.isPrefCalendarReminderEmail === true) {
        requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"].push({
          "action": "EMAIL",
          "trigger": {
            "rel": {
              "m": 0,
              "related": "START",
              "neg": "1"
            }
          },
          "at": {
            "a": this.configService.prefs.zimbraPrefCalendarReminderEmail
          }
        });
      }
    } else if (requestAppointment["alarm"] !== undefined && requestAppointment["alarm"]["trigger"]) {
      // requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"] = this.prepareAlarmInfoFromAlarmResponse(requestAppointment);
    }

    // check appointment repeating
    // Kevin: adding condition to prevent recur added while appointment.repeat.value === 'F' / text === 'FREE' will occur error.
    if (requestAppointment.calendarRepeat.value !== "NON"
      && requestAppointment.calendarRepeat.value !== "F") {
      requestObject.CreateAppointmentRequest.m.inv.comp[0]["recur"] = this.prepareRecurInfo(requestAppointment);
    }

    return requestObject;
  }

  private prepareAlarmInfo(requestAppointment: AppointmentInCompose): object {
    const alarmInfo = [{
      "action": "DISPLAY",
      "trigger": {
        "rel": {
          "related": "START",
          "neg": "1"
        }
      }
    }];

    switch (requestAppointment.calendarAlarm.group) {
      case "WEEKS_BEFORE":
      case "WEEK_BEFORE":
        alarmInfo[0].trigger.rel["w"] = requestAppointment.calendarAlarm.value;
        break;
      case "DAYS_BEFORE":
      case "DAY_BEFORE":
        alarmInfo[0].trigger.rel["d"] = requestAppointment.calendarAlarm.value;
        break;
      case "HOURS_BEFORE":
        alarmInfo[0].trigger.rel["h"] = requestAppointment.calendarAlarm.value;
        break;
      case "MINUTES_BEFORE":
      case "MINUTE_BEFORE":
        alarmInfo[0].trigger.rel["m"] = requestAppointment.calendarAlarm.value;
        break;
      case "SECONDS_BEFORE":
      case "SECOND_BEFORE":
        alarmInfo[0].trigger.rel["s"] = requestAppointment.calendarAlarm.value;
        break;
    }

    return alarmInfo;
  }


  private prepareEmailAlarmInfo(requestAppointment: AppointmentInCompose): object {
    const alarmInfo = [{
      "action": "EMAIL",
      "trigger": {
        "rel": {
          "related": "START",
          "neg": "1"
        }
      },
      "at": {
        "a": this.configService.prefs.zimbraPrefCalendarReminderEmail
      }
    }];

    switch (requestAppointment.calendarAlarm.group) {
      case "WEEKS_BEFORE":
      case "WEEK_BEFORE":
        alarmInfo[0].trigger.rel["w"] = requestAppointment.calendarAlarm.value;
        break;
      case "DAYS_BEFORE":
      case "DAY_BEFORE":
        alarmInfo[0].trigger.rel["d"] = requestAppointment.calendarAlarm.value;
        break;
      case "HOURS_BEFORE":
        alarmInfo[0].trigger.rel["h"] = requestAppointment.calendarAlarm.value;
        break;
      case "MINUTES_BEFORE":
      case "MINUTE_BEFORE":
        alarmInfo[0].trigger.rel["m"] = requestAppointment.calendarAlarm.value;
        break;
      case "SECONDS_BEFORE":
      case "SECOND_BEFORE":
        alarmInfo[0].trigger.rel["s"] = requestAppointment.calendarAlarm.value;
        break;
    }

    return alarmInfo;
  }

  private prepareAlarmInfoFromAlarmResponse(requestAppointment: AppointmentInCompose) {
    const alarmInfo = [{
      "action": "DISPLAY",
      "trigger": {
        "rel": {
          "related": "START",
          "neg": "1"
        }
      }
    }];

    // if (requestAppointment.alarm.trigger.rel && requestAppointment.alarm.trigger.rel.$) {
    //   let rel = appointment.alarm.trigger.rel.$;
    //   let relTimeKeys = ["w", "d", "h", "m", "s"];

    //   for (let key in rel) {
    //     if (rel.hasOwnProperty(key) && relTimeKeys.indexOf(key) > -1) {
    //       rel[key] = Number(rel[key]);
    //     }
    //   }

    //   alarmInfo[0].trigger.rel = rel;
    // } else {
    //   alarmInfo = undefined;
    // }

    return alarmInfo;
  }

  private prepareRecurInfo(requestAppointment: AppointmentInCompose): object {
    const recurInfo = {
      "add": {
        "rule": {
          "interval": {
            "ival": 1
          },
          "freq": requestAppointment.calendarRepeat.value
        }
      }
    };

    if (requestAppointment["recurUntil"] !== undefined) {
      recurInfo.add.rule["until"] = {
        d: requestAppointment["recurUntil"]
      };
    }

    return recurInfo;
  }

  // #region prepare data for calendar controls
  private generateTimePointOptions(date?: Date): Date[] {
    date = date ? new Date(date) : new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const timeList: Date[] = [];

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const newDate = new Date(date);
        newDate.setHours(i);
        newDate.setMinutes(j * 15);
        timeList.push(newDate);
      }
    }

    return timeList;
  }

  displayOptions(): KeyValue[] {
    return [
      { value: "F", text: "FREE" },
      { value: "T", text: "TENTATIVE" },
      { value: "B", text: "BUSY" },
      { value: "O", text: "OUT_OF_OFFICE" }
    ];
  }

  repeatOptions(): KeyValue[] {
    return [
      { value: "NON", text: "DOES_NOT_REPEAT" },
      { value: "DAI", text: "EVERY_DAY" },
      { value: "WEE", text: "EVERY_WEEK" },
      { value: "MON", text: "EVERY_MONTH" },
      { value: "YEA", text: "EVERY_YEAR" }
    ];
  }

  reminderOptions(): KeyValue[] {
    // Populate data for reminder options (alarm)
    let reminderOptions: KeyValue[] = [];

    const reminders = localStorage.getItem("Appointment_Reminder_Option");

    if (reminders) {
      reminderOptions = JSON.parse(reminders);
    } else {
      // Populate data for reminderOptions
      const reminderOptionTexts = {
        minutes: [1, 5, 10, 15, 30, 45, 60],
        hours: [2, 3, 4, 5, 18],
        days: [1, 2, 3, 4],
        weeks: [1, 2]
      };

      reminderOptions.push({ value: "", text: "", group: "NEVER" });
      reminderOptions.push({ value: 0, text: "", group: "AT_TIME_OF_EVENT" });

      let optionValue = 1;

      for (let i = 0; i < reminderOptionTexts.minutes.length; i++) {
        reminderOptions.push({
          group: reminderOptionTexts.minutes[i] === 1 ? "MINUTE_BEFORE" : "MINUTES_BEFORE",
          value: reminderOptionTexts.minutes[i],
          text: reminderOptionTexts.minutes[i].toString()
        });

        optionValue++;
      }

      for (let i = 0; i < reminderOptionTexts.hours.length; i++) {
        reminderOptions.push({
          group: "HOURS_BEFORE",
          value: reminderOptionTexts.hours[i],
          text: reminderOptionTexts.hours[i].toString()
        });

        optionValue++;
      }

      for (let i = 0; i < reminderOptionTexts.days.length; i++) {
        reminderOptions.push({
          group: reminderOptionTexts.days[i] === 1 ? "DAY_BEFORE" : "DAYS_BEFORE",
          value: reminderOptionTexts.days[i],
          text: reminderOptionTexts.days[i].toString()
        });

        optionValue++;
      }

      for (let i = 0; i < reminderOptionTexts.weeks.length; i++) {
        reminderOptions.push({
          group: reminderOptionTexts.weeks[i] === 1 ? "WEEK_BEFORE" : "WEEKS_BEFORE",
          value: reminderOptionTexts.weeks[i],
          text: reminderOptionTexts.weeks[i].toString()
        });

        optionValue++;
      }

      // store reminder data to cookies.
      localStorage.setItem("Appointment_Reminder_Option", JSON.stringify(reminderOptions));
    }

    return reminderOptions;
  }

  // #endregion

  mappingAppointmentToEvents(apptRaw: Appointment[]): CalendarEvent[] {
    const events: CalendarEvent[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      // If there are more than one instance of the current appt
      if (appt.inst && appt.inst.length > 0) {
        // Loop through the instances
        appt.inst.forEach((instance) => {
          clonnedAppt = { ...appt };
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(instance.s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            // if (this.currentEventsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentEventsToDate.valueOf()) {
              // Clone the current appt with start and end date time of current instance
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              events.push(this.processAppointmentData(clonnedAppt));
            // }
          } else {
            clonnedAppt.inst = [instance];
            // Push it into appointmentList
            events.push(this.processAppointmentData(clonnedAppt));
          }
        });
      }
    });

    return events;
  }

  private processAppointmentData(appt: Appointment): CalendarEvent {
    const eventId = appt.id + "_" + appt.inst[0].ridZ;

    let startDate = parseInt(appt.inst[0].s, 10);
    let endDate = startDate + appt.dur;

    if (appt.inst[0].dur) {
      endDate = startDate + appt.inst[0].dur;
    }
    if (appt.allDay) {
      if (appt.inst && appt.inst[0].ex) {
        if (appt.inst && appt.inst[0].dur) {
          startDate = moment(startDate).startOf("day").valueOf();
          endDate = moment(startDate).add(this.getDaysFromMillis(appt.inst[0].dur) - 1, "days").endOf("day").valueOf();
        } else {
          startDate = moment(startDate).startOf("day").valueOf();
          endDate = moment(startDate).startOf("day").valueOf();
        }
      } else {
        startDate = moment(appt.inst[0].ridZ).startOf("day").valueOf();
        endDate = moment(appt.inst[0].ridZ).add(this.getDaysFromMillis(appt.dur) - 1, "days").endOf("day").valueOf();
      }
    }

    return <CalendarEvent>{
      eventId: eventId,
      id: appt.id,
      invId: appt.inst[0].invId ? appt.inst[0].invId : appt.invId,
      apptId: appt.id,
      isRepeatAppt: appt.inst[0].recur !== undefined ? appt.inst[0].recur : appt.recur,
      inst: appt.inst,
      allDay: appt.allDay,
      fb: appt.fb,
      fba: appt.fba,
      alarm: appt.alarm,
      duration: appt.dur ? appt.dur : 0,
      s: appt.s,
      ms: appt.ms,
      rev: appt.rev,
      isOrganizer: appt.isOrg,
      title: appt.name,
      location: appt.loc,
      folderId: appt.l || appt.l,
      start: new Date(startDate),
      end: new Date(endDate),
      bgcolor: this.getFolderColorById(appt.l),
      alarmData: appt.alarmData ? appt.alarmData : undefined,
      draggable: this.isMobileScreen ? false : this.isReadOnlyFolder(appt.l) ? false : appt.isOrg ? true : false,
      tn: appt.tn ? appt.tn : "",
      f: appt.f ? appt.f : "",
      neverSent: appt.neverSent,
      otherAtt: appt.otherAtt ? appt.otherAtt : false,
      hasEx: !!appt.hasEx ? appt.hasEx : false,
      t: appt.t ? appt.t : "",
      class: appt.class ? appt.class : "",
      ex: appt.inst && appt.inst[0].ex ? true : false,
      ptst: appt.ptst ? appt.ptst : undefined
    };
  }

  private generateAppointmentRequest(calExpandInstStart: number, calExpandInstEnd: number): AppointmentRequest {
    return <AppointmentRequest>{
      calExpandInstStart: calExpandInstStart,
      calExpandInstEnd: calExpandInstEnd,
      limit: 500,
      offset: 0,
      sortBy: "none",
      types: "appointment",
      query: this.isTrashFolderSelected ? "inid:'3'" : ""
    };
  }

  getFolderColorById(folderId: string): string {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let folderColor: string = "#000099";
    if (!!folderId && !!fld) {
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                        folderColor = f.folderColor;
                    }
                } else {
                    if (f.id === folderId) {
                        folderColor = f.folderColor;
                    }
                }
            } else {
                if (f.id === folderId) {
                    folderColor = f.folderColor;
                }
            }
        });
    }
    return folderColor;
  }

  generateCalendarFolderColor(folders: CalendarFolder[]): void {
    folders.forEach(folder => {
      if (folder.id !== "3" && folder.name !== "Trash") {
        folder.folderColor = this.getColor(folder);
      }
      const childFolder = CalenderUtils.getChildFolders([folder]);
      childFolder.map(f => {
        if (f.id !== "3") {
          f.folderColor = this.getColor(f);
        }
      });
    });
    this.calendarFolders = folders;
  }

  private generateNewEvent(requestAppointment: AppointmentInCompose, eventExtraOptions?: any): CalendarEvent {
    eventExtraOptions = eventExtraOptions || {};

    const newEvent: CalendarEvent = <CalendarEvent>{
      eventId: "newId",
      // id: requestAppointment.id,
      // apptId: requestAppointment.id,
      // inviteId: requestAppointment.invid,
      // isRepeatAppt: requestAppointment.recur,
      allDay: requestAppointment.isAllDay,
      fb: requestAppointment.calendarDisplay.value,
      fba: requestAppointment.calendarDisplay.value,
      // alarm: requestAppointment.alarm,
      // duration: requestAppointment.dur ? requestAppointment.dur : 0,
      // isOrganizer: requestAppointment.isOrg,
      title: requestAppointment.subject,
      location: requestAppointment.location,
      folderId: requestAppointment.calendarFolder.id,
      start: requestAppointment.startDate,
      end: requestAppointment.endDate,
      bgcolor: this.getFolderColorById(requestAppointment.calendarFolder.id),
      isNewInMobile: this.isMobileScreen,
      resizable: {
        beforeStart: true,
        afterEnd: true
      }
    };

    if (eventExtraOptions["CreateAppointmentResponse"] !== undefined) {
      newEvent.isAdded = true;
      newEvent.eventId = eventExtraOptions["CreateAppointmentResponse"][0]["apptId"]
        + "_" + moment(newEvent.start).format("YYYYMMDDTHHmmss") + "Z";
      newEvent.apptId = eventExtraOptions["CreateAppointmentResponse"][0]["apptId"];
      newEvent.calItemId = eventExtraOptions["CreateAppointmentResponse"][0]["calItemId"];
      newEvent.invId = eventExtraOptions["CreateAppointmentResponse"][0]["calItemId"];
      newEvent.rev = eventExtraOptions["CreateAppointmentResponse"][0]["rev"];
      newEvent.isNewInMobile = false;
      delete newEvent.resizable;
    }

    return newEvent;
  }

  private getEmptyAppointmentModel(): AppointmentInCompose {
    return <AppointmentInCompose>{
      subject: "",
      location: "",
      calendarFolder: <CalendarFolder>{
        "folderColor": ""
      },
      calendarDisplay: <KeyValue>{ value: "", text: "" },
      calendarRepeat: <KeyValue>{ value: "", text: "" },
      calendarAlarm: <KeyValue>{ value: 0, text: "" },
      calendarTimeSpanDuration: 30,
      isPrivate: false
    };
  }

  private getColor(folder: CalendarFolder): string {
    if (folder["rgb"]) {
      return folder["rgb"];
    } else if (folder["color"]) {
      const colorIndex = CALENDARCONSTANT.COLOR_CODES.indexOf(folder["color"]);
      if (colorIndex >= 0) {
        return CALENDARCONSTANT.RGB_CODES[colorIndex];
      } else {
        return CALENDARCONSTANT.COLOR_CODES[folder["color"]].toLowerCase();
      }
    } else if (folder["owner"]) {
      return CALENDARCONSTANT.RGB_CODES[CALENDARCONSTANT.DEFAULT_FOLDER_COLOR];
    } else {
      return CALENDARCONSTANT.RGB_CODES[1];
    }
  }

  private getDaysFromMillis(milliseconds: number): number {
    return ((milliseconds / 3600) / 1000) / 24;
  }

  mapAppointmentFromMsg(appointmentResponse: any): Appointment {
    const appointMent: any = {};
    appointMent.id = appointmentResponse.id;
    appointMent.d = appointmentResponse.d;
    appointMent.f = appointmentResponse.f;
    appointMent.l = appointmentResponse.l;
    appointMent.md = appointmentResponse.md;
    appointMent.ms = appointmentResponse.ms;
    appointMent.rev = appointmentResponse.rev;
    appointMent.t = appointmentResponse.t;
    appointMent.s = appointmentResponse.s;
    appointMent.tn = appointmentResponse.tn;
    if (appointmentResponse.mp) {
      appointMent.mp = appointmentResponse.mp;
    }
    if (appointmentResponse.inv && appointmentResponse.inv[0].comp[0]) {
      const component = appointmentResponse.inv[0].comp[0];
      appointMent.alarmData = component.alarm;
      appointMent.apptId = component.apptId;
      if (component.at) {
        appointMent.at = component.at;
      }
      appointMent.calItemId = component.calItemId;
      appointMent.ciFolder = component.ciFolder;
      appointMent.class = component.class;
      appointMent.compNum = component.compNum;
      appointMent.desc = "";
      appointMent.descHTML = "";
      if (component.desc) {
        appointMent.desc = component.desc[0]._content;
      }
      if (component.descHtml) {
        appointMent.descHTML = component.descHtml[0]._content;
      }
      if (component.draft) {
        appointMent.draft = component.draft;
      }
      appointMent.fb = component.fb;
      appointMent.fba = component.fba;
      appointMent.name = component.name;
      if (component.or) {
        appointMent.or = component.or;
      }
      if (component.s) {
        appointMent.startDateData = component.s;
      }
      if (component.e) {
        appointMent.endDateData = component.e;
      }
      appointMent.seq = component.seq;
      appointMent.status = component.status;
      appointMent.transp = component.transp;
      appointMent.uid = component.uid;
      appointMent.x_uid = component.x_uid;
      appointMent.url = component.url;
      appointMent.isOrg = component.isOrg;
      appointMent.allDay = false;
      if (component.allDay) {
        appointMent.allDay = component.allDay;
      }
      appointMent.loc = "";
      if (component.loc) {
        appointMent.loc = component.loc;
      }
      if (component.recur) {
        appointMent.recur = component.recur;
      }
      if (component.neverSent) {
        appointMent.neverSent = component.neverSent;
      }
      appointMent.isOrg = component.isOrg;
    }
    if (appointmentResponse.inv && appointmentResponse.inv[0].replies) {
      const reply = appointmentResponse.inv[0].replies[0];
      appointMent.reply = reply.reply;
    }
    return appointMent;
  }

  printAppointment(id: string): void {
    let timeZone = jstimezonedetect.determine().name();
    timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
    if (environment.isCordova) {
      this.commonService.getMobileAppointmentPrintData(id, timeZone).pipe(take(1)).subscribe( res => {
        cordova.plugins.printer.print(res, { duplex: "long" }, function (response) {
        });
      });
    } else {
      window.open(this.configService.API_URL + "/api/printAppointment?id=" + id + "&tz=" + timeZone);
    }
  }

  openAttachment(id: string, part: string): void {
    const queryParams = "id=" + id + "&part=" + part + "&disp=a";
    const authorization = this.electronService.isElectron
      ? btoa(localStorage.getItem(MailConstants.ZM_AUTH_TOKEN).trim())
      : btoa(localStorage.getItem(MailConstants.ZM_AUTH_TOKEN).trim());
    const url = this.configService.API_URL + "/api/getAttachment/?" + queryParams + "&passport=" + authorization;
    if (environment.isElectron) {
      ElectronService.downloadFile(url, part);
    } else {
      window.open(url, "_system");
    }
  }

  newAppointmentFullComposeMapping(appointmentInCompose: AppointmentInCompose): Appointment {
    const appointMent: any = {};
    appointMent.l = appointmentInCompose.calendarFolder.id;
    appointMent.alarmData = appointmentInCompose.calendarAlarm;
    appointMent.ciFolder = appointmentInCompose.calendarFolder.id;
    appointMent.desc = "";
    appointMent.descHTML = "";
    appointMent.fb = appointmentInCompose.calendarDisplay.value;
    appointMent.fba = appointmentInCompose.calendarDisplay.value;
    appointMent.name = appointmentInCompose.subject;
    appointMent.startDateData = [{
      d: new Date(appointmentInCompose.startDate)
    }];
    appointMent.endDateData = [{
      d: new Date(appointmentInCompose.endDate)
    }];
    appointMent.allDay = appointmentInCompose.isAllDay;
    appointMent.loc = appointmentInCompose.location;
    appointMent.recur = appointmentInCompose.calendarRepeat.value.toString();
    appointMent.class = appointmentInCompose.isPrivate ? "PRI" : "PUB";
    appointMent.transp = "O";
    return appointMent;
  }

  getAppointmentData(appt: any): CalendarEvent {
    const inv = appt.inv[0].comp[0];
    const startDate = moment(inv.s[0].d).toDate();
    const endDate = moment(inv.e[0].d).toDate();
    return <CalendarEvent>{
      start: startDate,
      end: endDate,
      title: inv.name,
      folderId: inv.ciFolder
    };
  }

  createCalendarFolder(targetFolder: CalendarFolder, title: string, freeBusy: boolean, color?: string, url?: string) {
    let targetFolderId = null;
    if (targetFolder) {
      targetFolderId = targetFolder.id;
    } else {
      targetFolderId = "1";
    }
    let body = null;
    if (color) {
      body = { folderId: targetFolderId, name: title, view: "appointment", rgb: color, f: "#"};
    } else {
      body = { folderId: targetFolderId, name: title, view: "appointment", color: 1, f: "#"};
    }
    if (freeBusy === true) {
      body.f = body.f + "b";
    }
    if (url) {
      body.url = url;
    }
    this.commonService.createFolder(body).subscribe(res => {
      if (targetFolder) {
        if (!targetFolder.folder) {
          targetFolder.folder = [];
        }
        if (!targetFolder.folder.find(f => f.id === (res as CalendarFolder).id)) {
          targetFolder.folder.push(res as CalendarFolder);
        }
        const folder: CalendarFolder = this.getRootFolder(targetFolder);
        if (folder.perm) {
          this.toastService.show("CALENDARS.CALENDAR_CREATE_MSG");
          this.getUserCalendarFolders();
        } else {
          this.getCalendarUpdatedRootFolder(folder, "CALENDARS.CALENDAR_CREATE_MSG");
        }
      } else {
        this.store.dispatch(new CreateCalendarFolderSuccess({ folder: res as CalendarFolder }));
        this.toastService.show("CALENDARS.CALENDAR_CREATE_MSG");
        this.broadCaster.broadcast("EXTERNAL_CALENDAR_FOLDER_FOLDER", { folder: res});
      }
    },
    err => {
      if (err.indexOf("object with that name already exists") !== -1) {
        this.toastService.show("DUPLICATE_CALENDER_FOLDER_MSG");
      } else {
        this.toastService.showPlainMessage(err);
      }
    });
  }

  getRootFolder(targetFolder: CalendarFolder): CalendarFolder {
    if (!targetFolder) {
      return null;
    }
    if (targetFolder.id && targetFolder.id.includes(":")) {
      const folderNames: any[] = targetFolder.absFolderPath.split("/");
      const regShareFolderIdExp = /[^:\\]+/;
      const folderZid = targetFolder.id.match(regShareFolderIdExp)[0];

      const rootFolder = this.calendarFolders.find(folder => (folder.zid && folder.zid === folderZid &&
        folderNames.indexOf(folder.oname) > -1));
      return rootFolder;
    } else if (targetFolder.absFolderPath) {
      const reg = /[^/\\]+/;
      const rootFolderName = targetFolder.absFolderPath.match(reg)[0];
      return this.calendarFolders.find(folder => folder.name.toLowerCase() === rootFolderName.toLowerCase());
    }
  }

  getCalendarUpdatedRootFolder(folder: CalendarFolder, keyMessage?) {
    if (!folder) {
      return;
    }
    const body = {
      view: "appointment",
      folder: {
        uuid: folder.uuid
      }
    };
    this.commonService.getCalendarFolders(body).subscribe(res => {
      if (!!res && res) {
        let fld;
        if (res.folder) {
          fld = res.folder[0];
        } else if (res.link) {
          fld = res.link[0];
        }
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: fld.id, changes: fld }));
        if (keyMessage) {
          this.toastService.show(keyMessage);
        }
      }
    });
  }

  mappingAppointmentToSearchEvents(apptRaw: Appointment[]): CalendarEvent[] {
    const events: CalendarEvent[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      clonnedAppt = { ...appt };
      // If there are more than one instance of the current appt
      if (appt.inst && appt.inst.length > 0) {
        // Loop through the instances
        appt.inst.forEach((instance) => {
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(instance.s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            if (this.currentEventsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentEventsToDate.valueOf()) {
              // Clone the current appt with start and end date time of current instance
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              events.push(this.processAppointmentData(clonnedAppt));
            }
          } else {
              clonnedAppt.inst = [instance];
              events.push(this.processAppointmentData(clonnedAppt));
          }
        });
      }
    });

    return events;
  }

  getTags(): void {
    this.store.dispatch(new LoadMailTags());
    this.commonService.getTags().subscribe(
      res => {
      this.store.dispatch(new LoadMailTagsSuccess({ tags: res }));
    }, err => {
      this.store.dispatch(new LoadMailTagsFail());
      this.toastService.showPlainMessage(err);
    });
  }

  createTag(tagName: string, rgb: string): Observable<MailTag> {
    const subject = new Subject<any>();
    this.store.dispatch(new CreateMailTag());
    this.commonService.createTag({tagName, rgb}).subscribe(
      res => {
        console.log("[CreateMailTagSuccess]", res);
        const tag = res.tag[0] as MailTag;
        subject.next(tag);
      this.store.dispatch(new CreateMailTagSuccess(tag));
    }, err => {
      subject.next(null);
      this.store.dispatch(new CreateMailTagFail());
      this.toastService.showPlainMessage(err);
    });
    return subject.asObservable().pipe(take(1));
  }

  updateTagColor(tagId: string, rgb: string): void {
    this.store.dispatch(new UpdateMailTag());
    this.commonService.tagAction({id: tagId, op: "color", rgb: rgb}).subscribe(
      res => {
      this.store.dispatch(new UpdateMailTagSuccess({id: tagId, changes: {rgb: rgb}}));
    }, err => {
      this.store.dispatch(new UpdateMailTagFail());
      this.toastService.showPlainMessage(err);
    });
  }

  renameTag(tagId: string, name: string): void {
    this.store.dispatch(new UpdateMailTag());
    this.commonService.tagAction({id: tagId, op: "rename", name: name}).subscribe(
      res => {
        this.toastService.show(MailConstants.TAG_NAME_RENAME_LBL);
      this.store.dispatch(new UpdateMailTagSuccess({id: tagId, changes: {name: name}}));
    }, err => {
      this.store.dispatch(new UpdateMailTagFail());
      this.toastService.showPlainMessage(err);
    });
  }

  deleteTag(tagId: string): Observable<string> {
    const subject = new Subject<string>();
    this.store.dispatch(new DeleteMailTag());
    this.commonService.tagAction({id: tagId, op: "delete"}).subscribe(
      res => {
        this.toastService.show(MailConstants.TAG_REMOVE_LBL);
      this.store.dispatch(new DeleteMailTagSuccess(tagId));
      subject.next(tagId);
    }, err => {
      this.store.dispatch(new DeleteMailTagFail());
      subject.next(null);
      this.toastService.showPlainMessage(err);
    });
    return subject.asObservable();
  }

  getSelectedSubFolder(folderId: string, rootFolder: CalendarFolder): CalendarFolder {
    if (rootFolder === null || rootFolder === undefined) {
      return null;
    }
    if (rootFolder.id === folderId) {
      return rootFolder;
    }
    if (rootFolder.folder) {
      for (let i = 0; i < rootFolder.folder.length; i++) {
        if (folderId === rootFolder.folder[i].id) {
          return rootFolder.folder[i];
        } else if (rootFolder.folder[i].folder) {
          const f =  this.getSelectedSubFolder(folderId, rootFolder.folder[i]);
          if (f) {
            return f;
          }
        }
      }
    } else {
      return null;
    }
  }

  deleteCalendarFolder(targetFolder: CalendarFolder): void {
    let trashLbl: string = "";
    this.translate.get("CALENDARS.TRASH_FOLDER").pipe(take(1)).subscribe(res => {
      trashLbl = res;
    });
    this.commonService.folderAction({ id: targetFolder.id, op: "trash" }).subscribe(res => {
      let transatedText: string = "";
      this.translate.get("CALENDARS.CALENDAR_MOVE_TO_MSG",
      { sourceFolder: targetFolder.name,
          destinationFolder: trashLbl
      }).pipe(take(1)).subscribe((text: string) => {
          transatedText = text;
      });
      this.toastService.showPlainMessage(transatedText);
    });
  }

  permenentDeleteCalendarFolder(targetFolder: CalendarFolder): void {
    this.commonService.folderAction({ id: targetFolder.id, op: "delete" }).subscribe(res => {
      const rootFolder: CalendarFolder = this.getRootFolder(targetFolder);
      if (targetFolder.l !== MailConstants.TRASH_MAIL_FOLDER_ID) {
        const parentFolder: CalendarFolder = this.getSelectedSubFolder(targetFolder.l, rootFolder);
        parentFolder.folder.splice(parentFolder.folder.indexOf(targetFolder), 1);
        if (parentFolder.folder.length === 0) {
           parentFolder.folder = null;
        }
      } else {
        if (rootFolder.folder) {
          rootFolder.folder.splice(rootFolder.folder.indexOf(targetFolder), 1);
        } else if (rootFolder.link) {
          rootFolder.link.splice(rootFolder.link.indexOf(<FolderLink>targetFolder), 1);
        }
        if (rootFolder.folder && rootFolder.folder.length === 0) {
          rootFolder.folder = null;
        }
      }
      this.getCalendarUpdatedRootFolder(rootFolder, "FOLDER_DELETED_MSG");
    },
    err => {
      this.toastService.showPlainMessage(err);
    });
  }

  changeFolderColor(targetFolder: CalendarFolder, folderColor) {
      this.commonService.folderAction({ id: targetFolder.id, op: "color", rgb: folderColor }).pipe(take(1)).subscribe(res => {
      const folder: CalendarFolder = this.getRootFolder(targetFolder);
      if (folder.perm) {
        this.getUserCalendarFolders();
      } else {
        this.getCalendarUpdatedRootFolder(folder);
      }
      },
      err => {
        this.toastService.showPlainMessage(err);
      });
    }

  emptyCalendarFolder(targetFolder: CalendarFolder): void {
    let folderId = "";
    if ( targetFolder.owner && targetFolder.perm ) {
      folderId = targetFolder.zid + ":" + targetFolder.rid;
    } else {
      folderId = targetFolder.id;
    }
    this.commonService.folderAction({ id: folderId, op: "empty" }).subscribe(res => {
      if (targetFolder.folder) {
        targetFolder.folder.splice(targetFolder.folder.indexOf(targetFolder), 1);
      } else if (targetFolder.link) {
        targetFolder.link.splice(targetFolder.link.indexOf(<FolderLink>targetFolder), 1);
      }
      if (targetFolder.folder && targetFolder.folder.length === 0) {
        targetFolder.folder = null;
      }
      this.getCalendarUpdatedRootFolder(targetFolder, "EMPTY_FODLER_SUCCESS_MSG");
    });
  }

    updateCalendarFolder(targetFolder: CalendarFolder, title, folderColor?: string) {
    const oldtitle: string = targetFolder.name;
    this.commonService.folderAction({ id: targetFolder.id, name: title, op: "rename"}).pipe(take(1)).subscribe(res => {
      if (folderColor) {
        this.changeFolderColor(targetFolder, folderColor);
      }
      targetFolder.name = title;
      let folder: CalendarFolder;
      const updatedAbsPath = targetFolder.absFolderPath.replace(oldtitle, title);
       targetFolder.absFolderPath = updatedAbsPath;
      if (targetFolder.l !==  MailConstants.ROOT_MAIL_FOLDER_ID) {
        folder = this.getRootFolder(targetFolder);
      } else {
        folder = targetFolder;
      }
       this.store.dispatch(new UpdateCalendarFolderSuccess({ id: folder.id, changes: folder }));
       if (folder.perm) {
        this.getUserCalendarFolders();
       } else {
        this.getCalendarUpdatedRootFolder(folder);
       }
    },
    err => {
      this.toastService.showPlainMessage(err);
    });
  }

  isReadOnlyFolder(folderId: string): boolean {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let readOnly: boolean = false;
    if (!!folderId && !!fld) {
        const folder = fld.filter(f => !!f).filter(f => f.id === folderId)[0];
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                      if (f.perm === "r" || f.perm === "rp") {
                        readOnly = true;
                      }
                    }
                } else if (f.id === folderId) {
                  if (f.perm === "r" || f.perm === "rp") {
                    readOnly = true;
                  }
                }
            }
            if (f.id === folderId && f.url) {
              readOnly = true;
            }
        });
        return readOnly;
    }
  }

  showOriginalAppointment(id: string | number): void {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    const url = this.configService.API_URL + "/api/showOriginalAppointment?id=" + id;
    if (isCordovaOrElectron) {
      const authorization = this.electronService.isElectron
      ? btoa(localStorage.getItem(MailConstants.ZM_AUTH_TOKEN).trim())
      : btoa(localStorage.getItem(MailConstants.ZM_AUTH_TOKEN).trim());
      window.open(url + "&passport=" + authorization, "_blank");
    } else {
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yeswidth=500,height=500"
      );
    }
  }

  brokenLinkDeleteCalendarFolder(targetFolder: CalendarFolder): void {
    this.commonService.folderAction({ id: targetFolder.id, op: "delete" }).subscribe(res => {
      this.getUserCalendarFolders();
    },
    err => {
      this.toastService.showPlainMessage(err);
    });
  }

  setDefaultComposeAppointment(calendarFolders: CalendarFolder[]): CalendarFolder {
    let calendarFolder: CalendarFolder;
    const allFolders = this.calendarComposeViewDefaultControl.calendarFolderOptions;
    const checkedFolders = allFolders.filter(folder => folder.f && folder.f.indexOf("#") !== -1);
    if (checkedFolders && checkedFolders.length === 1) {
      calendarFolder = checkedFolders[0];
    } else {
      calendarFolder = allFolders.filter( folder => folder.id === "10")[0];
    }
    return calendarFolder;
  }

  reminderOptionsWithoutBefore(): KeyValue[] {
    // Populate data for reminder options (alarm)
    const reminderOptions: KeyValue[] = [];
    const reminderOptionTexts = {
      minutes: [1, 5, 10, 15, 30, 45, 60],
      hours: [2, 3, 4, 5, 18],
      days: [1, 2, 3, 4],
      weeks: [1, 2]
    };

    reminderOptions.push({ value: 0, text: "", group: "NEVER" });

    let optionValue = 1;

    for (let i = 0; i < reminderOptionTexts.minutes.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.minutes[i] === 1 ? "MINUTE" : "MINUTES",
        value: reminderOptionTexts.minutes[i],
        text: reminderOptionTexts.minutes[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.hours.length; i++) {
      reminderOptions.push({
        group: "HOURS",
        value: reminderOptionTexts.hours[i],
        text: reminderOptionTexts.hours[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.days.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.days[i] === 1 ? "DAY" : "DAYS",
        value: reminderOptionTexts.days[i],
        text: reminderOptionTexts.days[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.weeks.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.weeks[i] === 1 ? "WEEK" : "WEEKS",
        value: reminderOptionTexts.weeks[i],
        text: reminderOptionTexts.weeks[i].toString()
      });

      optionValue++;
    }
    return reminderOptions;
  }

  isCalendarCheck(folderId: string): boolean {
    let check: boolean = false;
    const allFolders = [...this.calendarFolders, ...CalenderUtils.getChildFolders(this.calendarFolders)];
    allFolders.map(f => {
      if (folderId.toString().indexOf(":") !== -1) {
        const zid = folderId.split(":")[0];
        const rid = folderId.split(":")[1];
        if (!!f.rid && f.rid) {
            if (f.zid === zid && f.rid.toString() === rid && f.f && f.f.indexOf("#") !== -1) {
              check = true;
            }
        } else {
          if (f.id === folderId) {
            check = true;
          }
        }
      } else {
        if (f.id === folderId && f.f && f.f.indexOf("#") !== -1) {
          check = true;
        }
      }
    });
    return check;
  }

  reloadCalendarFolder(folder: CalendarFolder): void {
    const body = {
      id: folder.id,
      op: "sync"
    };
    this.commonService.folderAction(body).pipe(take(1)).subscribe(res => {
      this.getAllEvents();
    });
  }

  getAllEvents(): void {
    let currentDate = this.miniCalSelectedDate;
    if (currentDate === undefined || currentDate === null) {
      currentDate = new Date();
    }
    let addMoreDate = 0;
    let addMorePreDate = 0;
    switch (this.selectingCalendarView) {
      case CalendarView.Day: {
        addMoreDate = 0;
      } break;
      case CalendarView.DayThree: {
        addMoreDate = 2;
      } break;
      case CalendarView.Week: {
        addMoreDate = 6;
        addMorePreDate = 6;
      } break;
      case CalendarView.Workweek: {
        addMoreDate = 4;
        addMorePreDate = 4;
      } break;
      case CalendarView.Month: {
        currentDate.setDate(1);
        addMorePreDate = 6;
        addMoreDate = 37;
      } break;
      default: addMoreDate = 0; break;
    }
    CalenderUtils.calendarTimeLineScroll();
    if (this.selectingCalendarView !== CalendarView.List) {
      this.miniCalSelectedDate = currentDate;
      this.getCalendarEvents(true, currentDate, addMorePreDate, addMoreDate);
    }
  }

  openComposeForHTMLFreeBusy(value: string): void {
    let paramter: string = "";
    if (value === "html") {
      paramter = "fmt=freebusy";
    } else if (value === "ics") {
      paramter = "fmt=ifb";
    } else if (value === "ics-event") {
      paramter = "fmt=ifb&fbfmt=event";
    }
    const serverURL = !!this.configService.zimbraURL ? this.configService.zimbraURL : this.getServerURL();
    const linkItem = serverURL + "/home/" + this.userProfile.email + "?" + paramter;
    this.router.navigate(["/calendar/compose-mail"]);
    this.broadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
    setTimeout(() => {
      this.broadcaster.broadcast("SEND_LINK_FROM_CALENDAR", {
        url: linkItem.replace(/ /g, "%20")
      });
    }, 200);
  }

  getServerURL(): string {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    let serverURL = location.origin;
    if (isCordovaOrElectron) {
      serverURL = localStorage.getItem(MailConstants.SERVER_URL).trim();
    }
     return serverURL;
   }

   mappingListAppointmentToEvents(apptRaw: Appointment[]): CalendarEvent[] {
    const events: CalendarEvent[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      clonnedAppt = { ...appt };
      // If there are more than one instance of the current appt
      if (appt.inst && appt.inst.length > 0) {
        // Loop through the instances
        appt.inst.forEach((instance) => {
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(instance.s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            if (this.currentEventsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentEventsToDate.valueOf()) {
              // Clone the current appt with start and end date time of current instance
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              events.push(this.processListAppointmentData(clonnedAppt));
            }
          } else {
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              events.push(this.processListAppointmentData(clonnedAppt));
          }
        });
      }
    });

    return events;
  }

  private processListAppointmentData(appt: Appointment): CalendarEvent {
    const eventId = appt.id + "_" + appt.inst[0].ridZ;

    let startDate = parseInt(appt.inst[0].s, 10);
    let endDate = startDate + appt.dur;

    if (appt.allDay) {
      startDate = moment(new Date(startDate)).startOf("day").valueOf();
      endDate = moment(startDate).add(this.getDaysFromMillis(appt.dur) - 1, "days").endOf("day").valueOf();
    }

    return <CalendarEvent>{
      eventId: eventId,
      id: appt.id,
      invId: appt.inst[0].invId ? appt.inst[0].invId : appt.invId,
      apptId: appt.id,
      isRepeatAppt: appt.inst[0].recur !== undefined ? appt.inst[0].recur : appt.recur,
      inst: appt.inst,
      allDay: appt.allDay,
      fb: appt.fb,
      fba: appt.fba,
      alarm: appt.alarm,
      duration: appt.dur ? appt.dur : 0,
      s: appt.s,
      ms: appt.ms,
      rev: appt.rev,
      isOrganizer: appt.isOrg,
      title: appt.name,
      location: appt.loc,
      folderId: appt.l || appt.l,
      start: new Date(startDate),
      end: new Date(endDate),
      bgcolor: this.getFolderColorById(appt.l),
      alarmData: appt.alarmData ? appt.alarmData : undefined,
      draggable: this.isMobileScreen ? false : this.isReadOnlyFolder(appt.l) ? false : appt.isOrg ? true : false,
      tn: appt.tn ? appt.tn : "",
      f: appt.f ? appt.f : "",
      neverSent: appt.neverSent,
      otherAtt: appt.otherAtt ? appt.otherAtt : false,
      hasEx: !!appt.hasEx ? appt.hasEx : false,
      t: appt.t ? appt.t : "",
      class: appt.class ? appt.class : ""
    };
  }

  getAllFolderQuery(): string {
    return this.getShareFolderQuery(this.calendarFolders);
  }

  getCheckedCalendarFolder(folderId: string): string {
    let calFolderId: string = folderId;
    const allFolders = [...this.calendarFolders, ...CalenderUtils.getChildFolders(this.calendarFolders)];
    if (!!allFolders && allFolders.length > 0) {
      const folder = allFolders.filter(f => f.id === folderId)[0];
      if (!!folder) {
        if (folder.f && folder.f.indexOf("#") !== -1) {
          calFolderId = folderId;
        } else {
          const allCheckedFolder = allFolders.filter(f => f.f && f.f.indexOf("#") !== -1);
          if (!!allCheckedFolder && allCheckedFolder.length > 0) {
            const allowdFolder = allCheckedFolder.filter(f => !f.perm);
            if (!!allowdFolder && allowdFolder.length > 0) {
              calFolderId = allowdFolder[0].id;
            } else {
              calFolderId = "10";
            }
          } else {
            calFolderId = "10";
          }
        }
      }
    }
    return calFolderId;
  }

  getFolderById(folderId: string): CalendarFolder {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let calFolder: CalendarFolder;
    if (!!folderId && !!fld) {
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                        calFolder = f;
                    }
                } else {
                    if (f.id === folderId) {
                      calFolder = f;
                    }
                }
            } else {
                if (f.id === folderId) {
                  calFolder = f;
                }
            }
        });
    }
    return calFolder;
 }

 getTagsList(): Observable<any[]> {
    console.log("[getTagsList()][useVNCdirectoryAuth]:", this.configService.useVNCdirectoryAuth);
    if (this.configService.useVNCdirectoryAuth) {
      console.log("[getAllDirectoryTags]:");
      return this.store.select(getAllDirectoryTags);
    }
    console.log("[getMailTags]:");
    return this.store.select(getMailTags);
 }

 createDirectoryTag(tagName: string, rgb: string): Observable<DirectoryTag> {
  const subject = new Subject<any>();
  this.store.dispatch(new CreateDirectoryTag());
  this.appService.createDirectoryTag(tagName, rgb).subscribe(
    res => {
      console.log("[createDirectoryTag][response]:", res);
      if (res.tag) {
        const dt: DirectoryTag = {} as DirectoryTag;
        const t = res.tag;
        dt.id = t.id;
        dt.name = t.name;
        if (t.color_hex && t.color_hex !== null) {
          dt.color = t.color_hex;
        } else {
          dt.color = MailConstants.DEFAULT_COLOR;
        }
        subject.next(dt);
        this.store.dispatch(new CreateDirectoryTagSuccess(dt));
        if (!this.isTagAvailableInZimbra(tagName)) {
          this.createTag(tagName, rgb);
        }
      } else {
        subject.next(true);
        this.store.dispatch(new CreateDirectoryTagFail());
      }
  }, err => {
    // subject.next(null);
    this.store.dispatch(new CreateDirectoryTagFail());
  });
  return subject.asObservable().pipe(take(1));
}

isTagAvailableInZimbra(tagName: string): boolean {
  let isTagAvailable: boolean = false;
  let zimbraTags: MailTag[] = [];
  this.getZimbraTagsList().pipe(take(1)).subscribe(res => {
    zimbraTags = res;
  });
  if (zimbraTags.length > 0) {
    const available = zimbraTags.filter(t => t.name.toLowerCase() === tagName.toLowerCase())[0];
    if (!!available) {
      isTagAvailable = true;
    }
  }
  return isTagAvailable;
}

  getZimbraTagsList(): Observable<any[]> {
    console.log("[getMailTags]:");
    return this.store.select(getMailTags);
  }

  getFreeBusy(params): Observable<any> {
    return this.service.getFreeBusy(params);
  }

  getWorkingHours(params): Observable<any> {
    return this.service.getWorkingHours(params);
  }

}
