
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../actions";
import { MailTagActionTypes } from "../actions/mail-tag.action";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { MailTag } from "../common/models/mail-tag.model";


export interface MailTagState extends EntityState<MailTag> {
  selectedMailTag: MailTag;
  isLoading: boolean;
  isLoaded: boolean;
}

export const mailTagAdapter: EntityAdapter<MailTag> = createEntityAdapter<MailTag>({
  selectId: (mailTag: MailTag) => mailTag.id
});

export const initialState: MailTagState = mailTagAdapter.getInitialState({
  selectedMailTag: null,
  isLoading: false,
  isLoaded: false
});

export function MailTagReducer(state = initialState, action: Action): MailTagState {
  switch (action.type) {
    case MailTagActionTypes.SELECT_MAIL_TAG: {
      return {
        ...state,
       selectedMailTag: action.payload
      };
    }

    case MailTagActionTypes.LOAD_TAGS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailTagActionTypes.LOAD_TAGS_SUCCESS: {
      return mailTagAdapter.addAll(action.payload.tags, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailTagActionTypes.LOAD_TAGS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailTagActionTypes.CREATE_MAIL_TAG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailTagActionTypes.CREATE_MAIL_TAG_SUCCESS: {
      return mailTagAdapter.addOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailTagActionTypes.CREATE_MAIL_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailTagActionTypes.DELETE_MAIL_TAG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailTagActionTypes.DELETE_MAIL_TAG_SUCCESS: {
      return mailTagAdapter.removeOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailTagActionTypes.DELETE_MAIL_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailTagActionTypes.UPDATE_MAIL_TAG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailTagActionTypes.UPDATE_MAIL_TAG_SUCCESS: {
      return mailTagAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailTagActionTypes.UPDATE_MAIL_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailTagActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.MailTagState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case MailTagActionTypes.RESET_MAIL_TAG_STATE: {
      return mailTagAdapter.removeAll(state);
    }

    case MailTagActionTypes.DELETE_TAGS: {
      const newState =  {
        ...state
      };
      return mailTagAdapter.removeMany(action.payload, newState);
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedMailTag = (state: MailTagState) => state.selectedMailTag;
export const _getIsLoading = (state: MailTagState) => state.isLoading;
export const _getIsLoaded = (state: MailTagState) => state.isLoaded;
