
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="apple-ical-settings">
    <form [formGroup]="preferenceSettingsForm">
        <div class="header">
            {{ 'PREFERENCES.APPLE_ICAL' | translate }}
        </div>
        <div class="description">
            {{ 'PREFERENCES.NOTE_APPLE_ICAL' | translate }}
        </div>
        <mat-divider></mat-divider>
        <div>
            <mat-checkbox formControlName="zimbraPrefAppleIcalDelegationEnabled">
                {{ 'PREFERENCES.ENABLE_DELEGATION' | translate }}</mat-checkbox>
        </div>
        <div fxLayout="row" class="footer-bottom-desktop">
            <div class="button-actions footer-actions">
                <button class="save" (click)="savePreferences()">{{'COMMON.SAVE'| translate }}</button>
                <button class="cancel" (click)="cancelPreferences()">{{'CANCEL'| translate }}</button>
                <button class="save" (click)="undoChange()"> {{ 'UNDO_CHANGES' | translate }}</button>
            </div>
        </div>
    </form>
</div>