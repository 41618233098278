
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mail_folder-share_dialog">
  <div class="mail__dialog-header mobile-header" layout="row center-center">
    <div>
      <button (click)="close()" class="brand-color mat-button show-in-mobile">
        <mat-icon class="disable-select">chevron_left</mat-icon>
      </button>
    </div>
    <div class="header-title ">
      <span class="disable-select">{{ 'CALENDARS.SHARE_CALENDAR_LBL' | translate }}</span>
    </div>
    <div class="desktop-close">
      <button class="brand-color mat-button" (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="mobile_display_header">
    <div>
      {{ 'CALENDARS.SHARE_CALENDAR_LBL' | translate }}
    </div>
  </div>
  <div class="mail__dialog-body">
    <div class="message">
      {{ "CALENDARS.SHARE_CALENDAR_LBL" | translate }}
    </div>
    <div>
      <mat-form-field>
        <input [(ngModel)]="folderName" readonly #foldername matInput placeholder="{{ 'CALENDARS.SHARE_CALENDAR_NAME_LBL'| translate }}"
          [maxLength]="maxFolderTitleLength">
      </mat-form-field>
    </div>
    <div class="folder-type">
      <div class="lbl">{{ "CALENDARS.FOLDER_TYPE" | translate }}</div>
      <div class="type">{{ "CALENDARS.CALENDAR_HEADER_LBL" | translate }}</div>
    </div>
    <div class="share_with_section">
      <div class="title">
        {{ "SHARE_WITH" | translate }}
      </div>
      <mat-radio-group class="share-radio-group" [(ngModel)]="shareWithOption">
        <mat-radio-button class="share-radio-button" [value]="'guest'">
          {{ "EXTERNAL_GUEST" | translate }}
        </mat-radio-button>
        <mat-radio-button class="share-radio-button" [value]="'public'" disabled>
          {{ "PUBLICH_VIEW" | translate }}
        </mat-radio-button>
        <mat-radio-button class="share-radio-button" [value]="'usr'">
          {{ "OTHER_USER_MAIL" | translate }} {{prefixBold}}{{suffixNormal}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="email_section">
      <div class="to_compose_mail_autocomplete">
        <vp-autocomplete [isFocused]=true [place]="addEmailLabel" #addEmailAutoComplete></vp-autocomplete>
      </div>
    </div>
    <div class="role_section" *ngIf="shareWithOption === 'usr'">
      <div class="title">
        {{ "ROLE" | translate }}
      </div>
      <mat-radio-group class="share-radio-group" [(ngModel)]="shareRole">
        <mat-radio-button class="share-radio-button" [value]="''">
          {{ "NONE" | translate }}:
        </mat-radio-button>
        <mat-radio-button class="share-radio-button" [value]="'r'">
          {{ "VIEWER_VIEW" | translate }}
        </mat-radio-button>
        <mat-radio-button class="share-radio-button" [value]="'rwidx'">
          {{ "MANAGER_VIEW" | translate }}
        </mat-radio-button>
        <mat-radio-button class="share-radio-button" [value]="'rwidxa'">
          {{ "ADMIN_VIEW" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="private-section" *ngIf="shareRole !== ''">
      <div>
        <mat-checkbox [(ngModel)]="showPrivateAppointment"></mat-checkbox>
      </div>
      <div>
        <span>{{ 'SHOW_PRIVATE_APPOINTMENT' | translate }}</span>
      </div>
    </div>
    <div class="message_section">
      <div class="title" id="share-folder-msg-colon">
        {{ "MESSAGE_COLON" | translate }}
      </div>
      <div *ngIf="shareReply !== '0'" id="share-folder-msg-note">
        {{ "MESSAGE_NOTE" | translate }}
      </div>
      <mat-form-field id="share-reply-form-field">
        <mat-select [(value)]="shareReply" (selectionChange)="changeshareReply($event.value)" panelClass="sharePanelClass" id="share-reply-options-select">
          <mat-option value="0" id="share-reply-do-not-send">{{ "MESSAGE_OPTION1" | translate }}</mat-option>
          <mat-option value="1" id="share-reply-send-standard-msg">{{ "MESSAGE_OPTION2" | translate }}</mat-option>
          <mat-option value="2" id="share-reply-add-note-standard-msg">{{ "MESSAGE_OPTION3" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="shareReply === '2'">
      <mat-form-field>
        <textarea [(ngModel)]="notes" matInput placeholder="{{ 'MESSAGE_COLON'| translate }}"></textarea>
      </mat-form-field>
    </div>
    <div *ngIf="isCalendarShare" class="briefcase-url">
      <div class="url">
        {{ 'CALENDARS.URL_LBL_MSG' | translate }}
      </div>
      <div class="content-data">
        {{ 'CALENDARS.SHARE_DIALOG_URL_CONTENT' | translate }}
      </div>
      <div class="send-url">
        <span class="lable-link">{{ 'CALENDARS.ICS_LBL' | translate }}:</span>
        <a href="{{calendarShareURL}}.ics" class="copy-url-briefcase-share" target="_blank">{{calendarShareURL.substr(0,23) + '...' + calendarShareURL.substr(-10)}}.ics</a>
        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy" (click)="copyShareURL(calendarShareURL + '.ics')"></mat-icon>
      </div>
      <div class="send-url">
        <span class="lable-link">{{ 'VIEW' | translate }}:</span>
        <a href="{{calendarShareURL}}.html" class="copy-url-briefcase-share" target="_blank">{{calendarShareURL.substr(0,23) + '...' + calendarShareURL.substr(-10)}}.html</a>
        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy" (click)="copyShareURL(calendarShareURL + '.html')"></mat-icon>
      </div>
      <div class="send-url">
        <span class="lable-link">{{ 'OUTLOOK' | translate }}:</span>
        <a href="{{calendarShareURL}}" class="copy-url-briefcase-share" target="_blank">{{calendarShareURL.substr(0,28) + '...' + calendarShareURL.substr(-10)}}</a>
        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy" (click)="copyShareURL(calendarShareURL)"></mat-icon>
      </div>
    </div>
  </div>
  <div class="footer_save_cancel">
    <span *ngIf="!url.startsWith('/calendar')">{{ 'SHARE_FOLDER_LBL' | translate }}</span>
    <span *ngIf="url.startsWith('/calendar')">{{ 'CALENDARS.SAHRE_CALENDAR_LBL' | translate }}</span>
    <button mat-button (click)="shareFolder()">
      <mat-icon class="disable-select">check</mat-icon>
    </button>
  </div>
  <div class="footer_save_cancel_mobile">
    <button mat-button class="cancel_btn" (click)="closeDialog()" id="share-folder-cancel-btn">{{ "CANCEL_MAIL_LABEL" | translate }}</button>
    <button mat-button class="save_btn" (click)="shareFolder()" id="share-folder-share-btn">{{ "PREFERENCES.SHARE" | translate }}</button>
  </div>
</div>