
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="calendar-permission-settings">
    <form [formGroup]="preferenceForm">
        <div class="header">
            {{ 'PREFERENCES.PERMISSIONS' | translate }}
        </div>
        <div class="description">
            {{ 'PREFERENCES.PERMISSION_NOTE' | translate : {domain: domain} }}
        </div>
        <mat-divider></mat-divider>
        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.FREE_BUSY' | translate }}:
            </div>
            <div class="item">
                <mat-radio-group formControlName="freeBusyOption">
                    <mat-radio-button value="pub">
                        {{ 'PREFERENCES.ALLOW_BOTH_INTERNAL_AND_EXTERNAL' | translate }}</mat-radio-button>
                    <mat-radio-button value="all">
                        {{ 'PREFERENCES.ALLOW_ONLY_USERS_OF_INTERNAL_DOMAINS' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="dom">
                        {{ 'PREFERENCES.ALLOW_ONLY_USERS_OF_MY_DOMAIN' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="none">{{ 'PREFERENCES.FREE_BUSY_INFORMATION' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="usr">{{ 'PREFERENCES.ALLW_ONLY_INTERNAL_USER' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="element" [ngStyle]="{ display: isEnableFreeBusyAutoComplete() ? 'flex' : 'none' }">
            <div class="lbl">
            </div>
            <div class="item">
                <vp-autocomplete [place]="freeBusyLbl" [isFocused]=false #freeBusyAutoComplete>
                </vp-autocomplete>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="element">
            <div class="lbl">
                {{'PREFERENCES.INVITES' | translate }}:
            </div>
            <div class="item">
                <mat-radio-group formControlName="invitesOption">
                    <mat-radio-button value="pub">
                        {{ 'PREFERENCES.ALLOW_BOTH_INTERNAL_AND_EXTERNAL_USERS' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="all">{{ 'PREFERENCES.ALLOW_ONLY_INTERNAL_USERS' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="none">
                        {{ 'PREFERENCES.ANYONE_INVITE_TO_MEETING' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="usr">
                        {{ 'PREFERENCES.ALLOW_ONLY_INTERNAL_USERS_TO_INVITE' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="element" [ngStyle]="{ display: isEnableInviteAutoComplete() ? 'flex' : 'none' }">
            <div class="lbl">
            </div>
            <div class="item">
                <vp-autocomplete [place]="freeBusyLbl" [isFocused]=false #invitesAutoComplete>
                </vp-autocomplete>

            </div>
        </div>
        <div class="element">
            <div class="lbl">
            </div>
            <div class="item">
                <mat-checkbox formControlName="zimbraPrefCalendarSendInviteDeniedAutoReply">
                    {{ 'PREFERENCES.SEND_AUTO_REPLY' | translate }}</mat-checkbox>
            </div>
        </div>
        <div fxLayout="row">
            <div class="button-actions footer-actions">
                <button class="save" (click)="savePreferences()">{{'COMMON.SAVE'| translate }}</button>
                <button class="cancel" (click)="cancelPreferences()">{{'CANCEL'| translate }}</button>
                <button class="save" (click)="undoChange()"> {{ 'UNDO_CHANGES' | translate }}</button>
            </div>
        </div>
    </form>
</div>