
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Error } from "../models/error.model";
import * as _ from "lodash";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

export class ErrorService {
  private subject = new Subject<Error>();

  all(): Observable<Error> {
    return this.subject.asObservable();
  }

  only(code: number): Observable<Error> {
    return this.subject.asObservable().pipe(filter((err: Error) => err.code === code));
  }

  emit(model: Error) {
    this.subject.next(model);
  }

  exclude(codes: number[]): Observable<Error> {
    return this.subject.asObservable().pipe(filter((err: Error) => !_.includes(codes, err.code)));
  }
}
