
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, EventEmitter, Output, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { appRoutes, AppRoutes } from "./app.routes";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { getFederatedApps } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { take, distinctUntilChanged, takeUntil, filter } from "rxjs/operators";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { InstallAppDialogComponent } from "../install-app/install-app.component";
import { environment } from "src/environments/environment";
import { AppState } from "src/app/reducers/app";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "src/app/config.service";

@Component({
  selector: "vp-app-switcher",
  templateUrl: "app-switcher.html"
})
export class AppSwitcherComponent implements OnDestroy {
  @Input() switchApp: boolean;
  @Output() closeSwitch = new EventEmitter();
  routes: AppRoutes[] = appRoutes;
  private isAlive$ = new Subject<boolean>();
  constructor(private router: Router,
    private store: Store<AppState>,
    private configService: ConfigService,
    private matDialog: MatDialog,
    private broadcaster: Broadcaster) {
      this.store.select(getFederatedApps).pipe(filter(apps => !!apps && apps.length > 0), takeUntil(this.isAlive$)).subscribe(apps => {
        const routes = [];
        apps.forEach(app => {
            routes.push({
              title: app?.options?.title,
              icon: this.configService.get("publicVncDirectoryUrl") + app?.options?.icon,
              image: this.configService.get("publicVncDirectoryUrl") + app?.options?.image,
              nativeLink: `${app?.name?.toLowerCase()}://main`,
              path: app?.options?.url,
              position: app?.options?.position,
              active: true,
              enabled: true
            });
        });

        this.routes = routes;
      });
  }

  navigate(path: any) {
    this.broadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
    this.router.navigate(path);
    this.closeSwitch.emit(false);
  }

  checkInstalledApp(uri: string, appName: string): Observable<boolean> {
    const subject = new BehaviorSubject<boolean>(false);
    if (environment.isCordova) {
      if (CommonUtils.isOnAndroid()) {
        uri = CommonUtils.getPackageName(appName);
      }
      uri = uri.replace("main", "");
      console.log("[checkInstalledApp]", uri);
      appAvailability.check(
        uri, // URI Scheme
        () => {
          console.log("[checkInstalledApp] installed", appName);
          subject.next(true);
        },
        () => {
          console.log("[checkInstalledApp] not installed", appName);
          subject.next(false);
          const dialogArgs = {
            autoFocus: false,
            maxWidth: "100%",
            data: { appName: appName },
            panelClass: "install_app_dialog",
        };
        this.matDialog.open(InstallAppDialogComponent, dialogArgs);
        }
      );
    } else {
      subject.next(false);
      const dialogArgs = {
          autoFocus: false,
          maxWidth: "100%",
          data: { appName: appName },
          panelClass: "install_app_dialog",
      };
      this.matDialog.open(InstallAppDialogComponent, dialogArgs);
    }

    return subject.asObservable();
  }

  openApp(item): void {
    console.log("[openApp]", item);
    if (!environment.isCordova) {
      window.open(item.path, "_blank");
      this.closeSwitch.emit(false);
    } else {
      this.checkInstalledApp(item.nativeLink, item.title).subscribe(res => {
        if (!!res) {
          window.open(item.nativeLink, "_system");
          this.closeSwitch.emit(false);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.closeSwitch.unsubscribe();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }
}
