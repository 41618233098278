
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, NgZone, OnInit, Inject, OnDestroy } from "@angular/core";
import { take, takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/common/providers/error-service";
import { ErrorType } from "src/app/common/enums/mail-enum";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { CommonService } from "src/app/services/common.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { SearchRequest } from "src/app/common/models/search-request.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-mobile-select-address-dialog",
    templateUrl: "./mobile-select-address-dialog.component.html"
})
export class MobileSelectAddressDialogComponent implements OnInit, OnDestroy {
    isSearchView: boolean = false;
    searchField: string = "";
    allEmailAddresses: any[] = [];
    checkedAddressStatus: { [id: number]: boolean } = {};
    checkedIds: any[] = [];
    type: string = "";
    private isAlive$ = new Subject<boolean>();
    constructor(
        public dialogRef: MatDialogRef<MobileSelectAddressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private commonService: CommonService,
        private errorService: ErrorService,
        private mailBroadcaster: Broadcaster,
        private hotKeyService: NgxHotkeysService,
        private ngZone: NgZone
    ) {
        this.hotKeyService.pause(this.hotKeyService.hotkeys);
        this.type = this.data.type;
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_MOBILE_ADDRESS_SELECT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnInit() {
        this.changeShowNames("contacts");
    }

    close(address?: any[]) {
        this.dialogRef.close({ address: address });
    }
    showSearch(value: boolean): void {
        this.isSearchView = value;
    }

    changeShowNames(value, isSearch?: boolean) {
        this.checkedIds = [];
        this.checkedAddressStatus = {};
        if (value === "global") {
            const query = {
                name: this.searchField,
                limit: 100
            };
            this.commonService.searchGalRequest(query).pipe(take(1)).subscribe(res => {
                this.allEmailAddresses = [];
                if (res.cn) {
                    res.cn.forEach(ele => {
                        const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                        const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                        const fullNameUser = !!fullName ? fullName : ele._attrs.email;
                        this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
                    });
                    this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
                }
            }, error => {
                this.errorService.emit({ id: ErrorType.Generic, messages: error });
            });

        } else if (value === "personalandshared") {
            this.getContactFolders(isSearch);
        } else {
            const query: SearchRequest = {
                field: "contact",
                limit: 100,
                needExp: 1,
                offset: 0,
                query: this.searchField !== "" ? "(\"" + this.searchField + "\") (is:local)" : "(is:local)",
                sortBy: "nameAsc",
                types: "contact"
            };
            this.commonService.searchRequest(query).pipe(take(1)).subscribe(
                res => {
                    this.allEmailAddresses = [];
                    if (res.cn) {
                        res.cn.forEach(ele => {
                            const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                            const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                            const fullNameUser = !!fullName ? fullName : ele._attrs.email;
                            this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
                        });
                        this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
                    }
                }, error => {
                    this.errorService.emit({ id: ErrorType.Generic, messages: error });
                });
        }
    }

    getContactFolders(isSearch) {
        const body = {
            view: "contact",
        };
        this.commonService.getContactFolders(body).pipe(take(1)).subscribe(
            res => {
                let que = "";
                if (res.folder[0].link) {
                    for (let i = 0; i < res.folder[0].link.length; i++) {
                        if (i === 0) {
                            que += "inid:" + res.folder[0].link[i].id;
                        } else {
                            que += " OR inid:" + res.folder[0].link[i].id;
                        }
                    }
                }
                if (que.length > 0) {
                    que = "(" + que + " OR is:local)";
                } else {
                    que = "(is:local)";
                }
                if (this.searchField !== "") {
                    que = "(" + this.searchField + ")" + que;
                }
                const query: SearchRequest = {
                    field: "contact",
                    limit: 100,
                    needExp: 1,
                    offset: 0,
                    query: que,
                    sortBy: "nameAsc",
                    types: "contact"
                };
                this.commonService.searchRequest(query).pipe(take(1)).subscribe(searchRes => {
                    this.allEmailAddresses = [];
                    if (searchRes.cn) {
                        searchRes.cn.forEach(ele => {
                            const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                            const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                            const fullNameUser = !!fullName ? fullName : ele._attrs.email;
                            this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
                        });
                        this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
                    }
                }, error => {
                    this.errorService.emit({ id: ErrorType.Generic, messages: error });
                });
            },
            err => {
                this.errorService.emit({ id: ErrorType.Generic, messages: err });
            }
        );
    }

    onSelectAddress(id: number): void {
        this.checkedAddressStatus[id] = this.checkedAddressStatus[id] ? false : true;
        this.toggleMessage(id);
    }

    toggleMessage(id: number): void {
        const index = this.checkedIds.indexOf(id);
        if (index === -1) {
            this.checkedAddressStatus[id] = true;
            this.checkedIds.push(id);
        } else {
            this.checkedAddressStatus[id] = false;
            this.checkedIds.splice(this.checkedIds.indexOf(id), 1);
        }
    }

    selectUnselectAll(): void {
        if (this.checkedIds.length === this.allEmailAddresses.length) {
            this.checkedIds = [];
            this.checkedAddressStatus = {};
        } else {
            this.checkedIds = [];
            this.checkedAddressStatus = {};
            for (let i = 0; i < this.allEmailAddresses.length; i++) {
                this.onSelectAddress(i);
            }
        }
    }

    submit(): void {
        const address: any[] = [];
        this.checkedIds.map(id => {
            address.push(this.allEmailAddresses[id]);
        });
        this.close(address);
    }

    ngOnDestroy() {
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }
}
