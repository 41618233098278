
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef, HostBinding, ViewChild, ElementRef, NgZone } from "@angular/core";
import { PreferenceService } from "./shared/services/preference.service";
import { LoadPreferenceSuccessAction, UpdateAttributes } from "./store/actions";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BroadcastKeys } from "../common/enums/broadcast.enum";
import { Router } from "@angular/router";
import { Preference } from "./shared/models";
import { PreferenceRootState } from "./store/selectors";
import { Store } from "@ngrx/store";
import { PreferenceRepository } from "./repositories/preference.repository";
import { ElectronService } from "../services/electron.service";
import { Broadcaster } from "../common/providers/broadcaster.service";

@Component({
  selector: "vp-preference",
  templateUrl: "./preference.component.html"
})
export class PreferenceComponent implements OnInit, OnDestroy {
  showFooter: boolean;
  @ViewChild("backButton", {static: false}) backButton: ElementRef;
  private isAlive$ = new Subject<boolean>();
  constructor(
    private preferenceService: PreferenceService,
    private preferenceRepo: PreferenceRepository,
    private changeDetectorRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    public store: Store<PreferenceRootState>,
    private router: Router,
    private ngZone: NgZone,
    private electronService: ElectronService
  ) {
    this.getPreferences();
    this.broadcaster.broadcast("OPEN_CALENDAR_PREFERENCE");
  }

  get preferenceTitle(): string {
    return this.preferenceService.getPreferenceTitle();
  }

  @HostBinding("class.show-footer")
  get displayFooter(): boolean {
    return this.showFooter;
  }

  private getPreferences(): void {
    const prefs = this.electronService.isElectron
        ? this.electronService.getFromStorage("preferences")
        : (localStorage.getItem("preferences")
          ? JSON.parse(localStorage.getItem("preferences"))
          : null);
    if (!!prefs) {
      const preferences: Preference[] = [];
      for (const key of Object.keys(prefs)) {
        preferences.push({key: key, value: prefs[key]});
      }
      this.store.dispatch(new LoadPreferenceSuccessAction(preferences));
    }
    // this.preferenceService.getAttributes().subscribe(attrs => {
    //   this.store.dispatch(new UpdateAttributes(attrs));
    // });
  }

  ngOnInit() {
    this.preferenceService.onPreferenceChanges().pipe(takeUntil(this.isAlive$)).subscribe(showFooter => this.showFooter = showFooter);
    this.broadcaster.on<any>(BroadcastKeys.BACK_FROM_PREFERENCES)
    .pipe(takeUntil(this.isAlive$)).subscribe(val => {
      this.ngZone.run(() => {
        this.goBack();
      });
    });
    this.broadcaster.on<any>(BroadcastKeys.HIDE_MOBILE_PREFERENCE_SAVE_FOOTER)
    .pipe(takeUntil(this.isAlive$)).subscribe(val => {
      this.showFooter = false;
    });
  }

  ngOnDestroy() {
    this.broadcaster.broadcast("CLOSE_CALENDAR_PREFERENCE");
    this.changeDetectorRef.detach();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  saveChanges(): void {
    this.showFooter = false;
    this.preferenceService.setOnSaveChanges();
  }

  goBack(): void {
    console.log("[PreferenceComponent] goBack");
    this.showFooter = false;
    this.preferenceService.navigateTo(this.preferenceTitle);
    this.changeDetectorRef.markForCheck();
  }

}
