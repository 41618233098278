
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<mat-toolbar *ngIf="isLoggedIn" color="primary" [ngClass.xs]="{'mobile-search': searchMode}">
  <mat-toolbar-row fxShow.lt-sm="false" fxLayout="row" class="desktop-header">
    <div fxLayout="row" fxFlex="25%" fxLayoutGap="16px" fxLayoutAlign="start center">
      <button mat-icon-button (click)="siwtchAppMenuOption()">
        <mat-icon>apps</mat-icon>
      </button>

      <img class="vnc_logo" [src]="appLogo" />
      <div class="logo-title" (click)="switchApp = false; navigatorTo('/')" [innerHTML]="textLogo">

      </div>
    </div>
    <div fxLayout="row" fxFlex="50%" (click)="switchApp = false;" fxLayoutAlign="start center" class="search-bar">
      <input matInput [matAutocomplete]="autoSearch" type="text" (focus)="searchFocus()" (focusout)="searchFocusOut()"
        [(ngModel)]="searchKeyword" (keyup)="searchOnKeyUp()" (keyup.enter)="search()"
        placeholder="{{'HEADER_SEARCH_PLACEHOLDER' | translate}}" />
      <button mat-button *ngIf="searchKeyword" (click)="clearSearchText()">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button (click)="search()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxFlex="25%" (click)="switchApp = false;" fxLayoutAlign="end center" fxLayoutGap="8px">
      <span class="username"
        *ngIf="currentUser">{{ currentUser?.fullName || currentUser?.firstName + " " + currentUser?.lastName | titlecase}}</span>
      <vp-avatar *ngIf="currentUser && avatarURL" [type]="'desktop-profile'" [user]="currentUser" [matMenuTriggerFor]="headerMenu"> </vp-avatar>
      <img class="avatar-image" *ngIf="!currentUser || !avatarURL" src="assets/images/profile.png" height="40px"
        [matMenuTriggerFor]="headerMenu" />
      <button mat-icon-button [matMenuTriggerFor]="headerSettingsMenu">
        <mat-icon>settings</mat-icon>
      </button>

    </div>
    <div class="overlay" (click)="closeAppAwitcher()" *ngIf="switchApp"></div>
  </mat-toolbar-row>
  <!-- <mat-toolbar-row fxShow.sm="true" fxShow.gt-sm="false" fxLayout="row" class="mobile-header">
    <ng-container *ngIf="!searchMode">
      <button mat-icon-button class="sidebar-menu" (click)="siwtchAppMenuOption()">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="vnc-mail-mobile">{{brandName}}</span><span class="vnc-mail-mobile-sub" fxFlex="1 1 auto">mail<span
          class="mail_count"
          *ngIf="siteHeaderMenu[0]['badge'] && siteHeaderMenu[0]['badge'] !== '0'">{{ siteHeaderMenu[0]["badge"] > 99 ? "99+" : siteHeaderMenu[0]["badge"] }}</span></span>
      <button mat-icon-button (click)="toggleMobileSearch()">
        <mat-icon>search</mat-icon>
      </button>
    </ng-container>
  </mat-toolbar-row> -->
</mat-toolbar>

<mat-autocomplete [class]="searchMode && 'mobile-autocomplete'" #autoSearch="matAutocomplete"
  (optionSelected)="selectedOption($event)">
  <mat-option *ngFor="let optionItem of searchKeywordItem" [value]="optionItem.item">
    <mat-icon *ngIf="optionItem.type == 'message'">mail</mat-icon>
    <mat-icon *ngIf="optionItem.type != 'message'">drafts</mat-icon>
    <small>{{ optionItem.item }}</small>
  </mat-option>
  <mat-option *ngFor="let optionItem of historyKeywordItem" [value]="optionItem.item">
    <mat-icon *ngIf="optionItem.type == 'history'">history</mat-icon>
    <small>{{ optionItem.item }}</small>
  </mat-option>
  <!-- <mat-option *ngIf="searchKeyword && !searchKeywordItem" [value]="searchKeyword" disabled="disabled">
    <span>{{"NO_RESULTS_FOUND" | translate}}</span>
  </mat-option>
  <mat-option *ngIf="searchKeyword" [value]="searchKeyword" class="divider-opt" disabled="disabled">
    <span (click)="searchOnGoogle()" class="option-span" fxFlex="80%">Search the web for
      <strong>"{{ searchKeyword }}"</strong></span>
    <span fxFlex="20%"><img src="assets/images/google_logo.png" class="option-img"></span>
  </mat-option> -->
</mat-autocomplete>

<mat-menu #headerMenu="matMenu" class="header-menu">
  <button mat-menu-item (click)="openProfileDialog()">
    <mat-icon>person</mat-icon>
    <span>{{ 'PROFILE' | translate}}</span>
  </button>
  <button mat-menu-item (click)="changePassword()">
    <mat-icon>lock</mat-icon>
    <span>{{ 'CHANGE_PASSWORD_LBL' | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'LOGOUT' | translate }}</span>
  </button>

</mat-menu>

<mat-menu #headerSettingsMenu="matMenu" class="mail_desktop_settings_menu">
  <button mat-menu-item (click)="openAppearanceDialog()">
    <mat-icon>brush</mat-icon>
    <span>{{ 'APPEARANCE' | translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="headerSettingGeneralMenu">
    <mat-icon>settings</mat-icon>
    <span>{{ 'PREFERENCES.SETTINGS' |translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="preferenceMenu">
    <mat-icon>settings_applications</mat-icon>
    <span>{{ 'PREFERENCES_LBL' |translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="headerHelpMenu">
    <mat-icon>help_outline</mat-icon>
    <span>{{ 'HELP_LABEL' |translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="headerLegalNoticeMenu">
    <svg viewBox="0 0 24 24">
      <path fill="#000000" d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
    </svg>
    <span class="disable-select">{{ 'LEGAL_NOTICE' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #headerHelpMenu="matMenu" class="mail_desktop_settings_menu" >
  <button mat-menu-item (click)="aboutDialog()">
    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-information-variant"></mat-icon>
    <span>{{ 'APP_INFO' |translate }}</span>
  </button>
  <a href="{{userManual}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>import_contacts</mat-icon>
      <span>{{ 'USER_MANUAL' | translate }}</span>
    </button>
  </a>
  <a href="{{serviceDeskURL}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>headset_mic</mat-icon>
      <span>{{ 'SERVICE_DESK' | translate }}</span>
    </button>
  </a>
  <a href="{{faqURL}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>help</mat-icon>
      <span>{{ 'FAQ_LABEL' |translate }}</span>
    </button>
  </a>
</mat-menu>

<mat-menu #preferenceMenu="matMenu" class="mail_desktop_settings_menu" >
  <button mat-menu-item (click)="navigateToPreference('general')">
    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-settings-box"></mat-icon>
    <span>{{ 'MAIN_PREFERENCES' |translate }}</span>
  </button>
  <button mat-menu-item (click)="navigateToPreference('work-week-hours')">
    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-clock"></mat-icon>
    <span>{{ 'PREFERENCES.WORK_WEEK_AND_HOURS' |translate }}</span>
  </button>
  <button mat-menu-item (click)="navigateToPreference('create-appointment-setting')">
    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-check"></mat-icon>
    <span>{{ 'PREFERENCES.CREATING_APPOINTMENTS' |translate }}</span>
  </button>
  <button mat-menu-item (click)="navigateToPreference('permissions-setting')">
    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-lock"></mat-icon>
    <span>{{ 'PREFERENCES.PERMISSIONS' |translate }}</span>
  </button>
  <button mat-menu-item (click)="navigateToPreference('apple-ical')">
    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-apple"></mat-icon>
    <span>{{ 'PREFERENCES.APPLE_ICAL' |translate }}</span>
  </button>
  <button mat-menu-item (click)="navigateToPreference('notifications')">
    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-bell"></mat-icon>
    <span>{{ 'NOTIFICATIONS_LBL' |translate }}</span>
  </button>
  <button mat-menu-item *ngIf="isSecurityFeatureEnabled" (click)="navigateToPreference('security')">
      <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-lock"></mat-icon>
      <span>{{ 'SECURITY_PREF_LBL' |translate }}</span>
  </button>
</mat-menu>

<mat-menu #headerLegalNoticeMenu="matMenu" class="mail_desktop_settings_menu" >
  <a href="{{termsOfUse}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>assignment</mat-icon>
      <span>{{ 'TERMS_OF_USE' | translate }}</span>
    </button>
  </a>
  <a href="{{dataPrivacy}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>lock</mat-icon>
      <span>{{ 'DATA_PRIVACY' |translate }}</span>
    </button>
  </a>
</mat-menu>
<mat-menu #headerSettingGeneralMenu="matMenu" class="mail_desktop_settings_menu" >
    <button mat-menu-item (click)="openSettingsDialog()">
        <mat-icon>settings</mat-icon>
        <span>{{ 'SETTINGS_TITLE' | translate }}</span>
    </button>
</mat-menu>

<div class="user-menu" *ngIf="showUserMenu" [ngClass]="{'has-active-call': showActiveCall}">
  <div class="user-menu__mask" id="closeMainMenu" (click)="showUserMenu = false; updateMailListScrollBroadcast(false)">
  </div>
  <div class="user-menu__profile" [style.backgroundImage]="profileBackground">
    <div class="user-menu__profile__avatar" (click)="openProfileDialog()">
      <vp-avatar [type]="'profile'" [user]="currentUser"> </vp-avatar>
    </div>
    <h5 class="screen-name-mobile">{{currentUser?.email}}</h5>
  </div>
  <div class="user-menu__options">

    <mat-list class="option-list">
      <mat-list-item id="appSwitchBtn">
        <mat-icon (click)="openAppSwitcherDialog()">apps</mat-icon>
        <span class="option-name" (click)="openAppSwitcherDialog()">{{ 'APPS_TITLE' | translate }}</span>
      </mat-list-item>
      <mat-list-item id="logoutMenuBtn" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span class="option-name">{{ 'LOGOUT_TITLE' | translate }}</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>

<vp-app-switcher (closeSwitch)="switchApp = false" [switchApp]="switchApp"></vp-app-switcher>
