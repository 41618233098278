
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="desktop-view" *ngIf="!isMobileScreen">
    <div class="mail__dialog-header" layout="row center-center">
        <div class="header-title">
            <div class="title"><span>{{'CALENDARS.FIND_EQUIPMENT_LBL' | translate }}</span></div>
            <div class="icon" (click)="close()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="content">
            <div class="left-side">
                <div class="find-location">
                    {{ 'CALENDARS.SEARCH_EQUIPMENT_LBL' | translate }}
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{'CALENDARS.NAME_LBL' | translate }}" [(ngModel)]="nameField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.DESCRIPTION_EQUPMENT_LBL' | translate }}"
                            [(ngModel)]="descriptionField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.CONTACT_EQUIPMENT_LBL' | translate }}"
                            [(ngModel)]="contactField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.SITE_LBL' | translate }}" [(ngModel)]="siteField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.BUILDING_LBL' | translate }}"
                            [(ngModel)]="buildingField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.FLOOR_LBL' | translate }}" [(ngModel)]="floorField">
                    </mat-form-field>
                </div>
                <div class="search-btn">
                    <button mat-button (click)="searchRequest()">{{ 'CALENDARS.SEARCH_LBL' | translate }}</button>
                </div>
            </div>
            <div class="right-side">
                <div class="search-equipment">
                    <div class="list-item">
                        <table>
                            <tr>
                                <th *ngFor="let column of columns" mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, 'resource-' + column.title)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}" class="disable-select resource-{{column.title}}">
                                    {{ column.title }}
                               </th>
                            </tr>
                            <tr *ngFor="let resouce of calResource; index as i;"
                                (click)="selectEvent(resouce, i, $event)"
                                [ngClass]="{'is-selected': isSelectedItem(resouce)}">
                                <td class="disable-select" matTooltipPosition="above" matTooltip="{{resouce?.fullName}}" [matTooltipClass]="'resource-tooltip'">{{resouce?.fullName}}</td>
                                <td class="disable-select" matTooltipPosition="above" matTooltip="{{resouce?.fullName}}" [matTooltipClass]="'resource-tooltip'">{{resouce?.fullName}}</td>
                                <td class="disable-select"></td>
                                <td class="disable-select"></td>
                                <td matTooltipPosition="above" class="{{itemsById[resouce.email]?.className}}" [matTooltipClass]="'resource-tooltip'" matTooltip="{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate}}">{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}</td>                            </tr>
                        </table>
                    </div>
                </div>
                <div class="btns">
                    <button mat-button (click)="selectAll()"
                        [disabled]="calResource && calResource.length === 0">{{ 'CALENDARS.ADD_ALL_LBL' | translate }}</button>
                    <button mat-button (click)="addItem()"
                        [disabled]="selectedItems.length === 0">{{ 'CALENDARS.ADD_LBL' | translate }}</button>
                    <button mat-button (click)="removeEquipment()"
                        [disabled]="selectedEquipmentItem.length === 0">{{ 'CALENDARS.REMOVE_LBL' | translate }}</button>
                    <button mat-button (click)="removeAll()"
                        [disabled]="selectedCalResouce.length === 0">{{ 'CALENDARS.REMOVE_ALL_ALL' | translate }}</button>
                </div>
                <div class="location-for-equipment">
                    {{ 'CALENDARS.LOCATION_FOR_EQUIPMENT' | translate }}
                </div>
                <div class="selected-equipment">
                    <div class="list-item">
                        <table>
                            <tr>
                                <th *ngFor="let column of selectedColumns" class="disable-select"
                                    mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, 'selected-' + column.title)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}" class="disable-select selected-{{column.title}}"
                                >
                                    {{ column.title }}
                                </th>
                            </tr>
                            <tr *ngFor="let resouce of selectedCalResouce"
                                (click)="selectResouceEvent(resouce, i, $event)"
                                [ngClass]="{'is-selected': isSelectedEquipmentItem(resouce)}">
                                <td matTooltipPosition="above" [matTooltipClass]="'resource-tooltip'" matTooltip="{{resouce?.fullName}}">{{resouce?.fullName}}</td>
                                <td matTooltipPosition="above" [matTooltipClass]="'resource-tooltip'" matTooltip="{{resouce?.fullName}}">{{resouce?.fullName}}</td>
                                <td></td>
                                <td></td>
                                <td matTooltipPosition="above" class="{{itemsById[resouce.email]?.className}}" [matTooltipClass]="'resource-tooltip'" matTooltip="{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate}}">{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}</td>                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button mat-button (click)="close()">{{ 'COMMON.CANCEL' | translate }}</button>
        <button mat-button [disabled]="selectedCalResouce.length === 0"
            (click)="submit()">{{ 'CALENDARS.SELECT_EQUIPMENT_LBL' | translate }}</button>
    </div>
</div>

<div class="mobile-view" *ngIf="isMobileScreen">
    <div class="mail__dialog-header" layout="row center-center">
        <div class="header-title">
            <div class="icon" (click)="close()">
                <mat-icon>close</mat-icon>
            </div>
            <div class="title"><span>{{'CALENDARS.FIND_EQUIPMENT_LBL' | translate }}</span></div>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="content-item">
            <div class="search-content">
                <div class="find-location">
                    {{ 'CALENDARS.SEARCH_EQUIPMENT_LBL' | translate }}
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{'CALENDARS.NAME_LBL' | translate }}" [(ngModel)]="nameField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.DESCRIPTION_EQUPMENT_LBL' | translate }}"
                            [(ngModel)]="descriptionField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.CONTACT_EQUIPMENT_LBL' | translate }}"
                            [(ngModel)]="contactField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.SITE_LBL' | translate }}" [(ngModel)]="siteField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.BUILDING_LBL' | translate }}"
                            [(ngModel)]="buildingField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.FLOOR_LBL' | translate }}" [(ngModel)]="floorField">
                    </mat-form-field>
                </div>
                <div class="search-btn">
                    <button mat-button (click)="searchRequest()">{{ 'CALENDARS.SEARCH_LBL' | translate }}</button>
                </div>
            </div>
            <div class="mobile-search-equipment">
                    <div class="find-location">
                            {{ 'CALENDARS.SUGGEST_LOCATIONS_LBL' | translate }}
                        </div>
                    <mat-list role="list">
                        <mat-list-item *ngFor="let resouce of calResource; index as i;" (click)="selectEvent(resouce, i, $event)" [ngClass]="{'is-selected': isSelectedItem(resouce)}">
                            <div class="main-search-div">
                                <div class="left-search-item">
                                        {{resouce?.fullName}} <span class="{{itemsById[resouce.email]?.className}}">
                                            {{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}
                                        </span>
                                </div>
                                <div class="righ-search-close">
                                    <!-- <mat-icon>close</mat-icon> -->
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
            </div>
            <mat-divider></mat-divider>
            <div class="btns">
                    <button mat-button (click)="selectAll()"
                        [disabled]="calResource && calResource.length === 0">{{ 'CALENDARS.ADD_ALL_LBL' | translate }}</button>
                    <button mat-button (click)="addItem()"
                        [disabled]="selectedItems.length === 0">{{ 'CALENDARS.ADD_LBL' | translate }}</button>
                    <button mat-button (click)="removeEquipment()"
                        [disabled]="selectedEquipmentItem.length === 0">{{ 'CALENDARS.REMOVE_LBL' | translate }}</button>
                    <button mat-button (click)="removeAll()"
                        [disabled]="selectedCalResouce.length === 0">{{ 'CALENDARS.REMOVE_ALL_ALL' | translate }}</button>
            </div>
            <mat-divider></mat-divider>
            <div class="mobile-selected-equipment">
                <div class="find-location">
                        {{ 'CALENDARS.LOCATION_FOR_EQUIPMENT' | translate }}
                    </div>
                <mat-list role="list">
                    <mat-list-item *ngFor="let resouce of selectedCalResouce" (click)="selectResouceEvent(resouce, i, $event)" [ngClass]="{'is-selected': isSelectedEquipmentItem(resouce)}">
                        <div class="main-search-div">
                            <div class="left-search-item">
                                    {{resouce?.fullName}} <span class="{{itemsById[resouce.email]?.className}}">
                                        {{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}
                                    </span>
                            </div>
                            <div class="righ-search-close">
                                <mat-icon (click)="removeFromClose(resouce)">close</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>

<div class="mobile-footer" *ngIf="isMobileScreen && selectedCalResouce.length > 0">
    <span>{{ 'SAVE_CHANGES' | translate }}</span>
    <button mat-button (click)="submit()">
        <span>
            <mat-icon>check</mat-icon>
        </span>
    </button>
</div>