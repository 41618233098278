
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="mobile_Sidebar_createfolder_popup" class="mail_folder-create_dialog">
  <div class="content">
    <div id="mobile_Sidebar_createfolder_header" class="message">
      {{ "CALENDARS.NEW_CALENDAR_LBL" | translate }}
    </div>
    <div>
      <mat-form-field>
        <input id="mobile_Sidebar_createfolder_name" [(ngModel)]="folderTitle" #folderNameInput matInput
          placeholder="{{ 'CALENDARS.CALENDAR_NAME_LBL'| translate }}" autocomplete="off"
          autocorrect="off" autocapitalize="off" spellcheck="false" [maxLength]="maxFolderTitleLength">
      </mat-form-field>
    </div>
    <div class="color-action">
      <mat-radio-group class="create-folder-color-selection" (change)="updateDefaultColor($event)"
        [(ngModel)]="folderColor">
        <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
        <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
        <mat-radio-button class="blue-radio" value="#20ae80"></mat-radio-button>
        <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
        <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
        <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
        <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
      </mat-radio-group>
      <button id="mobile_Sidebar_createfolder_morecplor" (click)="openColorDialog()" class="brand-color mat-button">
        <mat-icon class="disable-select">more_horiz</mat-icon>
      </button>
    </div>
    <div class="check-content">
      <div class="check"><mat-checkbox [(ngModel)]="freeBusy"></mat-checkbox></div>
      <div class="check-msg">{{'CALENDARS.EXCLUDE_CALENDAR_REPORT_MSG' | translate }}</div>
    </div>
    <div class="actions">
      <a id="mobile_Sidebar_createfolder_cancelbtn" (click)="closeDialog()">{{ 'CANCEL_MAIL_LABEL' | translate }}</a>
      <a id="mobile_Sidebar_createfolder_createbrn" *ngIf="!isRename" [class.action-disabled]="folderTitle.length < 1"
        (click)="folderAction()">{{ 'CRAETE_FODLER_LBL' | translate }}</a>
      <a id="mobile_Sidebar_createfolder_renamebrn" *ngIf="isRename" [class.action-disabled]="folderTitle.length < 1"
        (click)="folderAction()">{{ 'RENAME_FODLER_LBL' | translate }}</a>
    </div>
  </div>
</div>