
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PreferenceService } from "../shared/services/preference.service";
import { ActivatedRoute } from "@angular/router";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs";
import * as _ from "lodash";
import { TFARepository } from "src/app/services/tfa.repository";
import { TFASettingsComponent } from "src/app/shared/components/tfa-settings/tfa-settings.component";
import { ConfigService } from "src/app/config.service";
import { RootState, getOnlineStatus } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { AuthService } from "src/app/common/providers/auth.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { environment } from "src/environments/environment";
import { Logout } from "src/app/actions/app";
import { MatDialog } from "@angular/material/dialog";
import { ToastService } from "src/app/common/providers/toast.service";

@Component({
    selector: "vp-preference-security",
    templateUrl: "./security.component.html"
})
export class SecurityComponent implements OnInit, OnDestroy {

    preferenceTitle = "SECURITY_PREF_LBL";
    preference: any = {};
    use2FA: boolean;
    is2FAEnabled: boolean;
    isSupport2FA: boolean = false;
    isAppOnline: boolean;
    private isAlive$ = new Subject<boolean>();
    constructor(
        private preferenceService: PreferenceService,
        private activatedRoute: ActivatedRoute,
        private mailBroadCaster: Broadcaster,
        private ngxHotKeyService: NgxHotkeysService,
        private changeDetectorRef: ChangeDetectorRef,
        private tfaRepo: TFARepository,
        private store: Store<RootState>,
        private configService: ConfigService,
        private toastService: ToastService,
        private auth: AuthService,
        private config: ConfigService,
        private matDialog: MatDialog,
    ) {
        this.activatedRoute.paramMap.subscribe(res => {
            setTimeout(() => {
                this.mailBroadCaster.broadcast(BroadcastKeys.OPEN_PREFERENCE_TAB, "security");
            }, 50);
        });
        this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
        this.preferenceService.setPreferenceTitle(this.preferenceTitle);
        this.get2FAStatus();
    }

    public get2FAStatus() {
      this.isSupport2FA = this.configService.two_factor_authentication;
      if (this.isSupport2FA) {
        this.tfaRepo.get2FAStatus().pipe(take(1)).subscribe( value => {
          if (value && value.tfa_enable) {
            this.is2FAEnabled = value.tfa_enable !== "false" ? true : false;
            this.use2FA = this.is2FAEnabled;
            this.changeDetectorRef.markForCheck();
          }
        });
      }
    }

    ngOnInit() {
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    cancelPreferences(): void {
        this.preferenceService.navigateTo();
    }

    savePreferences(): void {
        this.set2FASettings();
    }

    private set2FASettings() {
      if (!this.isAppOnline) {
        this.toastService.show("OFFLINE_CONNECTION");
        return;
      }
      if (this.isSupport2FA ) {
        if (this.is2FAEnabled && !this.use2FA ) {
          this.tfaRepo.disableTFA().subscribe( res => {
            this.is2FAEnabled = false;
            this.changeDetectorRef.markForCheck();
          });
        }
      }
    }

    setAuthenticationApp() {
      let dialogRef = this.matDialog.open(TFASettingsComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "tfa-setting-dialog"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.is2FAEnabled = true;
          this.auth.removeLogin();
          this.store.dispatch(new Logout());
          if (environment.isElectron || environment.isCordova) {
            this.auth.cordovaLogout();
            let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/calendar")[0];
            initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref;
            window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
            this.config.loginIframe();
            this.changeDetectorRef.markForCheck();
          } else {
            window.location.href = "/api/call-logout";
          }
        }
      });
    }
}
