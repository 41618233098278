
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header">
        <span *ngIf="!openEvent">{{ 'CALENDARS.MODIFY_RECURRING_ITEM_LBL' | translate }}</span>
        <span *ngIf="openEvent">{{ 'CALENDARS.NEW_RECURRING_ITEM_LBL' | translate }}</span>
    </div>
    <div class="messagae-content">
        "{{eventName}}" <span *ngIf="!openEvent">{{ 'CALENDARS.IS_RECURRING_APPT_MODIFY_CONTENT' | translate }}</span>
        <span *ngIf="openEvent">{{ 'CALENDARS.NEW_RECURRING_APPT_NEW_CONTENT' | translate }}</span>
    </div>
    <div>
        <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button value="instance">
                <span *ngIf="!openEvent">{{ 'CALENDARS.MODIFY_INSTANCE_LBL' | translate }}.</span>
                <span *ngIf="openEvent">{{ 'CALENDARS.NEW_INSTANCE_LBL' | translate }}.</span>
            </mat-radio-button>
            <mat-radio-button value="series">
                <span *ngIf="!openEvent">{{ 'CALENDARS.MODIFY_SERIES_LBL' | translate }}.</span>
                <span *ngIf="openEvent">{{ 'CALENDARS.NEW_SERIES_LBL' | translate }}.</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="submit-buttons">
        <button mat-button (click)="submit()">
            {{ 'COMMON.OK'| translate }}
        </button>
        <button mat-button (click)="close()">
            {{ 'COMMON.CANCEL'| translate }}
        </button>
    </div>
</div>