
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="isActionInProgress" class="action-processing">
  <vp-custom-spinner></vp-custom-spinner>
</div>
<vp-header *ngIf="isLoggedIn"></vp-header>
<div class="back-to-calendar" *ngIf="currentURL.startsWith('/preferences')" (click)="navigateToCalendar()">
  <mat-icon>arrow_back</mat-icon>
  {{ 'BACK_TO_CALENDAR' | translate }}
</div>
<div class="vp-module">
    <router-outlet></router-outlet>
</div>
<vp-notifications [options]="options"></vp-notifications>
<vp-authentication *ngIf="!configService.selectedServer"></vp-authentication>
