
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GeneralComponent } from "./general/general.component";
import { PreferenceComponent } from "./preference.component";
import { PreferenceRoutingModule } from "./preference-routing.module";
import { PreferenceSidebarComponent } from "./preference-sidebar/preference-sidebar.component";
import { SharedModule } from "../shared/shared.module";
import { PreferenceService } from "./shared/services/preference.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { preferenceRootReducer } from "./store/selectors/preference.selector";
import { PreferenceRepository } from "./repositories/preference.repository";
import { NotificationsComponent } from "./notifications/notifications.component";
import { WorkWeekHourComponent } from "./work-week-hours/work-week-hour.component";
import { WorkHoursDialogComponent } from "./work-week-hours/work-hours-dialog/work-hours-dialog.component";
import { PermissionPreferenceComponent } from "./permissions/permissions.component";
import { AppleIcalComponent } from "./apple-ical/apple-ical.component";
import { CreateAppointmentSettingComponent } from "./create-appointment/create-appointment-setting.component";
import { SecurityComponent } from "./security/security.component";
import { TFARepository } from "../services/tfa.repository";
import { TFAService } from "../services/tfa.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PreferenceRoutingModule,
    StoreModule.forFeature("preference", preferenceRootReducer),
    SharedModule
  ],
  declarations: [
    GeneralComponent,
    PreferenceComponent,
    PreferenceSidebarComponent,
    NotificationsComponent,
    WorkWeekHourComponent,
    WorkHoursDialogComponent,
    PermissionPreferenceComponent,
    AppleIcalComponent,
    CreateAppointmentSettingComponent,
    SecurityComponent
  ],
  providers: [
    PreferenceService,
    TFAService,
    TFARepository,
  ],
  entryComponents: [
    WorkHoursDialogComponent
  ]
})
export class PreferenceModule { }
