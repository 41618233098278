
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, NgZone, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, forkJoin, Observable } from "rxjs";
import { ConfirmationDialogComponent, ConfirmationData } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { Store } from "@ngrx/store";
import { take, takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import { ElectronService } from "src/app/services/electron.service";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ConfigService } from "src/app/config.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { AppState } from "src/app/reducers/app";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { getUserProfile } from "src/app/reducers";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { AutocompleteComponent } from "../autocomplete/autocomplete.component";
import { CommonService } from "src/app/services/common.service";
import { EmailInformation } from "src/app/common/models/email-information.model";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { Router } from "@angular/router";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: "vp-share-folder",
  templateUrl: "./share-folder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ShareFolderComponent implements OnDestroy {

  folderTitle: string = "";
  maxFolderTitleLength: number = 128;
  isRename: boolean = false;
  oldFlderName: string = "";
  shareReply = "1";
  addEmailLabel: string = "";
  folderName: string = "";
  shareWithOption: string = "usr";
  shareRole: string = "r";
  notes: string = "";
  emailInfo: any = [];
  folderId: string = "";
  fromAddress: string = "";
  private isAlive$ = new Subject<boolean>();
  duplicateEmail: boolean = false;
  reqPending: boolean = false;
  duplicateAddress: string = "";
  dialogName: string = "";
  labelName: string = "";
  shareMessage: string = "";
  errorMessage: string = "";
  briefcaseShareURL: string = "";
  prefixBold: string = "";
  suffixNormal: string = "";
  isCalendarShare: boolean = false;
  calendarFolderAbsPath: string = "";
  calendarShareURL: string = "";
  url: string = "";
  showPrivateAppointment: boolean = true;
  @ViewChild("addEmailAutoComplete", {static: false}) addEmailAutoComplete: AutocompleteComponent;

  constructor(
    private dialogRef: MatDialogRef<ShareFolderComponent>,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private appStore: Store<AppState>,
    private dialog: MatDialog,
    private mailBroadcaster: Broadcaster,
    private ngZone: NgZone,
    private configService: ConfigService,
    private electronService: ElectronService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private commonService: CommonService,
    private calendarRepository: CalendarRepository,
    private router: Router
  ) {
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 50);
    this.url = this.router.url;
    this.prefixBold = this.configService.get("prefixBold");
    this.suffixNormal = this.configService.get("suffixNormal");
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.appStore.select(getUserProfile).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res.email) {
        this.fromAddress = res.email;
        this.changeDetectionRef.markForCheck();
      } else {
        const userEmail = CalenderUtils.getProfileFromStorage();
        this.fromAddress = userEmail;
        this.changeDetectionRef.markForCheck();
      }
    });

    console.log(this.data);
    this.isRename = this.data.isRename;
    if (this.isRename) {
      this.folderTitle = this.data.targetFolder.name;
      this.oldFlderName = this.data.targetFolder.name;
    }
    this.folderName = this.data.folderName;
    if (this.folderName === "Calendar") {
      this.translateService.get("CALENDARS.CALENDAR_FOLDER").pipe(take(1)).subscribe(
        text => {
          this.folderName = text;
        }
      );
    }
    this.folderId = this.data.targetFolder.id;

    this.dialogName = "Share Folder";
    this.labelName = "Folder Name";
    this.shareMessage = "Folder shared.";
    this.initializeShare();
    if ( this.data.grant ) {
      this.shareWithOption = this.data.grant.gt;
      this.shareRole = this.data.grant.perm;
      if (this.shareRole.indexOf("p") !== -1) {
        this.showPrivateAppointment = true;
        this.shareRole = this.shareRole.replace("p", "");
      } else {
        this.showPrivateAppointment = false;
      }
      this.changeDetectionRef.markForCheck();
      setTimeout(() => {
        const grantee = this.data.grant.gt === "guest" ? this.data.grant.zid : this.data.grant.d;
         const email: EmailInformation = { a: grantee, d: grantee } as EmailInformation;
         if (!!this.addEmailAutoComplete) {
           this.addEmailAutoComplete.setEmailField(email);
         }
      }, 500);
      this.changeDetectionRef.markForCheck();
    }
    this.commonService.getAllFolders().subscribe((res) => {
      if (res.GetFolderResponse && res.GetFolderResponse.folder) {
        this.commonService.allFolders = [];
        for ( const folder of res.GetFolderResponse.folder.folder) {
          this.commonService.allFolders.push(folder);
          if (folder.folder) {
            if (Array.isArray(folder.folder)) {
              for ( const child of folder.folder) {
                this.commonService.allFolders.push(child);
              }
            } else {
              this.commonService.allFolders.push(folder.folder);
            }
          }
        }
      }
    });

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_SHARE_FOLDER_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        const _shareRole = this.getShareRole();
        this.dialogRef.close({ perm: _shareRole });
      });
    });

    if (this.data.briefcaseShareURL) {
      this.briefcaseShareURL = this.data.briefcaseShareURL;
      this.changeDetectionRef.markForCheck();
    }
    if (this.data.isCalendarShare) {
      this.isCalendarShare = true;
      if (this.data.targetFolder.perm) {
        if (this.data.targetFolder.owner) {
          const rest = this.data.targetFolder.rest;
          this.calendarShareURL = rest.replace(/ /g, "%20");
        } else {
          const rootFolder = this.calendarRepository.getRootFolder(this.data.targetFolder);
          const ownerName = rootFolder.owner;
          let originalPath = this.data.targetFolder.absFolderPath;
          originalPath = originalPath.substr(originalPath.indexOf(rootFolder.oname));
          const absPath = "/" + originalPath.replace(/ /g, "%20");
          this.calendarFolderAbsPath = absPath;
          this.calendarShareURL = this.configService.zimbraURL + "/service/user/" + ownerName + this.calendarFolderAbsPath;
        }
      } else {
        this.calendarFolderAbsPath = this.data.targetFolder.absFolderPath.replace(/ /g, "%20");
        this.calendarShareURL = this.configService.zimbraURL + "/home/" + this.fromAddress + this.calendarFolderAbsPath;
      }
    }
    this.changeDetectionRef.markForCheck();
  }

  initializeShare() {
    this.translateService.get(MailConstants.ADD_EMAIL).subscribe(
      message => {
        this.addEmailLabel = message;
      }
    );

    this.changeDetectionRef.markForCheck();
  }

  getEmails(): any[] {
    return this.addEmailAutoComplete.getSelectedEmail();
  }

  closeDialog(): void {
    this.close();
  }

  changeshareReply(value) {
    console.log(value);
  }

  close(): void {
    const _shareRole = this.getShareRole();
    this.dialogRef.close({ perm: _shareRole });
  }

  shareFolder() {
    if (this.getEmails().length === 0) {
      this.toastService.show(MailConstants.MAIL_REQUIRE);
      return;
    }
    const email = this.getEmails();

    this.emailInfo = [];
    this.getEmails().forEach(emailItem => {
      this.emailInfo.push(emailItem.email);
    });
    const body = {
      emailInfo: email[0].email,
      notes: this.notes,
      id: this.folderId
    };
    const _shareRole = this.getShareRole();
    console.log("[_shareRole]:", _shareRole);
    const request = {
      id: this.folderId,
      emailInfo: this.fromAddress,
      op: "grant",
      gt: this.shareWithOption,
      d:  this.emailInfo,
      perm:  _shareRole !== "0" ? _shareRole : "",
      pw: ""
    };
    this.commonService.shareFolder(request, this.emailInfo).subscribe(
      res => {
        if (res.FolderActionResponse) {
          if (this.shareReply === "0") {
            this.dialogRef.close({ perm: _shareRole, folderResponse: res.FolderActionResponse });
          } else {
            const requests = [];
            let sendAction = "new";
            if ( this.data.grant ) {
              sendAction = "edit";
            }
            for ( const mail of this.emailInfo ) {
              const options = {
                id: this.folderId,
                emailInfo: mail,
                notes: this.notes,
                action: sendAction
              };
              requests.push(
                this.commonService.sendShareFolderRequest(options)
              );
            }

            forkJoin(requests).subscribe(res2 => {
              console.log(res2);
              this.toastService.show("FOLDER_SHARED");
              this.dialogRef.close({ perm: _shareRole , folderResponse: res.FolderActionResponse});
            });
          }
        } else {
          console.log(res.Fault);
          // this.reqPending = false;
          const duplicateError = this.translateService.instant("GRANT_EXIST");
          const selfShareError = this.translateService.instant("CANNOT_GRANT_ACCESS");
          let msg = "";
          const duplicateAddresses = [];
          if (Array.isArray(res.Fault)) {
            for (const error of res.Fault) {
              msg = error.Reason.Text.toLowerCase();
              if ( msg.indexOf(selfShareError) > -1 ) {
                this.toastService.show("CANNOT_SHARE_YOURSELF");
                return;
              } else if (msg.indexOf(duplicateError) !== -1 ) {
                const duplicateAddress = msg.split("exists ")[1];
                this.duplicateEmail = true;
                console.log(this.commonService.allFolders);
                if (this.emailInfo.length === 1) {
                  duplicateAddresses.push(this.emailInfo[0]);
                } else if (duplicateAddress.indexOf("@") === -1) {
                  const folder = _.find(this.commonService.allFolders, (f) => {
                    return f.id === this.folderId;
                  });
                  if (folder && folder.acl && folder.acl.grant) {
                    const grant = folder.acl.grant;
                    if (Array.isArray(grant)) {
                      const found = _.find(grant, (g) => g.zid === duplicateAddress.trim());
                      if (found) {
                        duplicateAddresses.push(found.d);
                      }
                    } else {
                      if (grant.zid === duplicateAddress.trim()) {
                        duplicateAddresses.push(grant.d);
                      }
                    }
                  }
                }
                this.duplicateAddress = duplicateAddresses.join(", ");
              }
            }

          } else {
            msg = res.Fault.Reason.Text;
            if ( msg.indexOf(selfShareError) > -1 ) {
              this.toastService.show("CANNOT_SHARE_YOURSELF");
              return;
            } else if ( msg.indexOf(duplicateError) !== -1 ) {
              const duplicateAddress = msg.split("exists ")[1];
              this.duplicateEmail = true;

              if ( this.emailInfo.length === 1 ) {
                duplicateAddresses.push(this.emailInfo[0]);
              } else if ( duplicateAddress.indexOf("@") === -1 ) {
                const folder = _.find(this.commonService.allFolders, (f) => {
                  return f.id === this.folderId;
                });
                if ( folder && folder.acl && folder.acl.grant ) {
                  const grant = folder.acl.grant;
                  if ( Array.isArray(grant) ) {
                    const found = _.find(grant, (g) => g.zid === duplicateAddress.trim());
                    if ( found ) {
                      duplicateAddresses.push(found.d);
                    }
                  } else {
                    if ( grant.zid === duplicateAddress.trim() ) {
                      duplicateAddresses.push(grant.d);
                    }
                  }
                }
              }
              this.duplicateAddress = duplicateAddresses.join(", ");
            }
          }

          if ( msg && msg.indexOf(duplicateError) > -1 ) {
            this.errorMessage = this.translateService.instant("ALREDAY_SHARED", {email: this.duplicateAddress});
            const data: ConfirmationData = {
              action: "purge",
              titleKey: "SHARE_FOLDER",
              contentKey: this.errorMessage,
              actionKey: "OK"
            };

            this.openConfirmationDialog(data, { width: "400px" }).pipe(take(1)).subscribe(result => {
              if (result && result.confirmed) {
                const requestList = [];
                let sendAction = "new";
                if ( this.data.grant ) {
                  sendAction = "edit";
                }
                for ( const mail of this.emailInfo ) {
                  const options = {
                    id: this.folderId,
                    emailInfo: mail,
                    notes: this.notes,
                    action: sendAction
                  };
                  requestList.push(
                    this.commonService.sendShareFolderRequest(options)
                  );
                }

                forkJoin(requestList).subscribe(results => {
                  console.log(results);
                  this.toastService.show("FOLDER_SHARED");
                  this.dialogRef.close({ perm: _shareRole });
                });
              } else {
                this.dialogRef.close({ perm: _shareRole , folderResponse: res.FolderActionResponse });
              }
            });

            console.log(this.errorMessage);
          } else {
            console.log(msg);
          }
          this.dialogRef.close({ perm: _shareRole });
        }
      },
      error => {
        console.log(error);
        // this.errorService.emit({ id: ErrorType.Generic, messages: error });
      }
    );
  }


  private openConfirmationDialog(data: ConfirmationData, style: any): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: data,
      ...style, panelClass: "calendar_already_share_confirmation"
    });

    return dialogRef.afterClosed();
  }

  ngOnDestroy() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  copyShareURL(url: string): void {
    CalenderUtils.copyToClipboard([url]);
  }

  getShareRole(): string {
    let shareRoleItem = this.shareRole;
    if (this.shareRole !== "") {
        if (this.showPrivateAppointment) {
          shareRoleItem = this.shareRole.replace("p", "") + "p";
        } else {
          shareRoleItem = this.shareRole.replace("p", "");
        }
    }
    return shareRoleItem;
  }
}
