
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from "@angular/material-moment-adapter";
import { OnInit, OnDestroy, Component, NgZone } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { registerLocaleData } from "@angular/common";
import { ElectronService } from "src/app/services/electron.service";
import * as _moment from "moment";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";

const moment = _moment;

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: "DD. MMMM YYYY",
  },
  display: {
    dateInput: "DD. MMMM YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  },
};

@Component({
    selector: "vp-date-range-select-calendar-dialog",
    templateUrl: "./date-range-select-dialog.component.html",
    providers: [
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS
        },
      ]
})

export class DateRangeSelectDialogComponent implements OnInit, OnDestroy {

    startDateFormControl: FormControl = new FormControl(new Date());
    endDateFormControl: FormControl = new FormControl(new Date());
    browserLang: string = "en";
    private isAlive$ = new Subject<boolean>();

    constructor(
        public dialogRef: MatDialogRef<DateRangeSelectDialogComponent>,
        private translateService: TranslateService,
        private datePickerAdapter: DateAdapter<any>,
        private electronService: ElectronService,
        private broadcaster: Broadcaster,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.setLocale();
        this.setInitialDate();
        this.broadcaster.on<any>(BroadcastKeys.HIDE_DATE_RANGE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.complete();
        this.isAlive$.next(false);
    }

    close(): void {
        this.dialogRef.close({ start: this.startDateFormControl.value, end: this.endDateFormControl.value });
    }

    setInitialDate(): void {
        const startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        this.startDateFormControl.setValue(startDate);
        const endDateValue = new Date(this.startDateFormControl.value);
        endDateValue.setDate(endDateValue.getDate() + 14);
        this.endDateFormControl.setValue(endDateValue);
    }

    handleStartDateChanges(matEvent: MatDatepickerInputEvent<Date>): void {
        const startDate = moment(<Date>matEvent.value).toDate();
        const endDate = moment(<Date>this.endDateFormControl.value).toDate();
        if (startDate > endDate) {
            endDate.setDate(startDate.getDate() + 1);
            this.endDateFormControl.setValue(new Date(endDate));
        }
    }

    handleEndDateChanges(matEvent: MatDatepickerInputEvent<Date>): void {
        const endDate = moment(<Date>matEvent.value).toDate();
        const startDate = moment(<Date>this.startDateFormControl.value).toDate();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        if ((endDate < startDate) && startDate.toString() !== endDate.toString()) {
            startDate.setDate(endDate.getDate() - 1);
            this.startDateFormControl.setValue(new Date(startDate));
        }
    }

    setLocale() {
        this.browserLang = this.translateService.getBrowserLang();
        const localLang = this.electronService.isElectron
            ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
            : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
            this.browserLang = localLang;
        }
        this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
        this.datePickerAdapter.setLocale(this.browserLang);
        if (this.browserLang === "de") {
            registerLocaleData(localeDE, this.browserLang);
        } else {
            registerLocaleData(localEN, this.browserLang);
        }
    }
}
