
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, Title, HammerModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppRoutingModule, AuthGuard } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { SharedModule } from "./shared/shared.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./app.effects";
import { StoreModule } from "@ngrx/store";
import { reducers, reset } from "./reducers";
import { ConfigService } from "./config.service";
import { AuthService } from "./common/providers/auth.service";
import { AvailableRoutes } from "./common";
import { ResponsiveService } from "./common/providers/responsive.service";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { HttpModule } from "@angular/http";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorService } from "./common/providers/error-service";
import { ToastService } from "./common/providers/toast.service";
import { HttpService } from "./services/http.service";
import { HeaderService } from "./services/header.service";
import { NgxHotkeysModule } from "ngx-hotkeys-vnc";
import { ContextMenuModule } from "ngx-contextmenu";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { VNCNotificationsService, VNCNotificationComponent, VNCNotificationsComponent } from "./shared/components/notifications";
import { VNCAuthenticationComponent } from "./shared/components/vnc-authentication";
import { ColorChromeModule } from "ngx-color/chrome";
import { InstallAppDialogComponent } from "./shared/components/install-app/install-app.component";
import { FilesStorageService } from "./services/files-storage.service";
import { CommonUtils } from "./common/utils/common-util";
import { ElectronService } from "./services/electron.service";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { PreferenceModule } from "./preference/preference.module";
import { CommonService } from "./services/common.service";

import { CalendarRepository } from "./calendar/repositories/calendar.repository";
import { PreferenceService } from "./preference/shared/services/preference.service";
import { PreferenceRepository } from "./preference/repositories/preference.repository";
import { DatePickAdapterService } from "./services/date-picker-service";
import { AppService } from "./services/app.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, CommonUtils.getBaseUrl() + "/assets/i18n/", ".json");
}

export class BaluHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 6
    },
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageNotFoundComponent,
    VNCAuthenticationComponent,
    VNCNotificationComponent,
    VNCNotificationsComponent,
    InstallAppDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NoopAnimationsModule,
    HttpClientModule,
    HttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FlexLayoutModule,
    ContextMenuModule.forRoot({
      autoFocus: true
    }),
    SharedModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers: [reset], runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false,
    }}),
    EffectsModule.forRoot([AppEffects]),
    NgxHotkeysModule.forRoot(),
    ColorChromeModule,
    PreferenceModule,
    HammerModule
  ],
  providers: [
    Title,
    AuthService,
    ConfigService,
    AuthGuard, AvailableRoutes,
    ResponsiveService,
    ErrorService,
    ToastService,
    HttpService,
    HeaderService,
    CommonService,
    FilesStorageService,
    ElectronService,
    Broadcaster,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: BaluHammerConfig
    },
    VNCNotificationsService,
    DatePipe,
    CalendarRepository,
    PreferenceService,
    PreferenceRepository,
    DatePickAdapterService,
    AppService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    InstallAppDialogComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
