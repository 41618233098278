
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { CalendarFolder } from "./../../../common/models/calendar.model";
import { Action } from "src/app/actions";
import { CalendarEvent } from "calendar-utils";
import { Update } from "@ngrx/entity";

export enum CalendarActionTypes {
  LOAD_CALENDARS = "[Calendar] Load Calendars",
  UPDATE_CALENDAR = "[Calendar] Update Calendar",
  RESET_CALENDAR_STATE = "[Calendar] Reset Calendar State",
  SET_CALENDAR_FOLDERS = "[Calendar] Set Calendar Folders",
  SET_CALENDAR_EVENTS = "[Calendar] Set Calendar Events",
  SET_CALENDAR_EVENT = "[Calendar] Set Calendar Event",
  REMOVE_CALENDAR_EVENT = "[Calendar] Remove Calendar Event",
  UPDATE_CALENDAR_EVENT = "[Calendar] Update Calendar Event",
  UPDATE_CALENDAR_FOLDER = "[Calendar] Update Calendar Folder",
  CREATE_CALENDAR_FOLDER_SUCCESS = "[FOLDERS] Create Calendar Folder Success",
  UPDATE_CALENDAR_FOLDER_SUCCESS = "[FOLDERS] Update Calendar Folder Success",
  DELETE_CALENDAR_FOLDER_SUCCESS = "[FOLDERS] Delete Calendar Folder Success"
}

export enum CalendarEventActionTypes {
  SELECT_CALENDAR_EVENT = "[CALENDAR EVENTS] Select Calendar Event",
  LOAD_CALENDAR_EVENTS = "[CALENDAR EVENTS] Load Calendar Events",
  LOAD_CALENDAR_EVENTS_SUCCESS = "[CALENDAR EVENTS] Load Calendar Events Success",
  LOAD_CALENDAR_EVENTS_FAIL = "[CALENDAR EVENTS] Load Calendar Events Fail",

  LOAD_MORE_CALENDAR_EVENTS = "[CALENDAR EVENTS] Load More Calendar Events",
  LOAD_MORE_CALENDAR_EVENTS_SUCCESS = "[CALENDAR EVENTS] Load More Calendar Events Success",
  LOAD_MORE_CALENDAR_EVENTS_FAIL = "[CALENDAR EVENTS] Load More Calendar Events Fail",

  CREATE_CALENDAR_EVENT = "[CALENDAR EVENTS] Create Calendar Event",
  CREATE_CALENDAR_EVENT_FAIL = "[CALENDAR EVENTS] Create Calendar Event Fail",
  CREATE_CALENDAR_EVENT_SUCCESS = "[CALENDAR EVENTS] Create Calendar Event Success",

  DELETE_CALENDAR_EVENTS = "[CALENDAR EVENTS] Delete Calendar Events",
  DELETE_CALENDAR_EVENTS_FAIL = "[CALENDAR EVENTS] Delete Calendar Events Fail",
  DELETE_CALENDAR_EVENTS_SUCCESS = "[CALENDAR EVENTS] Delete Calendar Events Success",

  DELETE_CALENDAR_EVENT = "[CALENDAR EVENTS] Delete Calendar Event",
  DELETE_CALENDAR_EVENT_FAIL = "[CALENDAR EVENTS] Delete Calendar Event Fail",
  DELETE_CALENDAR_EVENT_SUCCESS = "[CALENDAR EVENTS] Delete Calendar Event Success",

  UPDATE_CALENDAR_EVENT = "[CALENDAR EVENTS] Update Calendar Event",
  UPDATE_CALENDAR_EVENT_FAIL = "[CALENDAR EVENTS] Update Calendar Event Fail",
  UPDATE_CALENDAR_EVENT_SUCCESS = "[CALENDAR EVENTS] Update Calendar Event Success",

  UPDATE_CALENDAR_EVENTS = "[CALENDAR EVENTS] Update Calendar Events",
  UPDATE_CALENDAR_EVENTS_FAIL = "[CALENDAR EVENTS] Update Calendar Events Fail",
  UPDATE_CALENDAR_EVENTS_SUCCESS = "[CALENDAR EVENTS] Update Calendar Events Success",

  RESET_CALENDAR_EVENTS = "[CALENDAR EVENTS] RESET Calendar Events",
  RESET_CALENDAR_EVENT_STATE = "[CALENDAR EVENTS] Reset State"
}

export class LoadCalendarEventsAction implements Action {
  readonly type = CalendarEventActionTypes.LOAD_CALENDAR_EVENTS;
}

export class LoadCalendarEventsSuccessAction implements Action {
  readonly type = CalendarEventActionTypes.LOAD_CALENDAR_EVENTS_SUCCESS;
  constructor(public payload: { events: CalendarEvent[] }) { }
}

export class LoadCalendarEventsFailedAction implements Action {
  readonly type = CalendarEventActionTypes.LOAD_CALENDAR_EVENTS_FAIL;
}

export class LoadMoreCalendarEventsAction implements Action {
  readonly type = CalendarEventActionTypes.LOAD_MORE_CALENDAR_EVENTS;
}

export class LoadMoreCalendarEventsSuccessAction implements Action {
  readonly type = CalendarEventActionTypes.LOAD_MORE_CALENDAR_EVENTS_SUCCESS;
  constructor(public payload: { events: CalendarEvent[] }) { }
}

export class LoadMoreCalendarEventsFailedAction implements Action {
  readonly type = CalendarEventActionTypes.LOAD_MORE_CALENDAR_EVENTS_FAIL;
}

export class CreateCalendarEventAction implements Action {
  readonly type = CalendarEventActionTypes.CREATE_CALENDAR_EVENT;
}

export class CreateCalendarEventSuccessAction implements Action {
  readonly type = CalendarEventActionTypes.CREATE_CALENDAR_EVENT_SUCCESS;
  constructor(public payload: { event: CalendarEvent }) { }
}

export class CreateCalendarEventFailAction implements Action {
  readonly type = CalendarEventActionTypes.CREATE_CALENDAR_EVENT_FAIL;
}

export class UpdateCalendarEventAction implements Action {
  readonly type = CalendarEventActionTypes.UPDATE_CALENDAR_EVENT;
}

export class UpdateCalendarEventSuccessAction implements Action {
  readonly type = CalendarEventActionTypes.UPDATE_CALENDAR_EVENT_SUCCESS;
  constructor(public payload: Update<CalendarEvent>) { }
}

export class UpdateCalendarEventFailAction implements Action {
  readonly type = CalendarEventActionTypes.UPDATE_CALENDAR_EVENT_FAIL;
}

export class UpdateCalendarEvenstAction implements Action {
  readonly type = CalendarEventActionTypes.UPDATE_CALENDAR_EVENTS;
}

export class UpdateCalendarEventsSuccessAction implements Action {
  readonly type = CalendarEventActionTypes.UPDATE_CALENDAR_EVENTS_SUCCESS;
  constructor(public payload: CalendarEvent[]) { }
}

export class UpdateCalendarEventsFailAction implements Action {
  readonly type = CalendarEventActionTypes.UPDATE_CALENDAR_EVENTS_FAIL;
}

export class DeleteCalendarEventsAction implements Action {
  readonly type = CalendarEventActionTypes.DELETE_CALENDAR_EVENTS;
}

export class DeleteCalendarEventsSuccessAction implements Action {
  readonly type = CalendarEventActionTypes.DELETE_CALENDAR_EVENTS_SUCCESS;
  constructor(public payload: { eventIds: string[] }) { }
}

export class DeleteCalendarEventsFailAction implements Action {
  readonly type = CalendarEventActionTypes.DELETE_CALENDAR_EVENTS_FAIL;
}

export class DeleteCalendarEventAction implements Action {
  readonly type = CalendarEventActionTypes.DELETE_CALENDAR_EVENT;
}

export class DeleteCalendarEventSuccessAction implements Action {
  readonly type = CalendarEventActionTypes.DELETE_CALENDAR_EVENT_SUCCESS;
  constructor(public payload: { event: CalendarEvent }) { }
}

export class DeleteCalendarEventFailAction implements Action {
  readonly type = CalendarEventActionTypes.DELETE_CALENDAR_EVENT_FAIL;
}

export class ResetCalendarEventsAction implements Action {
  readonly type = CalendarEventActionTypes.RESET_CALENDAR_EVENTS;
  constructor() {}
}

export class SetCalendarFolders implements Action {
  readonly type = CalendarActionTypes.SET_CALENDAR_FOLDERS;
  constructor(public payload: CalendarFolder[]) { }
}

export class UpdateCalendarFolder implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR_FOLDER;
  constructor(public payload: Update<CalendarFolder>) { }
}

export class CreateCalendarFolderSuccess implements Action {
  readonly type = CalendarActionTypes.CREATE_CALENDAR_FOLDER_SUCCESS;
  constructor(public payload: { folder: CalendarFolder }) {}
}

export class UpdateCalendarFolderSuccess implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR_FOLDER_SUCCESS;
  constructor(public payload: Update<CalendarFolder>) {}
}

export class DeleteCalendarFolderSuccess implements Action {
  readonly type = CalendarActionTypes.DELETE_CALENDAR_FOLDER_SUCCESS;
  constructor(public payload: { folder: CalendarFolder }) {}
}

export class SetCalendarEvents implements Action {
  readonly type = CalendarActionTypes.SET_CALENDAR_EVENTS;
  constructor(public payload: CalendarEvent[]) { }
}

export class SetCalendarEvent implements Action {
  readonly type = CalendarActionTypes.SET_CALENDAR_EVENT;
  constructor(public payload: CalendarEvent) { }
}

export class RemoveCalendarEvent implements Action {
  readonly type = CalendarActionTypes.REMOVE_CALENDAR_EVENT;
  constructor(public payload: CalendarEvent) { }
}

export class UpdateCalendarEvent implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR_EVENT;
  constructor(public payload: CalendarEvent) { }
}

export class LoadCalendars implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDARS;
  constructor() { }
}

export class UpdateCalendar implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR;
  constructor() { }
}


export type CalendarActions = LoadCalendars | UpdateCalendar;

