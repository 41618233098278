
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { PreferenceRepository } from "../repositories/preference.repository";
import { PreferenceService } from "../shared/services/preference.service";
import { Subject } from "rxjs";
import { filter, takeUntil, take, skip } from "rxjs/operators";
import * as _ from "lodash";
import { Preference } from "../shared/models";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { AutocompleteComponent } from "src/app/shared/components/autocomplete/autocomplete.component";
import { UserProfile } from "src/app/shared/models";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/reducers/app";
import { TranslateService } from "@ngx-translate/core";
import { getUserProfile } from "src/app/reducers";
import { MatSnackBar } from "@angular/material/snack-bar";

const AVAILABLE_KEYS = [
    "zimbraPrefCalendarSendInviteDeniedAutoReply"
];

@Component({
    selector: "vp-permission-preference",
    templateUrl: "./permissions.component.html"
})
export class PermissionPreferenceComponent implements OnInit, OnDestroy {

    preferenceTitle = "PREFERENCES.PERMISSIONS";
    preferenceForm: FormGroup;
    private isAlive$ = new Subject<boolean>();
    private preference = {};
    private valueChanges$;
    @ViewChild("freeBusyAutoComplete", { static: false }) freeBusyAutoComplete: AutocompleteComponent;
    @ViewChild("invitesAutoComplete", { static: false }) invitesAutoComplete: AutocompleteComponent;
    freeBusyLbl: string = "";
    customWorkingHours: string = "";
    freeBusyAddress: any[] = [];
    invitesAddress: any[] = [];
    profile: UserProfile;
    userEmail: string = "";
    domain: string = "";
    allPreferences: Preference[] = [];
    freeBusyACE: any = null;

    constructor(
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private preferenceRepo: PreferenceRepository,
        private preferenceService: PreferenceService,
        private activatedRoute: ActivatedRoute,
        private broadCaster: Broadcaster,
        private ngxHotKeyService: NgxHotkeysService,
        private store: Store<AppState>,
        private translateService: TranslateService,
        private snackBar: MatSnackBar
    ) {
        this.activatedRoute.paramMap.subscribe(res => {
            setTimeout(() => {
                this.broadCaster.broadcast(BroadcastKeys.OPEN_PREFERENCE_TAB, "permissions-setting");
            }, 50);
        });
        const generalForm = {};
        for (const key of AVAILABLE_KEYS) {
            generalForm[key] = [""];
        }
        generalForm["freeBusyOption"] = ["pub"];
        generalForm["invitesOption"] = ["pub"];

        this.preferenceForm = this.fb.group(generalForm);
        this.preferenceService.setPreferenceTitle(this.preferenceTitle);
        this.preferenceService.onSaveChanges().pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.savePreferences();
        });
        this.translateService.get("PREFERENCES.ENTER_EMAIL").pipe(take(1)).subscribe(res => {
            this.freeBusyLbl = res;
        });
        this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(res => {
            console.log("[getUserProfile]", res);
            this.profile = res;
            this.userEmail = res.email;
            this.domain = this.userEmail.split("@")[1];
        });
        this.getSetGrantRights();
    }

    ngOnInit() {
        setTimeout(() => {
            this.broadCaster.broadcast(BroadcastKeys.HIDE_MOBILE_PREFERENCE_SAVE_FOOTER);
            this.preferenceService.setPreferenceChanges(false);
        }, 2000);

        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), takeUntil(this.isAlive$))
            .subscribe(preferences => {
                this.allPreferences = preferences;
                this.bindFormData(preferences);
                console.log("[getPreferences]", this.preferenceForm.value, this.preference);
                this.changeDetectorRef.markForCheck();
                if (!this.valueChanges$) {
                    this.valueChanges$ = this.preferenceForm.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(changes => {
                        this.preferenceService.setPreferenceChanges(true);
                    });
                }
            });
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
    }

    cancelPreferences(): void {
        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), take(1)).subscribe(preferences => {
                this.bindFormData(preferences);
                this.preferenceService.navigateTo("PREFERENCES_LBL");
                this.changeDetectorRef.markForCheck();
            });
    }

    private getChangesValue(): Preference[] {
        let preferences: Preference[] = [];
        AVAILABLE_KEYS.forEach(key => {
            if (!_.isEqual(this.preference[key], this.preferenceForm.value[key])) {
                let value = this.preferenceForm.value[key];
                if (typeof value === "boolean") {
                    value = value ? "TRUE" : "FALSE";
                }
                preferences = [...preferences, ...[{ key, value }]];
            }
        });
        return preferences;
    }

    savePreferences(): void {
        const changes = this.getChangesValue();
        this.preferenceService.modifyPrefs(changes).pipe(take(1)).subscribe(res => {
            this.preferenceRepo.updatePreferences(changes);
            this.grantRevokeRights();
        });
    }

    private bindFormData(preferences: Preference[]): void {
        AVAILABLE_KEYS.forEach(key => {
            const preference: Preference = _.find(preferences, { key: key });
            if (preference) {
                let value: any = preference.value;
                if (value === "TRUE") {
                    value = true;
                } else if (value === "FALSE") {
                    value = false;
                }
                this.preference[key] = value;
                this.preferenceForm.get(key).setValue(value);
            }
        });
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    getSetGrantRights(): void {
        const request: any = {
            "GetRightsRequest": {
                "@": {
                    xmlns: "urn:zimbraAccount"
                }
            }
        };
        this.preferenceService.batchRequest(request).pipe(take(1)).subscribe(res => {
            if (!!res && res.GetRightsResponse && res.GetRightsResponse[0].ace) {
                const aces = res.GetRightsResponse[0].ace;
                this.freeBusyACE = aces;
                aces.map(item => {
                    if (item.right === "viewFreeBusy") {
                        if (item.deny && item.gt) {
                            this.preferenceForm.get("freeBusyOption").setValue("none");
                        } else {
                            this.preferenceForm.get("freeBusyOption").setValue(item.gt);
                            if (item.gt === "usr") {
                                const emailInfo: any = { a: item.d, t: item.zid, d: item.d };
                                this.freeBusyAutoComplete.setEmailField(emailInfo);
                                this.freeBusyAddress.push(item);
                            }
                        }
                    } else if (item.right === "invite") {
                        if (item.deny && item.gt) {
                            this.preferenceForm.get("invitesOption").setValue("none");
                        } else {
                            this.preferenceForm.get("invitesOption").setValue(item.gt);
                            if (item.gt === "usr") {
                                const emailInfo: any = { a: item.d, t: item.zid, d: item.d };
                                this.invitesAutoComplete.setEmailField(emailInfo);
                                this.invitesAddress.push(emailInfo);
                            }
                        }
                    }
                });
            }
        }, error => {
            this.preferenceService.showMessage(error);
        });
    }

    grantRevokeRights(): void {
        if (this.preferenceForm.get("freeBusyOption").value !== "usr") {
            this.freeBusyAutoComplete.resetEmail();
        }
        if (this.preferenceForm.get("invitesOption").value !== "usr") {
            this.invitesAutoComplete.resetEmail();
        }
        const selectedFree = this.freeBusyAutoComplete.getSelectedEmail();
        const selectedInvite = this.invitesAutoComplete.getSelectedEmail();
        const revokeAttribute: any[] = [];
        const grantAttribute: any[] = [];
        this.freeBusyAddress.map(item => {
            revokeAttribute.push({
                right: "viewFreeBusy",
                gt: "usr",
                d: item.d,
                zid: item.zid
            });
        });
        this.invitesAddress.map(item => {
            revokeAttribute.push({
                right: "invite",
                gt: "usr",
                d: item.d,
                zid: item.zid
            });
        });
        selectedFree.map((item: any) => {
            grantAttribute.push({
                right: "viewFreeBusy",
                gt: "usr",
                d: item.email
            });
        });
        selectedInvite.map((item: any) => {
            grantAttribute.push({
                right: "invite",
                gt: "usr",
                d: item.email
            });
        });

        if (this.preferenceForm.get("freeBusyOption").value !== "usr") {
            grantAttribute.push({
                right: "viewFreeBusy",
                gt: this.preferenceForm.get("freeBusyOption").value === "none"
                ? "all" : this.preferenceForm.get("freeBusyOption").value
            });
            if (this.preferenceForm.get("freeBusyOption").value === "dom") {
                grantAttribute.map( item => {
                    if (item.gt === "dom") {
                        item.d = this.domain;
                    }
                });
            }
            if (this.preferenceForm.get("freeBusyOption").value === "none") {
                grantAttribute.map( item => {
                    if (item.gt === "all" && item.right === "viewFreeBusy") {
                        item.deny = "1";
                    }
                });
            }
            if (selectedFree.length > 0) {
                selectedFree.map((item: any) => {
                    revokeAttribute.push({
                        right: "viewFreeBusy",
                        gt: "usr",
                        d: item.email
                    });
                });
            } else {
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "dom",
                    d: this.domain
                });
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "all",
                    zid: "00000000-0000-0000-0000-000000000000"
                });
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "all",
                    deny: "1"
                });
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "pub"
                });
            }
        }

        if (this.preferenceForm.get("freeBusyOption").value === "usr") {
            if (selectedFree.length === 0) {
                grantAttribute.push({
                    right: "viewFreeBusy",
                    gt: "all",
                    deny: "1"
                });
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "dom",
                    d: this.domain
                });
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "all",
                    zid: "00000000-0000-0000-0000-000000000000"
                });
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "all",
                    deny: "1"
                });
                revokeAttribute.push({
                    right: "viewFreeBusy",
                    gt: "pub"
                });
            }
        }

        if (this.preferenceForm.get("invitesOption").value !== "usr") {
            grantAttribute.push({
                right: "invite",
                gt: this.preferenceForm.get("invitesOption").value === "none"
                ? "all" : this.preferenceForm.get("invitesOption").value
            });
            if (this.preferenceForm.get("invitesOption").value === "none") {
                grantAttribute.map( item => {
                    if (item.gt === "all" && item.right === "invite") {
                        item.deny = "1";
                    }
                });
            }
            if (selectedInvite.length > 0) {
                selectedInvite.map((item: any) => {
                    revokeAttribute.push({
                        right: "invite",
                        gt: "usr",
                        d: item.email
                    });
                });
            } else {
                revokeAttribute.push({
                    right: "invite",
                    gt: "dom",
                    d: this.domain
                });
                revokeAttribute.push({
                    right: "invite",
                    gt: "all",
                    zid: "00000000-0000-0000-0000-000000000000"
                });
                revokeAttribute.push({
                    right: "invite",
                    gt: "all",
                    deny: "1"
                });
                revokeAttribute.push({
                    right: "invite",
                    gt: "pub"
                });
            }
        }
        if (this.preferenceForm.get("invitesOption").value === "usr") {
            if (selectedInvite.length === 0) {
                grantAttribute.push({
                    right: "invite",
                    gt: "all",
                    deny: "1"
                });
                revokeAttribute.push({
                    right: "invite",
                    gt: "dom",
                    d: this.domain
                });
                revokeAttribute.push({
                    right: "invite",
                    gt: "all",
                    zid: "00000000-0000-0000-0000-000000000000"
                });
                revokeAttribute.push({
                    right: "invite",
                    gt: "all",
                    deny: "1"
                });
                revokeAttribute.push({
                    right: "invite",
                    gt: "pub"
                });
            }
        }


        const req = {
            "RevokeRightsRequest": {
                "@": {
                    xmlns: "urn:zimbraAccount"
                },
                ace: revokeAttribute
            },
            "GrantRightsRequest": {
                "@": {
                    xmlns: "urn:zimbraAccount"
                },
                ace: grantAttribute
            }
        };
        this.preferenceService.batchRequest(req).pipe(take(1)).subscribe(res => {
                if (res.Fault) {
                    const fault = res.Fault[0];
                    if (fault.Reason && fault.Reason.Text) {
                        const reason = fault.Reason.Text;
                        if (reason.indexOf("no such account") !== -1) {
                            const email = reason.split(":")[1];
                            this.translateService.get("CALENDARS.NO_SUCH_ACCOUNT_GRANT", {
                                email: email
                            }).subscribe( resp => {
                                this.snackBar.open(resp, "", {
                                    duration: 2000,
                                    panelClass: "mobile_snackbar"
                                });
                            });
                        } else {
                            this.snackBar.open(reason, "", {
                                duration: 2000,
                                panelClass: "mobile_snackbar"
                            });
                        }
                    }
                } else {
                    this.preferenceService.showMessage("PREFERENCES_SAVED");
                    this.broadCaster.broadcast(BroadcastKeys.HIDE_MOBILE_PREFERENCE_SAVE_FOOTER);
                    this.preferenceService.navigateTo();
                }
        }, error => {
            this.snackBar.open(error, "", {
                duration: 2000,
                panelClass: "mobile_snackbar"
            });
        });
    }

    undoChange(): void {
        if (!!this.freeBusyACE && this.freeBusyACE !== null ) {
            this.resetFreeBusyRights(this.freeBusyACE);
        }
        this.bindFormData(this.allPreferences);
        this.preferenceService.showMessage("PREFERENCES.PAGE_REVERTED");
    }

    isEnableFreeBusyAutoComplete(): boolean {
        return this.preferenceForm.get("freeBusyOption").value === "usr" ? true : false;
    }

    isEnableInviteAutoComplete(): boolean {
        return this.preferenceForm.get("invitesOption").value === "usr" ? true : false;
    }

    resetFreeBusyRights(aces: any): void {
        this.invitesAddress = [];
        this.freeBusyAddress = [];
        this.freeBusyAutoComplete.resetEmail();
        this.invitesAutoComplete.resetEmail();
        aces.map(item => {
            if (item.right === "viewFreeBusy") {
                if (item.deny && item.gt) {
                    this.preferenceForm.get("freeBusyOption").setValue("none");
                } else {
                    this.preferenceForm.get("freeBusyOption").setValue(item.gt);
                    if (item.gt === "usr") {
                        const emailInfo: any = { a: item.d, t: item.zid, d: item.d };
                        this.freeBusyAutoComplete.setEmailField(emailInfo);
                        this.freeBusyAddress.push(item);
                    }
                }
            } else if (item.right === "invite") {
                if (item.deny && item.gt) {
                    this.preferenceForm.get("invitesOption").setValue("none");
                } else {
                    this.preferenceForm.get("invitesOption").setValue(item.gt);
                    if (item.gt === "usr") {
                        const emailInfo: any = { a: item.d, t: item.zid, d: item.d };
                        this.invitesAutoComplete.setEmailField(emailInfo);
                        this.invitesAddress.push(emailInfo);
                    }
                }
            }
        });
    }

}
