
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="create-appointment-settings">
    <form [formGroup]="preferenceSettingsForm">
        <div class="header">
            {{ 'PREFERENCES.CREATING_APPOINTMENTS' | translate }}
        </div>
        <div class="element" *ngIf="!!calendarFolder && calendarFolder !== null && isShowDefaultCalendar">
            <div class="lbl">
                {{ 'CALENDARS.CALENDAR_FOLDER' | translate }}:
            </div>
            <div class="item">
                <button [matMenuTriggerFor]="folderList" class="calendar-folder-btn">
                    <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-blank"
                        [style.color]="calendarFolder?.folderColor"></mat-icon>
                    <span>{{getFolderNameKey(calendarFolder.name)}}</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
            </div>
        </div>
        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.QUICK_ADD_DIALOG' | translate }}:
            </div>
            <div class="item">
                <mat-checkbox formControlName="zimbraPrefCalendarUseQuickAdd">
                    {{ 'PREFERENCES.USE_THE_QUICK_ADD_DIALOG' | translate }}</mat-checkbox>
            </div>
        </div>
        <div class="element">
            <div class="lbl">
                {{ 'PREFERENCES.TIME_ZONES' | translate }}:
            </div>
            <div class="item">
                <mat-checkbox formControlName="zimbraPrefUseTimeZoneListInCalendar">
                    {{ 'PREFERENCES.SHOW_TIMEZONE_IN_APPOINTMENT' | translate }}</mat-checkbox>
            </div>
        </div>
        <div fxLayout="row" class="footer-bottom-desktop">
            <div class="button-actions footer-actions">
                <button class="save" (click)="savePreferences()">{{'COMMON.SAVE'| translate }}</button>
                <button class="cancel" (click)="cancelPreferences()">{{'CANCEL'| translate }}</button>
                <button class="save" (click)="undoChange()"> {{ 'UNDO_CHANGES' | translate }}</button>
            </div>
        </div>
    </form>
    <mat-menu #folderList="matMenu" class="cal-dropdown-menu">
        <button vpAutoFocus mat-menu-item *ngFor="let option of allFolders" (click)="changeFolder(option)">
            <mat-icon class="mdi-16px margin-left" fontSet="mdi" fontIcon="mdi-calendar-blank"
                [style.color]="option.folderColor">
            </mat-icon>
            <span>{{getFolderNameKey(option.name)}}</span>
        </button>
    </mat-menu>
</div>