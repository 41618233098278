
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromCalendarState from "../reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Dictionary } from "@ngrx/entity";
import { CalendarEvent, CalendarFolder } from "../../../common/models/calendar.model";
import * as _ from "lodash";

export interface CalendarRootState {
  calendar: fromCalendarState.CalendarState;
  calendarEvent: fromCalendarState.CalendarEventState;
  calendarFolder: fromCalendarState.CalendarFolderState;
}

export const getCalendarRootState = createFeatureSelector<CalendarRootState>("calendar");

export const calendarRootReducer = {
  calendar: fromCalendarState.referenceReducer,
  calendarEvent: fromCalendarState.calendarEventReducer,
  calendarFolder: fromCalendarState.calendarFolderReducer
};

export const getCalendarState = createSelector(
  getCalendarRootState,
  state => state.calendar
);

export const getCalendarEventState = createSelector(
  getCalendarRootState,
  state => state.calendarEvent
);

export const getCalendarFolderState = createSelector(
  getCalendarRootState,
  state => state.calendarFolder
);

export const {
  selectIds: getCalendarIds,
  selectEntities: getCalendarEntities,
  selectAll: getAllCalendars,
  selectTotal: getTotalCalendars,
} = fromCalendarState.calendarAdapter.getSelectors(getCalendarState);

export const {
  selectIds: getCalendarEventIds,
  selectEntities: getCalendarEventEntities,
  selectAll: getAllEvents,
  selectTotal: getTotalEvents
} = fromCalendarState.calendarEventAdapter.getSelectors(getCalendarEventState);

export const getCalendarEventsState = createSelector(
  getCalendarRootState,
  state => state.calendarEvent.event
);

export const getRemovedCalendarEventState = createSelector(
  getCalendarState,
  state => state.removedEvent
);

export const getUpdateCalendarEventState = createSelector(
  getCalendarState,
  state => state.updatedEvent
);

export const getCalendarEventsByIds = createSelector(
  getCalendarEventEntities, (entities: Dictionary<CalendarEvent>, calendarEventIds: string[]) => {
    return calendarEventIds.map(calendarEventId => {
      return entities[calendarEventId];
    });
  }
);

export const {
  selectIds: getCalendarFolderIds,
  selectEntities: getCalendarFolderEntities,
  selectAll: getAllCalendarFolders,
  selectTotal: getTotalFolders
} = fromCalendarState.calendarFolderAdapter.getSelectors(getCalendarFolderState);

export const getCalendarFoldersState = createSelector(
  getCalendarFolderState,
  state => state.calendarFolders
);

export const getCalendarFolders = getAllCalendarFolders;
export const getAllCalendarEvents = getAllEvents;

export const getFolderById = createSelector(
  getCalendarFolderEntities,
  (entities: Dictionary<CalendarFolder>, folderId: string) => {
    return entities[folderId];
  }
);

export const getSharedFolderById = createSelector(
  getAllCalendarFolders,
  (folders: CalendarFolder[], sharedId: string) => {
    const childFolders = getChildFolders(folders);
    const zid = sharedId.split(":")[0];
    const rid = sharedId.split(":")[1];
    const allFolders = [...folders.filter(f => f.owner), ...childFolders];
    return _.find(allFolders, {zid: zid, rid: rid});
  }
);

function getChildFolders(folders: CalendarFolder[]): CalendarFolder[] {
  let allFolders: CalendarFolder[] = [];
  let childFolders: CalendarFolder[] = [];
  folders.filter(f => f.folder && f.folder.length > 0).forEach(f => {
    allFolders = getChildFolders(f.folder);
    childFolders = [...childFolders, ...f.folder];
  });
  return [...allFolders, ...childFolders];
}
