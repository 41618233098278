
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Observable, of, forkJoin, Subject } from "rxjs";
import { CommonUtils } from "src/app/common/utils/common-util";
import { HttpClient } from "@angular/common/http";
import { map, take, tap } from "rxjs/operators";
import { Filter } from "../models/filter.model";
import * as _ from "lodash";
import { Signature, Preference, DataSource } from "../models";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Identity, IdentityAttribute } from "../models/identity.model";
import { Attribute } from "../models/attribute.model";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { MailConstants } from "../../../common/utils/mail-constants";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MatSnackBar } from "@angular/material/snack-bar";

const ZIMBRA_ACCOUNT = "urn:zimbraAccount";
@Injectable()
export class PreferenceService {

    public allFolders: any = [];
    public attachmentInfo: any;
    public uploadAttachmentSizeLimit: number;
    private preferenceChanges$ = new Subject<boolean>();
    private saveChanges$ = new Subject<number>();
    private preferenceTitle: string;
    isMobileScreen: boolean = false;
    public dataSource$: DataSource[];
    public emailUserDisplayType: string = "firstname";
    constructor(
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private broadcaster: Broadcaster,
        private route: Router,
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver,
        private configService: ConfigService) {
          this.breakpointObserver.observe(["(max-width: 599px)"]).subscribe((state: BreakpointState) => {
              if (state.matches) {
                  this.isMobileScreen = true;
                } else {
                    this.isMobileScreen = false;
                }
            });
        }

    setPreferenceChanges(value: boolean): void {
        this.preferenceChanges$.next(value);
    }

    setOnSaveChanges(): void {
        this.saveChanges$.next(new Date().getTime());
    }

    onPreferenceChanges(): Observable<boolean> {
        return this.preferenceChanges$;
    }

    onSaveChanges(): Observable<number> {
        return this.saveChanges$;
    }

    setPreferenceTitle(title: string): void {
        this.preferenceTitle = title;
    }

    getPreferenceTitle(): string {
        return this.preferenceTitle;
    }

    batchRequest(request: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    batchRequest2(request: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/batchRequest2", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(tap(res => {
                if (res["Fault"] && res["Fault"]["Reason"]) {
                    return of({ error: res["Fault"]["Reason"]["Text"] });
                }
            }), take(1));
    }

    getFilters(option: "all" | "incoming" | "outgoing"): Observable<{ incomingFilters: Filter[], outgoingFilters: Filter[] }> {
        let request: any = {};
        if (option === "incoming") {
            request = {
                "GetFilterRulesRequest": {
                    "@": { "xmlns": "urn:zimbraMail" }
                }
            };
        } else if (option === "outgoing") {
            request = {
                "GetOutgoingFilterRulesRequest": {
                    "@": { "xmlns": "urn:zimbraMail" }
                }
            };
        } else {
            request = {
                "GetFilterRulesRequest": {
                    "@": { "xmlns": "urn:zimbraMail" }
                },
                "GetOutgoingFilterRulesRequest": {
                    "@": {
                        "xmlns": "urn:zimbraMail"
                    }
                }
            };
        }
        return this.batchRequest2(request)
            .pipe(map((res: any) => {
                let incomingFilters: Filter[] = [];
                let outgoingFilters: Filter[] = [];
                if (res.GetFilterRulesResponse && res.GetFilterRulesResponse[0].filterRules
                    && res.GetFilterRulesResponse[0].filterRules[0].filterRule) {
                    let _incomingFilters = res.GetFilterRulesResponse[0].filterRules[0].filterRule;
                    _incomingFilters = (Array.isArray(_incomingFilters) ? _incomingFilters : [_incomingFilters]);
                    incomingFilters = _incomingFilters.map(f => {
                        f.active = f.active === "1";
                        return f;
                    }) as Filter[];
                }
                if (res.GetOutgoingFilterRulesResponse && res.GetOutgoingFilterRulesResponse[0].filterRules
                    && res.GetOutgoingFilterRulesResponse[0].filterRules[0].filterRule) {
                    let _outgoingFilters = res.GetOutgoingFilterRulesResponse[0].filterRules[0].filterRule;
                    _outgoingFilters = (Array.isArray(_outgoingFilters) ? _outgoingFilters : [_outgoingFilters]);
                    outgoingFilters = _outgoingFilters.map(f => {
                        f.active = f.active === "1";
                        return f;
                    }) as Filter[];
                }
                return { incomingFilters, outgoingFilters };
            }, take(1)));
    }

    getSignatures(): Observable<Signature[]> {
        const request = {
            GetSignaturesRequest: {
                "@": {
                    "xmlns": ZIMBRA_ACCOUNT,
                    "requestId": "0"
                }
            }
        };
        return this.batchRequest(request).pipe(map(res => {
            if (res.GetSignaturesResponse && res.GetSignaturesResponse[0].signature) {
                let signatures = res.GetSignaturesResponse[0].signature;
                signatures = Array.isArray(signatures) ? signatures : [signatures];
                return signatures.map(signature => {
                    const data: Signature = {
                        id: signature.id,
                        name: signature.name,
                        content: signature.content[0]._content.replace(/(?:\r\n|\r|\n)/g, "<br />").replace(/\t/g, "    ").
                        replace(/                    /ig, ""),
                        type: signature.content[0].type
                    };
                    return data;
                });
            } else {
                return of([]);
            }
        }));
    }

    getAttributes(): Observable<Attribute[]> {
        return this.http.get(this.configService.API_URL + "/api/getInfo?sections=attrs",
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1), map((res: any) => {
                return this.getAllAttributes(res.attrs._attrs);
            }));
    }

    getAllAttributes(_attrs: any): Attribute[] {
        const attr: Attribute[] = [];
        for (const key in _attrs) {
          if (_attrs.hasOwnProperty(key)) {
            const atribute: Attribute = {
              key: key ,
              value: _attrs[key]
            };
            attr.push(atribute);
          }
        }
        return attr;
    }

    getPreference(prefName): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/getPrefs", { prefName: prefName },
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    private setPreference(preference: Preference) {
        return {
            "@": {
                "name": preference.key
            },
            "#": preference.value
        };
    }

    private getPref(data) {
        return { key: data.$.name, value: data._ };
    }

    savePreferences(preferences): Observable<any> {
        const pref = _.map(preferences, this.setPreference.bind(this));
        const request = {
            ModifyPrefsRequest: {
                "@": {
                    "xmlns": ZIMBRA_ACCOUNT,
                    "requestId": "0"
                },
                "pref": pref
            }
        };
        return this.batchRequest(request);
    }

    modifyPrefs(changes: Preference[]): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/modifyPrefs", { prefs: changes },
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }


    modifyFilterRules(filters: Filter[]): Observable<any> {
        const request = {
            ModifyFilterRulesRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                "filterRules": [
                    {
                        filterRule: filters
                    }
                ]
            }
        };
        return this.batchRequest(request);
    }

    modifyOutgoingFilterRules(filters: Filter[]) {
        const request = {
            ModifyOutgoingFilterRulesRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                "filterRules": [
                    {
                        filterRule: filters
                    }
                ]
            }
        };
        return this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    getDataSources(): Observable<DataSource[]> {
        const request = {
            GetDataSourcesRequest: {
                "@": {
                    "xmlns": "urn:zimbraMail",
                    "requestId": "0"
                }
            }
        };
        return this.batchRequest(request).pipe(map(res => {
            let accounts: DataSource[] = [];
            if (!!res.GetDataSourcesResponse) {
                const datasources = res.GetDataSourcesResponse[0];
                if (datasources && !!datasources.pop3) {
                    const pop3 = Array.isArray(datasources.pop3) ? datasources.pop3 : [datasources.pop3];
                    const temp: DataSource[] = pop3.map((p) => {
                        const o = p;
                        o.accountType = "POP";
                        o.selected = false;
                        return o as DataSource;
                    });
                    accounts = [...accounts, ...temp];
                }
                if (datasources && !!datasources.imap) {
                    const imap = Array.isArray(datasources.imap) ? datasources.imap : [datasources.imap];
                    const temp: DataSource[] = imap.map((p) => {
                        const o = p;
                        o.accountType = "IMAP";
                        o.selected = false;
                        return o as DataSource;
                    });
                    accounts = [...accounts, ...temp];
                }
                for (const account of accounts) {
                    if (!account.defaultSignature || account.defaultSignature === "11111111-1111-1111-1111-111111111111") {
                        account.defaultSignature = "";
                    }
                    if (!account.forwardReplySignature || account.forwardReplySignature === "11111111-1111-1111-1111-111111111111") {
                        account.forwardReplySignature = "";
                    }
                }
            }
            // accounts.reverse();
            return accounts;
        }));
    }

    createDataSource(accounts): Observable<any> {
        const request = {
            CreateDataSourceRequest: accounts,
            NoOpRequest: {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: (accounts.length + 1)
                }
            }
        };
        return this.batchRequest2(request);
    }

    modifyDataSource(accounts): Observable<any> {
        const request = {
            ModifyDataSourceRequest: accounts
        };
        return this.batchRequest2(request);
    }

    getImportStatus(): Observable<any> {
        const request = {
            GetImportStatusRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                }
            }
        };
        return this.batchRequest2(request);
    }

    importData(account: any): Observable<any> {
        let request: any;
        if (account.accountType === "POP") {
            request = {
                ImportDataRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    pop3: {
                        "@": {
                            id: account.id
                        }
                    }
                }
            };
        } else {
            request = {
                ImportDataRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    imap: {
                        "@": {
                            id: account.id
                        }
                    }
                }
            };
        }
        if (account.accountType === "IMAP") {
            delete request.ImportDataRequest.pop3;
            request.ImportDataRequest.imap = {
                "@": {
                    id: account.id
                }
            };
        }
        return this.batchRequest2(request);
    }


    modifySignature(signature, htmlContent: string, plainContent: string) {
      const signatureRequest = {
        "@": {
            name: signature.name,
            id: signature.id
        },
        content: [
          {
            "@": {
                type: "text/html"
            },
            "#": htmlContent
        },
        {
            "@": {
                type: "text/plain"
            },
            "#": plainContent
        }
        ]
        };
        const request = {
            ModifySignatureRequest: {
                "@": {
                    xmlns: ZIMBRA_ACCOUNT
                },
                signature: signatureRequest
            }
        };
        return this.batchRequest2(request);
    }

    deleteSignature(ids: string[]) {
        const signatures = ids.map(id => {
            const signature = {
                "@": { "xmlns": ZIMBRA_ACCOUNT },
                "signature": { "@": { "id": id } }
            };
            return signature;
        });
        const request = {
            DeleteSignatureRequest: signatures
        };
        return this.batchRequest2(request);
    }

    createSignature(name: string, htmlContent: string, plainContent: string): Observable<any> {
        const signatureRequest = {
            "@": {
                name: name
            },
            content: [
                {
                    "@": {
                        type: "text/html"
                    },
                    "#": htmlContent
                },
                {
                    "@": {
                        type: "text/plain"
                    },
                    "#": plainContent
                }
            ]
        };
        const request = {
            CreateSignatureRequest: {
                "@": {
                    xmlns: ZIMBRA_ACCOUNT
                },
                signature: signatureRequest
            }
        };
        return this.batchRequest2(request);
    }

    showMessage(translationKey: string, duration: number = 2000): void {
        this.translate.get(translationKey).pipe(take(1)).subscribe((text: string) => {
            this.snackBar.open(text, "", {
                duration: duration,
                panelClass: "mobile_snackbar"
            });
        });
    }

    navigateTo(title?: string): void {
        if (title && (title === "PREFERENCES_LBL" || title === "PREFERENCES.SETTINGS")) {
          this.route.navigate(["/"]);
          // this.broadcaster.broadcast(BroadcastKeys.OPEN_SIBAR_DRAWER);
        } else {
          if (this.isMobileScreen) {
          this.route.navigate(["/preferences/", "prefOptions"]);
          } else {
            // this.route.navigate(["/"]);
            // this.broadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
          }
      }
    }

    deleteDataSources(accounts): Observable<any> {
        const request: any = {};
        request.DeleteDataSourceRequest = [];
        let i = 0;
        for (const account of accounts) {
            let r: any = {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: i
                },
                pop3: {
                    "@": {
                        id: account.id
                    }
                }
            };
            if (account.connectionType === "IMAP") {
                r = {
                    "@": {
                        xmlns: "urn:zimbraMail",
                        requestId: i
                    },
                    imap: {
                        "@": {
                            id: account.id
                        }
                    }
                };
            }
            request.DeleteDataSourceRequest.push(r);
            i++;
        }
        request.NoOpRequest = {
            "@": {
                xmlns: "urn:zimbraMail",
                requestId: i
            }
        };
        return this.batchRequest2(request);
    }

    testDataSource(data): Observable<any> {
        let request: any;
        if (data.accountType === "POP") {
            request = {
                TestDataSourceRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    pop3: {
                        "@": data
                    }
                }
            };
        } else {
            request = {
                TestDataSourceRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    imap: {
                        "@": data
                    }
                }
            };
        }
        if (data.connectionType === "IMAP") {
            delete request.TestDataSourceRequest.pop3;
            request.TestDataSourceRequest["imap"] = data;
        }
        return this.batchRequest2(request);
    }

    moveFolderToTrash(ids: string): Observable<any> {
        const request = {
            FolderActionRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                action: {
                    "@": {
                        id: ids,
                        op: "trash"
                    }
                }
            }
        };
        return this.batchRequest2(request);
    }

    modifyIdentity(identity): Observable<any> {
        const request = {
            ModifyIdentityRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount",
                    requestId: 0
                },
                identity: {
                    "@": {
                        id: identity.id
                    },
                    a: identity.a
                }
            },
            NoOpRequest: {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: 1
                }
            }
        };
        return this.batchRequest2(request);
    }

    getIdentities(): Observable<Identity> {
        const request = {
            GetIdentitiesRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount"
                }
            }
        };
        return this.batchRequest2(request).pipe(map(res => {
            let identity: Identity = null;
            if (Array.isArray(res.GetIdentitiesResponse) &&
                Array.isArray(res.GetIdentitiesResponse[0].identity)) {
                identity = {
                    id: res.GetIdentitiesResponse[0].identity[0].id,
                    name: res.GetIdentitiesResponse[0].identity[0].name,
                    attributes: this.getIdentityAttribute(res.GetIdentitiesResponse[0].identity[0]._attrs)
                };
            }
            return identity;
        }));

    }

    getIdentityAttribute(identity: any): IdentityAttribute[] {
        const identityAttribute: IdentityAttribute[] = [];
        for (const key in identity) {
            if (identity.hasOwnProperty(key)) {
              const preference: IdentityAttribute = {
                name: key ,
                value: identity[key]
              };
              identityAttribute.push(preference);
            }
          }
          return identityAttribute;
    }
    createFolder(name): Observable<any> {
        const request = {
            CreateFolderRequest: {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: 0
                },
                folder: {
                    "@": {
                        name: name,
                        l: 1,
                        fie: 1
                    }
                }
            }
        };
        return this.batchRequest2(request);
    }

    renameFolder(id, name): Observable<any> {
        const request = {
            FolderActionRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                action: {
                    "@": {
                        op: "rename",
                        id: id,
                        name: name
                    }
                }
            }
        };
        return this.batchRequest2(request);
    }

    processDataSources(listAccount): Observable<any> {
        const response = new Subject<any>();
        if (!Array.isArray(listAccount)) {
            listAccount = [listAccount];
        }

        const newAccounts = listAccount.filter((account) => {
            return !isNaN(+account.id);
        });

        const oldAccounts = listAccount.filter((account) => {
            return isNaN(+account.id);
        });

        const modifyDataSource = [];
        const queues = [];
        const folderRequest = [];
        if (newAccounts.length > 0) {
            for (const account of newAccounts) {
                let i = 0;
                folderRequest.push(this.createFolder(account.name).pipe(map(res => {
                    return this.generateDataSource(account, res, i);
                })));
                i++;
            }
        }

        if (oldAccounts.length > 0) {
            let i = 0;
            for (const account of oldAccounts) {
                let r: any = {
                    "@": {
                        xmlns: "urn:zimbraMail",
                        requestId: i
                    },
                    pop3: {
                        "@": account
                    }
                };
                if (account.accountType === "IMAP") {
                    r = {
                        "@": {
                            xmlns: "urn:zimbraMail",
                            requestId: i
                        },
                        imap: {
                            "@": account
                        }
                    };
                }
                this.renameFolder(account.l, account.name);
                modifyDataSource.push(r);
                i++;
            }
            queues.push(this.modifyDataSource(modifyDataSource));
        }
        console.log(folderRequest, queues);
        if (folderRequest.length > 0) {
            forkJoin(folderRequest).subscribe(results => {
                console.log("[folderRequest] createDataSource", results);
                queues.push(this.createDataSource(results));
                forkJoin(queues).subscribe(results2 => {
                    response.next(results2);
                });
            });
        } else {
            if (queues.length > 0) {
                forkJoin(queues).subscribe(results2 => {
                    response.next(results2);
                });
            } else {
                response.next([]);
            }
        }
        return response.asObservable().pipe(take(1));
    }

    generateDataSource(account, data, i): any {
        if (data.CreateFolderResponse && data.CreateFolderResponse[0].folder) {
            account.l = data.CreateFolderResponse[0].folder[0].id;
            let r: any = {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: i
                },
                pop3: {
                    "@": account
                }
            };

            if (account.accountType === "IMAP") {
                r = {
                    "@": {
                        xmlns: "urn:zimbraMail",
                        requestId: i
                    },
                    imap: {
                        "@": account
                    }
                };
            }
            return r;
        }
        return null;
    }

    modifyWhiteBlackList(blackList, whiteList): Observable<any> {
        const request: any = {
            ModifyWhiteBlackListRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount"
                }
            }
        };
        if (blackList.length > 0) {
            request.ModifyWhiteBlackListRequest.blackList = {
                addr: blackList
            };
        }
        if (whiteList.length > 0) {
            request.ModifyWhiteBlackListRequest.whiteList = {
                addr: whiteList
            };
        }
        return this.batchRequest2(request);
    }

    getWhiteBlackList(): Observable<{ blackList: any[], whiteList: any[] }> {
        const request = {
            GetWhiteBlackListRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount"
                }
            }
        };
        return this.batchRequest2(request).pipe(map(res => {
            let blackList: any[] = [];
            let whiteList: any[] = [];
            if (res.GetWhiteBlackListResponse) {
                if (res.GetWhiteBlackListResponse[0].blackList && res.GetWhiteBlackListResponse[0].blackList[0].addr) {
                    blackList = res.GetWhiteBlackListResponse[0].blackList[0].addr;
                    if (!Array.isArray(blackList)) {
                        blackList = [blackList];
                    }
                }
                if (res.GetWhiteBlackListResponse[0].whiteList && res.GetWhiteBlackListResponse[0].whiteList[0].addr) {
                    whiteList = res.GetWhiteBlackListResponse[0].whiteList[0].addr;
                    if (!Array.isArray(whiteList)) {
                        whiteList = [whiteList];
                    }
                }
            }
            return { blackList, whiteList };
        }));
    }

    getUploadAttachmentLimit(): Observable<any> {
        return this.http.get(this.configService.API_URL + "/api/getInfo?sections=attrs",
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1), map((res: any) => {
                localStorage.setItem("sectionAttributes", JSON.stringify(res));
                return res.attSizeLimit;
            }));
    }

    getDisplayUserType (): Observable<any> {
        const request = {
            GetInfoRequest: {
                "@": {
                    "xmlns": ZIMBRA_ACCOUNT,
                    "section": "zimlets"
                }
            }
        };
        return this.batchRequest(request);
    }

    setDisplayUserType(value: string): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/modifyPropertyDisplayUserType", { prop: value },
        { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    getPreferences(): Observable<Preference[]> {
        return this.http.post(this.configService.API_URL + "/api/getPrefs", {},
          { headers: CommonUtils.getZimbraHeader() }).pipe(take(1), map((res: any) => {
            const preferences: Preference[] = [];
            for (const key in res._attrs) {
              if (res._attrs.hasOwnProperty(key)) {
                const preference: Preference = {
                  key: key,
                  value: res._attrs[key]
                };
                preferences.push(preference);
              }
            }
            return preferences;
          }));
      }
}
