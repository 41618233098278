
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "src/app/actions";
import { Appointment } from "src/app/common/models/appoinment.model";
import { CalendarActionTypes, CalendarEventActionTypes } from "../actions";
import { CalendarFolder, CalendarEvent } from "src/app/common/models/calendar.model";

export interface CalendarState extends EntityState<Appointment> {
  isLoading: boolean;
  isLoaded: boolean;
  event: CalendarEvent;
  events: CalendarEvent[];
  updatedEvent: CalendarEvent;
  removedEvent: CalendarEvent;
}

export interface CalendarEventState extends EntityState<CalendarEvent> {
  isLoading: boolean;
  isLoaded: boolean;
  event: CalendarEvent;
}

export interface CalendarFolderState extends EntityState<CalendarFolder> {
  calendarFolders: CalendarFolder[];
}

export const calendarAdapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: (appointment: Appointment) => appointment.id
});

export const calendarEventAdapter: EntityAdapter<CalendarEvent> = createEntityAdapter<CalendarEvent>({
  selectId: (event: CalendarEvent) => event.eventId
});

export const calendarFolderAdapter: EntityAdapter<CalendarFolder> = createEntityAdapter<CalendarFolder>({
  selectId: (folder: CalendarFolder) => folder.id
});


export const initialState: CalendarState = calendarAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  event: <CalendarEvent>{},
  events: [],
  updatedEvent: <CalendarEvent>{},
  removedEvent: <CalendarEvent>{},
});

export const initialEventState: CalendarEventState = calendarEventAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  event: <CalendarEvent>{}
});

export const initialFolderState: CalendarFolderState = calendarFolderAdapter.getInitialState({
  calendarFolders: []
});

export function referenceReducer(
  state = initialState,
  action: Action
): CalendarState {
  switch (action.type) {

    case CalendarActionTypes.SET_CALENDAR_EVENTS: {
      return {
        ...state,
        events: action.payload
      };
    }

    case CalendarActionTypes.SET_CALENDAR_EVENT: {
      return {
        ...state,
        event: action.payload
      };
    }

    case CalendarActionTypes.REMOVE_CALENDAR_EVENT: {
      return {
        ...state,
        removedEvent: action.payload
      };
    }

    case CalendarActionTypes.UPDATE_CALENDAR_EVENT: {
      return {
        ...state,
        updatedEvent: action.payload
      };
    }

    case CalendarActionTypes.RESET_CALENDAR_STATE: {
      return calendarAdapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export function calendarEventReducer(state = initialEventState, action: Action): CalendarEventState {
  switch (action.type) {

    case CalendarEventActionTypes.LOAD_CALENDAR_EVENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CalendarEventActionTypes.LOAD_CALENDAR_EVENTS_SUCCESS: {
      return calendarEventAdapter.addAll(action.payload.events, {
        ...state,
        isloading: false,
        isLoaded: true
      });
    }

    case CalendarEventActionTypes.LOAD_CALENDAR_EVENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarEventActionTypes.LOAD_MORE_CALENDAR_EVENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CalendarEventActionTypes.LOAD_MORE_CALENDAR_EVENTS_SUCCESS: {
      return calendarEventAdapter.addMany(action.payload.events, {
        ...state,
        isloading: false,
        isLoaded: true
      });
    }

    case CalendarEventActionTypes.LOAD_MORE_CALENDAR_EVENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarEventActionTypes.CREATE_CALENDAR_EVENT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarEventActionTypes.CREATE_CALENDAR_EVENT_SUCCESS: {
      return calendarEventAdapter.addOne(action.payload.event, {
        ...state,
        isLoading: false,
        isloaded: true
      });
    }

    case CalendarEventActionTypes.CREATE_CALENDAR_EVENT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarEventActionTypes.UPDATE_CALENDAR_EVENT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarEventActionTypes.UPDATE_CALENDAR_EVENT_SUCCESS: {
      return calendarEventAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case CalendarEventActionTypes.UPDATE_CALENDAR_EVENT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarEventActionTypes.UPDATE_CALENDAR_EVENTS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarEventActionTypes.UPDATE_CALENDAR_EVENTS_SUCCESS: {
      const newState = {
        ...state,
        isLoading: false,
        isLoaded: true
      };

      return calendarEventAdapter.updateMany(action.payload
        .map((calendarEvent: CalendarEvent) =>
          Object.assign({ id: calendarEvent.eventId, changes: calendarEvent })), newState);
    }

    case CalendarEventActionTypes.UPDATE_CALENDAR_EVENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarEventActionTypes.DELETE_CALENDAR_EVENTS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarEventActionTypes.DELETE_CALENDAR_EVENTS_SUCCESS: {
      return calendarEventAdapter.removeMany(action.payload.eventIds, {
        ...state,
        isLoading: true
      });
    }

    case CalendarEventActionTypes.DELETE_CALENDAR_EVENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarEventActionTypes.DELETE_CALENDAR_EVENT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarEventActionTypes.DELETE_CALENDAR_EVENT_SUCCESS: {
      return calendarEventAdapter.removeOne(action.payload.event.eventId, {
        ...state,
        isLoading: true
      });
    }

    case CalendarEventActionTypes.DELETE_CALENDAR_EVENT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarEventActionTypes.RESET_CALENDAR_EVENTS: {
      return calendarEventAdapter.removeAll({ ...state });
    }

    default: {
      return state;
    }

  }
}

export function calendarFolderReducer(state = initialFolderState, action: Action): CalendarFolderState {
  switch (action.type) {

    case CalendarActionTypes.SET_CALENDAR_FOLDERS: {
      return calendarFolderAdapter.addAll(action.payload, {
        ...state
      });
    }

    case CalendarActionTypes.CREATE_CALENDAR_FOLDER_SUCCESS: {
      return calendarFolderAdapter.addOne(action.payload.folder, {
        ...state
      });
    }

    case CalendarActionTypes.UPDATE_CALENDAR_FOLDER_SUCCESS: {
      return calendarFolderAdapter.updateOne(action.payload, {
        ...state
      });
    }

    case CalendarActionTypes.DELETE_CALENDAR_FOLDER_SUCCESS: {
      return calendarFolderAdapter.removeOne(action.payload.folder.id, {
        ...state
      });
    }

    default: {
      return state;
    }

  }
}

export const _getCalendarFolders = (state: CalendarFolderState) => state.ids;
export const _getCalendarEvents = (state: CalendarState) => state.events;
export const _getCalendarEvent = (state: CalendarState) => state.event;
