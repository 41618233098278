
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PreferenceRepository } from "../repositories/preference.repository";
import { PreferenceService } from "../shared/services/preference.service";
import { Subject } from "rxjs";
import { filter, takeUntil, take } from "rxjs/operators";
import * as _ from "lodash";
import { Preference } from "../shared/models";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";

const AVAILABLE_KEYS = [
    "zimbraPrefAppleIcalDelegationEnabled"
];

@Component({
    selector: "vp-apple-ical-preference",
    templateUrl: "./apple-ical.component.html"
})
export class AppleIcalComponent implements OnInit, OnDestroy {

    preferenceTitle = "PREFERENCES.APPLE_ICAL";
    preferenceSettingsForm: FormGroup;
    private isAlive$ = new Subject<boolean>();
    private preference = {};
    private valueChanges$;
    allPreferences: Preference[] = [];

    constructor(
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private preferenceRepo: PreferenceRepository,
        private preferenceService: PreferenceService,
        private activatedRoute: ActivatedRoute,
        private broadCaster: Broadcaster,
        private ngxHotKeyService: NgxHotkeysService
    ) {
        this.activatedRoute.paramMap.subscribe(res => {
            setTimeout(() => {
                this.broadCaster.broadcast(BroadcastKeys.OPEN_PREFERENCE_TAB, "apple-ical");
            }, 50);
        });
        const generalForm = {};
        for (const key of AVAILABLE_KEYS) {
            generalForm[key] = [""];
        }
        this.preferenceSettingsForm = this.fb.group(generalForm);
        this.preferenceService.setPreferenceTitle(this.preferenceTitle);
        this.preferenceService.onSaveChanges().pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.savePreferences();
        });
    }

    ngOnInit() {
        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), takeUntil(this.isAlive$))
            .subscribe(preferences => {
                this.allPreferences = preferences;
                this.bindFormData(preferences);
                console.log("[getPreferences]", this.preferenceSettingsForm.value, this.preference);
                this.changeDetectorRef.markForCheck();
                if (!this.valueChanges$) {
                    this.valueChanges$ = this.preferenceSettingsForm.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(changes => {
                        this.preferenceService.setPreferenceChanges(true);
                    });
                }
            });
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
    }

    cancelPreferences(): void {
        this.preferenceRepo.getPreferencesByIds(AVAILABLE_KEYS)
            .pipe(filter(v => !!v && v.length > 0 && v.filter(pref => !!pref).length > 0), take(1)).subscribe(preferences => {
                this.bindFormData(preferences);
                this.preferenceService.navigateTo("PREFERENCES_LBL");
                this.changeDetectorRef.markForCheck();
            });
    }

    private getChangesValue(): Preference[] {
        let preferences: Preference[] = [];
        AVAILABLE_KEYS.forEach(key => {
            if (!_.isEqual(this.preference[key], this.preferenceSettingsForm.value[key])) {
                let value = this.preferenceSettingsForm.value[key];
                if (typeof value === "boolean") {
                    value = value ? "TRUE" : "FALSE";
                }
                preferences = [...preferences, ...[{ key, value }]];
            }
        });
        return preferences;
    }

    savePreferences(): void {
        const changes = this.getChangesValue();
        if (changes.length > 0) {
            this.preferenceService.modifyPrefs(changes).subscribe(res => {
                this.preferenceRepo.updatePreferences(changes);
                this.preferenceService.showMessage("PREFERENCES_SAVED");
                this.broadCaster.broadcast(BroadcastKeys.HIDE_MOBILE_PREFERENCE_SAVE_FOOTER);
                this.preferenceService.navigateTo();
            });
        }
    }

    private bindFormData(preferences: Preference[]): void {
        AVAILABLE_KEYS.forEach(key => {
            const preference: Preference = _.find(preferences, { key: key });
            if (preference) {
                let value: any = preference.value;
                if (value === "TRUE") {
                    value = true;
                } else if (value === "FALSE") {
                    value = false;
                }
                this.preference[key] = value;
                this.preferenceSettingsForm.get(key).setValue(value);
            }
        });
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    undoChange(): void {
        this.bindFormData(this.allPreferences);
        this.preferenceService.showMessage("PREFERENCES.PAGE_REVERTED");
    }

}
