
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { take } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import { MoveCalendarDialogComponent } from "../move-calendar-dialog/move-calendar-dialog.component";
import { CalendarFolder } from "src/app/common/models/calendar.model";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { TranslateService } from "@ngx-translate/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

@Component({
    selector: "vp-recover-calendar-delete-dialog",
    templateUrl: "./recover-calendar-delete.component.html"
})
export class RecoverCalendarDeleteComponent implements OnInit, OnDestroy {
    recoverItem: any[] = [];
    searchInput: string = "";
    selectedItems: any[] = [];
    constructor(
        private dialogRef: MatDialogRef<RecoverCalendarDeleteComponent>,
        private commonService: CommonService,
        private toastService: ToastService,
        private matDialog: MatDialog,
        private calendarRepository: CalendarRepository,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.getSearchRequestBody();
    }

    ngOnDestroy() { }

    close(): void {
        this.dialogRef.close();
    }

    getSearchRequestBody(searchString?: string) {
        let searchItem: string = "";
        this.selectedItems = [];
        if (searchString) {
            searchItem = searchString;
        }
        this.recoverItem = [];
        const requestObject = {
            "SearchRequest": {
                "@": {
                    "xmlns": "urn:zimbraMail"
                },
                _jsns: "urn:zimbraMail",
                inDumpster: 1,
                limit: 50,
                locale: { _content: "en_US" },
                needExp: 1,
                offset: 0,
                query: "-in:/Junk " + searchItem,
                sortBy: "dateDesc",
                types: "appointment"
            }
        };
        this.commonService.createBatchRequest(requestObject).pipe(take(1)).subscribe(res => {
            if (!!res && res.SearchResponse) {
                if (res.SearchResponse && res.SearchResponse[0].appt) {
                    const appt = res.SearchResponse[0].appt;
                    appt.map(apt => {
                        const date = apt.inst[0].s;
                        this.recoverItem.push({
                            id: apt.id,
                            name: apt.name,
                            startDate: new Date(date)
                        });
                    });
                }
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    searchItem(): void {
        this.getSearchRequestBody(this.searchInput);
    }

    selectEvent(item: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedItems.indexOf(item) === -1) {
            if ($event.ctrlKey) {
                this.selectedItems.push(item);
            } else {
                this.selectedItems = [];
                this.selectedItems.push(item);
            }
        } else {
            if (this.selectedItems.length > 1) {
                this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
            }
        }
    }

    isSelectedItem(item: any): boolean {
        return this.selectedItems.indexOf(item) !== -1;
    }

    openMoveDialog(): void {
        const dialogRef = this.matDialog.open(MoveCalendarDialogComponent, {
            maxWidth: "100%",
            autoFocus: true,
            panelClass: "calendar-move-dialog",
            data: { isCalendarMove: true, recover: true }
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.destinationFolder) {
                const destinationFolder: CalendarFolder = res.destinationFolder;
                const ids = this.selectedItems.map(i => i.id);
                const body = { id: ids.toString(), l: destinationFolder.id, op: "recover" };
                this.commonService.itemAction(body).pipe(take(1)).subscribe(resp => {
                    this.translateService.get("CALENDARS.EVENT_MOVE_TO_MSG", {
                        destinationFolder: destinationFolder.name
                    }).pipe(take(1)).subscribe((text: string) => {
                        this.toastService.show(text);
                    });
                    this.selectedItems.map(item => {
                        this.recoverItem.splice(this.recoverItem.indexOf(item), 1);
                    });
                    this.selectedItems = [];
                    this.calendarRepository.getAllEvents();
                }, error => {
                    this.toastService.showPlainMessage(error);
                });
            }
        });
    }
}
