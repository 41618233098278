
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<h1 mat-dialog-title>{{ data.titleKey | translate:data?.titleParam }}</h1>
<div mat-dialog-content>
    <p class="action-{{ data.action }}" [innerHTML]="data.contentKey | translate: data?.contentParam"></p>
</div>
<div mat-dialog-actions>
    <button class="cancel-btn" mat-button (click)="cancel()">{{ (data.cancelKey || 'CANCEL') | translate }}</button>
    <button class="ok-btn" mat-button (click)="ok()" cdkFocusInitial>{{ data.actionKey | translate }}</button>
</div>