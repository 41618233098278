
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ViewChild, NgZone } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { take, debounceTime, skip, takeUntil } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import * as _ from "lodash";
import { FlatTreeControl } from "@angular/cdk/tree";
import { of as observableOf, Observable, Subject } from "rxjs";
import { FormControl } from "@angular/forms";
import { Utils } from "src/app/common";
import { SelectionModel } from "@angular/cdk/collections";
import { CreateCalendarFolderSuccess } from "src/app/calendar/store/actions";
import { RootState } from "src/app/reducers";
import { CalendarRootState } from "src/app/calendar/store/selectors";
import { Store } from "@ngrx/store";
import { FolderLink } from "src/app/common/models/mail-folder.model";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";

@Component({
    selector: "vp-find-share-dialog",
    templateUrl: "./find-share-dialog.component.html"
})
export class FindShareDialogComponent implements OnInit, OnDestroy {
    type: string = "";
    allShare: any[] = [];
    filterdShare: any[] = [];
    calendarTreeControl: FlatTreeControl<any>;
    calendarTreeFlattener: MatTreeFlattener<any, any>;
    calendarDataSource: MatTreeFlatDataSource<any, any>;
    searchText: string = "";
    searchShare: string = "";
    searchShareTypeSelection: string = "appointment";
    searchFromTreeInput = new FormControl();
    searchFromEmail = new FormControl();
    searchedFromUsers: any[] = [];
    searchedToUsers: any[] = [];
    filteredFromEmails: string[];
    filteredToEmails: string[];
    checklistSelection = new SelectionModel<any>(true);
    private isAlive$ = new Subject<boolean>();
    @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;

    constructor(
        public dialogRef: MatDialogRef<FindShareDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private commonService: CommonService,
        private toastService: ToastService,
        private store: Store<CalendarRootState | RootState>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone
    ) {
        this.type = this.data.type;
        this.calendarTreeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.calendarTreeControl = new FlatTreeControl<any>(this._getLevel, this._isExpandable);
        this.calendarDataSource = new MatTreeFlatDataSource(this.calendarTreeControl, this.calendarTreeFlattener);
    }

    hasNestedChild = (_data: number, nodeData: any) => (nodeData.children && nodeData.children.length > 0);
    transformer = (node: any, level: number) => {
        const flat = node as any;
        flat.level = level;
        flat.expandable = !!node.children;
        return flat;
    }
    private _getLevel = (node: any) => node.level;

    private _isExpandable = (node: any) => node.expandable;

    private _getChildren(node: any): Observable<any[]> {
        const folders = node.children || [];
        return observableOf(folders);
    }

    hasChild = (_data: number, _nodeData: any) => _nodeData.expandable;


    ngOnInit() {
        const request = {
            "GetShareInfoRequest": {
                "@": {
                    xmlns: "urn:zimbraAccount"
                },
                includeSelf: 0
            }
        };
        this.batchRequest(request);

        this.searchFromTreeInput.valueChanges.pipe(debounceTime(100), skip(1)).subscribe(value => {
            this.searchText = value;
            this.filterFromTextInput(value);
        });
        this.searchFromEmail.valueChanges.pipe(debounceTime(100), skip(1)).subscribe(value => {
            this.searchShare = value;
            if (Utils.isJson(value)) {
                return;
            }
            this.loadEmailSuggestion(value, true);
        });

        this.broadcaster.on<any>(BroadcastKeys.HIDE_FIND_SHARE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.complete();
        this.isAlive$.next(false);
    }

    close(): void {
        this.dialogRef.close();
    }

    closeDialog(closeType: string): void {
        this.close();
    }

    getFilterDate(filterData: any): any {
        const result = _(filterData)
            .groupBy("ownerId")
            .map((v, ownerId) => ({
                folderPath: _.map(v)[0].ownerName || _.map(v)[0].ownerEmail,
                children: _.map(v)
            })).value();
        return result;
    }

    setToTree(filterItem: any): void {
        this.clearCheckListSelection();
        if (filterItem) {
            this.calendarDataSource.data = filterItem;
            this.calendarTreeControl.expandAll();
        }
    }

    searchFromTree(): void {
        this.filterFromTextInput(this.searchText);
    }

    searchResultRequest(): void {
        setTimeout(() => {
            if (this.autocompleteTrigger.openPanel) {
                this.autocompleteTrigger.closePanel();
            }
        }, 1);
        const owner = [{
            "@": {
                xmlns: "urn:zimbraAccount"
            },
            _jsns: "urn:zimbraAccount",
            "includeSelf": 0,
            "owner": {
                "@": {
                    "by": "name"
                },
                "#": this.searchShare
            }
        }];
        const request = {
            GetShareInfoRequest: owner
        };
        this.batchRequest(request);
    }

    filterFromTextInput(value: string): void {
        if (this.type === "appointment") {
            const filterItems = this.allShare.filter(
                item => item.view === "appointment" && item.folderPath.toLowerCase().includes(value.toLowerCase())
            );
            if (!!filterItems && filterItems.length > 0) {
                this.filterdShare = filterItems;
                this.filterdShare = this.getFilterDate(this.filterdShare);
                this.setToTree(this.filterdShare);
            } else {
                this.setToTree([]);
            }
        }
    }

    loadEmailSuggestion(query, fromMail) {
        if (query === "" || /\s/g.test(query)) {
            return;
        }
        const queryItem = {
            name: query,
            t: "all"
        };
        this.commonService.getAllAutoCompleteList(queryItem).subscribe(
            res => {
                if (fromMail) {
                    this.searchedFromUsers = [];
                } else {
                    this.searchedToUsers = [];
                }
                if (Array.isArray(res) && res.length > 0) {
                    if (res.length > 0) {
                        res.forEach(item => {
                            this.generateTagList(item, fromMail);
                        });
                    } else if (Utils.validateEmail(query)) {
                        if (fromMail) {
                            this.searchedFromUsers.push({ title: query, name: query, email: query, image: "", checked: false });
                        } else {
                            this.searchedToUsers.push({ title: query, name: query, email: query, image: "", checked: false });
                        }
                    }
                } else if (res.$) {
                    this.generateTagList(res, fromMail);
                }
                this._filter(query, fromMail);
            },
            err => {
                if (Utils.isJson(err._body)) {
                    this.toastService.showPlainMessage(JSON.parse(err._body).msg);
                }
            }
        );
    }

    generateTagList(data, fromMail) {
        const user = {
            title: "",
            name: "",
            email: "",
            image: "",
            checked: false
        };
        user.title = data.email ? data.email.replace(/"/g, "").trim() : "";
        user.name = data.email ? data.email.substring(data.email.indexOf(""), data.email.indexOf("<") - 1) : "";
        user.name = user.name ? user.name.replace(/"/g, " ").trim() : "";
        user.email = data.email ? data.email.substring(data.email.indexOf("<") + 1, data.email.indexOf(">")) : "";
        user.email = user.email ? user.email.replace(/"/g, " ").trim() : "";
        if (user.name === "") {
            user.name = user.email;
        }
        if (user.name.length > 20) {
            user.name = user.name.substring(0, 20) + "...";
        }
        if (fromMail) {
            this.searchedFromUsers.push(user);
        } else {
            this.searchedToUsers.push(user);
        }
        return user;
    }

    private _filter(value: string, fromMail): void {
        if (value !== null) {
            const filterValue = value.toLowerCase();
            if (fromMail) {
                this.filteredFromEmails = this.searchedFromUsers.filter(user => user.email.toLowerCase().includes(filterValue));
            } else {
                this.filteredToEmails = this.searchedToUsers.filter(user => user.email.toLowerCase().includes(filterValue));
            }
        }
    }

    selected(event: any) {
        this.searchFromEmail.setValue(event.option.value.email);
    }

    batchRequest(request: any): void {
        this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
            if (!!res && res.GetShareInfoResponse) {
                if (Array.isArray(res.GetShareInfoResponse) && res.GetShareInfoResponse[0].share) {
                    this.allShare = res.GetShareInfoResponse[0].share;
                    if (this.type === "appointment") {
                        const filterItems = this.allShare.filter(item => item.view === "appointment");
                        if (!!filterItems && filterItems.length > 0) {
                            this.filterdShare = filterItems;
                            this.filterdShare = this.getFilterDate(this.filterdShare);
                            console.log("[filterData]: ", this.filterdShare);
                            this.setToTree(this.filterdShare);
                        }
                    }
                } else {
                    this.filterdShare = [];
                    this.allShare = [];
                    this.setToTree([]);
                }
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    itemSelectionToggle(node: any, ev: any): void {
        this.checklistSelection.toggle(node);
    }

    add(): void {
        const mountItem: any[] = [];
        const selectedItems = this.checklistSelection.selected;
        if (selectedItems.length === 0) {
            this.close();
        } else {
            selectedItems.map(item => {
                const name = item.ownerName || item.ownerEmail.split("@")[0];
                mountItem.push({
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    _jsns: "urn:zimbraMail",
                    "link": {
                        "l": 1,
                        "name": name + "`s " + this.getFolderNameLastIndex(item.folderPath),
                        "view": this.type,
                        "zid": item.ownerId,
                        "rid": item.folderId
                    }
                });
            });
            const request = {
                CreateMountpointRequest: mountItem
            };
            this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
                console.log("[MountPointResponse] : ", res);
                if (!!res && res.CreateMountpointResponse) {
                    if (Array.isArray(res.CreateMountpointResponse)) {
                        const folderData = res.CreateMountpointResponse;
                        folderData.map(fd => {
                            const link = fd.link;
                            link.map(f => {
                                this.store.dispatch(new CreateCalendarFolderSuccess({ folder: f as FolderLink }));
                            });
                        });
                    }
                }
                this.close();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        }
    }

    clearCheckListSelection(): void {
        this.checklistSelection.clear();
    }

    getFolderNameLastIndex(str: string): string {
        const strItem = str.substring(str.lastIndexOf("/") + 1);
        return strItem;
    }
}
