
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-delete-event-notify-user-dialog",
    templateUrl: "./delete-event-notify-user.component.html"
})
export class DeleteEventNotifyDialogComponent implements OnInit, OnDestroy {
    selectedOption: string = "no";
    eventName: string = "";
    openEvent: boolean = false;
    type = "general";
    constructor(
        private dialogRef: MatDialogRef<DeleteEventNotifyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.dialogRef.disableClose = true;
        if (this.data.type) {
            this.type = this.data.type;
        }
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    cancel(): void {
        this.close();
    }

    close(value?: string): void {
        this.dialogRef.close({ value: value });
    }

    submit(): void {
        this.close(this.selectedOption);
    }
}
