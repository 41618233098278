
<!--
  ~ VNCcalendar : A calendar which collects all important data from various sources.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mail__dialog-header desktop-header" layout="row center-center">
    <div class="header-title">
        {{ 'PREFERENCES.CUSTOMIZE_WORKING_HOURS' | translate }}
    </div>
    <div class="desktop-close">
        <button class="brand-color mat-button" (click)="close()">
            <mat-icon class="disable-select">close</mat-icon>
        </button>
    </div>
</div>
<div class="mail__dialog-body">
    <table>
        <tr>
            <td>
                <mat-checkbox [(ngModel)]="sunday">{{ 'PREFERENCES.SUN' | translate }}</mat-checkbox>
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="sundayStartTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>{{ 'CALENDARS.TO_WORD_LBL' | translate }}</td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="sundayEndTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-checkbox [(ngModel)]="monday">{{ 'PREFERENCES.MON' | translate }}</mat-checkbox>
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="mondayStartTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>{{ 'CALENDARS.TO_WORD_LBL' | translate }}</td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="mondayEndTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>

        <tr>
            <td>
                <mat-checkbox [(ngModel)]="tuesday">{{ 'PREFERENCES.TUE' | translate }}</mat-checkbox>
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="tuesdayStartTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>{{ 'CALENDARS.TO_WORD_LBL' | translate }}</td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="tuesdayEndTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>


        <tr>
            <td>
                <mat-checkbox [(ngModel)]="wednsday">{{ 'PREFERENCES.WED' | translate }}</mat-checkbox>
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="wednsdayStartTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>{{ 'CALENDARS.TO_WORD_LBL' | translate }}</td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="wednsdayEndTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>


        <tr>
            <td>
                <mat-checkbox [(ngModel)]="thursday">{{ 'PREFERENCES.THU' | translate }}</mat-checkbox>
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="thursdayStartTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>{{ 'CALENDARS.TO_WORD_LBL' | translate }}</td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="thursdayEndTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>

        <tr>
            <td>
                <mat-checkbox [(ngModel)]="friday">{{ 'PREFERENCES.FRI' | translate }}</mat-checkbox>
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="fridayStartTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>{{ 'CALENDARS.TO_WORD_LBL' | translate }}</td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="fridayEndTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>

        <tr>
            <td>
                <mat-checkbox [(ngModel)]="saturday">{{ 'PREFERENCES.SAT' | translate }}</mat-checkbox>
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="saturdayStartTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>{{ 'CALENDARS.TO_WORD_LBL' | translate }}</td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="saturdayEndTime">
                        <mat-option *ngFor="let startOption of timeOption;" [value]="startOption | date: 'hh:mm a'">
                            {{ startOption | date: 'shortTime': '': browserLang }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </table>

    <div>
        {{ 'PREFERENCES.TIME_ZONE_PREFERENCES_LBL' | translate }} : {{timezone}}
    </div>
</div>
<div class="footer">
    <button mat-button (click)="close()">{{'COMMON.CANCEL' | translate }}</button>
    <button mat-button (click)="selectWorkHours()">{{ 'COMMON.SAVE' | translate }}</button>
</div>