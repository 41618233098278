
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild, NgZone } from "@angular/core";
import { takeUntil, take} from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { MailConstants } from "src/app/common/utils/mail-constants";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { MailTag } from "src/app/common/models/mail-tag.model";
import { ToastService } from "src/app/common/providers/toast.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { RootState, getMailTags, getTagById } from "src/app/reducers";
import { CommonService } from "src/app/services/common.service";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { CalendarColorControlDialogComponent } from "src/app/calendar/color-control-component/color-control-dialog.component";
import { MailUtils } from "src/app/common/utils/mail-utils";
import { forkJoin } from "rxjs";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfigService } from "src/app/config.service";

const COLOR_LIST = [MailConstants.DEFAULT_COLOR, "blue", "cyan", "green", "purple", "red", "yellow", "pink", "gray", "orange"];
@Component({
  selector: "vp-create-tag",
  templateUrl: "./create-tag.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTagComponent implements OnDestroy {
  maxTagNameLength: number = 128;
  isRename: boolean = false;
  isConversation: boolean = false;
  tagName: string = "";
  oldTagName: string = "";
  tags: MailTag[] = [];
  tagColor: string = MailConstants.DEFAULT_COLOR;
  @ViewChild("tagNameInput", {static: false}) tagNameInput: ElementRef<HTMLInputElement>;
  private isAlive$ = new Subject<boolean>();
  assignedTags: MailTag[] = [];
  prevAssignedTags: MailTag[] = [];
  isMultiple: boolean = false;
  selectedIds: any[] = [];
  convIds: any [] = [];
  msgIds: any [] = [];
  moduleType: string = "mail";
  briefcaseFiles: any = [];
  message: any;
  convMessages: any[] = [];
  conversation: any;
  calendarEvent: any;

  constructor(
    private dialogRef: MatDialogRef<CreateTagComponent>,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private mailBroadcaster: Broadcaster,
    private ngZone: NgZone,
    private store: Store<RootState>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private mailService: CommonService,
    private calendarRepository: CalendarRepository,
    private commonService: CommonService,
    private configService: ConfigService
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    if (this.data.moduleType) {
      this.moduleType = this.data.moduleType;
    }
     this.isRename = this.data.isRename;
     this.isMultiple = this.data.isMultiple;
    if (this.isRename) {
      this.tagName = this.data.tag.name;
      this.oldTagName = this.data.tag.name;
      if (this.data.tag.rgb) {
        this.tagColor = this.data.tag.rgb.toLowerCase();
        this.changeDetectionRef.markForCheck();
      }
    }

    if (this.data.moduleType) {
      this.moduleType = this.data.moduleType;
    }

    if (this.moduleType === "calendar") {
      this.setCalendarData();
    }
    console.log("[CreateTagComponent]", this.tags, this.data);
    this.calendarRepository.getTagsList().pipe(takeUntil(this.isAlive$))
      .subscribe(tags => {
        this.tags = tags.map(tag => {
          if (tag.color && COLOR_LIST[tag.color]) {
            tag.color = COLOR_LIST[tag.color];
          }
          if (tag.rgb) {
            tag.rgb = tag.rgb.toLowerCase();
            if (tag.rgb === "#999") {
              tag.rgb = "#999999";
            }
          }
          return tag;
        });
        this.changeDetectionRef.markForCheck();
      });
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_TAG_CREATE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    setTimeout(() => {
      if (this.tagNameInput) {
        this.tagNameInput.nativeElement.focus();
      }
    }, 200);
  }

  private setAssignedTags(ids) {
    this.assignedTags = this.getTags(ids);
    this.assignedTags.forEach(tag => {
      const tagIndex = this.tags.findIndex((v) => v.name.toLowerCase() === tag.name.toLowerCase());
      if (tagIndex !== -1) {
        this.tags.splice(tagIndex, 1);
      }
    });
  }

  closeDialog(): void {
    this.close();
  }

  updateDefaultColor(event) {
    this.tagColor = event.value;
    this.changeDetectionRef.markForCheck();
  }

  changeText(event) {
    if (this.tagName.length > this.maxTagNameLength) {
      this.tagName = this.tagName.substring(0, this.maxTagNameLength);
      this.changeDetectionRef.markForCheck();
    }
  }

  addTag() {
    if (this.tagName.length < 1) {
      return;
    }
    const isInTags = this.tags.filter(tag => this.tagName.toLowerCase() === tag.name.toLowerCase()).length > 0;
    const isInAssignedTags = this.assignedTags.filter(tag => this.tagName.toLowerCase() === tag.name.toLowerCase()).length > 0;
    if (isInTags || isInAssignedTags) {
      this.toastService.show("DUPLICATE_TAG_MSG");
      return;
    } else {
      if (this.configService.useVNCdirectoryAuth) {
        this.calendarRepository.createDirectoryTag(this.tagName, this.tagColor).subscribe(tag => {
          console.log("created new Directory tag", tag);
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          setTimeout(() => {
            this.addNewTagToAssign(tag);
          }, 100);
        });
      } else {
        this.calendarRepository.createTag(this.tagName, this.tagColor).subscribe(tag => {
          this.tagName = "";
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          setTimeout(() => {
            this.addTagToAssign(tag);
          }, 100);
        });
      }
    }
  }

  addTagToAssign(tag: MailTag) {
    const isTagAdded = this.assignedTags.filter(t => t.name.toLowerCase() === tag.name.toLowerCase()).length > 0;
    if (!isTagAdded) {
      const tagIndex = this.tags.findIndex((v) => v.name.toLowerCase() === tag.name.toLowerCase());
      if (tagIndex !== -1) {
          this.tags.splice(tagIndex, 1);
      }
      this.assignedTags.push(tag);
      if (this.configService.useVNCdirectoryAuth) {
        if (!this.calendarRepository.isTagAvailableInZimbra(tag.name)) {
          this.calendarRepository.createTag(tag.name, tag.color).pipe(take(1)).subscribe(tagItem => {
            console.log("[Response of tag::]", tagItem);
          });
        }
      }
    }
    this.changeDetectionRef.markForCheck();
  }

  saveTags() {
    if (this.moduleType === "calendar") {
      this.saveCalendarTag();
    }
  }

  private addTagToObject(data: any, tag: MailTag) {
    let changed = false;
    if (data.t) {
      const t = data.t.split(",");
      if (t.indexOf(tag.id) === -1) {
        t.push(tag.id);
      }
      data.t = t.join(",");
      changed = true;
    } else {
      data.t = tag.id;
      changed = true;
    }
    if (data.tn) {
      const t = data.tn.split(",");
      if (t.indexOf(tag.name) === -1) {
        t.push(tag.name);
      }
      data.tn = t.join(",");
    } else {
      data.tn = tag.name;
    }
    if (data.m) {
      data.m.forEach(m => {
        this.addTagToObject(m, tag);
      });
    }
    return changed;
  }

  save(): void {
    if (this.tagName.length < 1) {
      return;
    }
    if (this.isTagAlreadyExist()) {
      this.toastService.show("DUPLICATE_TAG_MSG");
      return;
    }
    if (this.isRename) {
      if (this.tagName.toLowerCase() !== this.oldTagName.toLowerCase()) {
        this.calendarRepository.renameTag(this.data.tag.id, this.tagName);
      }
      const color = this.data.tag.rgb || this.data.tag.color || "";
      if (this.tagColor.toLowerCase() !== color.toLowerCase()) {
        this.calendarRepository.updateTagColor(this.data.tag.id, this.tagColor);
      }
      this.close();
    } else {
      if (this.configService.useVNCdirectoryAuth) {
        this.calendarRepository.createDirectoryTag(this.tagName, this.tagColor).subscribe(tag => {
          console.log("created new Directory tag", tag);
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          this.dialogRef.close({tag: tag});
        });
      } else {
        this.calendarRepository.createTag(this.tagName, this.tagColor).subscribe(tag => {
          console.log("created new tag", tag);
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          this.dialogRef.close({tag: tag});
        });
      }
    }
  }

  openColorDialog() {
    const changeColorDialogRef = this.matDialog.open(CalendarColorControlDialogComponent, {
      height: "auto",
      maxHeight: "70%",
      width: "99%",
      maxWidth: "244px",
      autoFocus: false,
      panelClass: "calendar-color-control-dialog",
      data: { tagColor: this.tagColor }
    });
    changeColorDialogRef.afterClosed().subscribe(operation => {
      if (operation && operation.selectedColor) {
        this.tagColor = operation.selectedColor;
      }
    });
  }

  isTagAlreadyExist() {
    if (this.tagName.toLowerCase() === this.oldTagName.toLowerCase()) {
      return false;
    }
    return this.tags.filter(tag => this.tagName.toLowerCase() === tag.name.toLowerCase()).length > 0;
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  close(): void {
    this.dialogRef.close();
  }

  getTags(ids: string): MailTag[] {
    let tags: MailTag[] = [];
    if (ids) {
      tags = ids.split(",").map(id => {
        let tag: MailTag;
        this.store.select(state => getTagById(state, id)).pipe(take(1)).subscribe(t => {
          if (!!t) {
              if (t.color && MailConstants.COLOR_LIST[t.color]) {
                  t.color = MailConstants.COLOR_LIST[t.color];
              }
              if (t.rgb) {
                  t.rgb = t.rgb.toLowerCase();
                  if (t.rgb === "#999") {
                      t.rgb = "#999999";
                  }
              }
              tag = t;
              }
          });
          return tag;
      });
      tags = _.sortBy(tags.filter(t => !!t), t => t.name.toLowerCase());
    }
    return tags;
  }

  removeTag(tag: MailTag): void {
    this.tags.push(tag);
    const tagIndex = this.assignedTags.findIndex((v) => v.name.toLowerCase() === tag.name.toLowerCase());
    if (tagIndex !== -1) {
      this.assignedTags.splice(tagIndex, 1);
    }
  }

  setCalendarData(): void {
    this.calendarEvent = this.data.calendarEvent;
    if (!Array.isArray(this.calendarEvent)) {
      this.setAssignedTags(this.calendarEvent.t);
    }
    this.prevAssignedTags = this.assignedTags.slice();
    this.changeDetectionRef.markForCheck();
  }

  getTagColor(color): string {
    const isDark = MailUtils.lightOrDark(color) === "dark";
    if (isDark) {
        return "#ffffff";
    } else {
        return "#000000";
    }
  }

  saveCalendarTag(): void {
    const removeTags: MailTag[] = [];
    const allTags: MailTag[] = [];
    const updateTags: any = [];
    const ids = Array.isArray(this.calendarEvent) ? this.calendarEvent.map(ev => ev.apptId) : this.calendarEvent.apptId;
    this.prevAssignedTags.forEach( tag => {
      const tagIndex = this.assignedTags.findIndex((v) => v.name.toLowerCase() === tag.name.toLowerCase());
      if (tagIndex === -1) {
        let body;
        body = { id: ids.toString(), op: "!tag", tn: tag.name };
        updateTags.push(this.commonService.itemAction(body));
        removeTags.push(tag);
        allTags.push(tag);
      }
    });
    let addTags: MailTag[] = [];
    addTags = this.assignedTags.filter(tag => {
      return this.prevAssignedTags.findIndex((v) => v.name.toLowerCase() === tag.name.toLowerCase()) === -1;
    });
    addTags.forEach(tag => {
      let body;
      body = { id: ids.toString(), op: "tag", tn: tag.name };
      updateTags.push(this.commonService.itemAction(body));
      allTags.push(tag);
    });
    forkJoin(updateTags).subscribe(
      (response: any) => {
        response.forEach((res: any, index) => {
        });
        this.changeDetectionRef.markForCheck();
        this.dialogRef.close({ close: true});
      });
  }

  addNewTagToAssign(tag: any) {
    const isTagAdded = this.assignedTags.filter(t => t.name.toLowerCase() === tag.name.toLowerCase()).length > 0;
    if (!isTagAdded) {
      const tagIndex = this.tags.findIndex((v) => v.name.toLowerCase() === tag.name.toLowerCase());
      if (tagIndex !== -1) {
          this.tags.splice(tagIndex, 1);
      }
      this.assignedTags.push(tag);
    }
    this.changeDetectionRef.markForCheck();
  }

}
