
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectorRef, OnInit, HostListener, NgZone, OnDestroy, AfterViewChecked } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { environment } from "../environments/environment";
import { ConfigService } from "./config.service";
import { AuthService } from "./common/providers/auth.service";
import { take, filter, distinctUntilChanged, takeUntil, combineLatest, takeWhile, sampleTime, bufferTime } from "rxjs/operators";
import { MailConstants } from "./common/utils/mail-constants";
import {
  LoginSuccess,
  LoginFailed,
  OnlineStatus,
  SetUserProfile,
  SetPollingInterval,
  SetSession,
  ChangeSwipeAction,
  SetAvailableApps,
  SetAvailableZimlets,
  SetViewBy,
  SetReadingPanel,
  SetZimbraFeatures,
  ResetLastPhotoUpdate,
  SetDomainSpecificLogo,
  SetSearchFor,
  SetIncludeShared,
  SetWaitDisallowed,
  SetUserContacts,
  DeviceReady
} from "./actions/app";
import { Router } from "@angular/router";
import { BroadcastKeys } from "./common/enums/broadcast.enum";
import * as QuillNamespace from "quill";
import { PreferenceService } from "./preference/shared/services/preference.service";
import { timer, Subject, Observable, of } from "rxjs";
import { VNCNotificationsService } from "./shared/components/notifications";
import {
  getUserProfile, getIsLoggedIn, getActionProcessingState, getOnlineStatus,
  getPollingInterval, getSession, getCurrentFolder, getViewBy, getProps, getWaitDisallowed, RootState
} from "./reducers";
import * as _ from "lodash";
import { CommonUtils } from "./common/utils/common-util";
import { ElectronService } from "./services/electron.service";
import { CommonService } from "./services/common.service";
import { DeleteMultipleMailTag } from "./actions/mail-tag.action";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { Preference } from "./preference/shared/models";
import {
  CalendarRootState,
  getCalendarEventsByIds,
  getAllCalendarEvents,
  getCalendarFolders,
  getFolderById,
  getSharedFolderById
} from "./calendar/store/selectors";
import { CalendarEvent, CalendarFolder } from "./common/models/calendar.model";
import {
  CreateCalendarEventSuccessAction,
  UpdateCalendarEventSuccessAction,
  DeleteCalendarEventSuccessAction,
  CreateCalendarFolderSuccess,
  UpdateCalendarFolderSuccess,
  DeleteCalendarFolderSuccess
} from "./calendar/store/actions";
import * as moment from "moment";
import { BreakpointObserver } from "@angular/cdk/layout";
import { CalenderUtils } from "./calendar/utils/calender-utils";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { AppService } from "./services/app.service";
import {
  AppointmentInviteReplyDialogComponent
} from "./shared/components/event-invite-operation-dialog/event-invite-operation-dialog.component";
import { DirectoryTag } from "./common/models/directory-tag.model";
import { LoadDirectoryTagsFail, LoadDirectoryTagsSuccess, LoadMoreDirectoryTagsSuccessAction } from "./actions/directory-tag.action";

const CALENDARCONSTANT: any = {
  DEFAULT_FOLDER_COLOR: 1,
  COLOR_CODES: [
    null,
    "BLUE",
    "CYAN",
    "GREEN",
    "PURPLE",
    "RED",
    "YELLOW",
    "PINK",
    "GRAY",
    "ORANGE"
  ],
  RGB_CODES: [
    "#999999", // GRAY
    "#42a5f6", // BLUE
    "#00BCD4", // CYAN
    "#66bb6a", // GREEN
    "#5B69C3", // PURPLE
    "#F1524A", // RED
    "#ef6c00", // YELLOW
    "#E91E63", // PINK
    "#999999", // GRAY
    "#ffa500"  // ORANGE
  ]
};

@Component({
  selector: "vp-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})

export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  title = "VNCmail";
  isOnCordova = environment.isCordova;
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  isActionInProgress: boolean = false;
  isLoggedIn: boolean = false;
  options: any = {
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 60,
    maxStack: 5,
    showProgressBar: false,
    pauseOnHover: true,
    preventDuplicates: true,
    preventLastDuplicates: "visible",
    rtl: false,
    animate: "scale",
    position: ["right", "top"]
  };
  noOpPolling$: any;
  isOnline: {};
  interval: number;
  currentUser: any;
  private isAlive$ = new Subject<boolean>();
  checkAuthenticationInterval: any;
  // allFolders: MailFolder[] = [];
  currentFolderId: any;
  currentView = !!localStorage.getItem("currentView") ? localStorage.getItem("currentView") : "conversation";
  isUpdatedConv: boolean = false;
  isFirebaseSetUpCompleted = false;
  avatarPolling$: any;
  noOpRunning: boolean;
  lastTimeCallNoOp: number;
  nextNoOpTimeout: any;
  isMobileScreen: boolean = false;
  currentURL: string = "";

  constructor(
    private auth: AuthService,
    private changeDetectionRef: ChangeDetectorRef,
    public configService: ConfigService,
    private broadcaster: Broadcaster,
    private router: Router,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private preferenceService: PreferenceService,
    private notificationsService: VNCNotificationsService,
    private store: Store<RootState | CalendarRootState>,
    private matIconRegistry: MatIconRegistry,
    private electronService: ElectronService,
    private matDialog: MatDialog,
    private commonService: CommonService,
    private breakpointObserver: BreakpointObserver,
    private appService: AppService
  ) {
    console.log("[AppComponent] constructor", new Date());
    this.matIconRegistry.registerFontClassAlias("mdi");
    if (localStorage.getItem("federatedApps") !== null) {
      const federatedApps = JSON.parse(localStorage.getItem("federatedApps"));
      this.store.dispatch(new SetAvailableApps(federatedApps));
    }
    if (localStorage.getItem("getSearchFor") !== null) {
      try {
        this.store.dispatch(new SetSearchFor(localStorage.getItem("getSearchFor")));
      } catch (err) {
      }
    }
    let includeSharedItems = true;
    if (this.configService.prefs && typeof this.configService.prefs.zimbraPrefIncludeSharedItemsInSearch !== "undefined") {
      includeSharedItems = this.configService.prefs.zimbraPrefIncludeSharedItemsInSearch === "TRUE";
    }
    if (localStorage.getItem("includeSharedItems") !== null) {
      try {
        includeSharedItems = localStorage.getItem("includeSharedItems") === "true";
      } catch (err) {
      }
    }
    this.store.dispatch(new SetIncludeShared(includeSharedItems));
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    }
    this.checkInternetConnection();
    this.setupOfflineData();
    this.handlePollingTimeout();
    this.store.select(getUserProfile).pipe(filter(v => !!v)).subscribe(res => {
      if (!!res) {
        this.currentUser = res;
      }
    });

    this.store.select(getViewBy).pipe(takeUntil(this.isAlive$)).subscribe(value => {
      if (this.currentView !== value) {
        this.currentView = value;
      }
    });

    this.store.pipe(select(getOnlineStatus), filter(v => !v), takeUntil(this.isAlive$)).subscribe(val => {
      console.log("[getOnlineStatus]", val);
      if (this.noOpPolling$) {
        console.log("[handlePolling] unsubscribe", new Date());
        this.noOpPolling$.unsubscribe();
      }
      localStorage.setItem("lastTimeOnline", new Date().getTime().toString());
    });

    const isOnline$ = this.store.pipe(select(getOnlineStatus), filter(v => !!v), takeUntil(this.isAlive$));
    const isLoggedIn$ = this.store.pipe(select(getIsLoggedIn), filter(v => !!v), takeUntil(this.isAlive$));
    isOnline$.pipe(combineLatest(isLoggedIn$)).subscribe(res => {
      console.log("Logged in and online", res);
      this.isLoggedIn = true;
      this.isOnline = true;
      this.noOpRunning = false;
      this.changeDetectionRef.markForCheck();
      this.initData();
      this.handlePolling();
      localStorage.removeItem("lastTimeOnline");
      try {
        let pendingElectronOps = this.electronService.getFromStorage("openUri");
        console.log("found pendingElectronOps: ", pendingElectronOps);
        if (!!pendingElectronOps) {
          if (pendingElectronOps.startsWith("cal-ev-create")) {
            this.router.navigate(["/calendar"]);
            const mailAddress = pendingElectronOps.split("?to=")[1];
            setTimeout(() => {
              this.electronService.deleteFromStorage("openUri");
              this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_ATEENDEE", {
                to: mailAddress
              });
            }, 2000);
          }
          if (pendingElectronOps.startsWith("main/calendar")) {
            this.router.navigate(["/calendar"]);
          }
        }
      } catch (e) {
        console.log("error: ", e);
      }
    });

    this.store.pipe(select(getIsLoggedIn)).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if (!isLoggedIn && this.noOpPolling$) {
        console.log("[handlePolling] unsubscribe", new Date());
        this.noOpPolling$.unsubscribe();
      }
      this.changeDetectionRef.markForCheck();
    });

    this.store.pipe(select(getCurrentFolder)).subscribe(id => {
      console.log("[getCurrentFolder]", id);
      this.currentFolderId = id;
      this.changeDetectionRef.markForCheck();
    });

    this.store.select(getActionProcessingState).subscribe(actionInProgress => {
      setTimeout(() => {
        this.isActionInProgress = actionInProgress;
        this.changeDetectionRef.markForCheck();
      }, 200);
    });
    let token, serverURL;
    if (this.electronService.isElectron) {
      token = this.electronService.getFromStorage("token");
      serverURL = this.electronService.getFromStorage("serverURL");
      if (serverURL) {
        localStorage.setItem("serverURL", serverURL);
      }
    } else {
      token = localStorage.getItem("token");
      serverURL = localStorage.getItem("serverURL");
    }
    if (this.isCordovaOrElectron && !token && !!serverURL) {
      this.isLoggedIn = false;
      this.configService.loginIframe();
      return;
    } else if (!!token) {
      this.store.dispatch(new LoginSuccess());
      this.isLoggedIn = true;
      this.changeDetectionRef.markForCheck();
    }

    this.store.pipe(select(getOnlineStatus), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(isOnline => {
      this.isOnline = isOnline;
      if (!isOnline && this.noOpPolling$) {
        console.log("[handlePolling] unsubscribe", new Date());
        this.noOpPolling$.unsubscribe();
      }
      if (!isOnline && this.avatarPolling$) {
        console.log("[handlePolling] unsubscribe", new Date());
        this.avatarPolling$.unsubscribe();
      }
    });

    this.router.events.pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.currentURL = this.router.routerState.snapshot.url;
      this.changeDetectionRef.markForCheck();
    });

    if (this.electronService.ipcRenderer) {
      this.electronService.ipcRenderer.on("openUri", (event, message) => {
        console.log("[app.component][openUri] message: ", event, message);
        try {
          if (message.startsWith("cal-ev-create")) {
            this.router.navigate(["/calendar"]);
            const mailAddress = message.split("?to=")[1];
            setTimeout(() => {
              this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_ATEENDEE", {
                to: mailAddress
              });
            }, 2000);
          }
          if (message.startsWith("main/calendar")) {
            this.router.navigate(["/calendar"]);
          }
        } catch (e) {
          console.error("error processing openUri: ", e);
        }
      });
      this.electronService.ipcRenderer.on("powerMonitor", (event, message) => {
        console.log("[powerMonitor] message: ", message);
        if ((message === "resume") || (message === "unlock")) {
          this.broadcaster.broadcast(MailConstants.REFRESH_BROADCAST);
        }
      });
    }


    this.setSwipeConfig();
  }

  private setSwipeConfig() {
    const swipeAction = this.electronService.isElectron
      ? this.electronService.getFromStorage("swipeAction")
      : localStorage.getItem("swipeAction");
    if (swipeAction) {
      const parsedSwipeAction = JSON.parse(swipeAction);
      this.store.dispatch(new ChangeSwipeAction(parsedSwipeAction));
    }
  }

  private setupOfflineData(): void {
    const preferences = this.electronService.isElectron
      ? this.electronService.getFromStorage("preferences")
      : localStorage.getItem("preferences");
    const profileUser = localStorage.getItem("profileUser");
    if (!!preferences) {
      const prefs = typeof preferences === "string" ? JSON.parse(preferences) : preferences;
      this.configService.setPreferences(prefs);
      this.getPollingInterval();
    }
    if (!!profileUser) {
      this.currentUser = CommonUtils.parseUserProfile(profileUser);
    }
  }

  private handlePollingTimeout(): void {
    this.store.select(getPollingInterval)
      .pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(interval => {
        setTimeout(() => {
          this.interval = interval || MailConstants.MIN_POLLING_INTERVAL;
          this.handlePolling();
        }, 1000);
      });
  }

  private handlePolling(): void {
    console.log("[handlePolling]", this.isOnline, this.isLoggedIn);
    if (!this.isOnline || !this.isLoggedIn) {
      return;
    }
    if (!this.interval || this.interval < MailConstants.MIN_POLLING_INTERVAL) {
      this.interval = MailConstants.MIN_POLLING_INTERVAL;
    }
    if (this.noOpPolling$) {
      this.noOpPolling$.unsubscribe();
    }
    if (this.avatarPolling$) {
      this.avatarPolling$.unsubscribe();
    }
    console.log("[handlePolling] start", this.interval, new Date());
    this.noOpPolling$ = timer(0, this.interval).pipe(takeUntil(this.isAlive$)).subscribe(val => {
      console.log("[handlePolling] call noOp", new Date());
      this.noOp();
    });
    this.avatarPolling$ = timer(0, this.configService.AVATAR_SYNC_INTERVAL).pipe(takeUntil(this.isAlive$)).subscribe(val => {
      if (this.isOnline) {
        this.store.dispatch(new ResetLastPhotoUpdate());
      }
    });
  }

  private getFromStorage(key): string {
    return localStorage.getItem(key);
  }

  private removeFromStorage(key) {
    localStorage.removeItem(key);
  }

  private syncData(force?: boolean): void {
    console.log("[syncData]", force, new Date());
    console.log("[syncData] lastTokenGenerationTime", this.getFromStorage("lastTokenGenerationTime"));
    console.log("[syncData] lastTimeInBackground", this.getFromStorage("lastTimeInBackground"));
    console.log("[syncData] lastTimeOnline", this.getFromStorage("lastTimeInBackground"));
    if (!!this.getFromStorage("lastTimeOnline") || !!this.getFromStorage("lastTimeInBackground")) {
      let lastTime = new Date().getTime();
      if (!!this.getFromStorage("lastTimeInBackground")) {
        lastTime = +this.getFromStorage("lastTimeInBackground");
      } else if (!!this.getFromStorage("lastTimeOnline")) {
        lastTime = +this.getFromStorage("lastTimeOnline");
      }
      const totalSeconds = new Date().getTime() - (lastTime);
      console.log("[syncData] lastTimeOnline totalSeconds from now", totalSeconds);
      if (totalSeconds > MailConstants.TIME_TO_REFRESH_ALL) {
        console.log("[syncData] REFRESH_ALL");
        this.noOp();
      } else if (totalSeconds > MailConstants.TIME_TO_REFRESH_ONE_PAGE || force) {
        console.log("[syncData] REFRESH_ONE_PAGE");
        this.noOp();
      }
    }
  }


  private initData(): void {
    this.store.select(getOnlineStatus).pipe(filter(v => !!v), take(1)).subscribe(v => {
      this.appService.getProperties().subscribe(props => {
        console.log("[getProperties]", props);
      });
      this.commonService.getPreferences().subscribe(prefs => {
        const config: any = {};
        prefs.forEach(pref => {
          config[pref.key] = pref.value;
        });
        this.applyTheme(prefs);
        this.setLanguage(prefs);
        this.setZimbraFeaturesList();
        this.configService.setPreferences(config);
        this.setUIWithConfig(config);
        this.getPollingInterval();
        this.setupEmoji();
      });
      this.appService.getMyProducts().pipe(take(1)).subscribe(data => {
        if (data && data.products) {
          localStorage.setItem("federatedApps", JSON.stringify(data.products));
          this.store.dispatch(new SetAvailableApps(data.products));
        }
      });
    });
  }

  private setUIWithConfig(config): void {
    console.log("[setUIWithConfig]", config);
    if (config["zimbraPrefGroupMailBy"]) {
      localStorage.setItem("currentView", config["zimbraPrefGroupMailBy"]);
      this.store.dispatch(new SetViewBy(config["zimbraPrefGroupMailBy"]));
    }
    if (config["zimbraPrefConversationOrder"]) {
      localStorage.setItem("expandConversations", config["zimbraPrefConversationOrder"]);
    }
    if (config["zimbraPrefSortOrder"]) {
      const sortOrder = config["zimbraPrefSortOrder"].split(",BDLV")[0];
      if (!!sortOrder && sortOrder.split(":").length === 2) {
        const sortOrderResult = sortOrder.split(":")[1];
        const sort = sortOrderResult.split(/Asc|Desc/i)[0];
        let order = "desc";
        if (/Asc|Desc/i.test(sortOrderResult)) {
          order = sortOrderResult.match(/Asc|Desc/i)[0];
        }
        if (sort === "date") {
          localStorage.setItem("groupBy", "date");
        } else if (sort === "size") {
          localStorage.setItem("groupBy", "size");
        } else if (["date", "size", "name"].indexOf(sort) === -1 || (sort === "name" && this.currentView === "conversation")) {
          localStorage.setItem("groupBy", "");
        }
        console.log("[setUIWithConfig]", sortOrderResult, sort, order);
        localStorage.setItem("sortBy", sort);
        localStorage.setItem("sortOrder", order);
      }
    }
    if (config["zimbraPrefReadingPaneLocation"]) {
      localStorage.setItem("previewPanel", config["zimbraPrefReadingPaneLocation"]);
      this.store.dispatch(new SetReadingPanel(config["zimbraPrefReadingPaneLocation"]));
    }
  }

  noOp(withTimeout?: boolean): void {
    let isOnline = false;
    this.store.select(getOnlineStatus).pipe(take(1)).subscribe(v => {
      isOnline = v;
    });
    console.log("[noOp] isOnline", isOnline);
    if (!isOnline) {
      return;
    }
    if (!!this.getFromStorage("lastTokenGenerationTime")) {
      const lastTime = +this.getFromStorage("lastTokenGenerationTime");
      const lastTokenInSeconds = new Date().getTime() - lastTime;
      console.log("[syncData] lastTokenGenerationTime from now", lastTokenInSeconds);
      if (lastTokenInSeconds > MailConstants.TIME_TO_REFRESH_TOKEN) {
        console.log("[syncData] TIME_TO_REFRESH_TOKEN");
        this.broadcaster.broadcast("generateNewToken", new Date().getMinutes());
      }
    }
    const now = new Date().getTime();
    let shouldCall = true;
    let waitDisallowed = false;
    console.log("[noOp] lastTimeCallNoOp", this.lastTimeCallNoOp);
    if (this.lastTimeCallNoOp) {
      const lastTime = (now - this.lastTimeCallNoOp) / 1000;
      console.log(`[noOp] Called ${lastTime}s ago`);
      if (lastTime < MailConstants.MIN_INTERVAL) {
        shouldCall = false;
        if (this.nextNoOpTimeout) {
          clearTimeout(this.nextNoOpTimeout);
        }
        this.nextNoOpTimeout = setTimeout(() => {
          this.noOp();
        }, (MailConstants.MIN_INTERVAL - lastTime) * 1000);
      }
    }
    if (this.noOpRunning || !shouldCall) {
      return;
    }
    this.noOpRunning = true;
    this.store.pipe(select(getSession), take(1)).subscribe(session => {
      console.log("[getSession]", session);
      const body: any = {};
      body.session = {
        id: session.id,
      };
      body.seq = session.seq;
      this.store.select(getWaitDisallowed).pipe(take(1)).subscribe(val => {
        console.log("[noOp] getWaitDisallowed", val);
        waitDisallowed = val;
        if (!waitDisallowed) {
          body.wait = 1;
          body.timeout = 60;
          body.limitToOneBlocked = 1;
          withTimeout = true;
        }
      });
      this.commonService.noOpRequest(body).subscribe(res => {
        console.log("[noOpRequest]", res);
        this.noOpRunning = false;
        this.lastTimeCallNoOp = new Date().getTime();
        if (res.session) {
          const newSession: any = {
            id: res.session.id
          };
          if (res.notify) {
            if (!!res.notify["$"]) {
              newSession.seq = res.notify.seq;
            }
            console.log("[noOpRequest]", res.notify);
            const createdList = res.notify[0].created || {};
            const modifiedList = res.notify[0].modified || {};
            const deletedList = res.notify[0].deleted;
            console.log("[noOpRequest]", createdList, modifiedList);
            if (modifiedList.mbx && modifiedList.mbx[0].s) {
              this.configService.setUsedQuota(modifiedList.mbx[0].s);
            }
            this.processDeletedData(deletedList);
            this.processModifiedData(modifiedList, deletedList);
            this.processCreatedData(createdList, modifiedList);
          }
          if (res.session && session.id !== res.session.id || (newSession.seq && newSession.seq !== session.seq)) {
            session = { ...session, ...newSession };
            console.log("[SetSession]", session);
            this.store.dispatch(new SetSession(session));
          }
        }
        if (res.NoOpResponse && res.NoOpResponse.waitDisallowed !== undefined) {
          this.store.dispatch(new SetWaitDisallowed(res.NoOpResponse.waitDisallowed));
        }
        if (!waitDisallowed) {
          this.noOp(true);
        }
      }, err => {
        console.log("[noOpRequest]", err);
        this.noOpRunning = false;
        this.lastTimeCallNoOp = new Date().getTime();
        if (!waitDisallowed) {
          this.noOp(true);
        }
      });
    });
  }

  private processDeletedData(deletedList: any): void {
    if (deletedList && this.router.url.startsWith("/mail")) {
      if (deletedList.id) {
        const ids = deletedList.id.split(",");
        console.log("[processDeletedData]", ids);
        this.store.dispatch(new DeleteMultipleMailTag(ids));
      }
    }

    if (deletedList && this.router.url.startsWith("/calendar")) {
      if (deletedList.id) {
        const ids = deletedList.id.split(",");
        console.log("[processCalendarDeletedData]", ids);
        this.store.dispatch(new DeleteMultipleMailTag(ids));
        let flatFolders: CalendarFolder [] = [];
        this.store.select(getCalendarFolders).pipe(take(1)).subscribe(fold => {
          flatFolders = fold;
        });
        ids.forEach(id => {
          const f = CalenderUtils.getCalendarFolderById(flatFolders, id);
          if (f) {
            this.removeChildFromParent(f);
          }
        });
      }
    }
  }

  private processModifiedData(modifiedList: any, deletedList?: any): void {
    if (this.router.url.startsWith("/calendar") && modifiedList.appt) {
      const modifiedAppointmentList = modifiedList.appt;
      modifiedAppointmentList.map(app => {
        if (app.inv) {
          const component = app.inv[0].comp[0];
          const ridZ = component.allDay === true ? component.s[0].d : this.getAppointmentRidz(component.s[0].d);
          const eventId =  app.id + "_" + ridZ;
          let appts: CalendarEvent[] = [];
          this.store.select(getAllCalendarEvents).pipe(take(1)).subscribe((calendarEvents: CalendarEvent[]) => {
            appts = calendarEvents;
          });
          const apt = appts.filter(ap => ap.id === app.id)[0];
          if (!!apt && apt !== null) {
            console.log("[Modified Appointment] : ", app);
            const calEvent = this.getCalenderEvent(app);
            this.store.dispatch(new DeleteCalendarEventSuccessAction({ event: apt }));
            this.store.dispatch(new CreateCalendarEventSuccessAction({ event: calEvent }));
          }
        } else if (app.l === "3") {
          this.store.select(getAllCalendarEvents).pipe(take(1)).subscribe((calendarEvents: CalendarEvent[]) => {
            calendarEvents.map( apt => {
              if (apt.id === app.id) {
                console.log("[Deleted Appointment] : ", apt);
                this.store.dispatch(new DeleteCalendarEventSuccessAction({ event: apt }));
              }
            });
          });
        }
      });
    }
    if (modifiedList.folder && this.router.url.startsWith("/calendar")) {
      const folders = Array.isArray(modifiedList.folder) ? modifiedList.folder : [modifiedList.folder];
      console.log("[processModifiedData] folders", folders);
      let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(fold => {
        flatFolders = fold;
      });
      folders.filter(f => Object.keys(f).length > 2 && f.id !== "1").forEach(folder => {
        const updatedFolder = folder;
        if (updatedFolder.id.indexOf(":") === -1) {
          const f = CalenderUtils.getCalendarFolderById(flatFolders, updatedFolder.id);
          console.log("[processModifiedData]getFolderById", updatedFolder, f);
            if (!!f) {
              if (updatedFolder.l && f.l !== updatedFolder.l) {
                if (f.l === "1") {
                  this.store.dispatch(new DeleteCalendarFolderSuccess({folder: f}));
                  console.log("[processModifiedData][DeleteMailFolderSuccess]");
                } else {
                  this.deleteCalendarFolderFromParent(f);
                }
                const newFolder = {...f, ...updatedFolder};
                this.moveToNewCalendarParent(newFolder, updatedFolder.l);
              } else {
                const newFolder = {...f, ...updatedFolder};
                this.updateCalendarFolder(newFolder);
              }
              this.updateCalendarEventColor(f);
            } else {
              this.updateCalendarFolder(folder);
              this.updateCalendarEventColor(folder);
            }
        } else {
          this.store.pipe(select(state => getSharedFolderById(state, updatedFolder.id)), take(1)).subscribe(f => {
            console.log("[getSharedFolderById]", updatedFolder.id, f);
            if (!!f) {
              this.store.dispatch(new UpdateCalendarFolderSuccess({ id: updatedFolder.id, changes: updatedFolder }));
            }
          });
        }
      });
    }

    if (modifiedList.link && this.router.url.startsWith("/calendar")) {
      const folders = Array.isArray(modifiedList.link) ? modifiedList.link : [modifiedList.link];
      console.log("[processModified Share Calendar] folders", folders);
      let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(fold => {
        flatFolders = fold;
      });
      folders.filter(f => Object.keys(f).length > 2 && f.id !== "1").forEach(folder => {
        const updatedFolder = folder;
        if (updatedFolder.id.indexOf(":") === -1) {
          const f = CalenderUtils.getCalendarFolderById(flatFolders, updatedFolder.id);
          console.log("[processModifiedData]getFolderById", updatedFolder, f);
            if (!!f) {
              if (updatedFolder.l && f.l !== updatedFolder.l) {
                if (f.l === "1") {
                  this.store.dispatch(new DeleteCalendarFolderSuccess({folder: f}));
                  console.log("[processModifiedData][DeleteMailFolderSuccess]");
                } else {
                  this.deleteCalendarFolderFromParent(f);
                }
                const newFolder = {...f, ...updatedFolder};
                this.moveToNewCalendarParent(newFolder, updatedFolder.l);
              } else {
                const newFolder = {...f, ...updatedFolder};
                this.updateCalendarFolder(newFolder);
              }
              this.updateCalendarEventColor(f);
            } else {
              this.updateCalendarFolder(folder);
              this.updateCalendarEventColor(folder);
            }
        } else {
          this.store.pipe(select(state => getSharedFolderById(state, updatedFolder.id)), take(1)).subscribe(f => {
            console.log("[getSharedFolderById]", updatedFolder.id, f);
            if (!!f) {
              this.store.dispatch(new UpdateCalendarFolderSuccess({ id: updatedFolder.id, changes: updatedFolder }));
            }
          });
        }
      });
    }

  }

  private processCreatedData(createdList: any, modifiedList?: any): void {
    if (this.router.url.startsWith("/calendar") && createdList.appt) {
      const createAppointmentList = createdList.appt;
      createAppointmentList.map(app => {
        const component = app.inv[0].comp[0];
        const ridZ = this.getAppointmentRidz(component.s[0].d);
        const eventId =  app.id + "_" + ridZ;
        this.store.select(state => getCalendarEventsByIds(state, [eventId])).pipe(take(1)).subscribe(events => {
          const appointmentItem = events[0];
          if (appointmentItem === undefined) {
            const calEvent: any = this.getCalenderEvent(app);
            if (app.nextAlarm) {
              const componentItem = app.inv[0].comp[0];
              calEvent.alarmData = [{
                  "nextAlarm": app.nextAlarm,
                  "invId": app.id,
                  "compNum": 0,
                  "name": componentItem.name,
                  "loc": "",
                  "alarm": [
                    {
                      "action": "DISPLAY",
                      "trigger": [
                        {
                          "rel": [
                            {
                              "neg": true,
                              "m": 5,
                              "related": "START"
                            }
                          ]
                        }
                      ],
                      "desc": [
                        {}
                      ]
                    }
                  ]
                }];
            }
            console.log("[NoOp][createList][Create Appointment] : ", calEvent);
            this.store.dispatch(new CreateCalendarEventSuccessAction({ event: calEvent }));
          }
        });
      });
    }

    if (this.router.url.startsWith("/calendar") && createdList.folder) {
      const folders = Array.isArray(createdList.folder) ? createdList.folder : [createdList.folder];
      folders.forEach(folder => {
        folder = folder;
        this.store.pipe(select(state => getFolderById(state, folder.id)), take(1)).subscribe(f => {
          if (!f) {
            if (folder.l.toString() !== MailConstants.ROOT_MAIL_FOLDER_ID) {
              this.addChildCalendarFolderToParent(folder);
            } else {
              this.store.dispatch(new CreateCalendarFolderSuccess({ folder: folder as CalendarFolder }));
            }
          }
        });
      });
      console.log("[createdList] folders", folders);
    }
  }


  private zimbraNotification(): void {
    if (this.configService.prefs.zimbraPrefMailFlashTitle === "TRUE") {
      this.notificationsService.flashTitle("NEW_MESSAGE");
    }
    if (this.configService.prefs.zimbraPrefMailSoundsEnabled === "TRUE") {
      this.notificationsService.playReceiveMessage();
    }
  }

  private getPollingInterval(): void {
    const newInterval = CommonUtils.getPollingInterval(
      this.configService.prefs.zimbraPrefMailPollingInterval, MailConstants.MIN_POLLING_INTERVAL
    );
    this.store.dispatch(new SetPollingInterval(newInterval));
  }

  private deviceReady(): void {
    this.handleBackButton();
    document.addEventListener("online", this.handleConnectionChange.bind(this), false);
    document.addEventListener("offline", this.handleConnectionChange.bind(this), false);
    if (environment.isCordova && typeof navigator !== "undefined" && navigator.splashscreen) {
      navigator.splashscreen.hide();
      console.log("[AppComponent] hide splashscreen", new Date());
    }

    document.addEventListener("pause", () => {
      window.appInBackground = true;
      if (this.noOpPolling$) {
        console.log("[handlePolling] unsubscribe", new Date());
        this.noOpPolling$.unsubscribe();
      }
      if (this.avatarPolling$) {
        console.log("[handlePolling] unsubscribe avatarPolling$", new Date());
        this.avatarPolling$.unsubscribe();
      }
      localStorage.setItem("lastTimeInBackground", new Date().getTime().toString());
      if (environment.isCordova) {
        if (cordova.plugin.AndroidCalendarWidgetPlugin) {
          console.warn("[AppComponent][onDeviceReady] AndroidMailWidgetPlugin updateWidget");
          cordova.plugin.AndroidCalendarWidgetPlugin.updateWidget();
        } else {
          console.warn("[AppComponent][onDeviceReady] AndroidMailWidgetPlugin is null");
        }
      }
    });

    document.addEventListener("resume", () => {
      window.appInBackground = false;
      this.syncData();
      this.handlePolling();
    });

    this.broadcaster.on<any>(BroadcastKeys.HANDLE_BACK_BUTTON).subscribe(val => {
      this.changeDetectionRef.detectChanges();
    });
    if (environment.isCordova) {
      if (typeof Keyboard !== "undefined") {
        try {
          Keyboard.shrinkView(true);
        } catch (ex) {

        }
      }
    }
    if (CommonUtils.isOnAndroid()) {
      CommonUtils.requestPermissions();
    }

    this.store.select(getProps).pipe(takeWhile(() => !this.isFirebaseSetUpCompleted), filter(v => !!v)).subscribe(v => {
      console.log("[getProps]:", this.isFirebaseSetUpCompleted);
      this.firebaseSetup();
    });
    this.store.dispatch(new DeviceReady(true));
    this.deepLinksHandlerSetup();
    StatusBar.show();
    if (CommonUtils.isOnIOS()) {
      const currentName = localStorage.getItem("theme") || environment.theme;
      if (currentName === "green") {
        StatusBar.backgroundColorByHexString("#20ae80");
      } else {
        StatusBar.backgroundColorByHexString("#317bbc");
      }
    }
    if (universalLinks) {
      console.log("[universalLinks][launchedAppFromLink]");
      universalLinks.subscribe("launchedAppFromLink", this.onApplicationDidLaunchFromLink.bind(this));
    }
  }

  private checkInternetConnection() {
    this.store.dispatch(new OnlineStatus(navigator.onLine));
    if (!environment.isCordova) {
      window.addEventListener("online", this.handleConnectionChange.bind(this));
      window.addEventListener("offline", this.handleConnectionChange.bind(this));
    }
  }

  private handleConnectionChange(event): void {
    console.log("NETWORK connection status is now: ", event);
    if (!navigator.onLine) {
      console.log("set lastTimeOnline", event);
      localStorage.setItem("lastTimeOnline", new Date().getTime().toString());
    }
    this.store.dispatch(new OnlineStatus(navigator.onLine));
  }

  ngOnInit() {
    console.log("[AppComponent] ngOnInit", this.configService.selectedServer, environment, new Date());
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.registerSignature();
    if (!this.configService.selectedServer) {
      this.changeDetectionRef.markForCheck();
      return;
    }
    this.store.pipe(select(getOnlineStatus), filter(v => !!v), take(1)).subscribe(isOnline => {
      this.isLoggedIn = isOnline;
      this.loadConfig();
      this.getZimbraUser();
    });

    const user = localStorage.getItem("profileUser");
    if (user) {
      const parsedUser = typeof user === "string" ? JSON.parse(user) : user;
      this.store.dispatch(new SetUserProfile(parsedUser));
    }
    document.addEventListener("deviceready", this.onDeviceReady.bind(this));
    this.broadcaster.on<any>(MailConstants.CALL_NO_OP_REQUEST).subscribe(val => {
      this.noOp();
    });
    this.broadcaster.on<any>("OPEN_CALENDAR_PREFERENCE").subscribe(val => {
      this.currentURL = this.router.routerState.snapshot.url;
      this.changeDetectionRef.markForCheck();
    });
    this.broadcaster.on<any>("CLOSE_CALENDAR_PREFERENCE").subscribe(val => {
      this.currentURL = this.router.routerState.snapshot.url;
      this.changeDetectionRef.markForCheck();
    });
    this.broadcaster.on<any>("onOpenNotification").pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[onOpenNotification]");
      this.changeDetectionRef.markForCheck();
      this.changeDetectionRef.detectChanges();
    });
    this.broadcaster.on<any>("OPEN_INVITE_REPLY_DIALOG").pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[OPEN_INVITE_REPLY_DIALOG]", res);
      if (!!res && res.id) {
        const mid = res.id;
        this.commonService.getMsgRequest({id: mid}).pipe(take(1)).subscribe(resp => {
          if (resp.m) {
            const message: any = resp.m[0];
            this.matDialog.open(AppointmentInviteReplyDialogComponent, {
              maxWidth: "100%",
              autoFocus: false,
              panelClass: "appointment-preview-dialog",
              id: "appointment-preview-dialog",
              data: { message: message }
            });
          }
        }, error => {
          console.error("[Error in openInviteReply]", error);
        });
      }
    });
  }

  ngAfterViewChecked(): void {
  }

  ngOnDestroy(): void {
    if (this.noOpPolling$) {
      console.log("[handlePolling] ngOnDestroy unsubscribe", new Date());
      this.noOpPolling$.unsubscribe();
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  registerSignature() {
    const Quill: any = QuillNamespace;
    const BlockEmbed = Quill.import("blots/block/embed");
    class VNCSignature extends BlockEmbed {
      static create(value) {
        const node = super.create(value);
        node.innerHTML = value.text;
        return node;
      }
      static value(node) {
        return {
          style: node.getAttribute("contenteditable"),
          text: node.innerHTML
        };
      }
    }
    VNCSignature.blotName = "signature";
    VNCSignature.className = "signature";
    VNCSignature.tagName = "signature";
    Quill.register({
      "formats/signature": VNCSignature
    });
    const FontStyle = Quill.import("attributors/style/font");
    Quill.register(FontStyle, true);

    const Link = Quill.import("formats/link");
    Link.sanitize = function (url) {
      if (!url.toLowerCase().startsWith("http") && !url.toLowerCase().startsWith("https")) {
        return "https://" + url;
      }
      return url;
    };

    const Inline = Quill.import("blots/inline");
    class MisspelledBlot extends Inline {
      static create(value) {
        const node = super.create();
        console.log("MisspelledBlot", value);
        node.setAttribute("class", "misspelled");
        node.setAttribute("word", value.word);
        return node;
      }

      static formats(node) {
        console.log("[MisspelledBlot] formats", node);
        return { word: node.getAttribute("word") };
      }

      static value(node) {
        console.log("[MisspelledBlot] value", node);
        return { word: node.getAttribute("word") };
      }
    }
    MisspelledBlot.blotName = "misspelled";
    MisspelledBlot.tagName = "misspelled";

    Quill.register(MisspelledBlot);

    const icons = Quill.import("ui/icons");
    this.changeEditorToolbarIcon(icons);

    const size = Quill.import("attributors/style/size");
    size.whitelist = ["12px", "14px", "18px", "24px"];
    Quill.register(size, true);

    class DividerBlot extends BlockEmbed { }
    DividerBlot.blotName = "divider";
    DividerBlot.tagName = "hr";
    Quill.register(DividerBlot);
  }

  loadConfig() {
    console.log("[root.ts] inside loadData()");
    this.commonService.getConfigurations().pipe(take(1)).subscribe(config => {
      console.log("[getConfiguration]", config);
      if (!!config && config.avatarURL) {
        this.configService.updateConfig(config);
      }
      if (config.changePasswordConfig) {
        localStorage.setItem("changePasswordType", config.changePasswordConfig.changePasswordType);
      }
      if (config.URLS) {
        this.configService.URLS = config.URLS;
      }
      if (config.showHideSettingsMenu) {
        this.configService.showHideSettingsMenu = config.showHideSettingsMenu;
        this.broadcaster.broadcast("SHOW_HIDEMENU_ITEMS");
      }
      if (config.showContactActions) {
        this.configService.set("showContactActions", config.showContactActions);
        localStorage.setItem("showContactActions", config.showContactActions.toString());
      }
      if (config.showContactActivity) {
        this.configService.set("showContactActivity", config.showContactActivity);
        localStorage.setItem("showContactActivity", config.showContactActivity.toString());
      }
      if (config.publicVncDirectoryUrl) {
        this.configService.set("publicVncDirectoryUrl", config.publicVncDirectoryUrl);
        localStorage.setItem("publicVncDirectoryUrl", config.publicVncDirectoryUrl);
      }
      if (config.displayHeaderAvatar) {
        this.configService.set("displayHeaderAvatar", config.displayHeaderAvatar);
        localStorage.setItem("displayHeaderAvatar", config.displayHeaderAvatar.toString());
      }
      if (config.canUpdateAvatar) {
        this.configService.set("canUpdateAvatar", config.canUpdateAvatar);
        localStorage.setItem("canUpdateAvatar", config.canUpdateAvatar.toString());
      }
      if (config.prefixBold) {
        this.configService.set("prefixBold", config.prefixBold);
        localStorage.setItem("prefixBold", config.prefixBold);
      }
      if (config.suffixNormal) {
        this.configService.set("suffixNormal", config.suffixNormal);
        localStorage.setItem("suffixNormal", config.suffixNormal);
      }
      if (config.hideAppsMenu) {
        this.configService.hideAppsMenu = config.hideAppsMenu;
        this.broadcaster.broadcast("HIDE_APPS_MENU");
      }
      if (config.zimbraURL) {
        this.configService.zimbraURL = config.zimbraURL;
      }
      if (config.showMailDeleteConfirmDialog) {
        this.configService.showMailDeleteConfirmDialog = config.showMailDeleteConfirmDialog;
        this.broadcaster.broadcast("SHOW_MAIL_DELETE_CONFIRM");
      }
      if (config.two_factor_authentication) {
        this.configService.two_factor_authentication = config.two_factor_authentication;
        localStorage.setItem("twoFactorAuthentication", config.two_factor_authentication ? "true" : "false");
      } else {
        this.configService.two_factor_authentication = false;
        localStorage.setItem("twoFactorAuthentication", "false");
      }
      if (config.domainSpecificLogo) {
        const attributes = config.domainSpecificLogo;
        const domainSpecLogo: any[] = [];
        for (const key in attributes) {
          if (attributes.hasOwnProperty(key)) {
            const domainAndLogo: any = {
              domain: key,
              logo: attributes[key]
            };
            domainSpecLogo.push(domainAndLogo);
          }
        }
        console.log("[DomainSpecificLogo] : ", domainSpecLogo);
        this.store.dispatch(new SetDomainSpecificLogo(domainSpecLogo));
      }
      if (config.useVNCdirectoryAuth !== null) {
        if (config.useVNCdirectoryAuth) {
          this.configService.useVNCdirectoryAuth = true;
        }
      }
    });
  }

  private loadProfile() {
    console.log("[loadProfile] start", new Date());
    this.auth.getProfile().subscribe(res => {
      console.log("[loadProfile] res", new Date());
      if (res !== null && res.user) {
        this.isLoggedIn = true;
        /* Get directory tags if useVNCdirectoryAuth is enabled */
        setTimeout(() => {
          console.log("[root.ts][getAllDirectoryTag][Call]:", this.configService.useVNCdirectoryAuth);
          if (this.configService.useVNCdirectoryAuth) {
            this.getDirectoryTags(0);
          }
        }, 2000);
        console.log("[loadProfile] isLoggedIn", new Date());
        const tmpUser = { firstName: res.user.firstName, lastName: res.user.lastName, email: res.user.email };
        localStorage.setItem("profileUser", JSON.stringify(tmpUser));
        this.store.dispatch(new SetUserProfile(tmpUser));
        this.changeDetectionRef.markForCheck();
      } else {
        this.handleLoginFailed();
      }
    }, err => {
      if (err.status === 504) {
        this.handleLoginFailed();
      }
    });
  }

  getZimbraUser() {
    return this.auth
      .getZmAuthToken()
      .pipe(take(1))
      .subscribe(
        res => {
          if (res && res !== null && res.authToken) {
            console.log("[getZimbraUser]", res, new Date());
            this.isLoggedIn = true;
            localStorage.setItem(MailConstants.ZM_AUTH_TOKEN, res.authToken);
            this.appService.saveAuthToken(res.authToken);
            localStorage.setItem("lastTokenGenerationTime", JSON.stringify(new Date().getTime()));
            this.store.dispatch(new LoginSuccess());
            this.loadConfig();
            let timeout = 2000;
            if (this.isCordovaOrElectron) {
              // have to execute handlePolling func after succes login (Electron env) to start noOp timer
              // think we have to do the same for Cordova env
              timeout = 1500;
            }
            setTimeout(() => {
              this.loadProfile(); // this progress just to get avatar for the user, so can do async later
              this.getZimbraUploadSizeLimit();
              this.getDataSource();
              this.getPersonas();
              this.getZimlets();
              this.getUserContacts();
            }, timeout);
            this.changeDetectionRef.markForCheck();
          } else {
            this.handleLoginFailed();
          }
        },
        err => {
          this.handleLoginFailed();
        }
      );
  }

  private getZimbraUploadSizeLimit(): void {
    this.preferenceService.getUploadAttachmentLimit().pipe(take(1)).subscribe(res => {
      this.preferenceService.uploadAttachmentSizeLimit = this._base64toNormalSize(res);
      this.getMailboxMetaData();
    });
  }

  private _base64toNormalSize(base64): number {
    if (!base64 || base64 === -1) { // -1 is unlimited
      return base64;
    }
    return base64 / 1.34;
  }

  private handleLoginFailed(): void {
    console.log("[handleLoginFailed] isCordova", !!environment.isCordova);
    console.log("[handleLoginFailed] isElectron", !!environment.isElectron);
    this.store.dispatch(new LoginFailed());
    if (this.isCordovaOrElectron) {
      this.isLoggedIn = false;
      this.configService.loginIframe();
    } else {
      window.location.href = this.configService.API_URL + "/api/login";
    }
  }

  @HostListener("window:message", ["$event"])
  windowMessageEventHandler(event: MessageEvent) {
    const eventData = event.data;

    console.log("[AppComponent] windowMessageEventHandler ", eventData);
    if (eventData.source && eventData.source === "@devtools-page") {
      // Chrome Redux-devtools extension message
      return;
    }

    if (eventData && eventData.type === "GO_TO_SERVER_URL_PAGE") {
      console.log("[AppComponent] GO_TO_SERVER_URL_PAGE", eventData);
      this.ngZone.run(() => {
        this.configService.selectedServer = false;
        this.changeDetectionRef.markForCheck();
      });
      if (document.querySelector("#loginIframe") !== null) {
        document.querySelector("#loginIframe").remove();
      }
    } else if (eventData && eventData.type === "TFA_OTP_VERIFICATION") {
      if (eventData.token && eventData.token.trim().length > 0) {
        localStorage.setItem("unVerifiedToken", eventData.token);
        if (document.querySelector("#loginIframe") !== null) {
          document.querySelector("#loginIframe").remove();
        }
        this.configService.tfaOtpIframe();
        return;
      }
    } else if (eventData && eventData.type === "GO_TO_LOGIN_SCREEN") {
      this.configService.hideTfaOtpIframe();
      this.configService.loginIframe();
    } else if (eventData && eventData.type === "VNC_PORTAL_POST_MESSAGE") {
      console.log("[AppComponent] login postback update: ", eventData);
      let unverifiedToken = localStorage.getItem("unVerifiedToken");
      if (unverifiedToken) {
        localStorage.removeItem("unVerifiedToken");
        this.configService.hideTfaOtpIframe();
      }
      if (this.electronService.isElectron) {
        this.electronService.setToStorage("token", eventData.token);
        localStorage.setItem("token", eventData.token);
      } else {
        localStorage.setItem("token", eventData.token);
      }

      if (document.querySelector("#loginIframe") !== null) {
        document.querySelector("#loginIframe").remove();
      }
      this.store.dispatch(new OnlineStatus(navigator.onLine));
      this.ngZone.run(() => {
        this.store.pipe(select(getOnlineStatus), filter(v => !!v), take(1)).subscribe(isOnline => {
          this.getZimbraUser();
          this.router.navigate(["/"], { skipLocationChange: true, replaceUrl: true });
          this.changeDetectionRef.markForCheck();
        });
      });
    } else if (eventData && !eventData.link && (typeof eventData.link) !== "function") {

      console.log("[AppComponent] data.link snippet: ", event, eventData.link);

      if (this.electronService.isElectron) {
        this.electronService.openExternalUrl(eventData.link);
      } else if (device.platform === "iOS") {
        window.open(eventData.link, "_system");
      } else {
        navigator.app.loadUrl(eventData.link, {
          openExternal: true
        });
      }
    }
    this.changeDetectionRef.markForCheck();
  }

  @HostListener("document:click", ["$event"])
  documentClickEventHandler(event: any) {
    if (!this.isCordovaOrElectron) {
      return;
    }

    let url: string;

    if (event.target.classList.contains("open-new-window") || event.target.classList.contains("ql-preview")) {
      url = event.target.href;
    }

    if (url) {
      event.stopPropagation();
      event.preventDefault();
      console.log("[documentClickEventHandler]", event);

      if (this.electronService.isElectron) {
        this.electronService.openExternalUrl(url);
      } else if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    }
  }


  registerShortcuts(): void {
  }

  private handleBackButton(): void {
    console.log("[handleBackButton] call");
    document.addEventListener("backbutton", (e) => {
      console.log("[handleBackButton] event", e);
      if (document.querySelector("#loginIframe") !== null) {
        navigator.app.exitApp();
      } else if (document.querySelector("#tfaOtpIframe") !== null) {
        this.configService.hideTfaOtpIframe();
        this.configService.loginIframe();
      } else if (document.querySelector("vp-confirm-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONFIRM_MAIN_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONFIRM_MAIN_DIALOG);
      } else if (document.querySelector("vp-save-appointment-confirmation-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SAVE_APPOINTMENT_CONFIRM_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SAVE_APPOINTMENT_CONFIRM_DIALOG);
      } else if (document.querySelector("vp-send-update-attendee-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SEND_UPDATE_ATTENDEE_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SEND_UPDATE_ATTENDEE_DIALOG);
      } else if (document.querySelector("vp-calendar-equipment-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_EQUIPMENT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_EQUIPMENT_DIALOG);
      } else if (document.querySelector("vp-equipment-conflict-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_EQUIPMENT_CONFLICT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_EQUIPMENT_CONFLICT_DIALOG);
      } else if (document.querySelector("vp-save-change-appointment-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_APPOINTMENT_SAVE_CHANGE_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_APPOINTMENT_SAVE_CHANGE_DIALOG);
      } else if (document.querySelector("vp-calendar-find-location-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_FIND_LOCATION_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_FIND_LOCATION_DIALOG);
      } else if (document.querySelector("vp-select-addresses") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SELECT_ADDRESS_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SELECT_ADDRESS_DIALOG);
      } else if (document.querySelector("vp-mobile-select-address-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_ADDRESS_SELECT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_ADDRESS_SELECT_DIALOG);
      } else if (document.querySelector("vp-custom-repeat-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CUSTOM_REPEAT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CUSTOM_REPEAT_DIALOG);
      } else if (document.querySelector("vp-suggest-preferences-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SUGGEST_PREFERENCES_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SUGGEST_PREFERENCES_DIALOG);
      } else if (document.querySelector("vp-email-notification-configure") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_EMAIL_NOTIFICATION_CONFIGURE);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_EMAIL_NOTIFICATION_CONFIGURE);
      } else if (document.querySelector("vp-calender-edit-appointment-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_EDIT_EVENT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_EDIT_EVENT_DIALOG);
      } else if (document.querySelector("vp-create-calendar-folder") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CREATE_NEW_CALENDAR_FOLDER);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CREATE_NEW_CALENDAR_FOLDER);
      } else if (document.querySelector("vp-external-calendar-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_EXTERNAL_CALENDAR_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_EXTERNAL_CALENDAR_DIALOG);
      } else if (document.querySelector("vp-find-share-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_FIND_SHARE_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_FIND_SHARE_DIALOG);
      } else if (document.querySelector("vp-move-calendar-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOVE_CALENDAR_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOVE_CALENDAR_DIALOG);
      } else if (document.querySelector("vp-share-folder") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SHARE_FOLDER_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SHARE_FOLDER_DIALOG);
      } else if (document.querySelector("vp-mobile-calendar-folder-opration") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_CALENDAR_OPERATION_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_CALENDAR_OPERATION_DIALOG);
      } else if (document.querySelector("vp-mobile-calendar-folder-list") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_CALEDAR_LIST);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_CALEDAR_LIST);
      } else if (document.querySelector("vp-apps-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_APP_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_APP_DIALOG);
      } else if (document.querySelector("vp-avatar-cropper-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_AVATAR_CROPPER_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_AVATAR_CROPPER_DIALOG);
      } else if (document.querySelector("vp-avatar-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_PROFILE_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_PROFILE_DIALOG);
      } else if (document.querySelector("vp-general-settings-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_GENERAL_SETTINGS);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_GENERAL_SETTINGS);
      } else if (document.querySelector("vp-calendar-appearance-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_APPEARANCE_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_APPEARANCE_DIALOG);
      } else if (document.querySelector("vp-about-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_VERSION_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_VERSION_DIALOG);
      } else if (document.querySelector("vp-help-faq-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_FAQ_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_FAQ_DIALOG);
      } else if (document.querySelector("vp-servicedesk-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SERVICEDESK);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SERVICEDESK);
      } else if (document.querySelector("vp-legal-notice-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_LEGAL_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_LEGAL_DIALOG);
      } else if (document.querySelector("vp-mobile-about-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_ABOUT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_ABOUT_DIALOG);
      } else if (document.querySelector("vp-tfa-settings") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_TFA_SETTING_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HIDE_TFA_SETTING_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_GENERAL_SETTINGS);
      } else if (document.querySelector("vp-preference") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.BACK_FROM_PREFERENCES);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.BACK_FROM_PREFERENCES);
      } else if (document.querySelector("vp-calendar-print-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_PRINT_EVENT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_PRINT_EVENT_DIALOG);
      } else if (document.querySelector("vp-appointment-preview-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_PREVIE_EVENT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_PREVIE_EVENT_DIALOG);
      } else if (document.querySelector("vp-mobile-calendar-contextmenu-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_CONTEXT_MENU_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_CONTEXT_MENU_DIALOG);
      } else if (document.querySelector("vp-date-range-select-calendar-dialogg") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_DATE_RANGE_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_DATE_RANGE_DIALOG);
      } else if (document.querySelector("vp-calendar-compose-component .cal-compose-bg.display") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_QUICK_EVENT_CREATE_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_QUICK_EVENT_CREATE_DIALOG);
      } else if (document.querySelector("vp-appointment-invite-operation-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION);
      } else if (document.querySelector("vp-tag-list-operation-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_TAG_OPERATION_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_TAG_OPERATION_DIALOG);
      } else if (document.querySelector("vp-mobile-tags-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_TAG_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_TAG_DIALOG);
      }
      setTimeout(() => {
        this.changeDetectionRef.detectChanges();
      }, 20);
    }, false);
  }

  onDeviceReady(): void {
    if (typeof cordova !== "undefined") {
      const androidContextMenu = new cordova.AndroidContextMenu();
      window["androidContextMenu"] = androidContextMenu;
      androidContextMenu.setCallback(function (text) {
        console.log("[selectedText]", text.selectedText);
      });
      window["androidContextMenu"].setDismissMenu(false);
    }
  }

  getDataSource(): void {
    this.preferenceService.getDataSources().subscribe(res => {
      this.preferenceService.dataSource$ = res;
    });
  }

  isDataSourceFolder(folderId: any): boolean {
    if (this.preferenceService.dataSource$ === undefined || this.preferenceService.dataSource$.length === 0) {
      return false;
    }
    const dataSource = this.preferenceService.dataSource$.filter(item => item.l.toString() === folderId.toString());
    if (dataSource.length > 0) {
      return true;
    }
    return false;
  }


  private getZimlets(): void {
    this.commonService.getAttributes("zimlets").pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.store.dispatch(new SetAvailableZimlets(res.zimlets.zimlet));
    });
  }

  applyTheme(prefs: Preference[]): void {
    const currentTheme = prefs.filter(item => item.key === "zimbraPrefSkin");
    const theme = localStorage.getItem(MailConstants.THEME);
    if (theme === null || theme === undefined) {
      if (currentTheme !== null && !!currentTheme) {
        const zimbraSkin = currentTheme[0].value;
        if (zimbraSkin === "harmony" || zimbraSkin === "vnc") {
          localStorage.setItem(MailConstants.THEME, "default");
        } else {
          localStorage.setItem(MailConstants.THEME, "green");
          this.loadTheme();
        }
      } else {
        localStorage.setItem(MailConstants.THEME, "default");
      }
    }
  }

  private loadTheme() {
    if (environment.isCordova) {
      const initialHref = window.location.href.split("/www/")[0];
      window.location.href = initialHref + "/www/index.html";
    } else if (environment.isElectron) {
      const initialHref = window.location.href.split("/calendar")[0];
      window.location.href = `${initialHref}/index.html`;
    } else {
      self.location.reload();
    }
  }

  private getNormalize(id) {
    if (typeof (id) !== "string") {
      return id;
    }
    const idx = id.indexOf(":");
    const localId = (idx === -1) ? id : id.substr(idx + 1);
    return localId;
  }

  private changeEditorToolbarIcon(icons: any): void {
    icons["bold"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-bold' fonticon='mdi-format-bold' fontset='mdi' role='img'>" +
      "</mat-icon>";
    icons["italic"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-italic' fonticon='mdi-format-italic' fontset='mdi' " +
      "role='img'></mat-icon>";
    icons["underline"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-underline' fonticon='mdi-format-underline' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["strike"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-strikethrough-variant' " +
      "fonticon='mdi-format-strikethrough-variant' fontset='mdi' role='img'></mat-icon>";
    icons["blockquote"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-quote-close' fonticon='mdi-format-quote-close' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["code-block"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-code-tags' fonticon='mdi-format-code-tags' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["link"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-link' fonticon='mdi-link' fontset='mdi' role='img'></mat-icon>";
    icons["image"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-image' fonticon='mdi-image' fontset='mdi' role='img'></mat-icon>";
    icons["clean"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-clear' fonticon='mdi-format-clear' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["list"]["ordered"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-list-numbered' fonticon='mdi-format-list-numbered'" +
      "fontset='mdi' role='img'></mat-icon>";
    icons["list"]["bullet"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-list-bulleted' fonticon='mdi-format-list-bulleted' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["header"]["1"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-header-1' fonticon='mdi-format-header-1' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["header"]["2"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-header-2' fonticon='mdi-format-header-2' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["script"]["sub"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-subscript' fonticon='mdi-format-subscript' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["script"]["super"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-superscript' fonticon='mdi-format-superscript' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["indent"]["-1"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-indent-decrease' fonticon='mdi-format-indent-decrease' " +
      "fontset='mdi' role='img'></mat-icon>";
    icons["indent"]["+1"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-indent-increase' fonticon='mdi-format-indent-increase' " +
      "fontset='mdi' role='img'></mat-icon>";
  }

  getPersonas(): void {
    this.commonService.getAttributes("idents").pipe(take(1)).subscribe(res => {
      console.log("Identity (Persona) :: ", res.identities);
      if (res.identities && res.identities.identity && Array.isArray(res.identities.identity)) {
      }
    });
  }

  private setupEmoji() {
    let emojiType = "google";
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") !== -1;
    if (CommonUtils.isOnIOS() || CommonUtils.isOnIOSMobile() || isMac) {
      emojiType = "apple";
    }
    wdtEmojiBundle.defaults.emojiType = emojiType;
    wdtEmojiBundle.defaults.emojiSheets = {
      "google": CommonUtils.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png"),
      "apple": CommonUtils.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png")
    };
    if (!wdtEmojiBundle.emoji) {
      console.log("EmojiConvertor", EmojiConvertor);
      wdtEmojiBundle.emoji = new EmojiConvertor();

      wdtEmojiBundle.emoji.img_set = emojiType;
      wdtEmojiBundle.emoji.use_sheet = true;
      wdtEmojiBundle.emoji.supports_css = true;
      wdtEmojiBundle.emoji.allow_native = false;
      wdtEmojiBundle.emoji.img_sets = {
        "google": {
          mask: 2,
          path: "emoji-data/img-google-64/",
          sheet: CommonUtils.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png")
        },
        "apple": {
          mask: 2,
          path: "emoji-data/img-apple-64/",
          sheet: CommonUtils.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png")
        }
      };
      document.querySelector("body").dataset.wdtEmojiBundle = emojiType;

      this.changeDetectionRef.markForCheck();
    }
  }

  private firebaseClearAlliOSNotifications() {
    // remove all notifications on opening the app
    if (CommonUtils.isOnIOS()) {
      window.FirebasePlugin.clearAllNotifications(() => {
        console.log("[AppComponent][FirebasePlugin clearAllNotifications] success");
      }, error => {
        console.error("[AppComponent][FirebasePlugin clearAllNotifications] error", error);
      });
    }
  }

  setZimbraFeaturesList(): void {
    this.commonService.getAttributes("attrs").pipe(take(1)).subscribe(res => {
      if (!!res) {
        if (res.attrs && res.attrs._attrs) {
          const featuresAttributes = res.attrs._attrs;
          const preferences: Preference[] = [];
          for (const key in featuresAttributes) {
            if (featuresAttributes.hasOwnProperty(key)) {
              const preference: Preference = {
                key: key,
                value: featuresAttributes[key]
              };
              preferences.push(preference);
            }
          }
          this.store.dispatch(new SetZimbraFeatures(preferences));
        }
      }
    });
  }

  setLanguage(prefs: Preference[]): void {
    prefs.map(p => {
      if (p.key === "zimbraPrefLocale") {
        const lang = CommonUtils.getCurrentLanguage(p.value);
        this.configService.updateLanguage(lang);
        if (this.electronService.isElectron) {
          this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, lang);
        } else {
          localStorage.setItem(MailConstants.MAIL_LANGUAGE, lang);
        }
      }
    });
  }

  updateCalendarFolder(folder) {
    let flatFolders: CalendarFolder [] = [];
    this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
      flatFolders = folders;
    });
    const updatedFolder = CalenderUtils.getCalendarFolderById(flatFolders, folder.id);
    if (updatedFolder) {
      const parentFolder = CalenderUtils.getParentById(flatFolders, updatedFolder.l);
      console.log("[updateFolder] found the folder", updatedFolder);
      if (parentFolder) {
        CalenderUtils.updateChildFolder(parentFolder, folder);
        parentFolder.folderColor = this.getColor(parentFolder);
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
      } else {
        folder.folderColor = this.getColor(folder);
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: folder.id, changes: folder }));
      }
    }
  }

  getMailboxMetaData(): void {
    this.commonService.getAttributes("mbox").pipe(take(1)).subscribe(res => {
      if (!!res && res.used) {
        this.configService.setUsedQuota(res.used);
      }
    });
  }

  getCalenderFolderColorById(folderId: string): String {
    let folderColor: string = "#000099";
    this.store.select(getCalendarFolders).subscribe(folders => {
      const fld = [...folders , ...CalenderUtils.getChildFolders(folders)];
        if (!!folderId && !!fld) {
          fld.map( f => {
              if (folderId.toString().indexOf(":") !== -1) {
                  const zid = folderId.split(":")[0];
                  const rid = folderId.split(":")[1];
                  if (!!f.rid && f.rid) {
                      if (f.zid === zid && f.rid.toString() === rid) {
                          folderColor = f.folderColor;
                      }
                  } else {
                      if (f.id === folderId) {
                          folderColor = f.folderColor;
                      }
                  }
              } else {
                  if (f.id === folderId) {
                      folderColor = f.folderColor;
                  }
              }
          });
      }
    });
    return folderColor;
  }

  getCalenderEvent(app: any): CalendarEvent {
    const component = app.inv[0].comp[0];
    let isOrganizer: boolean = true;
    if (!!component.or && !!component.or.a) {
      const email = component.or.a;
      if (email !== this.currentUser.email) {
        isOrganizer = false;
      }
    }
    const ridZ = component.allDay === true ? component.s[0].d : this.getAppointmentRidz(component.s[0].d);
    const calEvent = <any> {
      allDay : component.allDay ? true : false,
      fb : component.fb,
      fba: component.fba,
      alarm: component.alarm ? component.alarm : [],
      start: moment(component.s[0].d).toDate(),
      end: moment(component.e[0].d).toDate(),
      apptId: component.apptId,
      id: component.apptId,
      location: component.loc ? component.loc : "",
      bgcolor: this.getCalenderFolderColorById(app.l),
      title: component.name,
      folderId: component.ciFolder,
      invId: app.id + "-" + app.inv[0].id,
      inst: [{
        ridZ: ridZ
      }],
      eventId: app.id + "_" + ridZ,
      draggable: this.isMobileScreen ? false : true,
      isOrganizer: isOrganizer
    };
    if (!isOrganizer) {
      calEvent.ptst = "NE";
    }
    return calEvent;
  }

  getAppointmentRidz(timeStamp: string): string {
    return moment(moment(timeStamp).toDate()).utc().format("YYYYMMDDTHHmmss") + "Z";
  }


  addChildCalendarFolderToParent(folder) {
      let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
        flatFolders = folders;
      });
      const parentFolder = CalenderUtils.getParentById(flatFolders, folder.l);
      console.log("[addChildToParent] found the folder", parentFolder);
      if (parentFolder) {
        CalenderUtils.addChildFolder(parentFolder, folder);
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
        console.log("[addChildToParent] found the parent and update", parentFolder);
      }
  }

  deleteCalendarFolderFromParent(f: CalendarFolder) {
    console.log("[deleteCalendarFolderFromParent]", f);
    this.removeChildFromParent(f);
  }

  removeChildFromParent(folder) {
    let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
        flatFolders = folders;
    });
    const updatedFolder = CalenderUtils.getCalendarFolderById(flatFolders, folder.id);
    if (updatedFolder) {
      const parentFolder = CalenderUtils.getParentById(flatFolders, updatedFolder.l);
      console.log("[removeChildFromParent] found the folder", updatedFolder);
      if (parentFolder) {
        CalenderUtils.removeChildFolder(parentFolder, folder);
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
        console.log("[removeChildFromParent] found the parent and update", parentFolder);
      }
    }
  }

  moveToNewCalendarParent(newFolder, newParentId) {
    let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
        flatFolders = folders;
    });
    const parent = CalenderUtils.getCalendarFolderById(flatFolders, newParentId);
    if (parent) {
      const children = parent.folder || [];
      if (!_.find(children, {id: newFolder.id})) {
        children.push(newFolder);
        parent.folder = children;
        this.updateCalendarFolder(parent);
      }
    }
  }

  updateCalendarEventColor(folder: CalendarFolder): void {
    if (!!folder) {
      this.store.select(getAllCalendarEvents)
      .pipe(take(1))
      .subscribe((calendarEvents: CalendarEvent[]) => {
        if (!!calendarEvents) {
          const events = calendarEvents.filter(ev => ev.folderId === folder.id);
          if (!!events && events.length) {
            events.map(eve => {
              const updateFolder = this.getCalendarFolder(folder);
              if (!!updateFolder) {
                eve.bgcolor = updateFolder.folderColor;
                this.store.dispatch(new UpdateCalendarEventSuccessAction({ id: eve.eventId, changes: eve }));
              }
            });
          }
        }
      });
    }
  }

  getCalendarFolder(folder): CalendarFolder {
    let calFolder: CalendarFolder;
    this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
      calFolder = CalenderUtils.getCalendarFolderById(res, folder.id);
    });
    return calFolder;
  }

  getColor(folder: CalendarFolder): string {
    if (folder["rgb"]) {
      return folder["rgb"];
    } else if (folder["color"]) {
      const colorIndex = CALENDARCONSTANT.COLOR_CODES.indexOf(folder["color"]);
      if (colorIndex >= 0) {
        return CALENDARCONSTANT.RGB_CODES[colorIndex];
      } else {
        return CALENDARCONSTANT.COLOR_CODES[folder["color"]].toLowerCase();
      }
    } else if (folder["owner"]) {
      return CALENDARCONSTANT.RGB_CODES[CALENDARCONSTANT.DEFAULT_FOLDER_COLOR];
    } else {
      return CALENDARCONSTANT.RGB_CODES[1];
    }
  }

  navigateToCalendar(): void {
    this.router.navigate(["/calendar"]);
  }


  getUserContacts(): void {
    const groupMemberIds: any [] = [];
    this.commonService.getAllUserContacts().pipe(take(1)).subscribe(res => {
      if (!!res && res.cn && Array.isArray(res.cn)) {
        const contacts = res.cn;
        contacts.map( c => {
          if (!!c.m && c.m !== null) {
            groupMemberIds.push(c.id);
          }
        });
        if (!!groupMemberIds && groupMemberIds !== null && groupMemberIds.length > 0) {
          this.getAllDerefGroupMember(groupMemberIds, contacts);
        }
        this.setUserContacts(contacts);
      }
    });
  }

  getAllDerefGroupMember(groupMemberId: any, contacts: any): void {
    const contactIds: any[] = [];
    groupMemberId.map((id, index) => {
      contactIds.push({
        "@": {
          xmlns: "urn:zimbraMail"
        },
        _jsns: "urn:zimbraMail",
        cn: {
          id: id
        },
        requestId: index,
        returnCertInfo: "1",
        derefGroupMember: "1"
      });
    });
    const request = {
      GetContactsRequest: contactIds
    };
    this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
      if (!!res.GetContactsResponse && Array.isArray(res.GetContactsResponse)) {
        const contactItem = res.GetContactsResponse;
        contactItem.map( item => {
          if (item.cn) {
            const cnItem = item.cn[0];
            if (cnItem.m) {
              const member = cnItem.m;
              member.map(m => {
                if (m.type && (m.type === "G" || m.type === "C") && m.cn) {
                  const con = m.cn[0];
                  if (m.type === "G" && m.value) {
                    con["value"] = m.value;
                  }
                  contacts.push(con);
                }
              });
            }
          }
        });
        this.setUserContacts(contacts);
      }
    });
  }

  setUserContacts(contacts): void {
    this.store.dispatch(new SetUserContacts(contacts));
  }

  private firebaseSetup() {
    console.log("[AppComponent][firebaseSetup]");

    try {
      this.appService.saveApiUrl();

      window.FirebasePlugin.initCrashlytics(() => {
        console.log("[AppComponent][firebaseSetup][initCrashlytics] OK");
      }, function (error) {
        console.error("[AppComponent][firebaseSetup][initCrashlytics]", error);
      });
      const isOnline$ = this.store.pipe(select(getOnlineStatus), filter(v => !!v), takeWhile(() => !this.isFirebaseSetUpCompleted));
      const isLoggedIn$ = this.store.pipe(select(getIsLoggedIn), filter(v => !!v), takeWhile(() => !this.isFirebaseSetUpCompleted));
      isOnline$.pipe(combineLatest(isLoggedIn$)).subscribe(res => {
        console.log("firebaseSetup Logged in and online", res);
        this.setFirebaseToken();
      });
      window.FirebasePlugin.onNotificationOpen(($event) => {
        console.log("[AppComponent][firebaseSetup][onNotificationOpen]", $event, window.appInBackground);
        if ($event.tap) {
          if ($event.mid) {
            console.log("[AppComponent][firebaseSetup][onNotificationOpen] calendar detail", $event);
            // Open Notification event handing here. And will open Event from here
            setTimeout(() => {
              this.ngZone.run(() => {
                this.broadcaster.broadcast("onOpenNotification");
                if (document.querySelector("vp-appointment-invite-operation-dialog") !== null) {
                  this.broadcaster.broadcast(BroadcastKeys.HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION);
                }
                if ($event.notificationType && $event.notificationType === "appointment") {
                  setTimeout(() => {
                    this.broadcaster.broadcast("OPEN_INVITE_REPLY_DIALOG", { id : $event.mid });
                  }, 200);
                }
              });
            }, 200);
          }
          if (CommonUtils.isOnIOS()) {
            this.firebaseClearAllNotifications();
          }
        }
      }, function (error) {
        console.error("[AppComponent][firebaseSetup][onNotificationOpen]", error);
      });
      // will be called only in foreground
      window.FirebasePlugin.onNotificationReceived(($event) => {
        console.log("[AppComponent][FirebasePlugin][onNotificationReceived][notification]", $event);
        if (CommonUtils.isOnAndroid() && !window.appInBackground) {
         if (!this.configService.scheduledNotification.includes($event.mid)
          && this.configService.prefs.zimbraPrefMailToasterEnabled === "TRUE") {
            this.noOp();
            this.configService.scheduledNotification.unshift($event.mid);
              console.log("[scheduleCalendarNotification] call");
              this.notificationsService.scheduleCalendarNotification($event.fromDisplay, $event.mid,
              $event.subject, $event.body, $event.folderId, $event.ntype, $event.cid, $event.fromAddress,
              $event.notificationType, $event.appointmentId);
          }
        }
      }, function (error) {
        console.error("[AppComponent][firebaseSetup][onNotificationReceived]", error);
      });
    } catch (e) {
      console.error("[[AppComponent][firebaseSetup] error", e);
    }
  }

  private firebaseClearAllNotifications() {
    // remove all notifications on opening the app
    window.FirebasePlugin.clearAllNotifications( () => {
      console.log("[AppComponent][FirebasePlugin clearAllNotifications] success");
    }, error => {
      console.error("[AppComponent][FirebasePlugin clearAllNotifications] error", error);
    });
  }

  private setFirebaseToken() {
    window.FirebasePlugin.onTokenRefresh((token) => {
      console.log("[AppComponent]Firebase token", token);
      if (token && token.trim().length > 0) {
        this.appService.setFirebaseToken(token).subscribe(res => {
          if (!!res) {
            this.isFirebaseSetUpCompleted = true;
            console.log("[AppComponent] Firebase token set");
          }
        }, err => {
          console.error("[AppComponent] Firebase token not set", err);
        });
      }
    }, error => {
      console.error("[AppComponent] Firebase onTokenRefresh error", error);
    });
  }

  private deepLinksHandlerSetup() {
    window.handleOpenURL = url => {
      console.log("[AppComponent][handleOpenURL]", url);
      // "vnccalendar://compose
      // "vnccalendar://main"
      // "vnccalendar://details/%s"
      // "vnccalendar://appointment_details/%s/%s"; //where %s is id of event in format 'xxxxx-xxxxx' and %s for ridz

      if (url.includes("compose")) {
        setTimeout(() => {
          this.ngZone.run(() => {
            console.log("[OPEN_APPOINTMENT_FULL_COMPOSE_FOR_WIDGET]");
            this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_WIDGET");
          });
        }, 1500);
      } else if (url.includes("main")) {
        this.router.navigate(["/calendar"]);
        setTimeout(() => {
          this.ngZone.run(() => {
            this.broadcaster.broadcast("onOpenCalendarApp");
          });
        }, 200);
      } else if (url.includes("appointment_detail")) {
        const urlSplited = url.split("/");
        const eventId = urlSplited[3];
        const ridz = urlSplited[4];
        this.router.navigate(["/calendar"]);
        if (document.querySelector("vp-appointment-preview-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_PREVIE_EVENT_DIALOG);
        }
        setTimeout(() => {
          this.ngZone.run(() => {
            console.log("[appointment_detail]: eventId: ", eventId, " ridz: ", ridz);
            this.broadcaster.broadcast("OPEN_PREVIEW_DIALOG_FRON_EVENT_ID", {
              eventId: eventId,
              ridz: ridz
            });
          });
        }, 1500);
      } else if (url.includes("details")) {
        const urlSplited = url.split("/");
        const date = urlSplited[urlSplited.length - 1];
        console.log("[urlSplited][date]: ", date);
        this.router.navigate(["/calendar"]);
        const openDate = moment(date).toDate();
        console.log("[event][openDate]: ", openDate);
        setTimeout(() => {
          this.ngZone.run(() => {
            this.broadcaster.broadcast("SET_DAY_VIEW_WITH_DATE", openDate);
          });
        }, 2000);
      }
    };
  }

   // launchedAppFromLink Event Handler
   onApplicationDidLaunchFromLink (eventData: any): void {
    console.log("[eventData]:", eventData);
    if (eventData) {
      if (eventData.params) {
        const params = eventData.params;
        console.log("[params]", params);
        if (eventData.params && eventData.path === "/calendar/cal-ev-create") {
          console.log("[onApplicationDidLaunchFromLink]:", params.to);
          setTimeout(() => {
            this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_ATEENDEE", {
              to: params.to
            });
          }, 1500);
        }
      }
    }
  }

  getDirectoryTags(offsetRange: number): void {
    console.log("[configService.useVNCdirectoryAuth]: ", this.configService.useVNCdirectoryAuth);
    if (this.configService.useVNCdirectoryAuth) {
      const allDirectoryTags: DirectoryTag [] = [];
      const offset = offsetRange;
      const limit = 500;
      console.log("[getDirectoryTags] [offset]: ", offset , " [limit]:", limit);
      this.appService.getDirectoryTags(offset, limit).pipe(take(1)).subscribe(res => {
        console.log("[getDirectoryTags]: ", res);
        if (res && res.tags && res.tags.length > 0) {
          const tags = res.tags;
          tags.map((t: any) => {
            const dt: DirectoryTag = {} as DirectoryTag;
            dt.id = t.id;
            dt.name = t.name;
            if (t.color_hex && t.color_hex !== null) {
              dt.color = t.color_hex;
            } else {
              dt.color = MailConstants.DEFAULT_COLOR;
            }
            allDirectoryTags.push(dt);
          });
          if (offset === 0) {
            this.store.dispatch(new LoadDirectoryTagsSuccess(allDirectoryTags));
          } else {
            this.store.dispatch(new LoadMoreDirectoryTagsSuccessAction(allDirectoryTags));
          }
          if (tags.length === limit) {
            this.getDirectoryTags(offsetRange + limit);
          }
        }
      }, error => {
        console.log("[getDirectoryTags][Fail][Error]: ", error);
        this.store.dispatch(new LoadDirectoryTagsFail());
      });
    }
  }

}
