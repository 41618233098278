
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EventEmitter, Injectable } from "@angular/core";
import { Notification, NotificationEvent } from "./notifications.model";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { Router } from "@angular/router";
import { ElectronService } from "src/app/services/electron.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";

@Injectable()
export class VNCNotificationsService {
  id: string;
  emitter: Subject<NotificationEvent> = new Subject<NotificationEvent>();
  private receiveMessage: HTMLAudioElement;
  flashTitleInterval: any;
  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    private electronService: ElectronService,
    private broadcaster: Broadcaster) {
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    } else {
      this.initAudioFilesForBrowser();
    }
  }


  set(notification: Notification, to: boolean) {
    notification.id = Math.random().toString(36).substring(3);
    if (notification.extraData && notification.extraData.id) {
      notification.id = notification.extraData.id;
    }
    notification.click = new EventEmitter<{}>();
    this.emitter.next({ command: "set", notification: notification, add: to });
    return notification;
  }

  getChangeEmitter() {
    return this.emitter;
  }

  flashTitle(key: string): void {
    this.translate.get(key).pipe(take(1)).subscribe(val => {
      if (this.flashTitleInterval) {
        clearInterval(this.flashTitleInterval);
      }
      const originalTitle = document.querySelector("title").innerText;
      this.flashTitleInterval = setInterval(() => {
        this.titleService.setTitle(val);
        setTimeout(() => {
          this.titleService.setTitle(originalTitle);
        }, 1000);
      }, 2000);
      setTimeout(() => {
        if (this.flashTitleInterval) {
          clearInterval(this.flashTitleInterval);
        }
        this.titleService.setTitle(originalTitle);
      }, 10000);
    });
  }

  //// Access methods
  newConversation(title: string, content: string, extraData?: any): void {
    const _createChromeNotification = () => {
      const sticky = false;
      const notification = new ChromeNotification(title, {
        body: content,
        data: extraData,
        requireInteraction: sticky
      });
      notification.onclick = () => {
        if (this.electronService.isElectron) {
          this.electronService.showApp();
        } else {
          window.focus();
        }
        notification.close();
        if (extraData) {
          this.openMailDetail(notification.data);
        }
      };
      setTimeout(() => notification.close(), 5000);
    };

    if (!environment.isCordova && typeof ChromeNotification !== "undefined") {
      if (ChromeNotification.permission !== "granted") {
        ChromeNotification.requestPermission(permission => {
          if (permission === "granted") {
            _createChromeNotification();
          }
        });
      } else {
        _createChromeNotification();
      }
    }

    this.set({
      title: title,
      content: content,
      type: !!extraData && extraData.cid ? "message" : "conversation",
      extraData: extraData
    }, true);
  }

  openMailDetail(conversation: any): void {
    if (environment.isCordova || this.electronService.isElectron) {
      this.broadcaster.broadcast("RESET_SELECTION");
    }
    const folderId = +conversation.l || +conversation.m[0].l;
    const conversationId = conversation.id;
    switch (folderId) {
      case MailConstants.FOLDER_ID.INBOX: this.router.navigate(["/mail/inbox/detail/" + conversationId]); break;
      case MailConstants.FOLDER_ID.DRAFTS: this.router.navigate(["/mail/drafts/detail/" + conversationId]); break;
      case MailConstants.FOLDER_ID.SENT: this.router.navigate(["/mail/sent/detail/" + conversationId]); break;
      case MailConstants.FOLDER_ID.STARRED: this.router.navigate(["/mail/starred/detail/" + conversationId]); break;
      case MailConstants.FOLDER_ID.TRASH: this.router.navigate(["/mail/trash/detail/" + conversationId]); break;
      case MailConstants.FOLDER_ID.JUNK: this.router.navigate(["/mail/junk/detail/" + conversationId]); break;
      default: this.router.navigate([`/mail/folder/${folderId}/detail/${conversationId}`]); break;
    }
  }
  // With type method
  create(title: string, content: string, type: string, extraData?: any) {
    return this.set({ title: title, content: content, type: type, extraData: extraData }, true);
  }

  // HTML Notification method
  html(title: string, html: any, type: string, extraData?: any) {
    return this.set({ title: title, html: html, type: type, extraData: extraData }, true);
  }

  // Remove all notifications method
  remove(id?: string) {
    if (id) {
      this.emitter.next({ command: "clean", id: id });
    } else {
      this.emitter.next({ command: "cleanAll" });
    }
  }

  deviceReady() {
    // for Cordova only env
    if (cordova.plugins && cordova.plugins.NativeAudio) {
      this.initAudioFilesForCordova();
    } else {
      console.log("NativeAudio plugin API is not available");
      this.initAudioFilesForBrowser();
    }
  }

  public playReceiveMessage() {
    if (environment.isCordova && !this.receiveMessage) {
      if (!window.appInBackground) {
        this.playAudioCordova("alert");
      }
    } else {
      this.playAudioBrowser(this.receiveMessage);
    }
  }

  private initAudioFilesForBrowser() {
    this.receiveMessage = this.loadAudio("assets/sounds/alert.mp3");
  }

  private initAudioFilesForCordova() {
    cordova.plugins.NativeAudio.preloadSimple("alert", "assets/sounds/alert.mp3", function (res) {
      console.log("NativeAudio preloadSimple alert result: " + res);
    }, function (error) {
      console.log("NativeAudio preloadSimple alert error: " + error);
    });
  }

  private loadAudio(path: string): HTMLAudioElement {
    const audio = new Audio(path);
    audio.preload = "auto";
    audio.load();
    return audio;
  }

  private playAudioBrowser(audioElement: HTMLAudioElement) {
    const audio = audioElement.cloneNode() as HTMLAudioElement;
    audio.addEventListener("ended", () => {
      audio.pause();
      audio.currentTime = 0;
      audio.remove();
    }, false);
    audio.play();
  }

  private playAudioCordova(type) {
    cordova.plugins.NativeAudio.play(type);
  }

  scheduleLocalMailNotification(fromDisplay: string, mid: string, title: string, message: string,
    folderId: string, type: string, cid: string, fromAddress: string): void {
    if (window.FirebasePlugin && window.FirebasePlugin.scheduleLocalMailNotification) {
      const options = {
        mid,
        fromDisplay,
        fromAddress,
        title,
        body: message,
        subject: title,
        folderId: folderId,
        type,
        cid
      };
      window.FirebasePlugin.scheduleLocalMailNotification(options, () => {
        console.log("[scheduleLocalMailNotification] success", options);
      }, error => {
        console.log("[scheduleLocalMailNotification] error", error);
      });
    }
  }

  scheduleCalendarNotification(fromDisplay: string, mid: string, title: string, message: string,
    folderId: string, type: string, cid: string, fromAddress: string, notificationType: string, appointmentId: string): void {
    if (window.FirebasePlugin && window.FirebasePlugin.scheduleCalendarNotification) {
      const options = {
        mid: mid,
        fromDisplay: fromDisplay,
        fromAddress: fromAddress,
        title: title,
        body: message,
        subject: title,
        folderId: folderId,
        cid: cid,
        ntype: type,
        type: notificationType,
        appointmentId: appointmentId,
        notificationType: notificationType
      };
      console.log("[scheduleCalendarNotification] options: ", options);
      window.FirebasePlugin.scheduleCalendarNotification(options, () => {
        console.log("[scheduleCalendarNotification] success", options);
      }, error => {
        console.log("[scheduleCalendarNotification] error", error);
      });
    }
  }

}
