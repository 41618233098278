
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ActionReducerMap, createSelector } from "@ngrx/store";
import {
    _getIsLoggedInUserLoading ,
    _getSidebarExpanderStatus ,
    appReducer ,
    AppState ,
    _getUserProfile , _IsDeviceReady,
    _getOnlineStatus,
    _getIsLoggedIn,
    _getProcessingState,
    _getContactProfiles,
    _getSession,
    _getPollingInterval,
    _getSwipeAction,
    _getFederatedApps,
    _getViewBy,
    _getExpandConversation,
    _getReadingPane,
    _getAvailableZimlets,
    _getCurrentFolder,
    _getCurrentQuery,
    _getFirebaseToken,
    _getProps,
    _getzimbraFeatures,
    _getLastPhotoUpdate,
    _getLastPhotoUpdateByEmail,
    _getdomainSpecificLogo,
    _getIncludeSharedItems,
    _getSearchFor,
    _getWaitDisallowed,
    _getUserContacts,
    _getCalendarSelectTag
} from "./app";
import { AppActionTypes } from "../actions/app";
import {_getSearchKeyword, searchReducer, SearchState, searchFolderAdapter} from "./search";
import * as fromMailTag from "../reducers/mail-tag.reducer";
import { Dictionary } from "@ngrx/entity";
import { MailTag } from "../common/models/mail-tag.model";
import * as fromDirectoryTag from "./directory-tag.reducers";
import { directoryTagAdapter } from "./directory-tag.reducers";

export interface RootState {
  app: AppState;
  search: SearchState;
  mailTag: fromMailTag.MailTagState;
  directoryTag: fromDirectoryTag.DirectoryTagState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  search: searchReducer,
  mailTag: fromMailTag.MailTagReducer,
  directoryTag: fromDirectoryTag.DirectoryTagReducer
};

export function reset(reducer) {
  return function (state, action) {

    if (action.type === AppActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const getSearchState = state => state.search;
export const getMailTagState = state => state.mailTag;
export const getAllContactsState =  state => state.allContacts;
export const getDirectoryTagState = state => state.directoryTag;

export const {
  selectIds: getSearchFolderIds,
  selectEntities: getSearchFolderEntities,
  selectAll: getAllSearchFolder,
  selectTotal: getTotalSearchFolder,
} = searchFolderAdapter.getSelectors(getSearchState);
export const getSearchFolders = getAllSearchFolder;

export const {
  selectIds: getMailTagIds,
  selectEntities: getMailTagEntities,
  selectAll: getAllMailTag,
  selectTotal: getTotalMailTag,
} = fromMailTag.mailTagAdapter.getSelectors(getMailTagState);
export const getMailTags = getAllMailTag;

export const getTagById = createSelector(
  getMailTagEntities,
  (entities: Dictionary<MailTag>, tagId: string) => {
    return entities[tagId];
  }
);

/* Directory tag Reducers */

export const {
  selectIds: getDirectoryTagIds,
  selectEntities: getDirectoryTagEntities,
  selectAll: getAllDirectoryTag,
  selectTotal: getTotalDirectoryTag,
} = directoryTagAdapter.getSelectors(getDirectoryTagState);
export const getAllDirectoryTags = getAllDirectoryTag;

/**
 *  App Related Reducers
 */
export const getAppState =  state => state.app;

export const getSearchKeyword = createSelector(getSearchState, _getSearchKeyword);
export const getIsLoggedInUserLoading = createSelector(getAppState, _getIsLoggedInUserLoading);
export const getSidebarExpanderStatus = createSelector(getAppState, _getSidebarExpanderStatus);
export const getUserProfile = createSelector(getAppState, _getUserProfile);
export const getIsDeviceReady = createSelector(getAppState, _IsDeviceReady);
export const getOnlineStatus = createSelector(getAppState, _getOnlineStatus);
export const getIsLoggedIn = createSelector(getAppState, _getIsLoggedIn);
export const getActionProcessingState = createSelector(getAppState, _getProcessingState);
export const getContactProfiles = createSelector(getAppState, _getContactProfiles);
export const getSession = createSelector(getAppState, _getSession);
export const getPollingInterval = createSelector(getAppState, _getPollingInterval);
export const getSwipeAction = createSelector(getAppState, _getSwipeAction);
export const getFederatedApps = createSelector(getAppState, _getFederatedApps);
export const getViewBy = createSelector(getAppState, _getViewBy);
export const getExpandConversation = createSelector(getAppState, _getExpandConversation);
export const getReadingPane = createSelector(getAppState, _getReadingPane);
export const getAvailableZimlets = createSelector(getAppState, _getAvailableZimlets);
export const getCurrentFolder = createSelector(getAppState, _getCurrentFolder);
export const getCurrentQuery = createSelector(getAppState, _getCurrentQuery);
export const getFirebaseToken = createSelector(getAppState, _getFirebaseToken);
export const getProps = createSelector(getAppState, _getProps);
export const getZimbraFeatures = createSelector(getAppState, _getzimbraFeatures);
export const getLastPhotoUpdate = createSelector(getAppState, _getLastPhotoUpdate);
export const getIncludeSharedItems = createSelector(getAppState, _getIncludeSharedItems);
export const getSearchFor = createSelector(getAppState, _getSearchFor);
export const getLastPhotoUpdateByEmail = (state: AppState, email: string) => {
  return getLastPhotoUpdate(state)[email];
};
export const getDomainSpecificLogo = createSelector(getAppState, _getdomainSpecificLogo);
export const getWaitDisallowed = createSelector(getAppState, _getWaitDisallowed);
export const getAllUserContacts = createSelector(getAppState, _getUserContacts);
export const getCalendarSelectTag = createSelector(getAppState, _getCalendarSelectTag);
