
/*
 * VNCcalendar : A calendar which collects all important data from various sources.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { MailFolder } from "../models/mail-folder.model";
import { UserProfile } from "../../shared/models";
import { Message } from "../models/message.model";
import { EmailInformation } from "../models/email-information.model";
import { Invitation } from "../models/invitation.model";
import { SearchRequest } from "../models/search-request.model";
import { Conversation } from "../models/conversation.model";
import * as moment from "moment";
import { MultiPart } from "../models/multipart.model";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
import { SearchFolder } from "../../shared/models/search-folder";
import { RandomColor } from "../../common/utils/random-color";
import { Contact } from "../models/contact";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ElectronService } from "src/app/services/electron.service";
import { Preference } from "src/app/preference/shared/models";
import { Subject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { CommonUtils } from "src/app/common/utils/common-util";

export class MailUtils {
    static mailBody = "";
    static hexcase = 0;  /* hex output format. 0 - lowercase; 1 - uppercase        */
    static chrsz = 8;  /* bits per input character. 8 - ASCII; 16 - Unicode      */
    static _NON_WHITESPACE = /\S+/;
    static TRIM_RE = /^\s+|\s+$/g;
    static COMPRESS_RE = /\s+/g;
    static ORIG_UNKNOWN = "UNKNOWN";
    static ORIG_QUOTED = "QUOTED";
    static ORIG_SEP_STRONG = "SEP_STRONG";
    static ORIG_WROTE_STRONG = "WROTE_STRONG";
    static ORIG_WROTE_WEAK = "WROTE_WEAK";
    static ORIG_HEADER = "HEADER";
    static ORIG_LINE = "LINE";
    static HTML_SEP_ID = "zwchr";
    static NOTES_SEPARATOR = "*~*~*~*~*~*~*~*~*~*";
    // Regexes for finding stuff in msg content
    static SIG_RE = /^(- ?-+)|(__+)\r?$/;
    static SPLIT_RE = /\r\n|\r|\n/;
    static HDR_RE = /^\s*\w+:/;
    static COLON_RE = /\S+:$/;
    // regexes for finding a delimiter such as "On DATE, NAME (EMAIL) wrote:"
    static ORIG_EMAIL_RE = /[^@\s]+@[A-Za-z0-9\-]{2,}(\.[A-Za-z0-9\-]{2,})+/;
    static ORIG_DATE_RE = /\d+\s*(\/|\-|, )20\d\d/; // matches "03/07/2014" or "March 3, 2014" by looking for year 20xx
    static ORIG_INTRO_DE_RE = new RegExp("^(-{2,}|" + "auf" + "\\s+)", "i");
    static ORIG_INTRO_RE = new RegExp("^(-{2,}|" + "on" + "\\s+)", "i");
    static MSG_SEP_RE = new RegExp("\\s*--+\\s*(" + "Original Message" + "|" +
    "Originalnachricht" + "|" + "Weitergeleitete Nachricht" + "|" +
    "Forwarded Message" + "|" + "Original Appointment" + ")\\s*--+\\s*", "i");
    static MSG_ORIGINAL_RE = new RegExp("\\s*--+\\s*(" + "Original Message" + "|" +
    "Originalnachricht" + "|" + "Original Appointment" + ")\\s*--+\\s*", "i");
    static MSG_FORWARD_RE = new RegExp("\\s*--+\\s*(" + "Weitergeleitete Nachricht" + "|" +
    "Forwarded Message" + ")\\s*--+\\s*", "i");
    static MSG_REGEXES = [
        {
            type: MailUtils.ORIG_QUOTED,
            regex: /^\s*(>|\|)/
        },
        {
            type: MailUtils.ORIG_SEP_STRONG,
            regex: new RegExp("^\\s*--+\\s*(" + "Original Message" + "|" +
                "Originalnachricht" + "|" + "Weitergeleitete Nachricht" + "|" +
                "Forwarded Message" + "|" + "Original Appointment" + ")\\s*--+\\s*$", "i")
        },
        {
            type: MailUtils.ORIG_SEP_STRONG,
            regex: new RegExp("^" + "Begin forwarded message:" + "$", "i")
        },
        {
            type: MailUtils.ORIG_HEADER,
            regex: new RegExp("^\\s*(" + ["from:", "to:", "subject:", "date:", "sent:", "cc:"].join("|") + ")")
        },
        {
            type: MailUtils.ORIG_LINE,
            regex: /^\s*_{5,}\s*$/
        }
    ];
    static SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    static IGNORE_NODE = { "#comment": true, br: true, script: true, select: true, style: true };

    static parseSearchFoders(res): SearchFolder[] {
        const searchFolders: SearchFolder[] = [];
        if (res && res.GetSearchFolderResponse && res.GetSearchFolderResponse[0].search) {
            if (Array.isArray(res.GetSearchFolderResponse[0].search)) {
                res.GetSearchFolderResponse[0].search.forEach(element => {
                    if (element.types === "conversation") {
                        searchFolders.push(element as SearchFolder);
                    }
                });
            } else {
                searchFolders.push(res.GetSearchFolderResponse[0].search as SearchFolder);
            }
        }
        return searchFolders;
    }

    static parseCreateSearchResponse(res) {
        if (res && res.CreateSearchFolderResponse) {
            return res.CreateSearchFolderResponse[0].search[0] as SearchFolder;
        } else if (res.Fault && res.Fault[0].Reason) {
            throw Error(res.Fault[0].Reason.Text);
        }
        return null;
    }

    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    // Generate Contact for userProfile from json object
    static getUserProfile(contactDetail: any, serverUrl): UserProfile {
        const contact: any = {};
        contact.id = contactDetail.id;
        contact.parentId = contactDetail.l;
        let contactDetailMap = {};
        if (contactDetail._attrs) {
            contactDetailMap = contactDetail._attrs;
        }
        contact.firstName = contactDetailMap["firstName"];
        contact.lastName = contactDetailMap["lastName"];

        if (contact.firstName && contact.firstName !== undefined) { contact.firstName = contact.firstName.trim(); }
        if (contact.lastName && contact.lastName !== undefined) { contact.lastName = contact.lastName.trim(); }
        if (contact.nickname && contact.nickname !== undefined) { contact.nickname = contact.nickname.trim(); }
        contact.fullName = this.getFullName(contact.firstName, contact.lastName);
        if (contact.fullName) {
            contact.firstLastCharacters = this.getCharacters(contact.firstName, contact.lastName);
        } else {
            contact.firstLastCharacters = "NL";
        }

        contact.email = this.getEmailAddress(contactDetailMap);

        const firtsName = contact.firstName ? contact.firstName : "";
        const lastName = contact.lastName ? contact.lastName : "";
        const fullName = firtsName + " " + lastName;
        const profile: UserProfile = {
            contactId: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email[0],
            fullName: fullName,
            firstLastCharacters: contact.firstLastCharacters
        };

        return profile;
    }

    private static getFullName(firstName: string, lastName: string): string {
        if (firstName && lastName) {
            return firstName.trim() + " " + lastName.trim();
        } else {
            if (firstName && firstName !== undefined) {
                return firstName.trim();
            } else if (lastName && lastName !== undefined) {
                return lastName.trim();
            }
        }
    }

    private static getEmailAddress(contactDetailMap: any): string[] {
        const emailAddress: string[] = [];
        for (const key in contactDetailMap) {
            if (contactDetailMap[key] !== undefined && contactDetailMap[key] !== "" && (key.indexOf("email") > -1)) {
                emailAddress.push(contactDetailMap[key]);
            }
        }
        return emailAddress;
    }

    private static getCharacters(firstName, lastName) {
        if (firstName && firstName !== undefined && lastName && lastName !== undefined) {
            const fName: string = firstName.trim();
            const lName: string = lastName.trim();
            const chr1 = fName.length > 0 ? fName.charAt(0) : "";
            const chr2 = lName.length > 0 ? lName.charAt(0) : "";
            return (chr1 + chr2).trim();
        } else if (firstName && firstName !== undefined) {
            const fName: string = firstName.trim();
            const chr1 = fName.length > 0 ? fName.charAt(0) : "";
            return chr1;
        } else if (lastName && lastName !== undefined) {
            const lName: string = lastName.trim();
            const chr2 = lName.length > 0 ? lName.charAt(0) : "";
            return chr2;
        } else {
            return "NA";
        }
    }

    static parseUserProfile(contact) {
        const contactUser = typeof contact === "string"
            ? JSON.parse(contact)
            : contact;
        const user: UserProfile = {};
        user.firstName = contactUser.firstName;
        user.lastName = contactUser.lastName;
        if (Array.isArray(contactUser.email)) {
            user.email = contactUser.email[0];
        } else {
            user.email = contactUser.email;
        }
        user.avtarUrl = contactUser.avtarUrl;
        user.fullName = contactUser.fullName;
        user.firstLastCharacters = contactUser.firstLastCharacters;
        user.contactId = contactUser.contactId;
        return user;
    }

    static mapConversation(c: any, request: SearchRequest): Conversation {
        let conversation: Conversation;
        let messages: Message[] = [];
        try {
            if (request.types === "conversation") {
                conversation = c as Conversation;
                c.m = Array.isArray(c.m) ? c.m : [c.m];
            } else {
                conversation = c as Conversation;
                c.m = Array.isArray(c) ? c : [c];
                conversation.isMessage = true;
            }
            c.e = Array.isArray(c.e) ? c.e : [c.e];
            if (conversation.tn) {
                conversation.tags = conversation.tn.split(",");
            } else {
                conversation.tags = [];
            }
            conversation.e = c.e.map(e => this.mapEmailInformation(e));
            if (request.types !== "conversation") {
                const from = conversation.e.filter(v => !!v).reverse().find(f => f.t && f.t === "f");
                if ( from && from.a && from.a === undefined ) {
                    from.a = MailUtils.getEmailFromStorage();
                }
            }
            conversation.senderEmail = conversation.e.reverse().find(f => f.t === "f").a;
            conversation.fromDisplay = conversation.e.reverse().find(f => f.t === "f").p || conversation.senderEmail;
            conversation.lastSender = conversation.senderEmail.charAt(0);
            conversation.color = RandomColor.getCharColor(conversation.lastSender);
            conversation.fr = c.fr;
            conversation.su = c.su;
            conversation.query = request.originalQuery;
            messages = c.m.map(m => this.mapMessage(m));
            conversation.m = messages;
        } catch (ex) {
            console.log("[mapConversation] ex", ex, c);
        }
        return conversation;
    }

    static checkEmailArray(emails): string {
        if (Array.isArray(emails)) {
          return emails[0];
        } else if (emails) {
          return emails;
        }
      }

    static getRandomColor() {
        const color = Math.floor(0x1000000 * Math.random()).toString(16);
        return "#" + ("000000" + color).slice(-6);
    }

    static mapMessage(m: any): Message {
        const message: Message = m as Message;
        return message;
    }
    static isToday(momentDate): boolean {
        const REFERENCE = moment();
        const TODAY = REFERENCE.clone().startOf("day");
        return momentDate.isSame(TODAY, "d");
    }

    static isDateFromPastYear(momentDate): boolean {
        const REFERENCE = moment();
        const TODAY = REFERENCE.clone().startOf("year");
        return !momentDate.isSame(REFERENCE, "year");
    }

    static mapEmailMessage(m: any, query?: any): Message {
        const message: Message = m as Message;
        if (!!m.e) {
            m.e = Array.isArray(m.e) ? m.e : [m.e];
            const from = MailUtils.getEmailFromStorage();
            message.e = m.e.map(e => this.mapEmailInformation(e));
            // if ( message.e.find(e => e.t === "f") === undefined ) {
            //     const item: EmailInformation = { a: from , d: from , p: from , t: "f" } as EmailInformation;
            //     message.e.push(item);
            // }
            const displayUserType = localStorage.getItem("getDisplayUserType") === null ? "firstname" :
            localStorage.getItem("getDisplayUserType");
            const currentUrl = window.location.href;
            let condition = "f";
            if (currentUrl.indexOf("/mail/drafts") !== -1 || currentUrl.indexOf("/mail/sent") !== -1) {
                condition = "t";
            }
            const recipients = message.e.filter(v => v.t && v.t === condition).map(e => {
                if (displayUserType === "firstname") {
                    return e.d || e.a;
                } else if (displayUserType === "fullname") {
                    return e.p || e.d || e.a;
                } else {
                    return e.a;
                }
            });
            message.recipients = recipients.join(", ");
            if (recipients.length > 0) {
                message.senderEmail = message.e.find(v => v.t === condition).a;
                message.lastSender = message.senderEmail.charAt(0);
                message.color = RandomColor.getCharColor(message.lastSender);
            }
        }
        if (m.mid) {
            message.mid = m.mid;
        }
        message.su = m.su;
        if (m.fr) {
            message.fr = m.fr;
        }
        if (!message.sf) {
            message.sf = message.d;
        }
        if (!message.sd) {
            message.sd = message.d;
        }
        if (m.mp) {
            m.mp = Array.isArray(m.mp) ? m.mp : [m.mp];
            message.mp = m.mp.map(e => this.mapMultiPart(e));
        }
        if (m.shr) {
            message.shr = m.shr[0].content;
        }
        if (m.inv) {
            message.inv = this.mapInvitation(m.inv[0], message);
        }
        if (m.l) {
            message.l = m.l;
        }

        if (!!query) {
            message.query = query;
        }
        if (m.mid) {
            message.mid = m.mid;
        }
        message.isExternalImage = false;
        return message;
    }

    static mapInvitation(inv: any, message: Message): Invitation {
        const invitation: Invitation = {};
        invitation.type = inv.type;
        if (inv.comp && inv.comp[0]) {
            invitation.method = inv.comp[0].method;
            if (inv.comp[0].loc) {
                invitation.loc = inv.comp[0].loc;
            }
            if (inv.comp[0].deschtml) {
                invitation.body = inv.comp[0].deschtml[0]._content;
            } else if (inv.comp[0].desc) {
                invitation.body = inv.comp[0].desc[0]._content;
            } else {
                invitation.body = "";
            }
            const startDate = moment(inv.comp[0].s[0].d).format("dddd, MMMM D, YYYY");
            const endDate = moment(inv.comp[0].e[0].d).format("dddd, MMMM D, YYYY");
            const startTime = moment(inv.comp[0].s[0].d).format("hh:mm a");
            const endTime = moment(inv.comp[0].e[0].d).format("hh:mm a");
            if (startDate === endDate) {
                invitation.date = startDate + " . " + startTime + " - " + endTime;
            } else {
                invitation.date = startDate + " . " + startTime + " - " + endDate + " . " + endTime;
            }
            let attendees: any[] = [];
            invitation.attendees = [];
            if (Array.isArray(inv.comp[0].at)) {
                attendees = inv.comp[0].at;
            } else {
                attendees = [inv.comp.at];
            }
            attendees.map(item => {
                invitation.attendees.push(item.a);
            });
            if (inv.comp[0].or) {
                invitation.or = inv.comp[0].or.a;
            }
        }
        if (invitation.body) {
            if (invitation.body.indexOf("*~*~*~*~*~*~*~*~*~*") !== -1) {
                invitation.body = invitation.body.replace("</body></html>", "").
                    split("<div>*~*~*~*~*~*~*~*~*~*</div><br>")[1] ||
                    invitation.body.split("*~*~*~*~*~*~*~*~*~*")[1] || "";
            }
            if (invitation.body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") !== -1) {
                invitation.body = invitation.body.replace("</body></html>", "").
                    split("~ ~ ~ ~ ~ ~ ~ ~ ~")[1] || "";
            }
        }

        if (inv.replies) {
            invitation.replies = inv.replies;
        }
        if (inv.comp && inv.comp[0]) {
            invitation.fonderId = inv.comp[0].ciFolder;
            invitation.appointmentId = inv.comp[0].apptId;
        }
        return invitation;
    }

    static mapEmailInformation(e: any): EmailInformation {
        const email: EmailInformation = e as EmailInformation;
        return email;
    }

    static mapMultiPart(mp: any): MultiPart {
        const multiPart: MultiPart = mp as MultiPart;
        if (mp.content) {
            multiPart.content = mp.content;
        }
        if (mp.mp) {
            multiPart.mp = mp.mp as MultiPart[];
        }
        return multiPart;
    }

    static isYesterday(momentDate): boolean {
        const REFERENCE = moment();
        const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
        return momentDate.isSame(YESTERDAY, "d");
    }

    static isWithinAWeek(momentDate): boolean {
        const REFERENCE = moment();
        const A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");
        return momentDate.isAfter(A_WEEK_OLD);
    }

    static isWithinTwoWeeks(momentDate): boolean {
        const REFERENCE = moment();
        const TWO_WEEKS_OLD = REFERENCE.clone().subtract(17, "days").startOf("day");
        return momentDate.isAfter(TWO_WEEKS_OLD);
    }

    static isMoreThanTwoWeeks(momentDate): boolean {
        return !this.isWithinTwoWeeks(momentDate);
    }

    static isUnderAWeek(momentDate): boolean {
        const REFERENCE = moment();
        const A_WEEK_OLD = REFERENCE.clone().subtract(REFERENCE.day(), "days").startOf("day");
        return momentDate.isBetween(A_WEEK_OLD, REFERENCE);
    }

    static getHeaderLabelByDate(d: string): string {
        const date: number = +d;
        if (this.isToday(moment(date))) {
            return "TODAY";
        } else if (this.isYesterday(moment(date))) {
            return "YESTERDAY";
        } else if (this.isUnderAWeek(moment(date))) {
            return moment(date).format("dddd").toUpperCase();
        } else if (this.isWithinAWeek(moment(date))) {
            return "ONE_WEEK_AGO";
        } else if (this.isWithinTwoWeeks(moment(date))) {
            return "TWO_WEEKS_AGO";
        } else if (this.isMoreThanTwoWeeks(moment(date))) {
            return "OLDER_THAN_TWO_WEEKS";
        }
        return d;
    }

    static getDateBeforeOffset(days: number) {
        const today = new Date();
        return new Date(today.setDate(today.getDate() - days));
    }

    static getWeekDay(day: number, isShort?: boolean): string {
        const weekdays: string[] = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
        if (!weekdays[day]) {
            return null;
        }
        if (isShort) {
            return weekdays[day].substr(0, 3);
        } else {
            return weekdays[day];
        }
    }

    static getHeaderLabelBySize(size: number) {
        /*
        Enormous (> 5MB) >= 5242880
        Huge (1 - 5MB) >= 1048576 - 5242880
        Very Large (500KB - 1MB) >= 512000 - < 1048576
        Large (100 - 500KB) >= 102400 - < 512000
        Medium (25 - 100KB) >= 25600 - < 102400
        Small (10 - 25KB) >= 10240 - < 25600
        Tiny (< 10KB) < 10240
        */

        if (size < 1024) {
            return "Tiny (< 10KB)";
        } else if (size < 25600) {
            return "Small (10 - 25KB)";
        } else if (size < 102400) {
            return "Medium (25 - 100KB)";
        } else if (size < 512000) {
            return "Large (100 - 500KB)";
        } else if (size < 1048576) {
            return "Very Large (500KB - 1MB)";
        } else if (size < 5242880) {
            return "Huge (1 - 5MB)";
        }

        return "Enormous (> 5MB)";
    }

    static getEmailFrom(message: Message): string {
        const from: EmailInformation = message.e.filter(e => e.t === "f")[0];
        if ( from.d === undefined) {
            const email = MailUtils.getEmailFromStorage();
            from.d = email;
            from.a = email;
            from.p = email;
        }
        return from.d;
    }

    static getDetailShowNames(message: Message, type: string): String {
        const toList: string[] = [];
        const emailInfo: EmailInformation[] = message.e.filter(e => e.t === type);
        if (emailInfo.length > 3) {
            for (let i = 0; i < 3; i++) {
                toList.push(" " + emailInfo[i].d + " ");
            }
        } else {
            emailInfo.map(e => {
                toList.push(" " + e.d + " ");
            });
        }
        return toList.toString();
    }

    static getEmailBody(message: Message, toSendOnly?: boolean): string {
        let mailBody = "";
        this.mailBody = "";
        mailBody = this.getMailBodyFromMultiPart(message.mp);
        return this.getInlineImage(mailBody, message, toSendOnly);
    }

    static removeInlineStyle(content: string): string {
        if (content !== undefined && content !== null) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, "text/html");
            const style = htmlDoc.getElementsByTagName("style");
            if (style.length > 0) {
                for (let i = 0; i < style.length; i++) {
                    style[i].remove();
                }
            }
            const divs = htmlDoc.getElementsByTagName("div");
            if (!!divs && divs !== null && divs.length > 0 ) {
                for (let i = 0 ; i < divs.length ; i++ ) {
                    divs[i].removeAttribute("style");
                }
            }
            return htmlDoc.body.innerHTML;
        }
        return content;
    }

    static encryptLinks(content: string): string {
        if (content !== undefined && content !== null) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, "text/html");
            const links = htmlDoc.getElementsByTagName("a");
            if (links.length > 0) {
                for (let i = 0; i < links.length; i++) {
                    links[i].href = "##" + btoa(links[i].href);
                }
            }
            return htmlDoc.body.innerHTML;
        }
        return content;
    }

    static decryptLinks(content: string): string {
        if (content !== undefined && content !== null) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, "text/html");
            const links = htmlDoc.getElementsByTagName("a");
            if (links.length > 0) {
                for (let i = 0; i < links.length; i++) {
                    if (links[i].href.lastIndexOf("==") !== -1) {
                        try {
                            links[i].href = atob(links[i].href.split("##")[1]);
                        } catch (ex) {
                            console.log("[decryptLinks]", ex, links[i].href);
                        }
                    }
                }
            }
            return htmlDoc.body.innerHTML;
        }
        return content;
    }

    static getInlineImage(content: string, message: Message, toSendOnly?: boolean): string {
        const electronService = new ElectronService;
        if (content !== undefined && content !== null) {
            const attachMent: MultiPart[] = this.getAttachments(message.mp).filter(attachment => attachment.ci);
            content = content.replace(/dfsrc/g, "src");
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            const isCordovaOrElectron = environment.isCordova || environment.isElectron;
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    const src = img.getAttribute("src");
                    if (src !== null && src.indexOf("cid:") !== -1) {
                        img.setAttribute("pnsrc", src);
                        const cid = src.split("cid:")[1];
                        const multiPart: MultiPart = attachMent.filter(
                            attach => attach.ci.replace("<", "").
                                replace(">", "").trim() === cid.trim()
                        )[0];

                        const queryParams = "";
                        let serverURL = "";
                        if (isCordovaOrElectron) {
                            serverURL = localStorage.getItem(MailConstants.SERVER_URL).trim();
                        }
                        img.removeAttribute("style");
                        if (!!multiPart) {
                            let link = serverURL + "/api/getAttachment?id=" + message.id + "&part=" + multiPart.part +
                            "&cid=" + multiPart.ci.replace("<", "").replace(">", "");
                            link = CommonUtils.addTokenToRequest(link);
                            img.setAttribute("src", link);
                            img.removeAttribute("data-mce-src");
                            img.removeAttribute("pnsrc");
                        } else {
                            img.removeAttribute("src");
                        }
                    } else {
                        if (img !== null) {
                            const srca = img.getAttribute("src");
                            if (srca !== null) {
                                if (srca.indexOf("/Briefcase/") !== -1) {
                                    if (!isCordovaOrElectron) {
                                        const url = srca.substr(srca.indexOf("home"));
                                        img.setAttribute("src", location.origin + "/api/printDocument?url=" + url.replace(" ", "%20"));
                                    } else {
                                        const configURL =  localStorage.getItem(MailConstants.SERVER_URL).trim();
                                        const url = src.substr(srca.indexOf("home"));
                                        img.setAttribute("src", configURL + "/api/printDocument?url=" + url.replace(" ", "%20"));
                                    }
                                }
                            }
                        }
                    }
                }
                return htmlDoc.body.innerHTML;
            }
            return content;
        }
    }

    static setInlineImageToMail(content: string, zimbraUrl?: string): string {
        const electronService = new ElectronService;
        const isCordovaOrElectron = environment.isCordova || environment.isElectron;
        const parser = new DOMParser();
        if (content !== undefined && content !== null) {
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    console.log("setInlineImageToMail", img);
                    if (img.getAttribute("alt") !== null) {
                        const alt = img.getAttribute("alt");
                        if (alt.indexOf("cid:") !== -1) {
                            img.removeAttribute("src");
                            img.setAttribute("src", alt);
                            img.setAttribute("data-mce-src", alt);
                            img.removeAttribute("alt");
                        }
                    }
                    if (!!img.getAttribute("src") && img.getAttribute("src").indexOf("/api/getAttachment") !== -1) {
                        const src = img.getAttribute("src").split("cid=")[1];
                        const newSrc = `cid:${src}`;
                        img.setAttribute("src", newSrc);
                    }
                    if (!!img.getAttribute("src") && img.getAttribute("src").indexOf("api/printDocument?url") !== -1) {
                        if (!isCordovaOrElectron) {
                            let url = img.getAttribute("src");
                            url = url.replace(location.origin + "/api/printDocument?url=", zimbraUrl + "/");
                            img.setAttribute("src", url);
                        } else {
                            const configURL =  localStorage.getItem(MailConstants.SERVER_URL).trim();
                            let url = img.getAttribute("src");
                            url = url.replace(configURL + "/api/printDocument?url=", zimbraUrl + "/");
                            img.setAttribute("src", url);
                        }
                    }
                }
                return htmlDoc.body.innerHTML;
            }
            return content;
        }
    }

    static removeAttachmentURL(content: string): string {
        const parser = new DOMParser();
        if (!!content) {
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    if (!!img.getAttribute("src") && img.getAttribute("src").indexOf("/api/getAttachment") !== -1) {
                        const src = img.getAttribute("src").split("cid=")[1];
                        const newSrc = `cid:${src}`;
                        img.setAttribute("src", newSrc);
                    }
                }
                return htmlDoc.body.innerHTML;
            }
        }
        return content;
    }

    static getMailBodyFromMultiPart(mp): string {
        if (Array.isArray(mp)) {
            mp.forEach(item => {
                if (item && item.ct === "text/html" && item.content && item.body === true) {
                    this.mailBody = item.content;
                } else if (item.ct && item.ct === "multipart/report" && item.content && item.body === true) {
                    this.mailBody = this.plainTextToHTML(item.mp[0].content);
                } else if (item.ct && item.ct === "text/plain" && item.content && item.body === true) {
                    this.mailBody = item.content;
                } else if (item.mp) {
                    this.getMailBodyFromMultiPart(item.mp);
                }
            });
        } else if (!!mp) {
            if (mp.ct === "text/html" && mp.content && mp && mp.content && mp.body === true) {
                this.mailBody = mp.content;
            } else if (mp.ct === "text/plain" && mp.content && mp.body === true) {
                this.mailBody = mp.content;
            } else if (mp.mp) {
                this.getMailBodyFromMultiPart(mp.mp);
            }
        }
        return this.mailBody;
    }

    static getContentType(mp): string {
        let type = "text/html";
        if (Array.isArray(mp)) {
            mp.forEach(item => {
                if (item.mp) {
                    type = this.getContentType(item.mp);
                } else {
                    type = item.ct;
                }
            });
        } else if (!!mp) {
            if (mp.mp) {
                type = this.getContentType(mp.mp);
            } else {
                type = mp.ct;
            }
        }
        return type;
    }

    static getAttachments(mailParts): MultiPart[] {
        let results: MultiPart[] = [];
        if (mailParts) {
            if (Array.isArray(mailParts)) {
                for (const part of mailParts) {
                    const data = this.getAttachments(part);
                    data.forEach(item => {
                        if (item.filename) {
                            results.push(item);
                        }
                    });
                }
            } else {
                if (mailParts && (mailParts.filename)) {
                    results.push(mailParts);
                } else if (mailParts.mp) {
                    results = results.concat(this.getAttachments(mailParts.mp));
                }
            }
        }
        return results;
    }


    static getFileTypeExtension(contentType: string): string {
        const extensionMap = {
            "application/pdf": "PDF",
            "application/zip": "ZIP",
            "application/msword": "DOC",
            "image/png": "PNG",
            "image/jpg": "JPG",
            "image/jpeg": "JPG",
            "image/gif": "GIF",
            "text/plain": "TXT",
            "application/vnd.ms-powerpoint": "PPT"
        };
        if (Object.keys(extensionMap).includes(contentType)) {
            return extensionMap[contentType];
        } else {
            return "FILE";
        }
    }

    static getBaseUrl(): string {
        const baseUrl = window.location.href;

        if (environment.isCordova) {
            return baseUrl.split("/www/")[0] + "/www";
        } else if (environment.isElectron) {
            return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/mail")[0];
        } else {
            return "";
        }
    }

    static getAttachmentIds(attachmentList: MultiPart[]): string[] {
        const attchmentIds: string[] = [];
        attachmentList.forEach(attachment => {
            attchmentIds.push(attachment.part);
        });
        return attchmentIds;
    }

    static getAttachmentToSend(attachmentList: any[], id: string): any[] {
        const attachMentSave: any = [];
        attachmentList.forEach(item => {
            attachMentSave.push(
                {
                    mid: id,
                    part: item.part
                }
            );
        });
        return attachMentSave;
    }

    static stripSubjectPrefixes(subject: string): string {
        const regex = /^\s*(Re|Fw|Fwd|Re|Fwd|Fw):\s*/i;
        while (regex.test(subject)) {
            subject = subject.replace(regex, "");
        }
        return subject;
    }

    static plainTextToHTML(str: string): string {
        if (str !== undefined && str !== null) {
            return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
        } else {
            return "";
        }
    }

    static getShareData(share: any): any {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(share, "text/xml");

        const shareAction = xmlDoc.getElementsByTagName("share")[0].getAttribute("action");
        const grantorName = xmlDoc.getElementsByTagName("grantor")[0].getAttribute("name");
        const grantorEmail = xmlDoc.getElementsByTagName("grantor")[0].getAttribute("email");
        const grantorId = xmlDoc.getElementsByTagName("grantor")[0].getAttribute("id");

        const granteeName = xmlDoc.getElementsByTagName("grantee")[0].getAttribute("name");
        const granteeEmail = xmlDoc.getElementsByTagName("grantee")[0].getAttribute("email");
        const granteeId = xmlDoc.getElementsByTagName("grantee")[0].getAttribute("id");

        const rid = xmlDoc.getElementsByTagName("link")[0].getAttribute("id");
        const perm = xmlDoc.getElementsByTagName("link")[0].getAttribute("perm");
        const view = xmlDoc.getElementsByTagName("link")[0].getAttribute("view");
        const name = xmlDoc.getElementsByTagName("link")[0].getAttribute("name");
        let role = "";
        let roleName = "NONE";
        let allowedAction = "NONE";
        if (perm === "r" || perm === "rp") {
            role = "ROLE_VIEW";
            roleName = "ROLENAME_VIEWER";
            allowedAction = "ALLOWEDACTION_VIEW";
        } else if (perm === "rwidx" || perm === "rwidxp") {
            role = "ROLE_MANAGER";
            roleName = "ROLENAME_MANAGER";
            allowedAction = "ALLOWEDACTION_MANAGER";
        } else if (perm === "rwidxa" || perm === "rwidxap") {
            role = "ROLE_ADMIN";
            roleName = "ROLENAME_ADMIN";
            allowedAction = "ALLOWEDACTION_ADMIN";
        }

        return {
            shareAction: shareAction,
            grantorName: grantorName,
            grantorEmail: grantorEmail,
            grantorId: grantorId,
            granteeName: granteeName,
            granteeEmail: granteeEmail,
            granteeId: granteeId,
            rid: rid,
            perm: perm,
            view: view,
            name: name,
            role: role,
            roleName: roleName,
            allowedAction: allowedAction
        };
    }

    static getSharedNote(message: Message): string {
        const shareContent = this.getEmailBody(message);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(shareContent, "text/xml");
        if (xmlDoc.getElementsByTagName("p")[1] !== undefined && xmlDoc.getElementsByTagName("p")[1].innerHTML !== undefined) {
            return xmlDoc.getElementsByTagName("p")[1].innerHTML;
        }
        return "";
    }

    static parseSearchQuery(query): any {
        const parsedQuery = {
            searchText: "",
            mailFrom: "",
            mailTo: "",
            date: null,
            hasAttchement: false
        };
        const queryData: string[] = query.split(" ");
        if (queryData.length > 0) {
            const fromMailquery = queryData.find(q => q.includes("from:"));
            parsedQuery.mailFrom = fromMailquery ? fromMailquery.split(":")[1] : "";

            parsedQuery.searchText = queryData.find(q => !q.includes(":"));

            const toMailquery = queryData.find(q => q.includes("to:"));
            parsedQuery.mailTo = toMailquery ? toMailquery.split(":")[1] : "";

            const dateMailquery = queryData.find(q => q.includes("date:"));
            parsedQuery.date = dateMailquery ? new Date(dateMailquery.split(":")[1]) : null;

            const attachmentMailquery = queryData.find(q => q.includes("has:"));
            parsedQuery.hasAttchement = attachmentMailquery ? true : false;
        }
        return parsedQuery;
    }

    static setInlineImage(content: string, message: Message): string {
        if (content !== undefined && content !== null) {
            content = content.replace(/dfsrc/g, "src");
            const attachMent: MultiPart[] = this.getAttachments(message.mp).filter(attach => attach.ci);
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, "text/html");
            const images = htmlDoc.getElementsByTagName("img");
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    const src = img.getAttribute("src");
                    if (src.indexOf("/api/getAttachment") !== -1) {
                        const cid = src.split("cid=")[1];
                        const multiPart: MultiPart = attachMent.
                            filter(attach => attach.ci.replace("<", "").replace(">", "").trim() === cid)[0];
                        if (multiPart !== undefined && multiPart) {
                            const link = "cid:" + multiPart.ci.replace(">", "").replace("<", "");
                            img.setAttribute("src", link);
                        }
                    }
                }
                return htmlDoc.body.innerHTML;
            }
            return content;
        }
    }

    static getParseContacts(resData: any, serverUrl): Contact[] {
        const contacts: Contact[] = [];
        if (resData.cn && Array.isArray(resData.cn)) {
            resData.cn.forEach(e => {
                const contact = { id: e.id, avatarUrl: null, emails: null, blobImage: null };
                if (e.a && Array.isArray(e.a)) {
                    e.a.forEach(attr => {
                        if (attr.n === "image") {
                            contact.avatarUrl = serverUrl + "/api/getAvatar?user_id=" + e.id;
                        } else if (attr.n === "email") {
                            contact.emails = attr._;
                        }
                    });
                } else if (e.a) {
                    if (e.a.n === "image") {
                        contact.avatarUrl = serverUrl + "/api/getAvatar?user_id=" + e.id;
                    } else if (e.a.n === "email") {
                        contact.emails = e.a._;
                    }
                }
                if (contact.avatarUrl && contact.emails) {
                    const contact1 = contact as Contact;
                    contacts.push(contact1);
                }
            });
        } else if (resData.cn) {
            const e = resData.cn;
            const contact = { id: e.id, avatarUrl: null, emails: null, blobImage: null };
            if (e.a && Array.isArray(e.a)) {
                e.a.forEach(attr => {
                    if (attr.n === "image") {
                        contact.avatarUrl = serverUrl + "/api/getAvatar?user_id=" + e.id;
                    } else if (attr.n === "email") {
                        contact.emails = attr._;
                    }
                });
            } else if (e.a) {
                if (e.a.n === "image") {
                    contact.avatarUrl = serverUrl + "/api/getAvatar?user_id=" + e.id;
                } else if (e.a.n === "email") {
                    contact.emails = e.a._;
                }
            }
            if (contact.avatarUrl && contact.emails) {
                const contact1 = contact as Contact;
                contacts.push(contact1);
            }
        }
        return contacts;
    }

    static replaceLinkToAnchor(content: string): string {
        if (content === "" || content === undefined) {
            return "";
        }
        return content.replace(/(?:https?\:\/\/|www\.)+(?![^\s]*?")([\w.,@?!^=%&amp;:()\/~+#-]*[\w@?!^=%&amp;()\/~+#-])?/ig, (url) => {
            const wrap = document.createElement("div");
            const anchor = document.createElement("a");
            let href = url.replace(/&amp;/g, "&");
            if ( !url.startsWith("http") && !url.startsWith("https")) {
                href = "http://" + url;
            }
            anchor.href = href.replace(/&#64;/g, "@").replace(/&#61;/g, "=");
            anchor.target = "_blank";
            anchor.classList.add("open-new-window");
            anchor.innerHTML = url;
            wrap.appendChild(anchor);
            return wrap.innerHTML;
        });
    }

    static addClassToAnchor(content: string): string {
        if (content === "" || content === undefined) {
            return "";
        }
        const parser = new DOMParser();
        return content.replace(/<a [^>]+>([^<]+)<\/a>/ig, (aHref) => {
            const htmlDoc = parser.parseFromString(aHref, "text/html");
            const aTag = htmlDoc.getElementsByTagName("a")[0];
            aTag.setAttribute("class", "open-new-window");
            if (aTag.getAttribute("href") !== null ) {
                const url = aTag.getAttribute("href");
                if ( !url.startsWith("http") && !url.startsWith("https")) {
                    const href = "http://" + url;
                    aTag.setAttribute("href", href);
                }
            }
            return htmlDoc.body.innerHTML;
        });
    }

    static isAttachmentExceedLimit(filesSize: number, limitToUpload: number): boolean {
        if (filesSize > limitToUpload) {
            return true;
        }
        return false;
    }

    static getQueryByFolderId(folderId: number): string {
        console.log("[getQueryByFolderId]", folderId);
        let query = "";
        folderId = +folderId;
        switch (folderId) {
            case MailConstants.FOLDER_ID.INBOX: query = MailConstants.SEARCH_CRITERIA.IN_INBOX; break;
            case MailConstants.FOLDER_ID.DRAFTS: query = MailConstants.SEARCH_CRITERIA.IN_DRAFTS; break;
            case MailConstants.FOLDER_ID.SENT: query = MailConstants.SEARCH_CRITERIA.IN_SENT; break;
            case MailConstants.FOLDER_ID.JUNK: query = MailConstants.SEARCH_CRITERIA.IN_JUNK; break;
            case MailConstants.FOLDER_ID.TRASH: query = MailConstants.SEARCH_CRITERIA.IN_TRASH; break;
            case MailConstants.FOLDER_ID.STARRED: query = "is:flagged or ( in:trash and is:flagged )"; break;
            default: query = `(inid:"${folderId}")`;
        }
        return query;
    }

    static getFolderPath(folderId: string): string {
        const id = +folderId;
        let realFolder = "/mail/inbox";
        switch (id) {
            case MailConstants.FOLDER_ID.INBOX: folderId = "/mail/inbox"; break;
            case MailConstants.FOLDER_ID.DRAFTS: folderId = "/mail/drafts"; break;
            case MailConstants.FOLDER_ID.SENT: folderId = "/mail/sent"; break;
            case MailConstants.FOLDER_ID.JUNK: folderId = "/mail/junk"; break;
            case MailConstants.FOLDER_ID.TRASH: folderId = "/mail/trash"; break;
            default: realFolder = `/mail/folder/${folderId}`;
        }
        return realFolder;
    }

    static getFolderId(query: any): number {
        let folderId: any;
        switch (query) {
            case MailConstants.SEARCH_CRITERIA.IN_INBOX: folderId = MailConstants.FOLDER_ID.INBOX; break;
            case MailConstants.SEARCH_CRITERIA.IN_DRAFTS: folderId = MailConstants.FOLDER_ID.DRAFTS; break;
            case MailConstants.SEARCH_CRITERIA.IN_SENT: folderId = MailConstants.FOLDER_ID.SENT; break;
            case MailConstants.SEARCH_CRITERIA.IN_JUNK: folderId = MailConstants.FOLDER_ID.JUNK; break;
            case MailConstants.SEARCH_CRITERIA.IN_TRASH: folderId = MailConstants.FOLDER_ID.TRASH; break;
            default: folderId = query.replace(/\D+/g, "");
        }
        return folderId;
    }

    static getPollingInterval(interval: string | number, minInterval: number): number {
        let newInterval = MailConstants.MIN_POLLING_INTERVAL;
        if (interval === "5m") {
            newInterval = 300 * 1000;
        } else if ((+interval % 60) !== 0) {
            newInterval = +interval;
        } else {
            newInterval = +interval * 1000;
        }
        if (newInterval < MailConstants.MIN_POLLING_INTERVAL || isNaN(newInterval)) {
            newInterval = MailConstants.MIN_POLLING_INTERVAL;
        }
        return newInterval;
    }

    static copyClipboard(): void {
        if (typeof cordova !== "undefined") {
            const text = window.getSelection().toString();
            cordova.plugins.clipboard.copy(text, () => {
                console.log("[copyClipboard] onSuccess", text);
            });
        } else {
            document.execCommand("copy");
        }
    }

    static selectAllText(selector: any): void {
        window.getSelection().selectAllChildren(selector);
    }

    static cutClipboard(): void {
        if (window.getSelection) {
            this.copyClipboard();
            if (window.getSelection().deleteFromDocument) {
                window.getSelection().deleteFromDocument();
            }
        }
    }

    static pasteClipboard(): void {
        if (typeof cordova !== "undefined") {
            cordova.plugins.clipboard.paste((text) => {
                if (window.getSelection().toString() !== "") {
                    const sel = window.getSelection();
                    if (sel.rangeCount) {
                        const range = sel.getRangeAt(0);
                        range.deleteContents();
                        range.insertNode(document.createTextNode("ded"));
                    }
                }
            });
        }
    }

    static clipboardPasteData(): Promise<any> {
        return new Promise(resolve => {
            if (typeof cordova !== "undefined") {
                cordova.plugins.clipboard.paste((text) => {
                    resolve(text);
                });
            } else {
                resolve("");
            }
        });
    }

    static clipboardPasteHTMLData(): Observable<string> {
        const subject = new Subject<string>();
        if (typeof cordova !== "undefined") {
            cordova.plugins.clipboard.pasteHTML((text) => {
                subject.next(text);
            });
        } else {
            subject.next("");
        }
        return subject.asObservable().pipe(take(1));
    }

    static getToolbarOptions(): any[] {
        return [
            { "font": [] },
            { "size": [false, "12px", "14px", "18px", "24px"] },
            "bold",
            "italic",
            "underline",
            "strike",
            { "color": [] },
            { "script": "super" },
            { "script": "sub"},
            { "header": 1 },
            { "header": 2 },
            "blockquote",
            "code-block",
            { "list": "ordered" },
            { "list": "bullet" },
            { "indent": "-1"},
            { "indent": "+1" },
            { "direction": "rtl" },
            { "header": [1, 2, 3, 4, 5, 6, false] },
            { "background": [] },
            "link",
            "image",
            "clean"
        ];
    }

    static getChildFolders(folders: MailFolder[]): MailFolder[] {
        let allFolders: MailFolder[] = [];
        let childFolders: MailFolder[] = [];
        folders.filter(f => f.children && f.children.length > 0).forEach(f => {
            childFolders = [...childFolders, ...f.children];
            allFolders = MailUtils.getChildFolders(childFolders);
        });
        return [...allFolders, ...childFolders];
    }

    static getParent(folders: MailFolder[], parentId: string): MailFolder {
        folders = folders.filter(f => f.children && f.children.length > 0);
        const childFolders = MailUtils.getChildFolders(folders);
        let parent: MailFolder = _.find(folders, { id: parentId }) || _.find(childFolders, { id: parentId });
        if (parent && parent.l && parent.l !== "1") {
            parent = MailUtils.getParent(folders, parent.l);
        }
        return parent;
    }

    static getParentById(flatFolders: any, parentId: string): MailFolder {
        let parent = flatFolders[parentId];
        if (parent && parent.l && parent.l !== "1") {
            parent = MailUtils.getParentById(flatFolders, parent.l);
        }
        return parent;
    }

    static updateChildFolder(parent: MailFolder, updatedFolder: any): MailFolder {
        parent.children = parent.children.map(folder => {
            if (folder.id === updatedFolder.id) {
                folder = { ...folder, ...updatedFolder };
                if (!folder.u) {
                    folder.u = "0";
                }
                console.log("[updateChildFolder]", folder);
                return folder;
            } else if (folder.children && folder.children.length > 0) {
                folder = MailUtils.updateChildFolder(folder, updatedFolder);
            }
            return folder;
        });
        return parent;
    }

    static addChildFolder(parent: MailFolder, newFolder: any): void {
        if (parent.id === newFolder.l) {
            if (!parent.children) {
                parent.children = [newFolder as MailFolder];
            } else if (!parent.children.find(fd => fd.id === newFolder.id)) {
                parent.children.push(newFolder as MailFolder);
                parent.children = _.sortBy(parent.children, f => f.name.toLowerCase());
            }
        } else if (parent.children && parent.children.length > 0) {
            parent.children = parent.children.map(folder => {
                MailUtils.addChildFolder(folder, newFolder);
                return folder;
            });
        }
    }

    static removeChildFolder(parent: MailFolder, deletedFolder: any): void {
        if (parent.id === deletedFolder.l) {
            _.remove(parent.children, {id: deletedFolder.id});
        } else if (parent.children && parent.children.length > 0) {
            parent.children = parent.children.map(folder => {
                MailUtils.removeChildFolder(folder, deletedFolder);
                return folder;
            });
        }
    }

    /*
       * Convert an array of big-endian words to a hex string.
       */
    static binb2hex(binarray) {
        /* tslint:disable */
        const hex_tab = this.hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
        let str = "";
        for (let i = 0; i < binarray.length * 4; i++) {
            str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
                hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
        }
        return str;
    }

    /*
         * Add integers, wrapping at 2^32. This uses 16-bit operations internally
         * to work around bugs in some JS interpreters.
    */
    static safe_add(x, y) {
        /* tslint:disable */
        const lsw = (x & 0xFFFF) + (y & 0xFFFF);
        const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xFFFF);
    }


    /*
     * Calculate the SHA-1 of an array of big-endian words, and a bit length
     */
    static core_sha1(x, len) {
        /* append padding */
        /* tslint:disable */
        x[len >> 5] |= 0x80 << (24 - len % 32);
        x[((len + 64 >> 9) << 4) + 15] = len;

        const w = Array(80);
        let a = 1732584193;
        let b = -271733879;
        let c = -1732584194;
        let d = 271733878;
        let e = -1009589776;

        for (let i = 0; i < x.length; i += 16) {
            const olda = a;
            const oldb = b;
            const oldc = c;
            const oldd = d;
            const olde = e;
            for (let j = 0; j < 80; j++) {
                if (j < 16) {
                    w[j] = x[i + j];
                } else {
                    w[j] = this.rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
                }
                const t = this.safe_add(this.safe_add(this.rol(a, 5), this.sha1_ft(j, b, c, d)),
                    this.safe_add(this.safe_add(e, w[j]), this.sha1_kt(j)));
                e = d;
                d = c;
                c = this.rol(b, 30);
                b = a;
                a = t;
            }

            a = this.safe_add(a, olda);
            b = this.safe_add(b, oldb);
            c = this.safe_add(c, oldc);
            d = this.safe_add(d, oldd);
            e = this.safe_add(e, olde);
        }
        return Array(a, b, c, d, e);

    }

    static str2binb(str) {
        const bin = Array();
        const mask = (1 << this.chrsz) - 1;
        for (let i = 0; i < str.length * this.chrsz; i += this.chrsz) {
            bin[i >> 5] |= (str.charCodeAt(i / this.chrsz) & mask) << (32 - this.chrsz - i % 32);
        }
        return bin;
    }

    /*
        * Bitwise rotate a 32-bit number to the left.
    */
    static rol(num, cnt) {
        return (num << cnt) | (num >>> (32 - cnt));
    }

    /*
         * Perform the appropriate triplet combination function for the current
         * iteration
    */
    static sha1_ft(t, b, c, d) {
        if (t < 20) {
            return (b & c) | ((~b) & d);
        }
        if (t < 40) {
            return b ^ c ^ d;
        }
        if (t < 60) {
            return (b & c) | (b & d) | (c & d);
        }
        return b ^ c ^ d;
    }

    /*
         * Determine the appropriate additive constant for the current iteration
    */
    static sha1_kt(t) {
        return (t < 20) ? 1518500249 : (t < 40) ? 1859775393 :
            (t < 60) ? -1894007588 : -899497514;
    }

    static hex_sha1(s) {
        return this.binb2hex(
            this.core_sha1(
                this.str2binb(s),
                s.length * this.chrsz
            )
        ) + "@zimbra";
    }

    static capitalizeFirstLetter(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    static getSaveDraftPollingInterval(pollingTime: string, defaultTime: string): number {
        let pollingInterval: number = 0;
        const MIN_INTERVAL: number = 30000;
        if (pollingTime !== undefined && pollingTime !== null && pollingTime !== "" ) {
            if( pollingTime.indexOf("d") !== -1 || pollingTime.indexOf("D") !== -1 ) {
                pollingInterval = parseInt(pollingTime,10) * 24 * 60 * 60 * 1000;
            }else if( pollingTime.indexOf("H") !== -1 || pollingTime.indexOf("h") !== -1 ) {
                pollingInterval = parseInt(pollingTime,10) * 60 * 60 *1000;
            }else if( pollingTime.indexOf("m") !== -1 || pollingTime.indexOf("M") !== -1 ) {
                pollingInterval = parseInt(pollingTime,10) * 60 * 1000;
            }else if( pollingTime.indexOf("s") !==-1 || pollingTime.indexOf("S") !== -1 ) {
                const secondInterval = parseInt(pollingTime,10) * 1000 ;
                pollingInterval = secondInterval < MIN_INTERVAL ? MIN_INTERVAL : secondInterval;
            }
            return pollingInterval < MIN_INTERVAL ? MIN_INTERVAL : pollingInterval;
        } else {
            return MIN_INTERVAL;
        }
    }

    static getFileAndIcon(contentType: string): any {
        let icon = "";
        let isImage = false;
        switch (contentType) {
            case "application/pdf":
                icon = "mdi-file-pdf-box";
                break;
            case "application/zip":
                icon = "mdi-zip-box";
                break;
            case "application/msword":
                icon = "mdi-file-word-box";
                break;
            case "application/vnd.ms-excel":
                icon = "mdi-file-excel-box"
                break;
            case "image/png":
            case "image/jpg":
            case "image/jpeg":
                isImage = true;
                break;
            case "image/gif":
                icon = "mdi-file";
                break;
            case "text/plain":
                icon = "mdi-file-document-box"
                break;
            case "application/vnd.ms-powerpoint":
                icon = "mdi-file-powerpoint-box"
                break;
            case "video/x-flv":
            case "video/mp4":
            case "application/x-mpegURL":
            case "video/MP2T":
            case "video/3gpp":
            case "video/quicktime":
            case "video/x-msvideo":
            case "video/x-ms-wmv":
            case "video/ogg":
                icon = "mdi-play";
                break;
            default:
                icon = "mdi-file"
        }
        return { icon: icon, isImage: isImage };
    }

    static _flatten(node, list) {
        const nodeName = node && node.nodeName.toLowerCase();
        if (this.IGNORE_NODE[nodeName]) {
            return;
        }
        list.push(node);
        const children = node.childNodes || [];
        for (let i = 0; i < children.length; i++) {
            this._flatten(children[i], list);
        }
    }

    static _checkNodeContent(node) {
        const content = node.textContent || "";
        if (!this._NON_WHITESPACE.test(content) || content.length > 200) {
            return null;
        }

        const type = this._getLineType(content);
        return (type === this.ORIG_SEP_STRONG || type === this.ORIG_WROTE_STRONG) ? type : null;
    }

    static _checkNode(el) {
        if (!el) { return null; }
        const nodeName = el.nodeName.toLowerCase();
        let type = null;
        if (nodeName === "#text") {
            const content = this.trim(el.nodeValue);
            if (this._NON_WHITESPACE.test(content)) {
                type = this._getLineType(content);
            }
        } else if (nodeName === "hr") {
            if (el.id === this.HTML_SEP_ID || (el.size === "2" && el.width === "100%" && el.align === "center")) {
                type = this.ORIG_SEP_STRONG;
            } else {
                type = this.ORIG_LINE;
            }
        } else if (nodeName === "pre") {
            type = this._checkNodeContent(el);
        } else if (nodeName === "div") {
            if (el.className === "OutlookMessageHeader" || el.className === "gmail_quote") {
                type = this.ORIG_SEP_STRONG;
            }
            type = type || this._checkNodeContent(el);
        } else if (nodeName === "span") {
            type = type || this._checkNodeContent(el);
        } else if (nodeName === "img") {
            type = this.ORIG_UNKNOWN;
        } else if (nodeName === "blockquote") {
            type = this.ORIG_QUOTED;
        }
        return type;
    }

    static _prune(node, clipNode) {
        const p = node && node.parentNode;
        while (p && p.lastChild && p.lastChild !== node) {
            p.removeChild(p.lastChild);
        }
        if (clipNode && p && p.lastChild === node) {
            p.removeChild(p.lastChild);
        }
        const nodeName = p && p.nodeName.toLowerCase();
        if (p && nodeName !== "body" && nodeName !== "html") {
            this._prune(p, false);
        }
    }

    static trim(str, compress?, space?) {
        if (!str) { return ""; }
        let trim_re = this.TRIM_RE;
        let compress_re = this.COMPRESS_RE;
        if (space) {
            trim_re = new RegExp("^" + space + "+|" + space + "+$", "g");
            compress_re = new RegExp(space + "+", "g");
        } else {
            space = " ";
        }
        str = str.replace(trim_re, "");
        if (compress) {
            str = str.replace(compress_re, space);
        }
        return str;
    }

    static _getLineType(testLine) {
        let type = this.ORIG_UNKNOWN;
        for (let j = 0; j < this.MSG_REGEXES.length; j++) {
            const msgTest = this.MSG_REGEXES[j];
            const regex = msgTest.regex;
            if (regex.test(testLine.toLowerCase())) {
                if (msgTest.type == this.ORIG_QUOTED && /^\s*\|.*\|\s*$/.test(testLine)) {
                    continue;
                }
                type = msgTest.type;
                break;
            }
        }
        if (type === this.ORIG_UNKNOWN) {
            const m = testLine.match(/(\w+):$/);
            const verb = m && m[1] && m[1].toLowerCase();
            if (verb) {
                let points = 0;
                points = points ? 5 : (verb === "changed") ? 0 : 2;
                if (this.ORIG_EMAIL_RE.test(testLine)) {
                    points += 4;
                }
                if (this.ORIG_DATE_RE.test(testLine)) {
                    points += 3;
                }
                if (points >= 7) {
                    type = this.ORIG_WROTE_STRONG;
                }
                else if (points >= 5) {
                    type = this.ORIG_WROTE_WEAK;
                }
            }
        }
        return type;
    }
    static DOC_TAG_REGEX = /<\/?(html|head|body)>/gi;
    static trimHtml(html) {
        if (!html) {
            return '';
        }
        let trimmedHtml = html;

        // remove doc-level tags if they don't have attributes
        trimmedHtml = trimmedHtml.replace(this.DOC_TAG_REGEX, '');

        // some editors like to put every <br> in a <div>
        trimmedHtml = trimmedHtml.replace(/<div><br ?\/?><\/div>/gi, '<br>');

        // remove leading/trailing <br>
        let len = 0;
        while (trimmedHtml.length !== len && (/^<br ?\/?>/i.test(trimmedHtml) || /<br ?\/?>$/i.test(trimmedHtml))) {
            len = trimmedHtml.length;	// loop prevention
            trimmedHtml = trimmedHtml.replace(/^<br ?\/?>/i, "").replace(/<br ?\/?>$/i, "");
        }

        // remove trailing <br> trapped in front of closing tags
        let m = trimmedHtml && trimmedHtml.match(/((<br ?\/?>)+)((<\/\w+>)+)$/i);
        if (m && m.length) {
            const regex = new RegExp(m[1] + m[3] + '$', 'i');
            trimmedHtml = trimmedHtml.replace(regex, m[3]);
        }

        // remove empty internal <div> containers
        trimmedHtml = trimmedHtml.replace(/(<div><\/div>)+/gi, '');

        return this.trim(trimmedHtml);
    }

    static getQuoteOnly(lines, index): string {
        const results = lines.splice(index, lines.length - 1);
        return results.join("\r\n");
    }

    static getBodyOnly(lines, index): string {
        const results = lines.splice(0, index);
        return results.join("\r\n");
    }

    static getOriginalContent(text, isHtml?: boolean) {
        if (!text) { return ""; }
        if (isHtml) {
            return this._getOriginalHtmlContent(text);
        }

        let results = [];
        let lines = text.split(this.SPLIT_RE);

        let curType, curBlock = [], count = {}, isMerged, unknownBlock, isBugzilla = false;
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            let testLine = this.trim(line);

            // blank lines are just added to the current block
            if (!this._NON_WHITESPACE.test(testLine)) {
                curBlock.push(line);
                continue;
            }

            // Bugzilla summary looks like QUOTED; it should be treated as UNKNOWN
            if ((testLine.indexOf("| DO NOT REPLY") === 0) && (lines[i + 2].indexOf("bugzilla") !== -1)) {
                isBugzilla = true;
            }

            let type = this._getLineType(testLine);
            if (type === this.ORIG_QUOTED) {
                type = isBugzilla ? this.ORIG_UNKNOWN : type;
            }
            else {
                isBugzilla = false;
            }

            // WROTE can stretch over two lines; if so, join them into one line
            let nextLine = lines[i + 1];
            isMerged = false;

            // if (nextLine && (type === this.ORIG_UNKNOWN) && (this.ORIG_INTRO_RE.test(testLine) || this.ORIG_INTRO_DE_RE.test(testLine))) {
            //     return this.getBodyOnly(lines, i);
            // }

            if (nextLine && (type === this.ORIG_UNKNOWN) && (this.ORIG_INTRO_RE.test(testLine) || this.ORIG_INTRO_DE_RE.test(testLine)) && nextLine.match(/\w+:$/)) {
                testLine = [testLine, nextLine].join(" ");
                type = this._getLineType(testLine);
                isMerged = true;
            }

            // LINE sometimes used as delimiter; if HEADER follows, lump it in with them
            if (type === this.ORIG_LINE) {
                let j = i + 1;
                nextLine = lines[j];
                while (!this._NON_WHITESPACE.test(nextLine) && j < lines.length) {
                    nextLine = lines[++j];
                }
                let nextType = nextLine && this._getLineType(nextLine);
                if (nextType === this.ORIG_HEADER) {
                    type = this.ORIG_HEADER;
                }
                else {
                    type = this.ORIG_UNKNOWN;
                }
            }

            // see if we're switching to a new type; if so, package up what we have so far
            if (curType) {
                if (curType !== type) {
                    results.push({type:curType, block:curBlock});
                    unknownBlock = (curType === this.ORIG_UNKNOWN) ? curBlock : unknownBlock;
                    count[curType] = count[curType] ? count[curType] + 1 : 1;
                    curBlock = [];
                    curType = type;
                }
            }
            else {
                curType = type;
            }

            if (isMerged && (type === this.ORIG_WROTE_WEAK || type === this.ORIG_WROTE_STRONG)) {
                curBlock.push(line);
                curBlock.push(nextLine);
                i++;
                isMerged = false;
            }
            else {
                curBlock.push(line);
            }
        }

        // Handle remaining content
        if (curBlock.length) {
            results.push({type:curType, block:curBlock});
            unknownBlock = (curType === this.ORIG_UNKNOWN) ? curBlock : unknownBlock;
            count[curType] = count[curType] ? count[curType] + 1 : 1;
        }

        // Now it's time to analyze all these blocks that we've classified

        // Check for UNKNOWN followed by HEADER
        let first = results[0], second = results[1];
        if (first && first.type === this.ORIG_UNKNOWN && second && (second.type === this.ORIG_HEADER || second.type === this.ORIG_WROTE_STRONG)) {
            let originalText = this._getTextFromBlock(first.block);
            if (originalText) {
                let third = results[2];
                if (third && third.type === this.ORIG_UNKNOWN) {
                    let originalThirdText = this._getTextFromBlock(third.block);
                    if (originalThirdText && originalThirdText.indexOf(this.NOTES_SEPARATOR) !== -1) {
                        return originalText + originalThirdText;
                    }
                }
                return originalText;
            }
        }

        // check for special case of WROTE preceded by UNKNOWN, followed by mix of UNKNOWN and QUOTED (inline reply)
        let originalText = this._checkInlineWrote(count, results);
        if (originalText) {
            return originalText;
        }

        // If we found quoted content and there's exactly one UNKNOWN block, return it.
        if (count[this.ORIG_UNKNOWN] === 1 && count[this.ORIG_QUOTED] > 0) {
            originalText = this._getTextFromBlock(unknownBlock);
            if (originalText) {
                return originalText;
            }
        }

        // If we have a STRONG separator (eg "--- Original Message ---"), consider it authoritative and return the text that precedes it
        if (count[this.ORIG_SEP_STRONG] > 0) {
            let block = [];
            for (let i = 0; i < results.length; i++) {
                let result = results[i];
                if (result.type === this.ORIG_SEP_STRONG) {
                    break;
                }
                block = block.concat(result.block);
            }
            originalText = this._getTextFromBlock(block);
            if (originalText) {
                return originalText;
            }
        }

        return text;
    }

    /**
     * A "... wrote:" separator is not quite as authoritative, since the user might be replying inline. If we have
     * a single UNKNOWN block before the WROTE separator, return it unless there is a mix of QUOTED and UNKNOWN
     * following the separator, except if there's only a single unknown block after the separator and it comes last.
     *
     * @private
     */
    static _checkInlineWrote (count, results) {
        if (count[this.ORIG_WROTE_STRONG] > 0) {
            let unknownBlock, foundSep = false, afterSep = {};
            for (let i = 0; i < results.length; i++) {
                let result = results[i], type = result.type;
                if (type === this.ORIG_WROTE_STRONG) {
                    foundSep = true;
                }
                else if (type === this.ORIG_UNKNOWN && !foundSep) {
                    if (unknownBlock) {
                        return null;
                    }
                    else {
                        unknownBlock = result.block;
                    }
                }
                else if (foundSep) {
                    afterSep[type] = true;
                }
            }

            let mixed = (afterSep[this.ORIG_UNKNOWN] && afterSep[this.ORIG_QUOTED]);
            const endsWithUnknown = (count[this.ORIG_UNKNOWN] === 2 && results[results.length - 1].type === this.ORIG_UNKNOWN);
            if (unknownBlock && (!mixed || endsWithUnknown)) {
                const originalText = this._getTextFromBlock(unknownBlock);
                if (originalText) {
                    return originalText;
                }
            }
        }
    }

    static _getTextFromBlock(block) {
        if (!(block && block.length)) { return null; }
        var originalText = block.join("\n") + "\n";
        originalText = originalText.replace(/\s+$/, "\n");
        return (this._NON_WHITESPACE.test(originalText)) ? originalText : null;
    }

    static _getOriginalHtmlContent(text) {
        let htmlNode = document.createElement("div");
        htmlNode.innerHTML = text;
        while (this.SCRIPT_REGEX.test(text)) {
            text = text.replace(this.SCRIPT_REGEX, "");
        }
        let done = false, nodeList = [];
        this._flatten(htmlNode, nodeList);
        let ln = nodeList.length, i, results = [], count = {}, el, prevEl, nodeName, type, prevType, sepNode;
        for (i = 0; i < ln; i++) {
            el = nodeList[i];
            if (el.nodeType === 1) {
                el.normalize();
            }
            nodeName = el.nodeName.toLowerCase();
            type = this._checkNode(nodeList[i]);
            if (type === this.ORIG_UNKNOWN && el.nodeName === "#text" &&
                (this.ORIG_DATE_RE.test(el.nodeValue) || this.ORIG_INTRO_RE.test(el.nodeValue)  || this.ORIG_INTRO_DE_RE.test(el.nodeValue))) {
                let str = el.nodeValue;
                for (let j = 1; j < 10; j++) {
                    const el1 = nodeList[i + j];
                    if (el1 && el1.nodeName === "#text") {
                        str += el1.nodeValue;
                        if (/:$/.test(str)) {
                            type = this._getLineType(this.trim(str));
                            if (type === this.ORIG_WROTE_STRONG) {
                                i = i + j;
                                break;
                            }
                        }
                    }
                }
            }
            if (type !== null) {
                results.push({ type: type, node: el, nodeName: nodeName });
                count[type] = count[type] ? count[type] + 1 : 1;
                if (type === this.ORIG_SEP_STRONG || type === this.ORIG_WROTE_STRONG) {
                    sepNode = el;
                    done = true;
                    break;
                }
                if (type === this.ORIG_HEADER && prevType === this.ORIG_LINE) {
                    sepNode = prevEl;
                    done = true;
                    break;
                }
                prevEl = el;
                prevType = type;
            }
        }
        if (sepNode) {
            this._prune(sepNode, true);
        }
        const result = done && htmlNode.textContent ? htmlNode.innerHTML : text;
        return result;
    }

    static replaceDuplicateDiv(text: string): string {
        text = text.replace("</div></div>", "</div>");
        if (text.indexOf("</div></div>") !== -1) {
            text = this.replaceDuplicateDiv(text);
        }
        return text;
    }

    static getQuotedTextOnly(message) {
        const body = this.getEmailBody(message);
        const contentType = this.getContentType(message);
        const originalContent = this.getOriginalContent(body, contentType === "text/html");
        // console.log("[getQuotedTextOnly] body", body, body.length);
        // console.log("[getQuotedTextOnly] originalContent", originalContent, originalContent.length);
        if (originalContent.length >= body.length - 5) {
            return "";
        }
        if (body !== undefined && body !== null && body !== "") {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(body, "text/html");
            htmlDoc.getElementsByTagName("body")[0];
            const htmlBody = htmlDoc.body.innerHTML;
            const indexList = [{
                name: "zimbra",
                index: htmlBody.indexOf("id=\"zwchr\">")
            },
            {
                name: "gmail",
                index: htmlBody.indexOf("<div class=\"gmail_quote\">")
            },
            {
                name: "original",
                index: htmlBody.indexOf("Original Message")
            },
            {
                name: "original_de",
                index: htmlBody.indexOf("Originalnachricht")
            },
            {
                name: "forward",
                index: htmlBody.indexOf("Forwarded Message")
            },
            {
                name: "forward_de",
                index: htmlBody.indexOf("Weitergeleitete Nachricht")
            },
            {
                name: "blockquote",
                index: htmlBody.indexOf("<blockquote style=\"margin: 0 0 20px 0;\">")
            }];
            let foundIndex = indexList.filter(v => v.index !== -1);
            foundIndex = _.orderBy(foundIndex, "index", "asc");
            if (foundIndex.length > 0 && contentType === "text/html") {
                const replaceBy = foundIndex[0]; // get quote from smallest index
                let quotedText = "";
                switch (replaceBy.name) {
                    case "zimbra": {
                        htmlDoc.body.innerHTML = "";
                        const zimbraQuoted = htmlBody.split("id=\"zwchr\">");
                        zimbraQuoted.splice(1).forEach(item => {
                            const div = htmlDoc.createElement("div");
                            div.setAttribute("class", "quoted_text");
                            div.innerHTML = item;
                            htmlDoc.body.appendChild(div);
                        });
                        quotedText = htmlDoc.body.innerHTML;
                        break;
                    }
                    case "gmail": {
                        htmlDoc.body.innerHTML = "";
                        const zimbraQuoted = htmlBody.split("<div class=\"gmail_quote\">");
                        zimbraQuoted.splice(1).forEach(item => {
                            const div = htmlDoc.createElement("div");
                            div.setAttribute("class", "quoted_text");
                            div.innerHTML = item;
                            htmlDoc.body.appendChild(div);
                        });
                        quotedText = htmlDoc.body.innerHTML;
                        break;
                    }
                    case "original": {
                        const zimbraQuoted = htmlBody.split(/\s*--+\s*Original Message\s*--+\s*/ig);
                        htmlDoc.body.innerHTML = "";
                        zimbraQuoted.splice(1).forEach(item => {
                            const div = htmlDoc.createElement("div");
                            div.innerHTML = item;
                            htmlDoc.body.appendChild(div);
                        });
                        quotedText = "----- Original message -----" + htmlDoc.body.innerHTML;
                        break;
                    }
                    case "original_de": {
                        const zimbraQuoted = htmlBody.split(/\s*--+\s*Originalnachricht\s*--+\s*/ig);
                        htmlDoc.body.innerHTML = "";
                        zimbraQuoted.splice(1).forEach(item => {
                            const div = htmlDoc.createElement("div");
                            div.innerHTML = item;
                            htmlDoc.body.appendChild(div);
                        });
                        quotedText = "----- Originalnachricht -----" + htmlDoc.body.innerHTML;
                        break;
                    }
                    case "forward": {
                        const zimbraQuoted = htmlBody.split(/\s*--+\s*Forwarded Message\s*--+\s*/ig);
                        htmlDoc.body.innerHTML = "";
                        zimbraQuoted.splice(1).forEach(item => {
                            const div = htmlDoc.createElement("div");
                            div.innerHTML = item;
                            htmlDoc.body.appendChild(div);
                        });
                        quotedText = "----- Forwarded message -----" + htmlDoc.body.innerHTML;
                        break;
                    }
                    case "forward_de": {
                        const zimbraQuoted = htmlBody.split(/\s*--+\s*Weitergeleitete Nachricht\s*--+\s*/ig);
                        htmlDoc.body.innerHTML = "";
                        zimbraQuoted.splice(1).forEach(item => {
                            const div = htmlDoc.createElement("div");
                            div.innerHTML = item;
                            htmlDoc.body.appendChild(div);
                        });
                        quotedText = "----- Weitergeleitete Nachricht -----" + htmlDoc.body.innerHTML;
                        break;
                    }
                    case "blockquote": {
                        const zimbraQuoted = htmlBody.split("<blockquote style=\"margin: 0 0 20px 0;\">");
                        htmlDoc.body.innerHTML = "";
                        zimbraQuoted.splice(1).forEach(item => {
                            const div = htmlDoc.createElement("div");
                            div.innerHTML = item;
                            htmlDoc.body.appendChild(div);
                        });
                        quotedText = htmlDoc.body.innerHTML;
                        break;
                    }
                    default: break;
                }
                return quotedText;
            } else if (htmlDoc.getElementsByClassName("OutlookMessageHeader").length > 0 && htmlDoc.getElementsByClassName("OutlookMessageHeader")[0]) {
                const zimbraQuoted = htmlBody.split("class=\"OutlookMessageHeader\">");
                htmlDoc.body.innerHTML = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.setAttribute("class", "quoted_text");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return htmlDoc.body.innerHTML;
            } else {
                const originalMsg = parser.parseFromString(originalContent, "text/html");
                const originalHTML = this.replaceDuplicateDiv(originalMsg.body.innerHTML);
                const bodyHTML = this.replaceDuplicateDiv(htmlDoc.body.innerHTML);
                const originalText = bodyHTML.slice(0, originalHTML.length);
                let quotedText = bodyHTML.split(originalText)[1];
                if (quotedText === "" || quotedText === undefined) {
                    quotedText = MailUtils.plainTextToHTML(bodyHTML.slice(originalHTML.length, bodyHTML.length));
                }
                htmlDoc.body.innerHTML = "";
                const div = htmlDoc.createElement("div");
                div.setAttribute("class", "quoted_text");
                if (contentType !== "text/html") {
                    quotedText = MailUtils.plainTextToHTML(quotedText);
                }
                div.innerHTML = quotedText;
                htmlDoc.body.appendChild(div);
                quotedText = htmlDoc.body.innerHTML;
                if (contentType !== "text/html") {
                    return this.plainTextToHTML(quotedText);
                }
                return quotedText;
            }
        }
        return "";
    }

    static getQuotedText(body: string): string {
        if (body !== undefined && body !== null && body !== "") {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(body, "text/html");
            htmlDoc.getElementsByTagName("body")[0];
            const htmlBody = htmlDoc.body.innerHTML;

            if (htmlBody.indexOf("id=\"zwchr\">") !== -1) {
                htmlDoc.body.innerHTML = "";
                const zimbraQuoted = htmlBody.split("id=\"zwchr\">");
                let allText = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.setAttribute("class", "quoted_text");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return htmlDoc.body.innerHTML;
            } else if (htmlBody.indexOf("<div class=\"gmail_quote\">") !== -1) {
                htmlDoc.body.innerHTML = "";
                const zimbraQuoted = htmlBody.split("<div class=\"gmail_quote\">");
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.setAttribute("class", "quoted_text");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return htmlDoc.body.innerHTML;
            } else if (htmlDoc.getElementsByClassName("OutlookMessageHeader").length > 0 && htmlDoc.getElementsByClassName("OutlookMessageHeader")[0]) {
                const zimbraQuoted = htmlBody.split("class=\"OutlookMessageHeader\">");
                htmlDoc.body.innerHTML = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.setAttribute("class", "quoted_text");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return htmlDoc.body.innerHTML;
            }  else if (htmlBody.indexOf("<blockquote style=\"margin: 0 0 20px 0;\">") !== -1) {
                const zimbraQuoted = htmlBody.split("<blockquote style=\"margin: 0 0 20px 0;\">");
                htmlDoc.body.innerHTML = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return htmlDoc.body.innerHTML;
            } else if (/\s*--+\s*Forwarded Message\s*--+\s*/ig.test(htmlBody)) {
                const zimbraQuoted = htmlBody.split(/\s*--+\s*Forwarded Message\s*--+\s*/ig);
                htmlDoc.body.innerHTML = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return "----- Forwarded message -----" + htmlDoc.body.innerHTML;
            } else if (/\s*--+\s*Weitergeleitete Nachricht\s*--+\s*/ig.test(htmlBody)) {
                const zimbraQuoted = htmlBody.split(/\s*--+\s*Weitergeleitete Nachricht\s*--+\s*/ig);
                htmlDoc.body.innerHTML = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return "---- Weitergeleitete Nachricht ----" + htmlDoc.body.innerHTML;
            } else {
                const originalMsg = parser.parseFromString(this._getOriginalHtmlContent(body), "text/html");
                const quotedText = htmlDoc.body.innerHTML.split(originalMsg.body.innerHTML)[1];
                if (quotedText === "" || quotedText === undefined) {
                    return "";
                }
                htmlDoc.body.innerHTML = "";
                const div = htmlDoc.createElement("div");
                div.setAttribute("class", "quoted_text");
                div.innerHTML = quotedText;
                htmlDoc.body.appendChild(div);
                return htmlDoc.body.innerHTML;
            }
        }
        return "";
    }

    static getLastMessageText(message: Message, toSendOnly?: boolean): string {
        const body = MailUtils.addClassToAnchor(MailUtils.replaceLinkToAnchor(MailUtils.getEmailBody(message)));
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(body, "text/html");
        htmlDoc.getElementsByTagName("body")[0];
        const htmlBody = htmlDoc.body.innerHTML;
        if (/\s*--+\s*Original Message\s*--+\s*/ig.test(htmlBody)) {
            const zimbraQuoted = htmlBody.split(/\s*--+\s*Original Message\s*--+\s*/ig);
            htmlDoc.body.innerHTML = "";
                const div = htmlDoc.createElement("div");
                div.innerHTML = zimbraQuoted[0];
                htmlDoc.body.appendChild(div);
            return htmlDoc.body.innerHTML;
        } else if (/\s*--+\s*Originalnachricht\s*--+\s*/ig.test(htmlBody)) {
            const zimbraQuoted = htmlBody.split(/\s*--+\s*Originalnachricht\s*--+\s*/ig);
            htmlDoc.body.innerHTML = "";
            zimbraQuoted.splice(1).forEach(item => {
                const div = htmlDoc.createElement("div");
                div.innerHTML = item;
                htmlDoc.body.appendChild(div);
            });
            return htmlDoc.body.innerHTML;
        } else {
            return MailUtils.getEmailBody(message, toSendOnly);
        }
    }

    static getOriginalQuotedText(body: string): string {
        if (body !== undefined && body !== null && body !== "") {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(body, "text/html");
            htmlDoc.getElementsByTagName("body")[0];
            const htmlBody = htmlDoc.body.innerHTML;

            if (htmlBody.indexOf("id=\"zwchr\">") !== -1) {
                htmlDoc.body.innerHTML = "";
                const zimbraQuoted = htmlBody.split("id=\"zwchr\">");
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.setAttribute("class", "quoted_text");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return htmlDoc.body.innerHTML;
            } else if (/\s*--+\s*Original Message\s*--+\s*/ig.test(htmlBody)) {
                const zimbraQuoted = htmlBody.split(/\s*--+\s*Original Message\s*--+\s*/ig);
                htmlDoc.body.innerHTML = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return "----- Original message -----" + htmlDoc.body.innerHTML;
            } else if (/\s*--+\s*Originalnachricht\s*--+\s*/ig.test(htmlBody)) {
                const zimbraQuoted = htmlBody.split(/\s*--+\s*Originalnachricht\s*--+\s*/ig);
                htmlDoc.body.innerHTML = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return "---- Originalnachricht ----" + htmlDoc.body.innerHTML;
            } else if (htmlBody.indexOf("id=\"zwchr\">") !== -1) {
                htmlDoc.body.innerHTML = "";
                const zimbraQuoted = htmlBody.split("id=\"zwchr\">");
                let allText = "";
                zimbraQuoted.splice(1).forEach(item => {
                    const div = htmlDoc.createElement("div");
                    div.setAttribute("class", "quoted_text");
                    div.innerHTML = item;
                    htmlDoc.body.appendChild(div);
                });
                return htmlDoc.body.innerHTML;
            } else {
                const originalMsg = parser.parseFromString(this._getOriginalHtmlContent(body), "text/html");
                const quotedText = htmlDoc.body.innerHTML.split(originalMsg.body.innerHTML)[1];
                if (quotedText === "" || quotedText === undefined) {
                    return "";
                }
                htmlDoc.body.innerHTML = "";
                const div = htmlDoc.createElement("div");
                div.setAttribute("class", "quoted_text");
                div.innerHTML = quotedText;
                htmlDoc.body.appendChild(div);
                return htmlDoc.body.innerHTML;
            }
        }
        return "";
    }

    static getMailTextBodyWithoutSignature(mailBody: string): string {
        if (mailBody !== undefined && mailBody !== "" && mailBody !== null) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(mailBody, "text/html");
            const htmlBody = htmlDoc.getElementsByTagName("body")[0];
            if (htmlBody.getElementsByTagName("signature")[0] !== null &&
                htmlDoc.getElementsByTagName("signature")[0] !== undefined) {
                htmlBody.removeChild(htmlDoc.getElementsByTagName("signature")[0]);
            }
            return this.HTMLToPlainText(htmlBody.innerHTML);
        }
        return "";
    }

    static getMailSigantureFromBody(mailBody: string): string {
        if (mailBody !== undefined && mailBody !== "" && mailBody !== null) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(mailBody, "text/html");
            const signatureTag = htmlDoc.getElementsByTagName("signature")[0];
            if (signatureTag !== null && signatureTag !== undefined) {
                return "---\n" + this.HTMLToPlainText(signatureTag.innerHTML);
            }
            return "";
        }
        return "";
    }

    static HTMLToPlainText(str: string): string {
        if (str !== undefined && str !== null) {
            return str.replace(/<\/div>/ig, "\n").replace(/<\/p>/ig, "\n").replace(/<[^>]+>/ig, "");
        } else {
            return "";
        }
    }

    static getMailFolders(folders: MailFolder[]): MailFolder[] {
        if (!folders) {
            return [];
        }
        return folders.filter(item => item.view !== "task" && item.view !== "contact" && item.view !== "appointment" && item.view !== "document" && item.absFolderPath !== "/Chats");
    }

    static hasNotifyRecipient(message: Message): EmailInformation {
        return message.e.filter( info => info.t === "n")[0];
    }

    static getPlainText(str: string): string {
        if (str !== undefined && str !== null) {
            return str.replace(/<\/div>/ig, "\n")
            .replace(/<\/p>/ig, "\n")
            .replace(/<br \/>/ig, "\n")
            .replace(/<br>/ig, "\n")
            .replace(/<[^>]+>/ig, "")
        } else {
            return "";
        }
    }

    static spellCheck(node,regexp) {
		switch (node.nodeType) {
			case 1:
				for (let i = node.firstChild; i; i = this.spellCheck(i,regexp)) {}
				node = node.nextSibling;
				break;
			case 3:
				if (!/[^\s\xA0]/.test(node.data)) {
					node = node.nextSibling;
					break;
				}
				let a = null, b = null;
				let result = /^[\s\xA0]+/.exec(node.data);
				if (result) {
					a = node;
					node = node.splitText(result[0].length);
				}
				result = /[\s\xA0]+$/.exec(node.data);
				if (result) {
					b = node.splitText(node.data.length - result[0].length);
				}

				let text = this.hightLightWord(node.data, false,regexp);
				text = text.replace(/^ +/, "&nbsp;").replace(/ +$/, "&nbsp;");
				let div = document.createElement("div");
                div.innerHTML = text;

				if (a) {
					div.insertBefore(a, div.firstChild);
				}
				if (b) {
					div.appendChild(b);
				}

				const p = node.parentNode;
				while (div.firstChild) {
					p.insertBefore(div.firstChild, node);
        }

				div = node.nextSibling;
				p.removeChild(node);
				node = div;
				break;
			default :
				node = node.nextSibling;
		}
		return node;
    }

    static hightLightWord (text, textWhiteSpace,regexp) {
		const wordIds = {};
		const spanIds = {};
		text = textWhiteSpace ? this.convertToHtml(text) : this.htmlEncode(text);
		let m;
		regexp.lastIndex = 0;
		while (m = regexp.exec(text)) {
			const str = m[0];
			const prefix = m[1];
			const word = m[2];
			const suffix = m[3];
			const id = 44;
			spanIds[id] = word;
			if (!wordIds[word])
				wordIds[word] = [];
			wordIds[word].push(id);

			const repl = [
				prefix,
				'<span word="',
				word, '" id="', id, '" class="spell_check_mis_spell">',
				word, '</span>',
				suffix
				].join("");
			text = [
				text.substr(0, m.index),
				repl,
				text.substr(m.index + str.length)
			].join("");
			regexp.lastIndex = m.index + repl.length - suffix.length;
		}
		return text;
    }

    static convertToHtml(str, quotePrefix?, openTag?, closeTag?) {
        openTag = openTag || "<blockquote>";
        closeTag = closeTag || "</blockquote>";
        if (!str) {
            return "";
        }
        str = this.htmlEncode(str);
        if (quotePrefix) {
            const prefix_re = /^(>|&gt;|\|\s+)/;
            let lines = str.split(/\r?\n/);
            let level = 0;
            for (let i = 0; i < lines.length; i++) {
                let line = lines[i];
                if (line.length > 0) {
                    let lineLevel = 0;
                    while (line.match(prefix_re)) {
                        line = line.replace(prefix_re, "");
                        lineLevel++;
                    }
                    while (lineLevel > level) {
                        line = openTag + line;
                        level++;
                    }
                    while (lineLevel < level) {
                        lines[i - 1] = lines[i - 1] + closeTag;
                        level--;
                    }
                }
                lines[i] = line;
            }
            while (level > 0) {
                lines.push(closeTag);
                level--;
            }
            str = lines.join("\n");
        }

        str = str
            .replace(/  /mg, ' &nbsp;')
            .replace(/^ /mg, '&nbsp;')
            .replace(/\t/mg, "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
            .replace(/\r?\n/mg, "<br>");

        return str;
    }

    static htmlEncode(str, includeSpaces?) {
        if (!str) {
            return "";
        }
        if (typeof(str) != "string") {
            str = str.toString ? str.toString() : "";
        }
        if (includeSpaces) {
            return str.replace(/[&]/g, '&amp;').replace(/  /g, ' &nbsp;').replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;');
        } else {
            return str.replace(/[&]/g, '&amp;').replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;');
        }
    }

    static getEmailFromData(message: Message): EmailInformation {
        const from: EmailInformation = message.e.filter(e => e.t === "f")[0];
        if ( from.d === undefined) {
            const email = MailUtils.getEmailFromStorage();
            from.d = email;
            from.a = email;
            from.p = email;
        }
        return from;
    }



    static isZimletEnabledOrMendatory(zimlets: any [] , zimletName: string): boolean {
        let isAvaiableZimlet: boolean = false;
        zimlets.map(item => {
            if (item.zimlet[0].name === zimletName) {
                if (item.zimletContext[0].presence === "enabled" || item.zimletContext[0].presence === "mandatory" ) {
                    isAvaiableZimlet = true;
                }
            }
        });
        return isAvaiableZimlet;
    }

    static stripTags(str, removeContent) {
        if (typeof str !== "string") {
            return "";
        }
        if (removeContent) {
            str = str.replace(/(<(\w+)[^>]*>).*(<\/\2[^>]*>)/, "$1$3");
        }
        return str.replace(/<\/?[^>]+>/gi, "");
    }

    static getLastMessageFromConv(messages: Message[]) {
        let lastMsg: Message;
        lastMsg = messages[0];
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].l !== "3" && messages[i].l !== "6") {
                return messages[i];
            }
        }
        return lastMsg;
    }

    static getEmailFromStorage(): string {
        const profile = MailUtils.getProfileFromStorage();
        if (!!profile) {
            return this.checkEmailArray(profile.email);
        }
        return "";
    }

    static getProfileFromStorage(): any {
        if (!!localStorage.profileUser) {
            return JSON.parse(localStorage.profileUser);
        } else {
            return {};
        }
    }

    static isZimbraFeatureEnabled(pref: Preference[], featureName: string): boolean {
        let isEnabled = true;
        if (pref.length > 0) {
            pref.filter( p => {
                 if (p.key === featureName && p.value === "FALSE") {
                     isEnabled = false;
                 }
            });
        }
        return isEnabled;
    }

    static lightOrDark(color) {
        if (color === "cyan" || color === "yellow" || color === "pink" || color === "orange") {
            return "light";
        }
        // Variables for red, green, blue values
        let r, g, b, hsp;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            r = color[1];
            g = color[2];
            b = color[3];
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {
            return 'light';
        } else {
            return 'dark';
        }
    }

    static hasExternalImages(content: string): boolean {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, "text/html");
        const images = htmlDoc.getElementsByTagName("img");
        let isExternalImage = false;
        for (let i=0;i<images.length;i++) {
            if (images[i].src.indexOf("/api/getAttachment") === -1) {
                isExternalImage = true;
                break;
            }
        }
        return isExternalImage;
    }

    static hideShowExternalImage(content: string, display: string): string {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, "text/html");
        const images = htmlDoc.getElementsByTagName("img");
        for (let i=0;i<images.length;i++) {
            if (images[i].src.indexOf("/api/getAttachment") === -1) {
                images[i].style.display =  display;
            }
        }
        return htmlDoc.body.innerHTML;
    }

    static renderEmoji(text: string): string {
        if (wdtEmojiBundle && wdtEmojiBundle.emoji) {
            wdtEmojiBundle.emoji.replace_mode = "unified";
            wdtEmojiBundle.emoji.allow_native = true;
            return wdtEmojiBundle.emoji.replace_emoticons(text);
        }
        return text;
    }

    static copyToClipboard(stringArray: String[]): void {
        let str = stringArray.join("\n");
          window.Clipboard = (function (window, document, navigator) {
            let textArea,
              copy;
            function isOS() {
              return navigator.userAgent.match(/ipad|iphone/i);
            }
            function createTextArea(text) {
              textArea = document.createElement("textArea");
              textArea.value = text;
              document.body.appendChild(textArea);
            }
            function selectText() {
              let range,
                selection;
              if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
              } else {
                textArea.select();
              }
            }
            function copyToClipboard() {
              document.execCommand("copy");
              document.body.removeChild(textArea);
            }
            copy = function (text) {
              createTextArea(text);
              selectText();
              copyToClipboard();
            };
            return {
              copy: copy
            };
          })(window, document, navigator);
          window.Clipboard.copy(str);
    }

    static replaceSignatureImageURL(content): string {
        const electronService = new ElectronService;
        const isCordovaOrElectron = environment.isCordova || environment.isElectron;
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, "text/html");
        const img = htmlDoc.getElementsByTagName("img");
        for (let i=0;i < img.length; i++) {
            if (!isCordovaOrElectron) {
                let url = img[i].getAttribute("src");
                if (!!url) {
                    url = url.substr(url.indexOf("home"));
                }
                img[i].setAttribute("src", location.origin + "/api/printDocument?url=" + url);
            } else {
                const configURL =  localStorage.getItem(MailConstants.SERVER_URL).trim();
                let url = img[i].getAttribute("src");
                if (!!url) {
                    url = url.substr(url.indexOf("home"));
                }
                img[i].setAttribute("src", configURL + "/api/printDocument?url=" + url);
            }
        }
        return htmlDoc.body.innerHTML;
    }

    static replaceSignatureImageToZimbraImage(content, zimbraURL): string {
        const electronService = new ElectronService;
        const isCordovaOrElectron = environment.isCordova || environment.isElectron;
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(content, "text/html");
        const img = htmlDoc.getElementsByTagName("img");
        for (let i=0; i < img.length; i++) {
            if (!isCordovaOrElectron) {
                let url = img[i].getAttribute("src");
                url = url.replace(location.origin+"/api/printDocument?url=", zimbraURL + "/");
                img[i].setAttribute("src", url);
                const dfsrc = url.substr(url.indexOf("/Briefcase") + 1);
                img[i].setAttribute("dfsrc", "doc:"+ dfsrc);
                const dataMCE = url.substr(url.indexOf("home"))
                img[i].setAttribute("data-mce-src", dataMCE);
            } else {
                const configURL =  localStorage.getItem(MailConstants.SERVER_URL).trim();
                let url = img[i].getAttribute("src");
                url = url.replace(configURL + "/api/printDocument?url=", zimbraURL + "/");
                img[i].setAttribute("src", url);
            }
        }
        return htmlDoc.body.innerHTML;
    }

    static getCurrentLanguage(language: string): string {
        let lang = "en";
        if (!!language) {
            if (language === "en_US" || language === "en_AU" || language === "en_GB" || language === "en") {
                lang = "en";
            } else if (language === "de") {
                lang = "de";
            } else {
                lang = "en";
            }
        }
        return lang;
    }

    static getEmailAddressFrom(message: Message): string {
        const from: EmailInformation = message.e.filter(e => e.t === "f")[0];
        return from.a;
    }

    static isIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
           // Edge (IE 12+) => return version number
           return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    static getMailShareFoldersQuery(folders: MailFolder[]): string {
        const ids: string [] = [];
        folders.map( f => {
          if (f.perm) {
            if (f.id.indexOf(":") !== -1 ) {
              ids.push("inid:" + "\"" + f.id + "\"");
            } else {
              ids.push("inid:" + f.id);
            }
            const children = MailUtils.getChildFolders([f]);
            if (children.length > 0) {
              children.map( c => {
                ids.push("inid:" + "\"" + c.id + "\"");
              });
            }
          }
        });
        ids.push("is:local");
        return ids.join(",").replace(/,/g, " OR ");
      }

    static isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    static getConversationDetailRoute(folderId, conversationId): string {
        let url = "";
        switch (folderId) {
            case MailConstants.FOLDER_ID.INBOX: url = "/mail/inbox/detail/" + conversationId; break;
            case MailConstants.FOLDER_ID.DRAFTS: url = "/mail/drafts/detail/" + conversationId; break;
            case MailConstants.FOLDER_ID.SENT: url = "/mail/sent/detail/" + conversationId; break;
            case MailConstants.FOLDER_ID.STARRED: url = "/mail/starred/detail/" + conversationId; break;
            case MailConstants.FOLDER_ID.TRASH: url = "/mail/trash/detail/" + conversationId; break;
            case MailConstants.FOLDER_ID.JUNK: url = "/mail/junk/detail/" + conversationId; break;
            default: url = `/mail/folder/${folderId}/detail/${conversationId}`; break;
        }
        return url;
    }

    static getMessageDetailRoute(folderId, messageId): string {
        let url = "";
        switch (folderId) {
            case MailConstants.FOLDER_ID.INBOX: url = "/mail/inbox/detail/m/" + messageId; break;
            case MailConstants.FOLDER_ID.DRAFTS: url = "/mail/drafts/detail/m/" + messageId; break;
            case MailConstants.FOLDER_ID.SENT: url = "/mail/sent/detail/m/" + messageId; break;
            case MailConstants.FOLDER_ID.STARRED: url = "/mail/starred/detail/m/" + messageId; break;
            case MailConstants.FOLDER_ID.TRASH: url = "/mail/trash/detail/m/" + messageId; break;
            case MailConstants.FOLDER_ID.JUNK: url = "/mail/junk/detail/m/" + messageId; break;
            default: url = `/mail/folder/${folderId}/detail/m/${messageId}`; break;
        }
        return url;
    }

    static isSystemFolder(folder: MailFolder): boolean {
        if ((!!folder && folder !== null) && (folder.name === MailConstants.INBOX_FOLDER_TITLE ||
          folder.name === MailConstants.DRAFTS_FOLDER_TITLE ||
          folder.name === MailConstants.INBOX_FOLDER_TITLE ||
          folder.name === MailConstants.SENT_FOLDER_TITLE ||
          folder.name === MailConstants.TRASH_FOLDER_TITLE ||
          folder.name === MailConstants.SPAM_FOLDER_TITLE ||
          folder.name === MailConstants.CHATS_FOLDER_TITLE ||
          folder.name === MailConstants.STARRED_FOLDER_TITLE)) {
          return true;
        }
        return false;
    }

    static isOnAndroid() {
        return typeof device !== "undefined" && !!device.platform && device.platform.toUpperCase() === "ANDROID";
    }
}
